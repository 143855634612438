import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";

import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  notification,
  Alert,
  Select,
} from "antd";

// import { toggleModal, create } from "../../redux/actions/manageOperaotrs";
import {
  toggleModal,
  create,
  update,
  setForm,
  fetchAllAdminAccounts,
} from "../../redux/actions/adminAccount";

// Utilities
import renderInput from "../utilities/inputForms";
import { getRegionByProvince } from "../utilities/getPhillipineMajorIslands";

const CreateAdminAccount = () => {
  const dispatch = useDispatch();

  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [isProvinceEdited, setIsProvinceEdited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [isBarangayEdited, setIsBarangayEdited] = useState(false);
  const [isAddScopeArea, setAddScopeArea] = useState(false);
  const [selectedMunicipality, setSelectedMunicipality] = useState([]);
  const { modal, loading, error, form, createLoading, formLoading } =
    useSelector(
      ({ adminAccountReducer }) => ({
        modal: adminAccountReducer.modal,
        loading: adminAccountReducer.loading,
        error: adminAccountReducer.error,
        createLoading: adminAccountReducer.createLoading,
        form: adminAccountReducer.form,
        formLoading: adminAccountReducer.formLoading,
      }),
      isEqual
    );

  const schema = yup.object().shape({
    user_name: yup
      .string()
      .required("Username is required field")
      .matches(/^\S*$/, "Username cannot contain spaces"),
    contact_number: yup
      .string()
      .required("Contact Number is required field")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .min(11, "too short")
      .max(11, "too long"),
    province: yup.string().required(),
    city: yup.string().required(),
    barangay: yup.string().required(),
    user_password: yup
      .string()
      .required("Password is required field")
      .matches(/^\S*$/, "Password cannot contain spaces"),
    dory_commissions: yup
      .string()
      .required("Platform Fee is required field")
      .matches(
        /^[0-9]+(\.[0-9]+)?$/,
        "Platform Fee should contain only numbers"
      ),
    fullName: yup.string().required(),
  });

  let defaultValues = {
    province: "",
    city: "",
    barangay: "",
    contact_number: "",
    user_password: "",
    use_name: "",
    dory_commissions: "",
    fullName: "",
  };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const hasForm = form ? Object.keys(form).length !== 0 : false;
  console.log("form:...", form);
  useEffect(() => {
    if (hasForm && form?.partner_group_areas !== undefined) {
      const parsedData = JSON.parse(form?.partner_group_areas);
      setSelectedMunicipality(parsedData);
      setCities(phil.getCityMunByProvince(form.prov_code));
    }
  }, [form, hasForm]);

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    setIsProvinceEdited(false);
    // setChangePassword(false);
    setIsCityEdited(false);
    setIsBarangayEdited(false);
    setSelectedMunicipality([]);
  };

  const addGroupedMunicipality = () => {
    setSelectedMunicipality([...selectedMunicipality, ""]);
    setAddScopeArea(true);
  };

  const removeGroupedMunicipality = (index) => {
    const updatedMunicipalities = [...selectedMunicipality];
    updatedMunicipalities.splice(index, 1);
    setSelectedMunicipality(updatedMunicipalities);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
    setIsProvinceEdited(true);
  };
  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    setBarangays(phil.getBarangayByMun(mun_code));
    setIsCityEdited(true);
  };

  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        const province = phil.provinces.filter(
          (province) => province.prov_code === values.province
        )[0];
        const city = phil.city_mun.filter(
          (city) => city.mun_code === values.city
        )[0]?.name;
        const barangay = phil.barangays.filter(
          (barangay) => barangay.brgy_code === values.barangay
        )[0]?.name;

        const newValue = {
          fullName: values.fullName,
          contact_number: values.contact_number,
          user_name: values.user_name,
          user_password: values.user_password,
          role: "operator_admin",
          province: province?.name,
          city: city,
          barangay: barangay,
          id: form.id,
          partner_group_areas:
            selectedMunicipality.length === 0
              ? JSON.stringify([city])
              : JSON.stringify(selectedMunicipality),
        };

        const { data } = await dispatch(update(newValue));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      } else {
        const province = phil.provinces.filter(
          (province) => province.prov_code === values.province
        )[0];
        const city = phil.city_mun.filter(
          (city) => city.mun_code === values.city
        )[0].name;
        const barangay = phil.barangays.filter(
          (barangay) => barangay.brgy_code === values.barangay
        )[0].name;

        let filteredSelectedMunicipality = [];
        selectedMunicipality
          .filter((d) => d !== "")
          .map((dd) => filteredSelectedMunicipality.push(dd));

        const createData = {
          fullName: values.fullName,
          contact_number: values.contact_number,
          user_name: values.user_name,
          user_password: values.user_password,
          role: "operator_admin",
          province: province.name,
          city: city,
          barangay: barangay,
          dory_commissions: values.dory_commissions,
          partner_group_areas:
            selectedMunicipality.length === 0
              ? JSON.stringify([city])
              : JSON.stringify(filteredSelectedMunicipality),
          prov_code: values.province,
        };

        const { data } = await dispatch(create(createData));

        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("Create/update partner merchant error:>>", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  return (
    <Modal
      title={hasForm ? "Update Admin Account" : "Create Admin Account"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <>
          {/*  */}
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Full Name",
                name: "fullName",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>
          {/*  */}

          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Contact Number",
                name: "contact_number",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Province",
                  name: "province",
                  errors: errors,
                  required: "true",
                  type: "select",
                  options: phil.provinces,
                  valueKey: "prov_code",
                  valueText: "name",
                  onChangeOutside: onChangeProvince,
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "City",
                  name: "city",
                  errors: errors,
                  required: "true",
                  type: "select",
                  options: cities,
                  valueKey: "mun_code",
                  valueText: "name",
                  onChangeOutside: onChangeCity,
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Barangay",
                  name: "barangay",
                  errors: errors,
                  required: "true",
                  type: "select",
                  options: barangays,
                  valueKey: "brgy_code",
                  valueText: "name",
                  onChangeOutside: () => setIsBarangayEdited(true),
                },
                control
              )}
            </Col>

            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Username",
                  name: "user_name",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>

            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Password",
                  name: "user_password",
                  errors: errors,
                  required: "true",
                  type: "password",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Platform Fee (%)",
                  name: "dory_commissions",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>

            <Col className="gutter-row" span={24}>
              {isAddScopeArea && (
                <Select
                  value={selectedMunicipality}
                  name={`municipality`}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={`Please select Municipality`}
                  onChange={(value) => {
                    setSelectedMunicipality(value);
                  }}
                  options={cities.map((city) => ({
                    value: city.name,
                    label: city.name,
                  }))}
                />
              )}

              {/* {selectedMunicipality.map((municipality, index) => (
                <div key={index} className="grouped-municipality">
                  <p>Scoped Municipalities (Optional)</p>
                  <Select
                    value={selectedMunicipality}
                    name={`municipality${index}`}
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={`Please select Municipality ${index + 1}`}
                    onChange={(value) => {
                      const updatedMunicipalities = [...selectedMunicipality];
                      updatedMunicipalities[index] = value;
                      setSelectedMunicipality(updatedMunicipalities);
                    }}
                    options={cities.map((city) => ({
                      value: city.name,
                      label: city.name,
                    }))}
                  />
                  {selectedMunicipality.length > 1 && (
                    <Button
                      type="link"
                      danger
                      onClick={() => removeGroupedMunicipality(index)}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              ))} */}
              <br />
              {!isAddScopeArea && (
                <Button
                  disabled={isAddScopeArea}
                  type="dashed"
                  onClick={addGroupedMunicipality}
                >
                  Add Scoped Area
                </Button>
              )}
            </Col>
          </Row>
        </>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={formLoading}
            disabled={formLoading}
          >
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateAdminAccount;
