import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col, Statistic, Radio, Spin, Button } from "antd";
import { Pie, Area, Bar } from "@ant-design/plots";
import { isEqual } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { FundProjectionScreenOutlined } from "@ant-design/icons";
// Components
import Wrapper from "../components/wrapper";
import DoryLogo from "../assets/images/DoryLogo.png";

const { Content: LayoutContent } = Layout;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 44,
    }}
    spin
  />
);

const SubOperatorTraining = () => {
  const { userInfo } = useSelector(
    ({ userLoginReducer }) => ({
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const Content = () => {
    return (
      <LayoutContent>
        <div style={{ justifyContent: "center", marginTop: 45 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                backgroundColor: "#3CB371",
                height: 280,
                borderRadius: 10,
                backgroundColor: "#00BF63",
                marginBottom: 20,
              }}
              width={300}
              src={DoryLogo}
              alt="Dory Logo"
            />
          </div>
          <h1
            style={{
              textAlign: "center",
              fontSize: 30,
              color: "black",
            }}
          >
            Welcome to Dory Platform
          </h1>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: 20,
                color: "gray",
                width: "25%",
              }}
            >
              Start your journey with our tutorials to learn about the Dory
              Platform.
            </p>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 22 }}
          >
            <Button
              style={{
                width: 150,
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#00BF63",
              }}
              size={"large"}
              href="https://dory-developers-s-school.teachable.com/courses/sub-operator-trainings/lectures/58237551"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Started
            </Button>
          </div>
        </div>
      </LayoutContent>
    );
  };

  return <Wrapper content={<Content />} />;
};

export default SubOperatorTraining;
