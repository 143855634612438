import React, { useState } from "react";
import { Layout } from "antd";
import { useLocation } from "react-router";

// Components
import Header from "./layout/Header";
import Footer from "./layout/Footer";

import MainClientSidebar from "./layout/sidebars/mainClientSidebar";
import HubSidebar from "./layout/sidebars/hubSidebar";
import ProvincialOfficeSidebar from "./layout/sidebars/provincialOfficeSidebar";
import RegionalOfficeSidebar from "./layout/sidebars/regionalOfficeSidebar";

const { Content } = Layout;

const Home = ({ content, modal = "" }) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const { pathname } = location;

  const sideBar = () => {
    return <MainClientSidebar collapsed={collapsed} />;
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout>
      {sideBar()}
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
        }}
      >
        <Header
          toggleCollapsed={toggleCollapsed}
          collapsed={collapsed}
          style={{
            padding: 0,
          }}
        />
        <Content
          className="site-layout-background"
          style={{
            padding: 12,
            minHeight: "84.3vh",
            margin: "10px 0px 10px 0px",
            // minHeight: "94.3vh",
            overflow: "initial",
          }}
        >
          {content}
          {modal}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default Home;
