import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";
import { Form, Row, Col, Button, Modal, notification, Select } from "antd";

// Actions
import {
  toggleModal,
  setForm,
  createGroupedAreas,
  updateGroupedAreas,
} from "../../redux/actions/groupedAreas";

// Utilities
import renderInput from "../utilities/inputForms";

const GroupedAreasModal = () => {
  const dispatch = useDispatch();

  const [cities, setCities] = useState([]);
  const [selectedMunicipality, setSelectedMunicipality] = useState([]);

  const { modal, formLoading, error, form } = useSelector(
    ({ groupedAreasReducer }) => ({
      modal: groupedAreasReducer.modal,
      formLoading: groupedAreasReducer.formLoading,
      error: groupedAreasReducer.error,
      form: groupedAreasReducer.form,
    }),
    isEqual
  );

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  useEffect(() => {
    if (hasForm) {
      const parsedData = JSON.parse(form.municipalities);
      setSelectedMunicipality(parsedData);

      setCities(phil.getCityMunByProvince(form.prov_code));
    }
  }, [form, hasForm]);

  const schema = yup.object().shape({
    province: yup.string().required(),
  });

  let defaultValues = {
    province: "",
    selectedMunicipality: [],
  };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    setSelectedMunicipality([]);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
  };

  const addGroupedMunicipality = () => {
    setSelectedMunicipality([...selectedMunicipality, ""]);
  };

  const removeGroupedMunicipality = (index) => {
    const updatedMunicipalities = [...selectedMunicipality];
    updatedMunicipalities.splice(index, 1);
    setSelectedMunicipality(updatedMunicipalities);
  };

  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        console.log("PUmasok");
        const updatedData = {
          id: form.id,
          groupedMunicipalities: selectedMunicipality,
        };

        const { data } = await dispatch(updateGroupedAreas(updatedData));

        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      } else {
        const province = phil.provinces.filter(
          (province) => province.prov_code === values.province
        )[0];

        const newData = {
          province: province.name,
          groupedMunicipalities: selectedMunicipality,
          prov_code: values.province,
        };

        const { data } = await dispatch(createGroupedAreas(newData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("Create/Update Fix rate error:", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      visible={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Province",
                name: "province",
                errors: errors,
                required: "true",
                type: "select",
                options: phil.provinces,
                valueKey: "prov_code",
                valueText: "name",
                onChangeOutside: onChangeProvince,
                disabled: hasForm,
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={24}>
            <h1>Grouped Municipalities</h1>
            {selectedMunicipality.map((municipality, index) => (
              <div key={index} className="grouped-municipality">
                <Select
                  value={selectedMunicipality[index]}
                  name={`municipality${index}`}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={`Please select Municipality ${index + 1}`}
                  onChange={(value) => {
                    const updatedMunicipalities = [...selectedMunicipality];
                    updatedMunicipalities[index] = value;
                    setSelectedMunicipality(updatedMunicipalities);
                  }}
                  options={cities.map((city) => ({
                    value: city.name,
                    label: city.name,
                  }))}
                />
                {selectedMunicipality.length > 1 && (
                  <Button
                    type="link"
                    danger
                    onClick={() => removeGroupedMunicipality(index)}
                  >
                    Remove
                  </Button>
                )}
              </div>
            ))}
            <br />
            <Button type="dashed" onClick={addGroupedMunicipality}>
              Add Grouped Municipality
            </Button>
          </Col>
        </Row>
        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default GroupedAreasModal;
