import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Button,
  Tooltip,
  Form,
  Input,
  Image,
  Popconfirm,
  notification,
  Tag,
  Modal,
  Card,
  Descriptions,
  Select,
} from "antd";
import {
  CheckSquareOutlined,
  PlusOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

import moment from "moment/moment";
// Actions

import { fetchAllReportedBookings } from "../redux/actions/reportedBookings";
import axiosInstance from "../helpers/axios";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const ValidateReportedBookings = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [reportedBooking, setReportedBooking] = useState([]);
  const [closeloading, setCloseLoading] = useState(false);
  const [reportedBookingStatus, setReportedBookingStatus] = useState("all");
  const [validateLoading, setValidateLoading] = useState(false);
  const {
    loading,
    data,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    userInfo,
  } = useSelector(
    ({ reportedBookingsReducer, userLoginReducer }) => ({
      data: reportedBookingsReducer.data,
      totalRecord: reportedBookingsReducer.totalRecord,
      nextPage: reportedBookingsReducer.nextPage,
      previousPage: reportedBookingsReducer.previousPage,
      currentPage: reportedBookingsReducer.currentPage,
      loading: reportedBookingsReducer.loading,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const handleChange = (filterValue) => {
    setReportedBookingStatus(filterValue);

    if (filterValue !== "all") {
      const payload = {
        page: 1,
        limit: 15,
        filterValue,
      };
      dispatch(fetchAllReportedBookings(payload));
    } else {
      const payload = {
        page: 1,
        limit: 15,
      };
      dispatch(fetchAllReportedBookings(payload));
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAllReportedBookings(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllReportedBookings(payload));
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 35 }}>Reported Bookings</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
          <Select
            placeholder="Filter status here"
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: "all",
                label: "All",
              },
              {
                value: "onProcess",
                label: "On process",
              },
              {
                value: "valid",
                label: "Valid",
              },
              {
                value: "invalid",
                label: "Invalid",
              },
            ]}
          />
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllReportedBookings({
        ...nextPage,
        searchText,
        reportedBookingStatus,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllReportedBookings({
        ...previousPage,
        searchText,
        reportedBookingStatus,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllReportedBookings({
        ...defaultPage,
        searchText,
        reportedBookingStatus,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllReportedBookings({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
        reportedBookingStatus,
      })
    );
  };

  const reviewReason = (data) => {
    console.log(data);
    setReportedBooking(data);
    setModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button onClick={() => reviewReason(original)} size="small">
              <Tooltip>Review Reason</Tooltip>
            </Button>
          </>
        ),
        width: 100,
      },
      {
        Header: "Order number",
        accessor: "booking_order_number",
      },
      {
        Header: "Rider name",
        accessor: "rider_name",
      },
      {
        Header: "Rider Contact Number",
        accessor: "rider_contact_number",
      },
      {
        Header: "Customer name",
        accessor: "customer_name",
      },
      {
        Header: "Customer Contact Number",
        accessor: "customer_contact_number",
      },
      {
        Header: "Merchant name",
        accessor: "merchant_name",
      },
      {
        Header: "Merchant Contact Number",
        accessor: "merchant_contact_number",
      },
      {
        Header: "Type",
        accessor: (row) => {
          if (row.bookingType === 1) {
            return <Tag color="#87d068">Pabili/padala</Tag>;
          } else if (row.bookingType === 2) {
            return <Tag color="#f50">Grocery</Tag>;
          } else {
            return <Tag color="#87d068">Merchant</Tag>;
          }
        },
      },
      {
        Header: "Report Status",
        accessor: "report_status",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllReportedBookings(defaultPage));
  }, [dispatch, defaultPage]);

  useEffect(() => {
    if (closeloading) {
      dispatch(fetchAllReportedBookings(defaultPage));
      setCloseLoading(false);
    }
  }, [dispatch, defaultPage, closeloading]);

  const reportValidate = async (data) => {
    try {
      setValidateLoading(true);
      const response = await axiosInstance(userInfo.token).put(
        `/api/bookings/validate-rerported-bookings`,
        {
          validateValue: data.status,
          orderNumber: data.data.booking_order_number,
        }
      );
      if (response.data.data) {
        openNotification("SUCCESS", response.data.message);
        setReportedBooking([]);
        setModal(false);
        setCloseLoading(true);
        setValidateLoading(false);
      }
      setValidateLoading(false);
    } catch (error) {}
  };

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={data} //
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <Modal
              maskClosable={true}
              closable={true}
              open={modal}
              width={700}
              footer={[
                <Button
                  key="cancel"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Close
                </Button>,
                <Button
                  loading={validateLoading}
                  danger
                  key="invalid"
                  onClick={() => {
                    reportValidate({
                      data: reportedBooking,
                      status: "invalid",
                    });
                  }}
                >
                  Invalid
                </Button>,
                <Button
                  loading={validateLoading}
                  key="valid"
                  type="primary"
                  onClick={() => {
                    reportValidate({
                      data: reportedBooking,
                      status: "valid",
                    });
                  }}
                >
                  Valid
                </Button>,
              ]}
            >
              <Descriptions
                bordered
                column={1}
                title={"Validate Reported Rider"}
              >
                <Descriptions.Item label={"Rider name"}>
                  {reportedBooking.rider_name}
                </Descriptions.Item>
                <Descriptions.Item label={"Rider Contact"}>
                  {reportedBooking.rider_contact_number}
                </Descriptions.Item>
                <Descriptions.Item label={"Customer name"}>
                  {reportedBooking.customer_name}
                </Descriptions.Item>
                <Descriptions.Item label={"Customer Contact"}>
                  {reportedBooking.customer_contact_number}
                </Descriptions.Item>
                <Descriptions.Item label={"Merchant name"}>
                  {reportedBooking.merchant_name === null
                    ? "--"
                    : reportedBooking.merchant_name}
                </Descriptions.Item>
                <Descriptions.Item label={"Merchant Contact"}>
                  {reportedBooking.merchant_contact_number === null
                    ? "--"
                    : reportedBooking.merchant_contact_number}
                </Descriptions.Item>
                <Descriptions.Item label="Reason">
                  <p>{reportedBooking.report_message}</p>
                </Descriptions.Item>
              </Descriptions>
            </Modal>
          </>
        }
      />
    </>
  );
};

export default ValidateReportedBookings;
