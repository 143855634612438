import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Checkbox,
  Typography,
  Button,
} from "antd";

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Image } = Input;

const renderInput = (
  {
    label,
    type = "text",
    valueKey = "",
    valueText = "",
    options = [],
    placeholder = "",
    name = "",
    errors = {},
    customError = false,
    setValue = () => {},
    mode = "",
    size = "",
    disabled = false,
    required = false,
    extra = () => {},
    onChangeOutside = () => {},
    allowClear = false,
    allowSpaceBelow = true,
  },
  control
) => {
  const errorBorderStyle =
    errors[name] || customError ? { border: "1px solid #ff4d4f" } : {};

  if (type === "radio") {
    return (
      <Form.Item
        label={<label htmlFor={label}>{label}</label>}
        required={required}
      >
        <Controller
          name={name}
          control={control}
          render={({ onChange, value }) => (
            <Radio.Group
              value={value}
              onChange={(e) => onChange(e.target.value)}
            >
              {options.map((d) => (
                <Radio value={d}>{d}</Radio>
              ))}
            </Radio.Group>
          )}
        />
        <div>
          <Text type="danger">{errors[name]?.message}</Text>
        </div>
      </Form.Item>
    );
  }

  if (type === "select") {
    return (
      <Form.Item
        label={<label htmlFor={label}>{label}</label>}
        required={required}
      >
        <Controller
          name={name}
          control={control}
          render={({ onChange, value }) => (
            <>
              <Select
                allowClear={allowClear}
                mode={mode}
                showSearch
                placeholder={placeholder}
                optionFilterProp="children"
                onChange={(e) => {
                  onChange(e);
                  onChangeOutside(e);
                }}
                value={value}
                disabled={disabled}
                style={errorBorderStyle}
                size={size}
              >
                {options.map((d) => (
                  <Option value={d[valueKey]}>{d[valueText]}</Option>
                ))}
              </Select>
              {extra()}
            </>
          )}
        />
        <br />
        <div>
          <Text type="danger">{errors[name]?.message}</Text>
        </div>
      </Form.Item>
    );
  }

  if (type === "date") {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ onChange, value }) => (
          <Form.Item
            label={<label htmlFor={label}>{label}</label>}
            required={required}
          >
            <DatePicker
              allowClear
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              disabled={disabled}
              style={{ ...errorBorderStyle, width: "100%" }}
              size={size}
            />
            <br />
            <div>
              <Text type="danger">{errors[name]?.message}</Text>
            </div>
          </Form.Item>
        )}
      />
    );
  }

  if (type === "textArea") {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ onChange, value }) => (
          <Form.Item
            label={<label htmlFor={label}>{label}</label>}
            required={required}
          >
            <TextArea
              rows={4}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
            />

            <br />
            <div>
              <Text type="danger">{errors[name]?.message}</Text>
            </div>
          </Form.Item>
        )}
      />
    );
  }

  if (type === "richText") {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ onChange, value }) => (
          <Form.Item
            label={<label htmlFor={label}>{label}</label>}
            required={required}
          >
            <Editor
              apiKey="nftjgem3snj307kf061m1g0ot7r37tp7ebd4o6jnqrglydzo"
              init={{
                height: 400,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image",
                  "charmap print preview anchor help",
                  "searchreplace visualblocks code",
                  "insertdatetime media table paste wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic | \
                  alignleft aligncenter alignright | \
                  bullist numlist outdent indent | help",
              }}
              onEditorChange={onChange}
              value={value}
            />

            <br />
            <div>
              <Text type="danger">{errors[name]?.message}</Text>
            </div>
          </Form.Item>
        )}
      />
    );
  }

  if (type === "password") {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ onChange, value }) => (
          <Form.Item
            label={<label htmlFor={label}>{label}</label>}
            required={required}
          >
            <Input.Password
              allowClear
              placeholder="input password"
              onChange={onChange}
              value={value}
              disabled={disabled}
              style={errorBorderStyle}
              size={size}
            />
            <br />
            <div>
              <Text type="danger">{errors[name]?.message}</Text>
            </div>
          </Form.Item>
        )}
      />
    );
  }

  if (type === "file") {
    return (
      <Controller
        name={name}
        control={control}
        render={({ onChange, value }) => (
          <Form.Item
            label={<label htmlFor={label}>{label}</label>}
            required={required}
          >
            <input
              type="file"
              onChange={(e) => {
                onChange(e.target.files);
              }}
              disabled={disabled}
              style={errorBorderStyle}
            />
            <br />
            <div>
              <Text type="danger">{errors[name]?.message}</Text>
            </div>
          </Form.Item>
        )}
      />
    );
  }

  if (type === "checkbox") {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ onChange, value }) => (
          <Form.Item required={required}>
            <Checkbox
              onChange={(e) => onChange(e.target.checked)}
              checked={value}
              disabled={disabled}
              style={errorBorderStyle}
            >
              <label htmlFor={label}>{label}</label>
            </Checkbox>

            <br />
            <div>
              <Text type="danger">{errors[name]?.message}</Text>
            </div>
          </Form.Item>
        )}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ onChange, value }) => (
        <Form.Item
          style={{ marginBottom: allowSpaceBelow ? 24 : 0 }}
          label={
            <>
              <label htmlFor={label} className="mr-2">
                {label}
              </label>
              <>{extra()}</>
            </>
          }
          required={required}
        >
          <Input
            allowClear
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e);
              onChangeOutside(e);
            }}
            value={value}
            type={type}
            disabled={disabled}
            style={errorBorderStyle}
            size={size}
          />

          <br />
          <div>
            <Text type="danger">{errors[name]?.message}</Text>
          </div>
        </Form.Item>
      )}
    />
  );
};

export default renderInput;
