import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Row,
  Col,
  Avatar,
  Divider,
  Button,
  Descriptions,
  Tag,
  Skeleton,
  message,
  Switch,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  CopyOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import axiosInstance from "../helpers/axios";
import ChangeContactNumberModal from "../components/users/changeContactNumberModal";
// Components
import Wrapper from "../components/wrapper";
import ChangePasswordModal from "../components/users/changePasswordModal";
import { fetchReferralLinksAndDefaultRider } from "../redux/actions/users";

const { Content: LayoutContent } = Layout;

const NewAccount = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const {
    userInfo,
    loading,
    formLoading,
    referralLinks,
    fetchreferralLinkLoading,
  } = useSelector(
    ({ userReducer, userLoginReducer }) => ({
      userInfo: userLoginReducer.userInfo,
      referralLinks: userLoginReducer.referralLinks,
      loading: userReducer.loading,
      formLoading: userReducer.formLoading,
      fetchreferralLinkLoading: userLoginReducer.loading,
    }),
    isEqual
  );

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [enablGrocery, setEnablGrocery] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  useEffect(() => {
    dispatch(fetchReferralLinksAndDefaultRider());
  }, [dispatch]);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Link copied to clipboard");
      })
      .catch((err) => {
        message.error("Failed to copy the link");
      });
  };
  const onChange = async (checked) => {
    setLoadingSwitch(true);
    const response = await axiosInstance(userInfo.token).patch(
      `/api/app/partner/services/${userInfo.id}`,
      {
        isGroceryActive: checked,
      }
    );
    if (response.data) {
      setEnablGrocery(checked);
      serviceChecker();
    }
    setLoadingSwitch(false);
  };

  const serviceChecker = useCallback(async () => {
    const response = await axiosInstance(userInfo.token).get(
      `/api/app/partner/services/${userInfo.id}`
    );
    if (response.data.result.isGroceryActive === 1) {
      setDisableSwitch(true);
      setEnablGrocery(true);
    }
  }, [disableSwitch]);

  useEffect(() => {
    if (userInfo.role === "operator_admin") {
      serviceChecker();
    }
  }, [disableSwitch]);

  const Content = () => {
    return (
      <LayoutContent>
        <div className="m-4">
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            c
          >
            <Col span={6}>
              <div className="p-4 rounded-md border border-solid">
                <div>
                  <div className="text-center mb-6">
                    <Avatar size={90} icon={<UserOutlined />} />
                    <div className="mt-2">
                      {loading || formLoading ? (
                        <Skeleton.Input active block />
                      ) : (
                        <span className="font-semibold text-lg text-gray-600">
                          {userInfo.user_name}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <Divider dashed />

                <div>
                  <Button
                    type="primary"
                    block
                    onClick={() => setModal(true)}
                    icon={<LockOutlined />}
                  >
                    Change password
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={18}>
              <div>
                {loading || formLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    <Descriptions
                      title=""
                      layout="vertical"
                      column={3}
                      bordered
                    >
                      <Descriptions.Item
                        label={
                          <span className="font-semibold text-gray-500">
                            Username
                          </span>
                        }
                      >
                        {userInfo.user_name}
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={2}
                        label={
                          <span className="font-semibold text-gray-500">
                            Phone
                          </span>
                        }
                        className="relative"
                      >
                        <Descriptions.Item
                          span={2}
                          label={
                            <span className="font-semibold text-gray-500">
                              Phone
                            </span>
                          }
                        >
                          {userInfo.contact_number}
                        </Descriptions.Item>

                        {/* {userInfo.contact_number} */}
                        <div className="flex items-center">
                          <button
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-700"
                            onClick={() => setContactModal(true)}
                          >
                            Edit
                          </button>
                        </div>
                      </Descriptions.Item>

                      <Descriptions.Item
                        span={2}
                        label={
                          <span className="font-semibold text-gray-500">
                            Service City
                          </span>
                        }
                      >
                        <Tag color="green" style={{ padding: 8 }}>
                          {userInfo.service_city}
                        </Tag>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={2}
                        label={
                          <span className="font-semibold text-gray-500">
                            Service Province
                          </span>
                        }
                      >
                        <Tag color="green" style={{ padding: 8 }}>
                          {userInfo.service_province}
                        </Tag>
                      </Descriptions.Item>

                      {/* <Descriptions.Item
                      span={2}
                      label={
                        <span className="font-semibold text-gray-500">
                          Main Address
                        </span>
                      }
                    >{`${userInfo.city}, ${userInfo.province}`}</Descriptions.Item> */}

                      {/* <Descriptions.Item
                        span={3}
                        label={
                          <span className="font-semibold text-gray-500">
                            Serviceable areas
                          </span>
                        }
                      >
                        {userInfo.role === "opertor-admin" && (
                        JSON.parse(userInfo.grouped_areas).map((d) => (
                          <Tag color="green" style={{ padding: 8 }}>
                            {d}
                          </Tag>
                        )))}
                      </Descriptions.Item> */}
                      <Descriptions.Item
                        label={
                          <span className="font-semibold text-gray-500">
                            Referral Link for Riders
                          </span>
                        }
                      >
                        <a
                          href={referralLinks?.rider_referral_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            marginRight: 8,
                          }}
                        >
                          {fetchreferralLinkLoading ? (
                            <Skeleton.Input size="small" />
                          ) : (
                            referralLinks?.rider_referral_link
                          )}
                        </a>
                        <Button
                          type="default"
                          icon={<CopyOutlined style={{ color: "gray" }} />}
                          style={{ backgroundColor: "white", marginLeft: 8 }}
                          onClick={() =>
                            copyToClipboard(referralLinks?.rider_referral_link)
                          }
                        />
                      </Descriptions.Item>

                      <Descriptions.Item
                        span={2}
                        label={
                          <span className="font-semibold text-gray-500">
                            Referral Link for Customers
                          </span>
                        }
                      >
                        <a
                          href={referralLinks?.sub_operator_customer_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            marginRight: 8,
                          }}
                        >
                          {fetchreferralLinkLoading ? (
                            <Skeleton.Input size="small" />
                          ) : (
                            referralLinks?.sub_operator_customer_link
                          )}
                        </a>
                        <Button
                          type="default"
                          icon={<CopyOutlined style={{ color: "gray" }} />}
                          style={{ backgroundColor: "white", marginLeft: 8 }}
                          onClick={() =>
                            copyToClipboard(
                              referralLinks?.sub_operator_customer_link
                            )
                          }
                        />
                      </Descriptions.Item>

                      <Descriptions.Item
                        span={2}
                        label={
                          <span className="font-semibold text-gray-500">
                            Referral Link for Merchant
                          </span>
                        }
                      >
                        <a
                          href={referralLinks?.referral_link_for_merchhants}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            marginRight: 8,
                          }}
                        >
                          {fetchreferralLinkLoading ? (
                            <Skeleton.Input size="small" />
                          ) : (
                            referralLinks?.referral_link_for_merchhants
                          )}
                        </a>
                        <Button
                          type="default"
                          icon={<CopyOutlined style={{ color: "gray" }} />}
                          style={{ backgroundColor: "white", marginLeft: 8 }}
                          onClick={() =>
                            copyToClipboard(
                              referralLinks?.referral_link_for_merchhants
                            )
                          }
                        />
                      </Descriptions.Item>
                      {/* test affiliate */}

                      <Descriptions.Item
                        span={2}
                        label={
                          <span className="font-semibold text-gray-500">
                            Referral Link for operator, sub-operator, affiliate
                          </span>
                        }
                      >
                        <a
                          href={
                            referralLinks?.referral_link_for_affiliate_operator_sub_operator
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            marginRight: 8,
                          }}
                        >
                          {fetchreferralLinkLoading ? (
                            <Skeleton.Input size="small" />
                          ) : (
                            referralLinks?.referral_link_for_affiliate_operator_sub_operator
                          )}
                        </a>
                        <Button
                          type="default"
                          icon={<CopyOutlined style={{ color: "gray" }} />}
                          style={{ backgroundColor: "white", marginLeft: 8 }}
                          onClick={() =>
                            copyToClipboard(
                              referralLinks?.referral_link_for_affiliate_operator_sub_operator
                            )
                          }
                        />
                      </Descriptions.Item>

                      {/* test
                      <Descriptions.Item
                        span={3}
                        label={
                          <span className="font-semibold text-gray-500">
                            Default rider credential
                          </span>
                        }
                      >
                        {fetchreferralLinkLoading ? (
                          <>
                            <Skeleton.Input size="default" />
                          </>
                        ) : (
                          <>
                            <p>
                              Username: {referralLinks?.defaultRiderUserName}
                            </p>
                            <span className="flex items-center">
                              Password:
                              {isPasswordVisible
                                ? ` ${referralLinks?.defaultRiderPassword}`
                                : " **********"}
                              <button
                                onClick={togglePasswordVisibility}
                                className="ml-2"
                              >
                                {isPasswordVisible ? (
                                  <EyeInvisibleOutlined />
                                ) : (
                                  <EyeOutlined />
                                )}
                              </button>
                            </span>
                          </>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      style={{ marginTop: 20 }}
                      title=""
                      layout="vertical"
                      column={3}
                      bordered
                    >
                      <Descriptions.Item
                        layout="vertical"
                        column={3}
                        bordered
                        label={
                          <span className="font-semibold text-gray-500">
                            Enable Grocery
                          </span>
                        }
                      >
                        <div>
                          After enabling it, grocery service will be available
                          in your serviceable area.
                        </div>
                        <Switch
                          disabled={loadingSwitch || fetchreferralLinkLoading}
                          checked={enablGrocery}
                          onChange={(checked) => {
                            onChange(checked);
                          }}
                        />
                      </Descriptions.Item> */}
                    </Descriptions>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </LayoutContent>
    );
  };

  return <Wrapper content={<Content />} />;
};

export default NewAccount;
