import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Button, Tooltip, Form, Input } from "antd";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import SucceedingRateModal from "../components/succeedingRate/modal";

// Actions
import {
  fetchSucceedingRates,
  toggleModal,
  setForm,
} from "../redux/actions/succeedingDistanceRate";
import moment from "moment/moment";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const SucceedingDistanceRate = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [changeSucceedingRate, setChangeSucceedingRate] = useState(false);

  const {
    loading,
    formLoading,
    succeedingRateData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    userInfo,
  } = useSelector(
    ({ succeedingRateReducer, userLoginReducer }) => ({
      succeedingRateData: succeedingRateReducer.data,
      totalRecord: succeedingRateReducer.totalRecord,
      nextPage: succeedingRateReducer.nextPage,
      previousPage: succeedingRateReducer.previousPage,
      currentPage: succeedingRateReducer.currentPage,
      loading: succeedingRateReducer.loading,
      formLoading: succeedingRateReducer.formLoading,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const onChange = (status, id) => {
    // dispatch(updatePartnerStore({ status, id }));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchSucceedingRates(payload));
  }, [delaySearchText, dispatch]);

  const onAdd = () => {
    dispatch(toggleModal());
  };
  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchSucceedingRates(payload));
  };
  const onEdit = ({ data }) => {
    setChangeSucceedingRate(true);
    dispatch(setForm(data));
    dispatch(toggleModal());
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Delivery Fee Succeeding Rates</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          {userInfo.role !== "operator_admin" && (
            <Form layout="inline">
              <Form.Item label="Search">
                <Search
                  placeholder="Enter order number or waybill number"
                  allowClear
                  style={{ width: "15VW" }}
                  value={searchText}
                  type="secondary"
                  size="small"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onSearch={onSearch}
                />
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    );
  };

  const extra = () => {
    const addButton = (
      <Button key="1" onClick={onAdd} className="mr-2">
        <PlusOutlined /> Add
      </Button>
    );

    return [addButton];
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchSucceedingRates({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchSucceedingRates({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchSucceedingRates({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchSucceedingRates({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button
              disabled={formLoading}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined />
              </Tooltip>
            </Button>
          </>
        ),
        width: 100,
      },
      {
        Header: "Based delivery fee",
        accessor: "based_delivery_fee",
      },
      {
        Header: "Succeeding Rate",
        accessor: "amount",
      },
      {
        Header: "Province",
        accessor: "province",
      },
      {
        Header: "Municipality",
        accessor: "municipality",
      },
      {
        Header: "Date Updated",
        accessor: "updated_at",
        Cell: ({ value }) => {
          return moment(value).isValid()
            ? moment(value).tz("Asia/Manila").format("MMMM DD, YYYY hh:mm A")
            : "--";
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchSucceedingRates(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={succeedingRateData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
          ]}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <SucceedingRateModal
              changeSucceedingRate={changeSucceedingRate}
              setChangeSucceedingRate={setChangeSucceedingRate}
            />
          </>
        }
      />
    </>
  );
};

export default SucceedingDistanceRate;
