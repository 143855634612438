import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Layout, Dropdown, Menu, Button, Badge } from "antd";
import "antd/dist/reset.css";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  BellOutlined,
} from "@ant-design/icons";
// import io from "socket.io-client";

// actions
import { logout as logoutUser } from "../../redux/actions/users";
// import { logout as logoutOperationAccount } from "../../redux/actions/operationAccount";

const { Header } = Layout;
// const socket = io.connect("http://localhost:9001");

const LayoutHeader = ({ toggleCollapsed, collapsed }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { userInfo } = useSelector(({ userLoginReducer, hubReducer }) => ({
    userInfo: userLoginReducer.userInfo,
  }));

  const logoutHandler = () => {
    dispatch(logoutUser());
    if (userInfo.role === "admin") {
      history.push("/signin");
    } else {
      history.push("/partner-signin");
    }
  };

  // useEffect(() => {
  //   if (account.operationAccountType === "Hub") {
  //     socket.on("receive_client_orders", (data) => {
  //       let ReadyForPickupByRiderCount = data.data.length;
  //       if (bookingsReadyForPickupByRiderCount !== 0) {
  //         ReadyForPickupByRiderCount =
  //           bookingsReadyForPickupByRiderCount + ReadyForPickupByRiderCount;
  //       }
  //       dispatch(
  //         setBookingsReadyForPickupByRiderCount(ReadyForPickupByRiderCount)
  //       );
  //     });
  //   }
  // }, [socket, bookingsReadyForPickupByRiderCount, account]);

  const accountMenu = (
    <div class="my-10">
      <div class="bg-white rounded overflow-hidden shadow-lg">
        <div class="text-center px-6  border-b">
          {userInfo.role === "operator_admin" ||
          userInfo.role === "sub_operator" ? (
            <p class="text-lg font-semibold">{`${userInfo?.user_name}`}</p>
          ) : (
            <p class="text-lg font-semibold">{`${userInfo?.first_name} ${userInfo?.last_name}`}</p>
          )}
          <p class="text-sm text-gray-600">{userInfo?.email_address}</p>
        </div>
        <div class="border-b">
          <div className="flex justify-center px-4 py-2">
            <button
              className="hover:bg-gray-100 font-bold py-2 px-4 rounded"
              onClick={logoutHandler}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <div className="flex justify-between">
        <div>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggleCollapsed,
            }
          )}
        </div>
        <div>
          <div className="mr-5">
            {userInfo?.operationAccountType === "Hub" && (
              <Dropdown trigger={["click"]}>
                <Button
                  type="text"
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  {/* <Badge
                    count={bookingsReadyForPickupByRiderCount}
                    style={{ fontSize: "70%", backgroundColor: "#52c41a" }}
                    overflowCount={10}
                  >
                    <BellOutlined style={{ fontSize: "120%" }} />
                  </Badge> */}
                </Button>
              </Dropdown>
            )}
            <Dropdown
              overlay={accountMenu}
              className="ml-2"
              trigger={["click"]}
            >
              <Button
                type="text"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {`${
                  userInfo.role === "operator_admin" ||
                  userInfo.role === "sub_operator"
                    ? userInfo.user_name
                    : userInfo?.first_name || userInfo.user_name
                } ${
                  userInfo.role === "operator_admin" ||
                  userInfo.role === "sub_operator"
                    ? ""
                    : userInfo?.last_name
                }`}{" "}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default LayoutHeader;
