import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { ALLFRANCHISEEPAYABLES } = actions;

export const fetchAllFranchiseePayables =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: ALLFRANCHISEEPAYABLES.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/franchisee-payables/partner-payables`;
      if (payload !== undefined) {
        const { page, limit, searchText, dateFilter, todateFilter, id } =
          payload;
        url = `/api/franchisee-payables/partner-payables/${id}?page=${page}`;
        if (page && limit && searchText) {
          url = `/api/franchisee-payables/partner-payables/${id}?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
        if (page && limit && dateFilter && todateFilter) {
          url = `/api/franchisee-payables/partner-payables/${id}?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}`;
        }
      }
      const response = await axiosInstance(token).get(url);

      dispatch({
        type: ALLFRANCHISEEPAYABLES.FETCH.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ALLFRANCHISEEPAYABLES.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const updateFranchiseePayablesStatus =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: ALLFRANCHISEEPAYABLES.UPDATE.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      const response = await axiosInstance(token).put(
        `/api/franchisee-payables/${payload.original.id}/${payload.status}/${payload.original.Franchise_id}`,
        {
          note: payload.note,
        }
      );

      dispatch({
        type: ALLFRANCHISEEPAYABLES.UPDATE.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: ALLFRANCHISEEPAYABLES.UPDATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
