import actions from "../actionTypes";
const { AFFILIATES } = actions;

const userInitialState = {
  modal: false,
  modalView: false,
  formLoading: false,
  data: [],
  form: {},
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
  // area affiliate
  dataAreaAffiliate: [],
  formAreaAffiliate: {},
  loadingAreaAffiliate: false,
  errorAreaAffiliate: null,
  totalRecordAreaAffiliate: 0,
  nextPageAreaAffiliate: undefined,
  previousPageAreaAffiliate: undefined,
  currentPageAreaAffiliate: undefined,
};

export const affiliatesReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AFFILIATES.FETCH.REQUEST:
      return { ...state, loading: true };
    case AFFILIATES.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case AFFILIATES.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    // area affiliate

    case AFFILIATES.FETCHAREAAFFILIATE.REQUEST:
      return { ...state, loadingAreaAffiliate: true };
    case AFFILIATES.FETCHAREAAFFILIATE.SUCCESS:
      return {
        ...state,
        dataAreaAffiliate: payload.data.data.result,
        totalRecordAreaAffiliate: payload.data.count,
        nextPageAreaAffiliate: payload.data?.data?.next,
        previousPageAreaAffiliate: payload.data?.data?.previous,
        currentPageAreaAffiliate: payload.data?.currentPage,
        loadingAreaAffiliate: false,
      };
    case AFFILIATES.FETCHAREAAFFILIATE.FAILURE:
      return {
        ...state,
        errorAreaAffiliate: payload,
        loadingAreaAffiliate: false,
      };

    default:
      return state;
  }
};
