import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Button,
  Tooltip,
  Form,
  Input,
  Modal,
  Badge,
  Switch,
} from "antd";
import {
  FormOutlined,
  PlusOutlined,
  //   EyeOutlined,
  LockOutlined,
  DropboxOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import PartnerStoreModal from "../components/partnetStores/modal";
// Actions
// import { toggleModal, setForm } from "../redux/actions/users";
import {
  fetchAllPartnerStore,
  toggleModal,
  setForm,
  update,
  updatePartnerStore,
} from "../redux/actions/partnerStores";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const Users = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [changePassword, setChangePassword] = useState(false);

  const {
    loading,
    formLoading,
    storeData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ partnerStoresReducer }) => ({
      storeData: partnerStoresReducer.data,
      totalRecord: partnerStoresReducer.totalRecord,
      nextPage: partnerStoresReducer.nextPage,
      previousPage: partnerStoresReducer.previousPage,
      currentPage: partnerStoresReducer.currentPage,
      loading: partnerStoresReducer.loading,
      formLoading: partnerStoresReducer.formLoading,
    }),
    isEqual
  );

  const onChange = (status, id) => {
    dispatch(updatePartnerStore({ status, id }));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAllPartnerStore(payload));
  }, [delaySearchText, dispatch]);

  const onAdd = () => dispatch(toggleModal());
  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllPartnerStore(payload));
  };
  const onEdit = ({ data }) => {
    setChangePassword(false);
    dispatch(setForm(data));
    dispatch(toggleModal());
  };

  // const onView = ({ data }) => {
  //   setViewMerchantsDetails(true);
  //   dispatch(setForm(data));
  //   dispatch(toggleModal());
  // };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Groceria Partner Store</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const extra = () => {
    const addButton = (
      <Button key="1" onClick={onAdd} className="mr-2">
        <PlusOutlined /> Add
      </Button>
    );

    return [addButton];
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllPartnerStore({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllPartnerStore({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllPartnerStore({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllPartnerStore({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Link
              to={`/partnerstoresproducts/${original.id}/${original.city}/${original.province}`}
            >
              <Button disabled={formLoading} size="small" type="text">
                <Tooltip title="Products">
                  <DropboxOutlined />
                </Tooltip>
              </Button>
            </Link>

            <Button
              disabled={formLoading}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined />
              </Tooltip>
            </Button>
            <Button
              disabled={formLoading}
              onClick={() => {
                onEdit({ data: original });
                setChangePassword(true);
              }}
              size="small"
              type="text"
            >
              <Tooltip title="Change password">
                <LockOutlined />
              </Tooltip>
            </Button>
            <Switch
              size="small"
              defaultChecked={original.isActive}
              onChange={(checked) => {
                onChange(checked, original.id);
              }}
            />
          </>
        ),
        width: 100,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "User name",
        accessor: "username",
      },
      {
        Header: "Barangay",
        accessor: "barangay",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Province",
        accessor: "province",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Latitude",
        accessor: "latitude",
      },
      {
        Header: "Longitude",
        accessor: "longitude",
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        Cell: ({ value }) => {
          return dayjs(value).format("YYYY-MM-DD");
        },
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: ({ value }) => {
          return value === true || value === 1 ? (
            <Badge status="success" text="Active" />
          ) : (
            <Badge status="error" text="Inactive" />
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllPartnerStore(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={storeData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
          ]}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <PartnerStoreModal
              changePassword={changePassword}
              setChangePassword={setChangePassword}
            />
          </>
        }
      />
    </>
  );
};

export default Users;
