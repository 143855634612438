import actions from "../actionTypes";
const { RIDER } = actions;

const userInitialState = {
  riderInMyAreaData: [],
  riderInMyAreaTotalRecord: 0,
  riderInMyAreaNextPage: undefined,
  riderInMyAreaPreviousPage: undefined,
  riderInMyAreaCurrentPage: undefined,

  loading: false,
  error: null,

  referredRiderData: [],
  referredRiderTotalRecord: 0,
  referredRiderNextPage: undefined,
  referredRiderPreviousPage: undefined,
  referredRiderCurrentPage: undefined,
  modal: false,
  modalView: false,
  reportLoading: false,
  error: null,
};

export const riderReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RIDER.TOGGLE:
      return { ...state, modal: payload, error: null };
    case RIDER.TOGGLEVIEW:
      return { ...state, modalView: payload, error: null };
    case RIDER.SET:
      return { ...state, form: payload };

    case RIDER.CREATE.REQUEST:
      return { ...state, reportLoading: true };

    case RIDER.CREATE.SUCCESS:
      return {
        ...state,
        reportLoading: false,
      };
    case RIDER.CREATE.FAILURE:
      return { ...state, reportLoading: false, error: payload };

    case RIDER.FETCHRIDERINMYAREA.REQUEST:
      return { ...state, loading: true };
    case RIDER.FETCHRIDERINMYAREA.SUCCESS:
      return {
        ...state,
        riderInMyAreaData: payload.data.data.result,
        riderInMyAreaTotalRecord: payload.data.count,
        riderInMyAreaNextPage: payload.data?.data?.next,
        riderInMyAreaPreviousPage: payload.data?.data?.previous,
        riderInMyAreaCurrentPage: payload.data?.currentPage,
        loading: false,
      };
    case RIDER.FETCHRIDERINMYAREA.FAILURE:
      return { ...state, error: payload, loading: false };

    case RIDER.FETCHREFERREDRIDER.REQUEST:
      return { ...state, loading: true };
    case RIDER.FETCHREFERREDRIDER.SUCCESS:
      return {
        ...state,
        referredRiderData: payload.data.data.result,
        referredRiderTotalRecord: payload.data.count,
        referredRiderNextPage: payload.data?.data?.next,
        referredRiderPreviousPage: payload.data?.data?.previous,
        referredRiderCurrentPage: payload.data?.currentPage,
        loading: false,
      };
    case RIDER.FETCHREFERREDRIDER.FAILURE:
      return { ...state, error: payload, loading: false };

    default:
      return state;
  }
};
