import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Form, Button, Modal, notification, Row, Col, Image } from "antd";

import {
  createFeaturedImage,
  toggleModalFeaturedImage,
} from "../../redux/actions/partnerMerchants";
import renderInput from "../utilities/inputForms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const AddFeaturedImageModal = ({ url, merchantId }) => {
  const dispatch = useDispatch();

  const [btnLoading, setBtnLoading] = useState(false);

  const { modalFeaturedImage } = useSelector(
    ({ merchantsReducer }) => ({
      modalFeaturedImage: merchantsReducer.modalFeaturedImage,
      form: merchantsReducer.form,
    }),
    isEqual
  );

  const yourMaxSizeInBytes = 5 * 1024 * 1024;

  let defaultValues = {
    image: "",
  };

  const schema = yup.object().shape({
    image: yup
      .mixed()
      .required("Image is required field")
      .test("fileSize", "File size is too large", (value) => {
        if (!value) return true;
        return value && value[0].size <= yourMaxSizeInBytes;
      }),
  });
  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const close = () => {
    dispatch(toggleModalFeaturedImage(false));
  };

  const onSubmit = async (values) => {
    try {
      setBtnLoading(true);
      const formData = new FormData();
      formData.append("image", values.image[0]);
      formData.append("merchantId", merchantId);

      const { data } = await dispatch(createFeaturedImage(formData));

      if (data) {
        openNotification("SUCCESS", data.message);
        close();
        setBtnLoading(false);
      }
    } catch (error) {
      setBtnLoading(false);
      console.log("Save partner merchant Menu error:>>", error);
    }
  };

  return (
    <Modal
      open={modalFeaturedImage}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <h1>Current Featured Product image</h1>
      {url === "" || url === undefined || url === null ? (
        <h1>None</h1>
      ) : (
        <Image
          width={200}
          src={`https://groceria-storage.sgp1.cdn.digitaloceanspaces.com/assets/images/partner-merchant-featured-image/${url}`}
        />
      )}

      <Form
        name="dynamic_form_nest_item"
        onFinish={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          maxWidth: 600,
        }}
        autoComplete="off"
      >
        <Row>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Image",
                name: "image",
                errors: errors,
                required: "true",
                type: "file",
              },
              control
            )}
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={btnLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddFeaturedImageModal;
