import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Popconfirm,
  DatePicker,
  Modal,
} from "antd";
import { CheckSquareOutlined, CloseOutlined } from "@ant-design/icons";

// Components
import formatCurrency from "../helpers/formatCurrency";
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// Actions
import { fetchMonitoringPayables } from "../redux/actions/monitoringPayables";
import moment from "moment/moment";
import dayjs from "dayjs";
const { Content: LayoutContent } = Layout;
const { Search } = Input;

const PayablesMonitoring = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [toformattedDate, setToFormattedDate] = useState(null);
  const [total, setTotal] = useState(null);
  const {
    loading,
    payablesMonitoring,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    totalPaid,
    totalOfUnpaid,
    totalOfOverAllDf,
  } = useSelector(
    ({ monitoringPayablesReducer }) => ({
      payablesMonitoring: monitoringPayablesReducer.data,
      totalRecord: monitoringPayablesReducer.totalRecord,
      nextPage: monitoringPayablesReducer.nextPage,
      previousPage: monitoringPayablesReducer.previousPage,
      currentPage: monitoringPayablesReducer.currentPage,
      loading: monitoringPayablesReducer.loading,
      totalPaid: monitoringPayablesReducer.totalPaid,
      totalOfUnpaid: monitoringPayablesReducer.totalOfUnpaid,
      totalOfOverAllDf: monitoringPayablesReducer.totalOfOverAllDf,
    }),
    isEqual
  );

  useEffect(() => {
    let i = [];
    payablesMonitoring.map((d) => i.push(d.collectedShippingFee));
    const total = i.reduce((acc, curr) => acc + parseFloat(curr), 0);
    setTotal(total.toFixed(2));
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      dateFilter: formattedDate === "Invalid Date" ? undefined : formattedDate,
      todateFilter:
        toformattedDate === "Invalid Date" ? undefined : toformattedDate,
    };
    if (toformattedDate !== null) {
      dispatch(fetchMonitoringPayables(payload));
    }
  }, [delaySearchText, toformattedDate, dispatch, formattedDate]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };
    dispatch(fetchMonitoringPayables(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 17 }}>Payables Monitoring</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            ></div>
          </Form>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 45,
            marginTop: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              padding: 25,
              border: "1px solid #DCDCDC",
              width: "15%",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span
                style={{ color: "#6495ED", fontSize: 16, marginBottom: 10 }}
              >
                Total Df Collections
              </span>
              <span style={{ color: "gray", fontSize: 16 }}>
                {formatCurrency(totalOfOverAllDf || 0)}
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              padding: 25,
              border: "1px solid #DCDCDC",
              width: "15%",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ color: "green", fontSize: 16, marginBottom: 10 }}>
                Paid
              </span>
              <span style={{ color: "gray", fontSize: 16 }}>
                {formatCurrency(totalPaid || 0)}
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              padding: 25,
              border: "1px solid #DCDCDC",
              width: "15%",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ color: "tomato", fontSize: 16, marginBottom: 10 }}>
                Unpaid
              </span>
              <span style={{ color: "gray", fontSize: 16 }}>
                {formatCurrency(totalOfUnpaid || 0)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchMonitoringPayables({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchMonitoringPayables({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchMonitoringPayables({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchMonitoringPayables({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Partner name",
        accessor: "partner_name",
      },
      {
        Header: "Total Collection",
        accessor: "collectedShippingFee",
      },
      // {
      //   Header: "Date",
      //   accessor: (row) => {
      //     const utcTime = moment.utc(row.ordersDate).tz("Asia/Manila");
      //     return utcTime.format("MMM D, YYYY hh:mm A"); // Format the time as needed platform_fee
      //   },
      // },
      {
        Header: "Status",
        accessor: (row) => {
          if (row.status === 0) {
            return "unpaid";
          }
        },
      },
      {
        Header: "Platfrom Fee",
        accessor: "platform_fee",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchMonitoringPayables(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={payablesMonitoring}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          // dateFilters={{ selectedDate }}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default PayablesMonitoring;
