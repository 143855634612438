import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { OPERATORPRICEAPPROVAL } = actions;

export const fetchAllPriceForApproval =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: OPERATORPRICEAPPROVAL.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/operator-pricing`;
      if (payload !== undefined) {
        const { page, limit, searchText, dateFilter } = payload;
        url = `/api/operator-pricing?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/operator-pricing?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
        if (page && limit && dateFilter) {
          url = `/api/operator-pricing?page=${page}&limit=${limit}&dateFilter=${dateFilter}`;
        }
      }

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: OPERATORPRICEAPPROVAL.FETCH.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: OPERATORPRICEAPPROVAL.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const updatePriceApproval = (payload) => async (dispatch, getState) => {
  dispatch({ type: OPERATORPRICEAPPROVAL.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).patch(
      `/api/operator-pricing`,
      payload
    );

    dispatch({
      type: OPERATORPRICEAPPROVAL.UPDATE.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: OPERATORPRICEAPPROVAL.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
