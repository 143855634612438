import actions from "../actionTypes";
const { NEWRIDERS } = actions;

const userInitialState = {
  modal: false,
  modalView: false,
  formLoading: false,
  data: [],
  form: {},
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
};

export const newRidersReducers = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NEWRIDERS.FETCH.REQUEST:
      return { ...state, loading: true };
    case NEWRIDERS.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case NEWRIDERS.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    default:
      return state;
  }
};
