import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { NATIONALMERCHANTMENU } = actions;

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: NATIONALMERCHANTMENU.TOGGLEVIEW, payload });
  };

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: NATIONALMERCHANTMENU.TOGGLE, payload });
  };

export const toggleDuplicateModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: NATIONALMERCHANTMENU.TOGGLEDUPLICATE, payload });
  };
export const setForm = (payload) => (dispatch) => {
  dispatch({ type: NATIONALMERCHANTMENU.SET, payload });
};

export const fetchNationalMerchantMenu =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: NATIONALMERCHANTMENU.FETCH.REQUEST });
    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      const { page, limit, searchText } = payload;
      let url = `/api/national-merchants/fetch-menu/${payload.userId}`;

      if (payload !== undefined) {
        url = `/api/national-merchants/fetch-menu/${payload.userId}?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/national-merchants/fetch-menu/${payload.userId}?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
      }
      const response = await axiosInstance(token).get(url);
      dispatch({
        type: NATIONALMERCHANTMENU.FETCH.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: NATIONALMERCHANTMENU.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const createNationalMerchantMenu =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: NATIONALMERCHANTMENU.CREATE.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();

    try {
      let token = "";
      if (userInfo !== null) token = userInfo.token;
      const response = await axiosInstance(token).post(
        `/api/national-merchants/create-menu`,
        payload
      );

      dispatch({
        type: NATIONALMERCHANTMENU.CREATE.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: NATIONALMERCHANTMENU.CREATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
      return error;
    }
  };

export const createMerchantWithMenus =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: NATIONALMERCHANTMENU.CREATEMERCHANT.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    try {
      let token = "";
      if (userInfo !== null) token = userInfo.token;
      const response = await axiosInstance(token).post(
        "/api/national-merchants/create-merchant-and-menu",
        payload
      );

      dispatch({
        type: NATIONALMERCHANTMENU.CREATEMERCHANT.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: NATIONALMERCHANTMENU.CREATEMERCHANT.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
      return error;
    }
  };

export const createMerchantWithMenusMultipleBranch =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: NATIONALMERCHANTMENU.CREATEMERCHANT.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    try {
      let token = "";
      if (userInfo !== null) token = userInfo.token;
      const response = await axiosInstance(token).post(
        "/api/national-merchants/create-merchant-and-menu-multiple-branch",
        {
          data: payload,
        }
      );

      dispatch({
        type: NATIONALMERCHANTMENU.CREATEMERCHANT.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: NATIONALMERCHANTMENU.CREATEMERCHANT.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
      return error;
    }
  };

export const updateNationalMerchantMenu =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: NATIONALMERCHANTMENU.UPDATE.REQUEST });
    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      const response = await axiosInstance(token).put(
        `/api/national-merchants/update-menu`,
        payload
      );

      dispatch({
        type: NATIONALMERCHANTMENU.UPDATE.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: NATIONALMERCHANTMENU.UPDATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
export const updateMenuStatus = (payload) => async (dispatch, getState) => {
  dispatch({ type: NATIONALMERCHANTMENU.UPDATE.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).put(
      `/api/national-merchants/update_menu_status/${payload.status}/${payload.id}`,
      payload
    );

    dispatch({ type: NATIONALMERCHANTMENU.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: NATIONALMERCHANTMENU.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
