import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { DELIVERYFEESETTINGS, SUCCEEDINGRATE } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: DELIVERYFEESETTINGS.TOGGLE, payload });
  };

// export const toggleModalView =
//   (payload = true) =>
//   async (dispatch) => {
//     dispatch({ type: DELIVERYFEESETTINGS.TOGGLEVIEW, payload });
//   };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: DELIVERYFEESETTINGS.SET, payload });
};

export const createMultipleDeliveryFeeSetting =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: DELIVERYFEESETTINGS.CREATE.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();

    try {
      let token = "";
      if (userInfo !== null) token = userInfo.token;
      const response = await axiosInstance(token).post(
        "/api/delivery-fee/setting",
        payload
      );
      dispatch({ type: DELIVERYFEESETTINGS.CREATE.SUCCESS, payload: response });
      dispatch({ type: SUCCEEDINGRATE.CREATE.SUCCESS, payload: response });
      return response;
    } catch (error) {
      dispatch({
        type: DELIVERYFEESETTINGS.CREATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
      return error;
    }
  };
