import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Select,
  Switch,
  Popconfirm,
  DatePicker,
  Tag,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import {
  PlusOutlined,
  FormOutlined,
  MenuOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

import {
  fetchPartnerCoupoons,
  toggleModal,
  setForm,
} from "../redux/actions/partnerCoupons";

import PartnerCreateCouponCode from "../components/createCoupons/partnerCreateCouponModal";
const { Content: LayoutContent } = Layout;
const { Search } = Input;

const PartnerCoupone = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [isApproved, setIsApproved] = useState(null);
  const [isVerified, setIsVerified] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);

  const {
    loading,
    data,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    formLoading,
  } = useSelector(
    ({ partnerCouponsReducer, userLoginReducer }) => ({
      data: partnerCouponsReducer.data,
      totalRecord: partnerCouponsReducer.totalRecord,
      nextPage: partnerCouponsReducer.nextPage,
      previousPage: partnerCouponsReducer.previousPage,
      currentPage: partnerCouponsReducer.currentPage,
      loading: partnerCouponsReducer.loading,
      formLoading: partnerCouponsReducer.formLoading,
    }),
    isEqual
  );

  const onAdd = () => dispatch(toggleModal());
  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchPartnerCoupoons(payload));
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchPartnerCoupoons({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchPartnerCoupoons({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchPartnerCoupoons({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchPartnerCoupoons({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      isApproved,
      isVerified,
      dateFilter: formattedDate === "Invalid Date" ? undefined : formattedDate,
    };
    dispatch(fetchPartnerCoupoons(payload));
  }, [delaySearchText, formattedDate, dispatch, isApproved, isVerified]);

  const onEdit = ({ data }) => {
    dispatch(setForm(data));
    dispatch(toggleModal());
  };

  const columns = useMemo(
    () => [
      {
        Header: "Coupon",
        accessor: "code",
      },
      {
        Header: "Status",
        accessor: (row) => {
          const expirationDate = moment(row.expiration_date).tz("Asia/Manila");
          const startDate = moment(row.start_date).tz("Asia/Manila");
          const currentDate = moment().tz("Asia/Manila");

          if (currentDate.isBefore(startDate)) {
            return <Tag color="#87d068">active</Tag>;
          } else if (currentDate.isAfter(expirationDate)) {
            return <Tag color="#f50">expired</Tag>;
          } else {
            return <Tag color="#87d068">active</Tag>;
          }
        },
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: ({ value }) => {
          return moment(value).isValid()
            ? moment(value).tz("Asia/Manila").format("MMMM DD, YYYY")
            : "--";
        },
      },
      {
        Header: "Expiration Date",
        accessor: "expiration_date",
        Cell: ({ value }) => {
          return moment(value).isValid()
            ? moment(value).tz("Asia/Manila").format("MMMM DD, YYYY")
            : "--";
        },
      },
      {
        Header: "Delivery Fee discount (%)",
        accessor: "less_delivery_fee_pecentage",
      },
      {
        Header: "User Limit",
        accessor: "limit",
      },
      {
        Header: "Remaining",
        accessor: "remaining",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchPartnerCoupoons(defaultPage));
  }, [dispatch, defaultPage]);
  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>Coupon Codes</h1>
        <div
          className="p-flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
            {/* <Form.Item label="Select Date">
              <DatePicker
                style={{ width: "15vw" }}
                value={selectedDate}
                onChange={(date, dateString) => {
                  setSelectedDate(date);
                  setFormattedDate(dayjs(dateString).format("YYYY-MM-DD"));
                }}
                placeholder="Select Date"
              />
            </Form.Item> */}
          </Form>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={data}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          dateFilters={{ selectedDate }}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Create coupons
            </Button>,
          ]}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <PartnerCreateCouponCode />
          </>
        }
      />
    </>
  );
};

export default PartnerCoupone;
