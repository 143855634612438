import React, { useMemo } from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router";

function ProtectedRoute({ authInfo, component: Component, ...rest }) {
  const location = useLocation();
  const { pathname } = location;

  const redirectPathname = useMemo(() => {
    if (
      pathname.includes("/hub/") ||
      pathname.includes("/provincialOffice/") ||
      pathname.includes("/regionalOffice/")
    ) {
      return "/operationAccount/signin";
    } else if (pathname.includes("/riders/")) {
      return "/riders/signin";
    } else {
      return "/signin";
    }
  }, [pathname]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authInfo.isAuth) {
          return <Component />;
        } else {
          return (
            <Redirect
              to={{
                pathname: redirectPathname,
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default ProtectedRoute;
