import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Popconfirm,
  DatePicker,
  Modal,
  Descriptions,
  Checkbox,
  Upload,
  notification,
  Statistic,
  Row,
  Col,
} from "antd";
import {
  CheckSquareOutlined,
  BarsOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import formatCurrency from "../helpers/formatCurrency";
// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import {
  fetchAllFranchiseePayables,
  //   updatePayablesStatus,
  UpdateFranchiseePayables,
} from "../redux/actions/franchiseePayables";
import { fetchPayablesInfo } from "../redux/actions/payablesInfo";
import moment from "moment/moment";
import dayjs from "dayjs";
const { Content: LayoutContent } = Layout;
const { Search } = Input;

const FranchiseePayables = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [toformattedDate, setToFormattedDate] = useState(null);
  const [franchiseeModal, setFranchiseeModal] = useState(false);
  const [payablesInfoModal, setPayableInfoModal] = useState(false);

  const [franchisePayables, setFranchisePayables] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [update, setUpdate] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedData, setCheckedData] = useState(null);
  const [checkedDataAll, setCheckedDataAll] = useState(null);
  const [overAllTotalDf, setOverAllTotalDf] = useState(null);
  const [overAllTotalDfRiders, setOverAllTotalDfRiders] = useState(null);
  const [totalRiderCommission, setTotalRiderCommission] = useState(null);
  const [totalRiderRemittance, setTotalRiderRemittance] = useState(null);
  const [imageFileLogo, setImageFileLogo] = useState(null);
  const [imageUrlLogo, setImageUrlLogo] = useState("");
  const [setloading, setLoading] = useState(false);
  const {
    loading,
    franchiseePayable,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    userInfo,
    payablesInfo,
    totalPlatformFee,
    totalCollectedDf,
    totalRiderRemit,
  } = useSelector(
    ({ franchiseePayablesReducer, userLoginReducer, payableInfoReducer }) => ({
      userInfo: userLoginReducer.userInfo,
      payablesInfo: payableInfoReducer.data,
      franchiseePayable: franchiseePayablesReducer.data,
      totalRecord: franchiseePayablesReducer.totalRecord,
      nextPage: franchiseePayablesReducer.nextPage,
      previousPage: franchiseePayablesReducer.previousPage,
      currentPage: franchiseePayablesReducer.currentPage,
      loading: franchiseePayablesReducer.loading,
      totalCollectedDf: franchiseePayablesReducer.totalCollectedDf,
      totalPlatformFee: franchiseePayablesReducer.totalPlatformFee,
      totalRiderRemit: franchiseePayablesReducer.totalRiderRemit,
    }),
    isEqual
  );

  console.log(franchiseePayable);
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setInputValue(inputValue);
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    let dfTotal = [];
    franchiseePayable?.map((d) => {
      dfTotal.push(d.collectedShippingFee);
    });

    const total = dfTotal.reduce((acc, curr) => acc + parseFloat(curr), 0);

    setOverAllTotalDf(parseFloat(total).toFixed(0));
  }, [franchiseePayable]);

  useEffect(() => {
    let totalDfRider = [];
    payablesInfo.map((d) => totalDfRider.push(d.collectedShippingFee));
    const total = totalDfRider.reduce((acc, curr) => acc + parseFloat(curr), 0);
    setOverAllTotalDfRiders(parseFloat(total).toFixed(0));
  }, [payablesInfo]);

  useEffect(() => {
    let totalDf = [];
    let totalRiderCom = [];
    payablesInfo.map((d) => totalDf.push(d.collectedShippingFee));

    payablesInfo.map((d) =>
      totalRiderCom.push((d.collectedShippingFee / 100) * d.commission)
    );
    const total = totalRiderCom.reduce(
      (acc, curr) => acc + parseFloat(curr),
      0
    );
    setTotalRiderCommission(total);
  }, [payablesInfo]);

  useEffect(() => {
    let totalDf = [];
    let totalRiderCom = [];
    payablesInfo.map((d) => totalDf.push(d.collectedShippingFee));

    payablesInfo.map((d) =>
      totalRiderCom.push(
        d.collectedShippingFee - (d.collectedShippingFee / 100) * d.commission
      )
    );
    const total = totalRiderCom.reduce(
      (acc, curr) => acc + parseFloat(curr),
      0
    );
    setTotalRiderRemittance(total);
  }, [payablesInfo]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      dateFilter: formattedDate === "Invalid Date" ? undefined : formattedDate,
      todateFilter:
        toformattedDate === "Invalid Date" ? undefined : toformattedDate,
    };
    if (toformattedDate !== null) {
      dispatch(fetchAllFranchiseePayables(payload));
    }
  }, [delaySearchText, toformattedDate, dispatch, formattedDate]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllFranchiseePayables(payload));
  };
  useEffect(() => {
    const checkedCollectedFees = isChecked.map((d) =>
      parseInt(d.collectedShippingFee)
    );
    const data = isChecked.map((d) => d.ordersDate);
    const formattedDates = data.map((date) => `'${date}'`).join(",");
    const total = checkedCollectedFees.reduce((curr, ucc) => curr + ucc, 0);

    const sortedDates1 = [...data].sort((a, b) => new Date(b) - new Date(a));
    const sortedDates2 = [...data].sort((a, b) => new Date(a) - new Date(b));

    const latestDate = sortedDates1[0];

    const oldest = sortedDates2[0];

    setIsCheckedAll(false);
    setCheckedData({
      totalFees: total,
      date: formattedDates,
      latestDate: latestDate,
      oldest: oldest,
    });
  }, [isChecked, totalPlatformFee, totalCollectedDf, totalRiderRemit]);

  const checkAll = (e) => {
    if (e) {
      const i = franchiseePayable.map((d) => parseInt(d.collectedShippingFee));
      const j = franchiseePayable.map((d) => d.ordersDate);
      const formattedDates = j.map((date) => `'${date}'`).join(",");
      const total = i.reduce((curr, ucc) => curr + ucc, 0);

      const sortedDates1 = [...j].sort((a, b) => new Date(b) - new Date(a));
      const sortedDates2 = [...j].sort((a, b) => new Date(a) - new Date(b));

      const latestDate = sortedDates1[0];

      const oldest = sortedDates2[0];
      setCheckedDataAll(franchiseePayable);
      setIsChecked(franchiseePayable);
      setCheckedData({
        totalFees: total,
        date: formattedDates,
        latestDate: latestDate,
        oldest: oldest,
      });

      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
      setIsChecked([]);
    }
  };

  const handleChangeLogo = (info) => {
    setLoading(true);
    const file = info.file.originFileObj;

    setImageFileLogo(file);

    const localUrl = URL.createObjectURL(file);
    setImageUrlLogo(localUrl);
    setLoading(false);
  };

  const uploadButtonLogo = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {setloading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>Partner Payables</h1>
        <div
          style={{
            backgroundColor: "#fafafa",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
            justifyContent: "space-between",
          }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
            {franchiseePayable.length !== 0 && (
              <>
                {isChecked.length >= 2 ||
                isChecked.length === franchiseePayable.length ? (
                  <Button
                    onClick={() => setFranchiseeModal(true)}
                    style={{ marginLeft: 5, marginRight: 10 }}
                    size="medium"
                  >
                    Deposit
                  </Button>
                ) : (
                  <></>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Checkbox
                    checked={isChecked.length === franchiseePayable.length}
                    onChange={(e) => checkAll(e.target.checked)}
                  ></Checkbox>
                  <span>Check ALL</span>
                </div>
              </>
            )}

            {/* <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Form.Item label="From:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={selectedDate}
                  onChange={(date, dateString) => {
                    setSelectedDate(date);
                    setFormattedDate(
                      dayjs(dateString)
                        .startOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item>
              <Form.Item label="To:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={toSelectedDate}
                  onChange={(date, dateString) => {
                    setToSelectedDate(date);
                    setToFormattedDate(
                      dayjs(dateString)
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item>
            </div> */}
          </Form>

          {/* <div>
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Total Delivery Fee Collection{" "}
                {formatCurrency(totalCollectedDf || 0)}
              </span>
            </div>
            <div>
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Total platfrom fee{" "}
                {formatCurrency(
                  // (overAllTotalDf * userInfo.dory_commissions) / 100
                  totalPlatformFee || 0
                )}
              </span>
            </div>
            <div>
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Total Rider Remittance{" "}
                {formatCurrency(
                  // (overAllTotalDf * userInfo.dory_commissions) / 100
                  totalRiderRemit || 0
                )}
              </span>
            </div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "30%",
            marginLeft: 25,
            marginTop: 25,
          }}
        >
          <Col span={12}>
            <Statistic
              title="Total Delivery Fee Collection"
              value={formatCurrency(totalCollectedDf || 0)}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Total platfrom fee"
              value={formatCurrency(totalPlatformFee || 0)}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Total Riders Remittance"
              value={formatCurrency(totalRiderRemit || 0)}
            />
          </Col>
        </div>
        {/* <div
          style={{
            width: "40%",
            border: "1px solid gray",
            marginLeft: 25,
          }}
        ></div> */}
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllFranchiseePayables({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllFranchiseePayables({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllFranchiseePayables({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllFranchiseePayables({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };
  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };
  const deposit = async (payload) => {
    const formData = new FormData();
    formData.append(
      "dailyCollection",
      isChecked.length >= 2
        ? checkedData.totalFees
        : franchisePayables.collectedShippingFee
    );

    formData.append(
      "totalPayables",
      isChecked.length >= 2
        ? (checkedData.totalFees * userInfo?.dory_commissions) / 100
        : (franchisePayables.collectedShippingFee *
            userInfo?.dory_commissions) /
            100
    );

    formData.append("user_id", userInfo.id);
    formData.append("reference_number", inputValue);

    formData.append(
      "date",
      isChecked.length >= 2
        ? checkedData.date
        : `'${franchisePayables.ordersDate}'`
    );

    formData.append(
      "latestDate",
      isChecked.length >= 2
        ? checkedData.latestDate
        : franchisePayables.ordersDate
    );

    formData.append(
      "oldestDate",
      isChecked.length >= 2 ? checkedData.oldest : franchisePayables.ordersDate
    );

    formData.append("image", imageFileLogo);
    formData.append("userName", userInfo.user_name);

    const { data } = await dispatch(UpdateFranchiseePayables(formData));
    if (data) {
      openNotification("SUCCESS", data.message);
      setUpdate((prevUpdate) => !prevUpdate);
      setIsChecked([]);
      setImageUrlLogo("");
      setImageFileLogo(null);
    }

    // dispatch(UpdateFranchiseePayables(payload));
  };

  const items = [
    {
      key: "1",
      label: "Total Collection",
      children:
        isChecked.length >= 2
          ? checkedData.totalFees
          : franchisePayables.collectedShippingFee,
    },
    {
      key: "2",
      label: "Platform Fee",
      children:
        isChecked.length >= 2
          ? (checkedData.totalFees * userInfo?.dory_commissions) / 100
          : (franchisePayables.collectedShippingFee *
              userInfo?.dory_commissions) /
            100,
    },
    {
      key: "3",
      label: "MUST READ!",
      children: "You can send your payables in DORY BDO account",
      span: 2,
    },
    {
      key: "4",
      label: "BDO Account number",
      children: "005210450474",
    },
    {
      key: "5",
      label: "Account Name",
      children: "DORY PH INC",
    },
  ];

  const handleCheckboxChange = (item) => {
    const i = isChecked.some((checkedItem) => checkedItem.id === item.id);
    if (!i) {
      if (isCheckedAll) {
        checkAll(false);
        setIsCheckedAll(false);
        setIsChecked(
          checkedDataAll.filter((checkedItem) => checkedItem.id !== item.id)
        );
      } else {
        setIsCheckedAll(false);
        setIsChecked([...isChecked, item]);
      }
    } else {
      setIsChecked(
        isChecked.filter((checkedItem) => checkedItem.id !== item.id)
      );
    }
  };

  const handlePayableInfo = (date) => {
    dispatch(fetchPayablesInfo(date));
    setPayableInfoModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Checkbox
              checked={
                isCheckedAll
                  ? true
                  : isChecked.some(
                      (checkedItem) => checkedItem.id === original.id
                    )
              }
              onChange={() => handleCheckboxChange(original)}
            ></Checkbox>

            <Button
              onClick={() => {
                setFranchiseeModal(true);
                setFranchisePayables(original);
              }}
              disabled={isChecked.length >= 2}
              style={{ marginLeft: 5 }}
              size="small"
            >
              Deposit
            </Button>

            <Tooltip title="More info">
              <BarsOutlined
                style={{ marginLeft: 5, fontSize: 15 }}
                onClick={() => handlePayableInfo(original.ordersDate)}
              />
            </Tooltip>
          </>
        ),
        width: 100,
      },
      {
        Header: "Total DF Collection",
        accessor: "collectedShippingFee",
      },
      {
        Header: "Platform fee",
        accessor: "platform_fee",
      },
      {
        Header: "Rider Commission",
        accessor: "rider_commision",
      },
      {
        Header: "Rider Remittance",
        accessor: "rider_remittance",
      },
      {
        Header: "Date",
        accessor: "ordersDate",
      },
    ],
    [isChecked, isCheckedAll]
  );

  useEffect(() => {
    dispatch(fetchAllFranchiseePayables(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={franchiseePayable}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          //   dateFilters={{ selectedDate }}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <Modal
              open={payablesInfoModal}
              onOk={() => {
                setPayableInfoModal(false);
              }}
              onCancel={() => {
                setPayableInfoModal(false);
              }}
              width={950}
            >
              <Descriptions
                title="PAYABLE DETAILS"
                bordered
                size={"large"}
                column={5}
              >
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  Rider Name
                </Descriptions.Item>
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  Collected Delivery Fee
                </Descriptions.Item>
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  Rider Commission
                </Descriptions.Item>
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  Rider Remittance
                </Descriptions.Item>
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  Date
                </Descriptions.Item>
                {payablesInfo.map((item) => (
                  <>
                    <Descriptions.Item>
                      {item.riderName === null
                        ? item.partnerUserName
                        : item.riderName}
                    </Descriptions.Item>
                    <Descriptions.Item>
                      {formatCurrency(item.collectedShippingFee)}
                    </Descriptions.Item>

                    <Descriptions.Item>
                      {item.commission !== null
                        ? formatCurrency(
                            (item.collectedShippingFee / 100) * item.commission
                          )
                        : "PARTNER ACCOUNT"}
                    </Descriptions.Item>
                    <Descriptions.Item>
                      {item.commission !== null
                        ? formatCurrency(
                            item.collectedShippingFee -
                              (item.collectedShippingFee / 100) *
                                item.commission
                          )
                        : "PARTNER ACCOUNT"}
                    </Descriptions.Item>
                    <Descriptions.Item>{item.ordersDate}</Descriptions.Item>
                  </>
                ))}
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  <span style={{ marginLeft: 45, fontWeight: "bold" }}>
                    TOTAL
                  </span>
                </Descriptions.Item>
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  {formatCurrency(overAllTotalDfRiders)}
                </Descriptions.Item>
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  {formatCurrency(totalRiderCommission)}
                </Descriptions.Item>
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  {formatCurrency(totalRiderRemittance)}
                </Descriptions.Item>
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  {" "}
                  {payablesInfo[0]?.ordersDate}
                </Descriptions.Item>
              </Descriptions>
            </Modal>

            <Modal
              open={franchiseeModal}
              onOk={() => {
                deposit();
                setFranchiseeModal(false);
                setInputValue("");
              }}
              onCancel={() => {
                setFranchiseeModal(false);
                setInputValue("");
              }}
              okButtonProps={{ disabled: imageFileLogo === null }}
            >
              {/* <p>
                <span style={{ fontWeight: "bold" }}> Total Collection: </span>{" "}
                {franchisePayables.collectedShippingFee}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}> Total Payable: </span>
                {(franchisePayables.collectedShippingFee *
                  userInfo?.dory_commissions) /
                  100}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}> GCASH NUMBER:</span>{" "}
                09269310186
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}> ACOUNT NAME:</span> Jerimy
                Neri
              </p> */}
              <Descriptions
                title="PAYABLE DETAILS"
                bordered
                items={items}
                size={"medium"}
                column={1}
              />

              {/* <div
                style={{
                  marginTop: 15,
                  marginLeft: 5,
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <span style={{ fontWeight: "bold" }}>Enter Ref No.</span>{" "}
                <Input
                  placeholder="Enter Ref No."
                  allowClear
                  style={{ width: "15VW" }}
                  value={inputValue}
                  type="secondary"
                  size="small"
                  onChange={handleChange}
                />
              </div> */}
              <Form>
                <div style={{ marginTop: 10 }}>
                  <p>
                    <span style={{ color: "red" }}> * </span>Proof of
                    transaction
                  </p>
                  <Upload
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    onChange={handleChangeLogo}
                  >
                    {imageUrlLogo ? (
                      <img
                        src={imageUrlLogo}
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    ) : (
                      uploadButtonLogo
                    )}
                  </Upload>
                </div>
              </Form>
            </Modal>
          </>
        }
      />
    </>
  );
};
export default FranchiseePayables;
