const formatCurrency = (value) => {
  const formatter = new Intl.NumberFormat("en-PH", {
    style: "currency",
    currency: "PHP",
  });

  return formatter.format(value);
};

export default formatCurrency;
