import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";
import axios from "axios";

import { Form, Row, Col, Button, Modal, notification } from "antd";

// Actions
// import {
//   toggleModal,
//   create,
//   setForm,
//   update,
// } from "../../redux/actions/users";
import {
  toggleModal,
  createNationalMerchantCategories,
  setForm,
  updateNationalMerchantCategories,
} from "../../redux/actions/nationalMerchantMenuCategories";

// Utilities
import renderInput from "../utilities/inputForms";
import { getRegionByProvince } from "../utilities/getPhillipineMajorIslands";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const NationalMerchantCategories = ({ changePassword = false }) => {
  const dispatch = useDispatch();
  // const [selectedImageName, setSelectedImageName] = useState("");
  const [cities, setCities] = useState([]);
  const { id } = useParams();
  const [barangays, setBarangays] = useState([]);
  const [isProvinceEdited, setIsProvinceEdited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [isBarangayEdited, setIsBarangayEdited] = useState(false);

  const { modal, formLoading, error, form, data } = useSelector(
    ({ nationalMerchantCategoryReducer, userLoginReducer }) => ({
      modal: nationalMerchantCategoryReducer.modal,
      formLoading: nationalMerchantCategoryReducer.formLoading,
      error: nationalMerchantCategoryReducer.error,
      form: nationalMerchantCategoryReducer.form,
      userInfo: userLoginReducer.userInfo,
      data: nationalMerchantCategoryReducer.data,
    }),
    isEqual
  );

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  const schema = yup.object().shape({
    title: yup.string().required("Name is required field"),
  });

  let defaultValues = {
    title: "",
  };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    setIsProvinceEdited(false);
    setIsCityEdited(false);
    setIsBarangayEdited(false);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
    setIsProvinceEdited(true);
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    setBarangays(phil.getBarangayByMun(mun_code));
    setIsCityEdited(true);
  };
  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        const newValues = {
          title: values.title,
        };
        const toMatch = omit(form, ["id"]);
        const changesKeys = Object.keys(
          ObjectDiff(toMatch, { ...toMatch, ...newValues })
        );
        if (!changesKeys.length) {
          return openNotification("INFO", "No changes made.");
        }

        const { data } = await dispatch(
          updateNationalMerchantCategories({
            ...newValues,
            id: form.id,
          })
        );
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      } else {
        const newData = {
          title: values.title,
          national_merchant_id: id,
        };
        const { data } = await dispatch(
          createNationalMerchantCategories(newData)
        );
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        {changePassword ? (
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Input New Password",
                name: "newPassword",
                errors: errors,
                type: "password",
              },
              control
            )}
          </Col>
        ) : (
          <>
            {/* <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Address",
                name: "address",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col> */}

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Title",
                    name: "title",
                    errors: errors,
                    required: "true",
                  },
                  control
                )}
              </Col>
            </Row>
          </>
        )}

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default NationalMerchantCategories;
