import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { STORES } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: STORES.TOGGLE, payload });
  };

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: STORES.TOGGLEVIEW, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: STORES.SET, payload });
};

export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: STORES.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      "/api/groceria/partner-stores",
      payload
    );
    dispatch({ type: STORES.CREATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: STORES.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const update = (payload) => async (dispatch, getState) => {
  dispatch({ type: STORES.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).put(
      `/api/groceria/partner-stores/${payload.id}`,
      payload
    );
    dispatch({ type: STORES.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: STORES.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const fetchAllPartnerStore = (payload) => async (dispatch, getState) => {
  dispatch({ type: STORES.FETCH.REQUEST });
  const { page, limit, searchText } = payload;

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  let { city } = userInfo;
  let { province } = userInfo;
  let { role } = userInfo;

  console.log(city);
  try {
    let url = `/api/groceria/partner-stores`;
    if (role === "operator_admin") {
      url = `/api/groceria/partner-stores?page=${page}&limit=${limit}&role=${role}&city=${city}&province=${province}`;
    } else {
      url = `/api/groceria/partner-stores?page=${page}&limit=${limit}`;
    }
    if (page && limit && searchText) {
      if (role === "operator_admin") {
        url = `/api/groceria/partner-stores?page=${page}&limit=${limit}&role=${role}&city=${city}&province=${province}&searchText=${searchText}`;
      } else {
        url = `/api/groceria/partner-stores?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }

    const response = await axiosInstance(token).get(url);

    dispatch({
      type: STORES.FETCH.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: STORES.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const updatePartnerStore = (payload) => async (dispatch, getState) => {
  dispatch({ type: STORES.UPDATE.REQUEST });

  const { status, id } = payload;

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;

  try {
    const response = await axiosInstance(token).put(
      `/api/groceria/partner-stores/${id}/update-status`,
      {
        isActive: status,
      }
    );

    dispatch({ type: STORES.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: STORES.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
