import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { PAYABLESTATUSINFO } = actions;

export const payablesStatusInfo = (payload) => async (dispatch, getState) => {
  dispatch({ type: PAYABLESTATUSINFO.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    let url = `/api/franchisee-payables/collection/${userInfo.id}/${payload.dateTo}/${payload.dateFrom}`;
    const response = await axiosInstance(token).get(url);

    dispatch({
      type: PAYABLESTATUSINFO.FETCH.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: PAYABLESTATUSINFO.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
