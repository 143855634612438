import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { MANAGEOPERATOR } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MANAGEOPERATOR.TOGGLE, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: MANAGEOPERATOR.SET, payload });
};

export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: MANAGEOPERATOR.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).post(
      "/api/user/operator-account",
      payload
    );

    dispatch({ type: MANAGEOPERATOR.CREATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: MANAGEOPERATOR.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const fetchOperators = (payload) => async (dispatch, getState) => {
  dispatch({ type: MANAGEOPERATOR.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  let { city } = userInfo;
  let { province } = userInfo;
  let { role } = userInfo;
  try {
    let url = `/api/manage-operators`;
    const {
      page,
      limit,
      searchText,
      isApproved,
      isVerified,
      dateFilter,
      todateFilter,
    } = payload;
    if (payload !== undefined) {
      url = `/api/manage-operators/?page=${page}&limit=${limit}`;
      if (role === "operator_admin") {
        url = `/api/manage-operators?page=${page}&limit=${limit}&role=${role}&city=${city}&province=${province}&partner_id=${userInfo.id}`;
      }
      if ((page && limit && isVerified === 1) || isVerified === 0) {
        if (role === "operator_admin") {
          url = `/api/manage-operators?page=${page}&limit=${limit}&is_verified=${isVerified}&role=${role}&city=${city}&province=${province}&partner_id=${userInfo.id}`;
        } else {
          url = `/api/manage-operators/?page=${page}&limit=${limit}&is_verified=${isVerified}`;
        }
      }
      if ((page && limit && isApproved === 1) || isApproved === 0) {
        if (role !== "operator_admin") {
          url = `/api/manage-operators/?page=${page}&limit=${limit}&is_approved=${isApproved}`;
        } else {
          url = `/api/manage-operators?page=${page}&limit=${limit}&is_approved=${isApproved}&role=${role}&city=${city}&province=${province}`;
        }
      }
      if (page && limit && searchText) {
        if (role !== "operator_admin") {
          url = `/api/manage-operators/?page=${page}&limit=${limit}&searchText=${searchText}`;
        } else {
          url = `/api/manage-operators?page=${page}&limit=${limit}&searchText=${searchText}&role=${role}&city=${city}&province=${province}&partner_id=${userInfo.id}`;
        }
      }
      if (page && limit && dateFilter && todateFilter) {
        console.log(dateFilter, " + ", todateFilter);
        url = `/api/manage-operators/?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}`;
      }
    }
    const response = await axiosInstance(token).get(url);

    dispatch({
      type: MANAGEOPERATOR.FETCH.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: MANAGEOPERATOR.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const activateOperator = (payload) => async (dispatch, getState) => {
  dispatch({ type: MANAGEOPERATOR.UPDATE.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;

  try {
    const response = await axiosInstance(token).put(
      `/api/manage-operators/activate/${payload.id}/${payload.status}`
    );

    dispatch({
      type: MANAGEOPERATOR.UPDATE.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: MANAGEOPERATOR.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const verifiedOperator = (payload) => async (dispatch, getState) => {
  dispatch({ type: MANAGEOPERATOR.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).put(
      `/api/manage-operators/verify/${payload.user_id}`
    );

    dispatch({
      type: MANAGEOPERATOR.UPDATE.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: MANAGEOPERATOR.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const approveOperator = (payload) => async (dispatch, getState) => {
  dispatch({ type: MANAGEOPERATOR.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).put(
      `/api/manage-operators/approval/${payload}`,
      {
        payload,
      }
    );

    dispatch({
      type: MANAGEOPERATOR.UPDATE.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: MANAGEOPERATOR.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const update = (payload) => async (dispatch, getState) => {
  dispatch({ type: MANAGEOPERATOR.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).patch(
      "/api/manage-operators",
      payload
    );

    dispatch({ type: MANAGEOPERATOR.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: MANAGEOPERATOR.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const softDeletion = (payload) => async (dispatch, getState) => {
  dispatch({ type: MANAGEOPERATOR.SOFTDELETION.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).put(
      `/api/manage-operators/delete/${payload.user_id}?partner_id=${
        userInfo.id
      }&role=${userInfo.role}&page=${1}&limit=${15}`
    );
    dispatch({ type: MANAGEOPERATOR.SOFTDELETION.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: MANAGEOPERATOR.SOFTDELETION.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
