import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { MERCHANTCATEGORY } = actions;

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTCATEGORY.TOGGLEVIEW, payload });
  };

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTCATEGORY.TOGGLE, payload });
  };
export const setForm = (payload) => (dispatch) => {
  dispatch({ type: MERCHANTCATEGORY.SET, payload });
};

export const fetch = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTCATEGORY.FETCH.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const { page, limit, searchText } = payload;
    let url = `/api/merchant/menu-category/${payload.userId}`;
    if (payload !== undefined) {
      url = `/api/merchant/menu-category/${payload.userId}?page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/merchant/menu-category/${payload.userId}?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }

    const response = await axiosInstance(token).get(url);
    console.log(response);
    dispatch({
      type: MERCHANTCATEGORY.FETCH.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTCATEGORY.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTCATEGORY.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      `/api/merchant/menu-category`,
      payload
    );
    dispatch({ type: MERCHANTCATEGORY.CREATE.SUCCESS, payload: response });

    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTCATEGORY.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const update = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTCATEGORY.UPDATE.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).put(
      `/api/merchant/menu-category/update`,
      payload
    );

    dispatch({ type: MERCHANTCATEGORY.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTCATEGORY.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const updateCategoryStatus = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTCATEGORY.UPDATESTATUS.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const status = payload.isActive ? "activate" : "deactivate";

    const response = await axiosInstance(token).put(
      `/api/merchant/menu-category/status/${status}/${payload.categoryId}`,
      payload
    );

    dispatch({
      type: MERCHANTCATEGORY.UPDATESTATUS.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTCATEGORY.UPDATESTATUS.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const softDeletionCategory = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTCATEGORY.SOFTDELETION.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).put(
      `/api/merchant/menu-category/delete/${payload.data.id}/${payload.merchantId}`
    );
    dispatch({
      type: MERCHANTCATEGORY.SOFTDELETION.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTCATEGORY.SOFTDELETION.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
