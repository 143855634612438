import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Button, Form, Input, Card, notification, Typography } from "antd";

// Components
import Wrapper from "../components/wrapper";

// Actions
import { fetchSucceedingRates } from "../redux/actions/succeedingDistanceRate";
import { createMultipleDeliveryFeeSetting } from "../redux/actions/deliveryFeeSettings";

const { Title } = Typography;

const DeliveryFeeSettings = () => {
  const [formAntd] = Form.useForm();
  const [formAntdPabiliPadala] = Form.useForm();
  const dispatch = useDispatch();

  const [areas, setAreas] = useState([]);
  const [areasDfeePabili, setAreasDfeePabili] = useState([]);
  const [loadingSaving, setLoadingSaving] = useState(false);

  const {
    loading,
    succeedingRateData,
    userInfo,
    succeedingRatePabiliPadalaData,
  } = useSelector(
    ({ succeedingRateReducer, userLoginReducer }) => ({
      succeedingRateData: succeedingRateReducer.data,
      succeedingRatePabiliPadalaData:
        succeedingRateReducer.succeedingRatePabiliPadala,
      loading: succeedingRateReducer.loading,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  useEffect(() => {
    if (userInfo.role === "operator_admin") {
      try {
        if (
          succeedingRateData.length !== 0 &&
          !loading &&
          succeedingRatePabiliPadalaData.length !== 0
        ) {
          const dfeeSettingData = succeedingRateData.map((d, index) => {
            return {
              areaId: d.id,
              city: d.municipality,
              province: d.province,
              key: index,
              baseDeliveryFee: d.based_delivery_fee,
              succeedingRate: d.amount,
            };
          });

          setAreas(dfeeSettingData);

          const dfeeSettingsPabiliPadala = succeedingRatePabiliPadalaData.map(
            (d, index) => {
              return {
                areaId: d.id,
                city: d.municipality,
                province: d.province,
                key: index,
                baseDeliveryFee: d.based_delivery_fee,
                succeedingRate: d.amount,
              };
            }
          );

          setAreasDfeePabili(dfeeSettingsPabiliPadala);

          formAntd.setFieldsValue({
            areas: dfeeSettingData,
          });

          formAntdPabiliPadala.setFieldsValue({
            areasDfeePabili: dfeeSettingsPabiliPadala,
          });

          //
        } else {
          const parsedAreas = JSON.parse(userInfo.grouped_areas);
          if (Array.isArray(parsedAreas)) {
            let a = parsedAreas.map((b, index) => ({
              areaId: index,
              city: b,
              province: userInfo.province,
              key: index,
              baseDeliveryFee: 40,
              succeedingRate: 7,
            }));

            setAreas(a);

            formAntd.setFieldsValue({
              areas: a,
            });

            formAntdPabiliPadala.setFieldsValue({
              areasDfeePabili: a,
            });
          }
        }
      } catch (error) {
        console.error("Error parsing variations:", error);
      }
    } else {
      formAntd.setFieldsValue({
        variations: [],
      });
    }
  }, [userInfo.grouped_areas, succeedingRateData]);
  //

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  useEffect(() => {
    dispatch(fetchSucceedingRates(defaultPage));
  }, [dispatch, defaultPage, loadingSaving]);

  const onFinish = async (values) => {
    try {
      setLoadingSaving(true);
      let dfeeSettingsData = [];

      if (values.hasOwnProperty("areasDfeePabili")) {
        dfeeSettingsData = values.areasDfeePabili;
      } else {
        dfeeSettingsData = values.areas;
      }

      let areaDeliverySettings = dfeeSettingsData.map((b) => {
        return {
          baseDeliveryFee: b.baseDeliveryFee,
          city: b?.city,
          province: b.province,
          succeedingRate: b.succeedingRate,
          areaId: b.areaId,
        };
      });

      const dataDeliveryFeeSetting = {
        areaDeliveryFeeObject: areaDeliverySettings,
        partners_id: userInfo.id,
        willUpdate: succeedingRateData.length !== 0,
      };

      const response = await dispatch(
        createMultipleDeliveryFeeSetting(dataDeliveryFeeSetting)
      );

      if (response) {
        openNotification("SUCCESS", response.data.message);
      }
      setLoadingSaving(false);
    } catch (error) {
      console.log(">>>> error creating/updating delivery fee settings");
      setLoadingSaving(false);
      openNotification("ERROR", "Something Went Wrong");
    }
  };

  const renderContent = () => {
    return (
      <div style={{ display: "flex", gap: "100px" }}>
        <Card size="medium" title="Delivery Fee Settings">
          {areas.length !== 0 && !loading && (
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              style={{
                maxWidth: 600,
              }}
              autoComplete="off"
              form={formAntd}
              layout="vertical"
            >
              <Form.List name="areas">
                {(fields) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <>
                        <div>
                          <Title level={4}>{areas[key]?.city}</Title>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 50,
                          }}
                          className="border-b-2 border-gray-100 mb-4"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "baseDeliveryFee"]}
                            label="Based delivery fee (within 1km)"
                            rules={[
                              {
                                required: true,
                                message: "Missing Based Delivery fee",
                              },
                              {
                                validator: (_, value) => {
                                  if (value && value < 30) {
                                    return Promise.reject(
                                      "Base delivery fee must be at least 30"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                            initialValue={areas[key]?.baseDeliveryFee || "5"}
                          >
                            <Input placeholder="40" type="number" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "succeedingRate"]}
                            label="Succeeding rate (in every 1km added)"
                            rules={[
                              {
                                required: true,
                                message: "Missing Succeeding rate delivery fee",
                              },
                              {
                                validator: (_, value) => {
                                  if (value && value < 5) {
                                    return Promise.reject(
                                      " Succeeding rate delivery fee must be at least 5"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                            initialValue={areas[key]?.succeedingRate || "5"}
                          >
                            <Input placeholder="7" type="number" />
                          </Form.Item>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingSaving}
                  block
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
        <Card size="medium" title="Pabili/Padala Delivery fee Settings">
          {areasDfeePabili.length !== 0 && !loading && (
            <Form
              name="dynamic_form_nest_item_dfee"
              onFinish={onFinish}
              style={{
                maxWidth: 600,
              }}
              autoComplete="off"
              form={formAntdPabiliPadala}
              layout="vertical"
            >
              <Form.List name="areasDfeePabili">
                {(fields) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <>
                        <div>
                          <Title
                            level={4}
                          >{`${areasDfeePabili[key]?.city} (as Pick Up Location)`}</Title>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 50,
                          }}
                          className="border-b-2 border-gray-100 mb-4"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "baseDeliveryFee"]}
                            label="Based delivery fee (within 1km)"
                            rules={[
                              {
                                required: true,
                                message: "Missing Based Delivery fee",
                              },
                              {
                                validator: (_, value) => {
                                  if (value && value < 30) {
                                    return Promise.reject(
                                      "Base delivery fee must be at least 30"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                            initialValue={
                              areasDfeePabili[key]?.baseDeliveryFee || "5"
                            }
                          >
                            <Input placeholder="40" type="number" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "succeedingRate"]}
                            label="Succeeding rate (in every 1km added)"
                            rules={[
                              {
                                required: true,
                                message: "Missing Succeeding rate delivery fee",
                              },
                              {
                                validator: (_, value) => {
                                  if (value && value < 5) {
                                    return Promise.reject(
                                      " Succeeding rate must be at least 5"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                            initialValue={
                              areasDfeePabili[key]?.succeedingRate || "5"
                            }
                          >
                            <Input placeholder="7" type="number" />
                          </Form.Item>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingSaving}
                  block
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </div>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} />
    </>
  );
};

export default DeliveryFeeSettings;
