import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "philippine-location-json-for-geer";
import axios from "axios";

import { Form, Row, Col, Button, Modal, notification } from "antd";

import {
  toggleModal,
  reportAccount,
  update,
} from "../../redux/actions/riderInMyArea";

// Utilities
import renderInput from "../utilities/inputForms";
// import { getRegionByProvince } from "../utilities/getPhillipineMajorIslands";

// import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ReportAccountModal = ({ partner_id = null, rider_id = null }) => {
  const dispatch = useDispatch();
  const [cities, setCities] = useState([]);
  //   const { merchantId } = useParams();
  //   const [barangays, setBarangays] = useState([]);
  //   const [isProvinceEdited, setIsProvinceEdited] = useState(false);
  //   const [isCityEdited, setIsCityEdited] = useState(false);
  //   const [isBarangayEdited, setIsBarangayEdited] = useState(false);

  const { modal, reportLoading, error, form, data } = useSelector(
    ({ riderReducer, userLoginReducer }) => ({
      modal: riderReducer.modal,
      reportLoading: riderReducer.reportLoading,
      error: riderReducer.error,
      form: riderReducer.form,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  const schema = yup.object().shape({
    report_reason: yup.string().required("Report reason is required"),
  });

  let defaultValues = {
    report_reason: "",
  };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    // dispatch(setForm({}));
    // setIsProvinceEdited(false);
    // setIsCityEdited(false);
    // setIsBarangayEdited(false);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onSubmit = async (values) => {
    try {
      const newData = {
        reason: values.report_reason,
        partner_id: partner_id,
        rider_id: rider_id,
      };
      const { data } = await dispatch(reportAccount(newData));
      if (data) {
        openNotification("SUCCESS", data.message);
        close();
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  return (
    <Modal
      title={"Reason"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Reason",
                name: "report_reason",
                errors: errors,
                required: "true",
                type: "textArea",
              },
              control
            )}
          </Col>
        </Row>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={reportLoading}>
            Report
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ReportAccountModal;
