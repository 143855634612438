import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";

import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  notification,
  Upload,
  Tag,
  Space,
  Select,
  Switch,
} from "antd";
import moment from "moment";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { fetchAllMerchantTag } from "../../redux/actions/partnerMerchants";

import {
  clearError,
  setForm,
  toggleModal,
  create,
} from "../../redux/actions/nationalMerchants";
// Utilities
import renderInput from "../utilities/inputForms";

const NationalMerchantModal = () => {
  const dispatch = useDispatch();

  const { modal, formLoading, error, form, tags, userInfo } = useSelector(
    ({ merchantsReducer, userLoginReducer, nationalMerchantsReducer }) => ({
      modal: nationalMerchantsReducer.modal,
      formLoading: nationalMerchantsReducer.formLoading,
      error: nationalMerchantsReducer.error,
      form: nationalMerchantsReducer.form,
      userInfo: userLoginReducer.userInfo,
      tags: merchantsReducer.tags,
    }),
    isEqual
  );

  const [selectedTags, setSelectedTags] = useState([]);

  // logo
  const [imageFileLogo, setImageFileLogo] = useState(null);
  const [imageUrlLogo, setImageUrlLogo] = useState("");
  const [loading, setLoading] = useState(false); // same with featured

  //featured Image
  const [imageFileFeatured, setImageFileFeatured] = useState(null);
  const [imageUrlFeatured, setImageUrlFeatured] = useState("");

  const handleChangeLogo = (info) => {
    setLoading(true);
    const file = info.file.originFileObj;

    setImageFileLogo(file);

    const localUrl = URL.createObjectURL(file);
    setImageUrlLogo(localUrl);
    setLoading(false);
  };

  const handleChangeFeatured = (info) => {
    setLoading(true);
    const file = info.file.originFileObj;

    setImageFileFeatured(file);

    const localUrl = URL.createObjectURL(file);
    setImageUrlFeatured(localUrl);
    setLoading(false);
  };

  const uploadButtonLogo = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  // test

  const hasForm = form ? Object.keys(form).length !== 0 : false;
  const yourMaxSizeInBytes = 5 * 1024 * 1024;

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (hasForm) {
      if (form.merchant_tags === null) {
        setSelectedTags([]);
      } else {
        const tagsArray = JSON.parse(form?.merchant_tags);

        setSelectedTags(tagsArray);
      }
    }
  }, [hasForm, form.merchant_tags, tags]);

  const schema = yup.object().shape({
    name: yup.string().required("Name is required field"),
  });

  let defaultValues = {
    name: "",
  };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));

    setSelectedTags([]);

    setImageFileLogo(null);
    setImageFileFeatured(null);
    setImageUrlLogo("");
    setImageUrlFeatured("");
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        if (selectedTags.length === 0) {
          openNotification("ERROR", "Select at least one tag");
          return;
        } else if (selectedTags.length > 8) {
          openNotification(
            "ERROR",
            "Tags should be limited to 8 selections, Please select the tags that best describe the merchant."
          );
          return;
        }

        const formData = new FormData();

        if (imageFileLogo) {
          formData.append("image", imageFileLogo);
        }

        if (imageFileFeatured) {
          formData.append("featuredImage", imageFileFeatured);
        }

        formData.append("name", values?.name);
        formData.append("merchant_tags", JSON.stringify(selectedTags));
        // working update logo no featured image
        //   const { data } = await dispatch(update({ formData, id: form.id }));
        //   if (data) {
        //     openNotification("SUCCESS", data.message);
        //     close();
        //   }
      } else {
        if (selectedTags.length === 0) {
          openNotification("ERROR", "Select at least one tag");
          return;
        } else if (selectedTags.length > 8) {
          openNotification(
            "ERROR",
            "Tags should be limited to 8 selections, Please select the tags that best describe the merchant."
          );
          return;
        }

        if (imageFileLogo === null) {
          openNotification("ERROR", "Logo is required");
          return;
        }

        if (imageFileFeatured === null) {
          openNotification("ERROR", "featured image is required");
          return;
        }

        const formData = new FormData();

        formData.append("featuredImage", imageFileFeatured);
        formData.append("name", values.name);
        formData.append("image", imageFileLogo);
        formData.append("merchant_tags", JSON.stringify(selectedTags));
        const { data } = await dispatch(create(formData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
          console.log(data.message);
        }
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
    }
  };

  useEffect(() => {
    if (error) {
      openNotification("ERROR", error);
      dispatch(clearError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (form) {
      reset(form);
    }
  }, [form, reset]);

  useEffect(() => {
    dispatch(fetchAllMerchantTag());
  }, [dispatch]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modal}
      width={700}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              {renderInput(
                {
                  label: "name",
                  name: "name",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
          </Row>

          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col span={5}>
              <>
                <p>
                  <span style={{ color: "red" }}> * </span>Logo
                </p>
                <Upload
                  name="image"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  onChange={handleChangeLogo}
                >
                  {hasForm ? (
                    imageUrlLogo ? (
                      <img
                        src={imageUrlLogo}
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    ) : (
                      <img
                        src={`https://groceria-storage.sgp1.cdn.digitaloceanspaces.com/assets/images/merchants/${form.image_url}`}
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    )
                  ) : imageUrlLogo ? (
                    <img
                      src={imageUrlLogo}
                      alt="avatar"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButtonLogo
                  )}
                </Upload>
              </>
            </Col>
            <Col span={5}>
              {/* featured */}
              <>
                <p>
                  <span style={{ color: "red" }}> * </span>Featured image
                </p>
                <Upload
                  name="featuredImage"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  onChange={handleChangeFeatured}
                >
                  {hasForm ? (
                    imageUrlFeatured ? (
                      <img
                        src={imageUrlFeatured}
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    ) : (
                      <img
                        src={`https://groceria-storage.sgp1.cdn.digitaloceanspaces.com/assets/images/partner-merchant-featured-image/${form.featured_image_url}`}
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    )
                  ) : imageUrlFeatured ? (
                    <img
                      src={imageUrlFeatured}
                      alt="avatar"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButtonLogo //same as featured
                  )}
                </Upload>
              </>
            </Col>
          </Row>

          <Row gutter={[16]}>
            <Col span={24}>
              <p>
                <span style={{ color: "red" }}> * </span>Tags
              </p>
              <Select
                mode="multiple"
                value={selectedTags}
                filterOption={filterOption}
                onChange={setSelectedTags}
                defaultValue={[]}
                showSearch
                optionFilterProp="children"
                style={{
                  width: "100%",
                }}
                options={tags}
              />
            </Col>
          </Row>
        </>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default NationalMerchantModal;
