import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Button, Tooltip, Form, Input, Badge, Tag } from "antd";
import { CheckSquareOutlined, CheckSquareFilled } from "@ant-design/icons";
import dayjs from "dayjs";

// Components
import Wrapper from "../components/wrapper";

import ServerSideTable from "../components/table/serverSideTable";

// Actions
// import { toggleModal, setForm } from "../redux/actions/users";
import {
  fetchAllRaffleTickets,
  toggleModal,
  setForm,
  updateVerifiedTicketStatus,
} from "../redux/actions/raffleTickets";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const RaffleTickets = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [update, setUpdate] = useState(false);

  const {
    loading,
    formLoading,
    ticketsData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ raffleTicketsReducer }) => ({
      ticketsData: raffleTicketsReducer.data,
      totalRecord: raffleTicketsReducer.totalRecord,
      nextPage: raffleTicketsReducer.nextPage,
      previousPage: raffleTicketsReducer.previousPage,
      currentPage: raffleTicketsReducer.currentPage,
      loading: raffleTicketsReducer.loading,
      formLoading: raffleTicketsReducer.formLoading,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAllRaffleTickets(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllRaffleTickets(payload));
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllRaffleTickets({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllRaffleTickets({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllRaffleTickets({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllRaffleTickets({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  const onCheck = (payload) => {
    dispatch(updateVerifiedTicketStatus(payload));
    setUpdate((prevUpdate) => !prevUpdate);
  };

  useEffect(() => {
    dispatch(fetchAllRaffleTickets(defaultPage));
  }, [dispatch, defaultPage, update]);

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button
              disabled={formLoading}
              onClick={() => {
                onCheck(original);
              }}
              size="small"
              type="text"
            >
              <Tooltip title="Verified">
                <CheckSquareOutlined />
              </Tooltip>
            </Button>
          </>
        ),
        width: 100,
      },
      {
        Header: "Full name",
        accessor: "fullname",
      },
      {
        Header: "Gcash referrence number",
        accessor: "gcash_referrence_number",
      },
      {
        Header: "Contact",
        accessor: "contact_number",
      },
      {
        Header: "Ticket",
        accessor: "ticket_number",
        Cell: ({ value }) => {
          try {
            const parsedData = JSON.parse(value);

            if (Array.isArray(parsedData)) {
              return parsedData.map((ticket_number) => (
                <Tag color="green" key={ticket_number}>
                  {ticket_number}
                </Tag>
              ));
            } else {
              return <Tag color="red">Invalid JSON</Tag>;
            }
          } catch (error) {
            return <Tag color="red">Invalid JSON</Tag>;
          }
        },
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        Cell: ({ value }) => {
          return dayjs(value).format("YYYY-MM-DD");
        },
      },
      {
        Header: "Status",
        accessor: "isVerified",
        Cell: ({ value }) => {
          return value === 1 ? (
            <Badge status="success" text="verified" />
          ) : (
            <Badge status="error" text="unverified" />
          );
        },
      },
    ],
    []
  );

  const customFilters = () => {
    return (
      <div className="mb-2">
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="Enter ticket number or first name or last name"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="small"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={() => {}}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  console.log(">>> ticketsData:", ticketsData);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={ticketsData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          //   extra={[
          //     <Button key="1" onClick={onAdd} className="mr-2">
          //       <PlusOutlined /> Add
          //     </Button>,
          //   ]}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={() => {}} />
    </>
  );
};

export default RaffleTickets;
