import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";
import axios from "axios";

import { Form, Row, Col, Button, Modal, notification, Switch } from "antd";

// Actions
import {
  toggleModal,
  create,
  setForm,
  updateFixRate,
} from "../../redux/actions/fixRates";

// Utilities
import renderInput from "../utilities/inputForms";
import { getRegionByProvince } from "../utilities/getPhillipineMajorIslands";
import { fetch } from "../../redux/actions/users";

const FixRatesModal = ({ changeFixRates = false, setChangeFixRates }) => {
  const dispatch = useDispatch();

  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [isCenter, setIsCenter] = useState(false);

  const { modal, formLoading, error, form } = useSelector(
    ({ fixRateReducer }) => ({
      modal: fixRateReducer.modal,
      formLoading: fixRateReducer.formLoading,
      error: fixRateReducer.error,
      form: fixRateReducer.form,
    }),
    isEqual
  );

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  const schema = changeFixRates
    ? yup.object().shape({
        fixRates: yup
          .number()
          .required("Fix Rate is required")
          .typeError("Fix Rate must be a valid number"),
      })
    : yup.object().shape({
        fixRates: yup
          .number()
          .required("Fix Rate is required")
          .typeError("Fix Rate must be a valid number"),
        province: yup.string().required(),
        city: yup.string().required(),
        barangay: yup.string().required(),
        distance_centro_to_brgyHall: yup.string().required(),
      });

  let defaultValues = changeFixRates
    ? { fixRates: "" }
    : {
        fixRates: "",
        province: "",
        city: "",
        barangay: "",
        latitude: "",
        longitude: "",
      };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    setChangeFixRates(false);
    setIsCenter(false);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    setBarangays(phil.getBarangayByMun(mun_code));
  };

  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        const { data } = await dispatch(
          updateFixRate({
            fixRate: values.fixRates,
            id: form.id,
          })
        );

        close();
        return openNotification("SUCCESS", data.message);
      } else {
        const province = phil.provinces.filter(
          (province) => province.prov_code === values.province
        )[0];
        const city = phil.city_mun.filter(
          (city) => city.mun_code === values.city
        )[0].name;
        const barangay = phil.barangays.filter(
          (barangay) => barangay.brgy_code === values.barangay
        )[0].name;

        const newData = {
          province: province.name,
          city,
          barangay,
          fixRates: values.fixRates,
          LocationType: isCenter,
          latitude: values.latitude,
          longitude: values.longitude,
          distance_centro_to_brgyHall: values.distance_centro_to_brgyHall,
        };

        const { data } = await dispatch(create(newData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("Create Fix rate error:>>", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        {changeFixRates ? (
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Fix rate",
                name: "fixRates",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>
        ) : (
          <>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Fix rate",
                  name: "fixRates",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Province",
                    name: "province",
                    errors: errors,
                    required: "true",
                    type: "select",
                    options: phil.provinces,
                    valueKey: "prov_code",
                    valueText: "name",
                    onChangeOutside: onChangeProvince,
                  },
                  control
                )}
              </Col>
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "City",
                    name: "city",
                    errors: errors,
                    required: "true",
                    type: "select",
                    options: cities,
                    valueKey: "mun_code",
                    valueText: "name",
                    onChangeOutside: onChangeCity,
                  },
                  control
                )}
              </Col>
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Barangay",
                    name: "barangay",
                    errors: errors,
                    required: "true",
                    type: "select",
                    options: barangays,
                    valueKey: "brgy_code",
                    valueText: "name",
                    onChangeOutside: () => {},
                  },
                  control
                )}
              </Col>

              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Distance centro to Brgy. hall",
                    name: "distance_centro_to_brgyHall",
                    errors: errors,
                    required: "true",
                  },
                  control
                )}
              </Col>

              <Col className="gutter-row" span={24}>
                <p>Set this location as the center.</p>
                <Switch
                  defaultValue={isCenter}
                  onChange={() => {
                    setIsCenter(!isCenter);
                  }}
                />
              </Col>
              {isCenter && (
                <>
                  <Col className="gutter-row" span={24}>
                    {renderInput(
                      {
                        label: "Latitude",
                        name: "latitude",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                  <Col className="gutter-row" span={24}>
                    {renderInput(
                      {
                        label: "Longitude",
                        name: "longitude",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                  </Col>
                </>
              )}
            </Row>
          </>
        )}

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default FixRatesModal;
