import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { RIDERPACKAGE } = actions;

export const fetchRiderAvailedPackage =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: RIDERPACKAGE.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/rider/fetch-availed-packages`;

      if (payload !== undefined) {
        const { page, limit, searchText } = payload;
        url = `/api/rider/fetch-availed-packages?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/rider/fetch-availed-packages?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
      }

      const response = await axiosInstance(token).get(url);
      dispatch({
        type: RIDERPACKAGE.FETCH.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: RIDERPACKAGE.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
