import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Layout, Form, Input, Badge } from "antd";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import { fetchReferredRider } from "../redux/actions/riderInMyArea";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const ReferredRiderPage = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    referredRiderData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(({ riderReducer }) => ({
    referredRiderData: riderReducer.referredRiderData,
    totalRecord: riderReducer.referredRiderTotalRecord,
    nextPage: riderReducer.referredRiderNextPage,
    previousPage: riderReducer.referredRiderPreviousPage,
    currentPage: riderReducer.referredRiderCurrentPage,
    loading: riderReducer.loading,
  }));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchReferredRider(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchReferredRider(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>Referred Riders</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchReferredRider({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchReferredRider({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchReferredRider({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchReferredRider({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Duty Status",
        accessor: (row) =>
          row.active_status === "offline" || row.active_status === null ? (
            <Badge status="error" text="Off Duty" />
          ) : (
            <Badge status="success" text="On Duty" />
          ),
      },

      {
        Header: "Name",
        accessor: "fullName",
      },
      {
        Header: "Contact number",
        accessor: "contact_number",
      },
      {
        Header: "preferred delivery city",
        accessor: (row) =>
          row.pref_delivery_city === null ? (
            <p style={{ color: "black" }}>Group Area</p>
          ) : (
            <p style={{ color: "black" }}>{row.pref_delivery_city}</p>
          ),
      },
      {
        Header: "preferred delivery province",
        accessor: (row) =>
          row.pref_delivery_province === null ? (
            <p style={{ color: "black" }}>Group Area</p>
          ) : (
            <p style={{ color: "black" }}>{row.pref_delivery_province}</p>
          ),
      },
      {
        Header: "Delivered Count",
        accessor: "delivered_count",
      },
      {
        Header: "Referred Customer",
        accessor: "referred_customer_count",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchReferredRider(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={referredRiderData} //
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default ReferredRiderPage;
