import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Popconfirm,
  DatePicker,
  Modal,
  Descriptions,
  Statistic,
  Col,
} from "antd";
import { CheckSquareOutlined, BarsOutlined } from "@ant-design/icons";

// Components
import formatCurrency from "../helpers/formatCurrency";
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import { fetchPayablesInfo } from "../redux/actions/payablesInfo";
import { fetchAllPayablesStatus } from "../redux/actions/payablesStatus";
import { payablesStatusInfo } from "../redux/actions/payableStatusInfo";
import moment from "moment/moment";
import dayjs from "dayjs";
const { Content: LayoutContent } = Layout;
const { Search } = Input;

const PayablesStatus = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [toformattedDate, setToFormattedDate] = useState(null);
  const [franchiseeModal, setFranchiseeModal] = useState(false);
  const [franchisePayables, setFranchisePayables] = useState("");
  const [payablesInfoModal, setPayableInfoModal] = useState(false);
  const [overAllTotalDf, setOverAllTotalDf] = useState(null);
  const [update, setUpdate] = useState(false);
  const {
    loading,
    franchiseePayable,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    userInfo,
    payablesInfo,
    payableStatusInfo,
    totalPlatformFee,
    totalCollectedDf,
  } = useSelector(
    ({
      payablesStatusReducer,
      userLoginReducer,
      payableInfoReducer,
      payableStatusInfoReducer,
    }) => ({
      userInfo: userLoginReducer.userInfo,
      franchiseePayable: payablesStatusReducer.data,
      totalRecord: payablesStatusReducer.totalRecord,
      nextPage: payablesStatusReducer.nextPage,
      previousPage: payablesStatusReducer.previousPage,
      currentPage: payablesStatusReducer.currentPage,
      loading: payablesStatusReducer.loading,
      payablesInfo: payableInfoReducer.data,
      payableStatusInfo: payableStatusInfoReducer.data,
      totalCollectedDf: payablesStatusReducer.totalCollectedDf,
      totalPlatformFee: payablesStatusReducer.totalPlatformFee,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    const i = {
      page: 1,
      limit: 15,
    };
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      dateFilter:
        formatDate(selectedDate) === "Invalid Date"
          ? undefined
          : formatDate(selectedDate) + " " + "00:00:00",
      todateFilter:
        formatDate(toSelectedDate) === "Invalid Date"
          ? undefined
          : formatDate(toSelectedDate) + " " + "23:59:59",
    };
    // if (toformattedDate !== null) {
    //   dispatch(fetchAllPayablesStatus(payload));
    // }
    if (formatDate(selectedDate) !== undefined) {
      dispatch(fetchAllPayablesStatus(payload));
    } else {
      dispatch(fetchAllPayablesStatus(i));
    }
  }, [delaySearchText, dispatch, toSelectedDate, selectedDate]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllPayablesStatus(payload));
  };

  useEffect(() => {
    console.log(">>>>", franchiseePayable);
    let dfTotal = [];
    franchiseePayable?.map((d) => {
      dfTotal.push(d.Daily_Collection);
    });

    const total = dfTotal.reduce((acc, curr) => acc + parseFloat(curr), 0);

    setOverAllTotalDf(parseFloat(total).toFixed(0));
  }, [franchiseePayable]);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setSelectedDate(dates[0]);

      setToSelectedDate(dates[1]);
    } else {
      setSelectedDate(null);
      setToSelectedDate(null);
    }
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>Partner Payable status</h1>
        <div
          className="p-2 flex justify-between"
          style={{
            backgroundColor: "#fafafa",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
            justifyContent: "space-between",
          }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
            <RangePicker
              style={{ width: "30vw" }}
              value={[selectedDate, toSelectedDate]}
              onChange={handleDateChange}
              placeholder={["Start Date", "End Date"]}
              format="YYYY-MM-DD"
            />
            {/* <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Form.Item label="From:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={selectedDate}
                  onChange={(date, dateString) => {
                    setSelectedDate(date);
                    setFormattedDate(
                      dayjs(dateString)
                        .startOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item>
              <Form.Item label="To:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={toSelectedDate}
                  onChange={(date, dateString) => {
                    setToSelectedDate(date);
                    setToFormattedDate(
                      dayjs(dateString)
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item>
            </div> */}
          </Form>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 45,
              marginRight: 35,
            }}
          >
            <div>
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Total Delivery Fee Collection {formatCurrency(overAllTotalDf)}
              </span>
            </div>
            <div>
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Total platfrom fee{" "}
                {formatCurrency(
                  (overAllTotalDf * userInfo.dory_commissions) / 100
                )}
              </span>
            </div>
          </div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "30%",
            marginLeft: 25,
            marginTop: 25,
          }}
        >
          <Col span={12}>
            <Statistic
              title="Total Deposited Collections"
              value={formatCurrency(totalCollectedDf || 0)}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Total Deposited platfrom fee"
              value={formatCurrency(totalPlatformFee || 0)}
            />
          </Col>
        </div>
        {/* <div
          style={{
            width: "40%",
            border: "1px solid gray",
            marginLeft: 25,
          }}
        ></div> */}
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllPayablesStatus({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllPayablesStatus({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllPayablesStatus({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllPayablesStatus({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  const handlePayableInfo = (date) => {
    dispatch(payablesStatusInfo(date));
    setPayableInfoModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Tooltip title="More info">
              <BarsOutlined
                style={{ marginLeft: 5, fontSize: 15 }}
                onClick={() =>
                  handlePayableInfo({
                    dateFrom: moment(original.collected_date_from).format(
                      "YYYY-MM-DD"
                    ),
                    dateTo: moment(original.collected_date_to).format(
                      "YYYY-MM-DD"
                    ),
                  })
                }
              />
            </Tooltip>
          </>
        ),
        width: 100,
      },
      {
        Header: "Collection",
        accessor: "Daily_Collection",
      },
      {
        Header: "Deposit Date",
        accessor: (row) => {
          const utcTime = moment.utc(row.created_at);
          return utcTime.format("MMM D, YYYY hh:mm A"); // Format the time as needed
        },
      },
      {
        Header: "Total Payables",
        accessor: "Total_payables",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Ref No.",
        accessor: "Reference_number",
      },
      {
        Header: "Note",
        accessor: (row) => {
          if (row.note === null) {
            return "--";
          } else {
            return row.note;
          }
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllPayablesStatus(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={franchiseePayable}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          //   dateFilters={{ selectedDate }}
        />
      </LayoutContent>
    );
  };
  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <Modal
              open={payablesInfoModal}
              onOk={() => {
                setPayableInfoModal(false);
              }}
              onCancel={() => {
                setPayableInfoModal(false);
              }}
              width={700}
            >
              <Descriptions
                title="PAYABLE DETAILS"
                bordered
                size={"large"}
                column={3}
              >
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  Rider Name
                </Descriptions.Item>
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  Collected Delivery Fee
                </Descriptions.Item>
                <Descriptions.Item style={{ fontWeight: "bold" }}>
                  Date
                </Descriptions.Item>
                {payableStatusInfo.map((item) => (
                  <>
                    <Descriptions.Item>
                      {item.riderName === null
                        ? item.partnerUserName
                        : item.riderName}
                    </Descriptions.Item>
                    <Descriptions.Item>
                      {item.collectedShippingFee}
                    </Descriptions.Item>
                    <Descriptions.Item>{item.ordersDate}</Descriptions.Item>
                  </>
                ))}
              </Descriptions>
            </Modal>
          </>
        }
      />
    </>
  );
};
export default PayablesStatus;
