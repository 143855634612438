import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { MERCHANTS } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTS.TOGGLE, payload });
  };

export const clearError =
  (payload = null) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTS.CLEARERROR, payload });
  };

export const toggleModalFeaturedImage =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTS.TOGGLEFEATUREDIMAGE, payload });
  };

export const toggleAddMenuModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTS.TOGGLEMENUMODAL, payload });
  };

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTS.TOGGLEVIEW, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: MERCHANTS.SET, payload });
};

// export const setMenu = (payload) => (dispatch) => {
//   dispatch({ type: MERCHANTS.SETMENU, payload });
// };

export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTS.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      "/api/groceria/partner-merchants",
      payload
    );

    dispatch({ type: MERCHANTS.CREATE.SUCCESS, payload: response });

    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTS.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const update = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTS.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).put(
      `/api/groceria/partner-merchants/${payload.id}`,
      payload.formData
    );
    dispatch({ type: MERCHANTS.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTS.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const fetchMerchants = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTS.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  let { token } = userInfo;
  let { city } = userInfo;
  let { province } = userInfo;
  let { role } = userInfo;

  try {
    let url = `/api/groceria/partner-merchants`;
    if (payload !== undefined) {
      const { page, limit, searchText, dateFilter, todateFilter } = payload;
      if (role === "operator_admin") {
        url = `/api/groceria/partner-merchants?page=${page}&limit=${limit}&role=${role}&city=${city}&province=${province}&partner_id=${userInfo.id}`;
      } else {
        url = `/api/groceria/partner-merchants?page=${page}&limit=${limit}`;
      }

      if (role === "operator_admin") {
        if (page && limit && searchText) {
          url = `/api/groceria/partner-merchants?page=${page}&limit=${limit}&role=${role}&city=${city}&province=${province}&searchText=${searchText}&partner_id=${userInfo.id}`;
        }
      } else {
        if (page && limit && searchText) {
          url = `/api/groceria/partner-merchants?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
      }

      if (page && limit && dateFilter && todateFilter) {
        if (role === "operator_admin") {
          url = `/api/groceria/partner-merchants?page=${page}&limit=${limit}&role=${role}&city=${city}&province=${province}&dateFilter=${dateFilter}&todateFilter=${todateFilter}&partner_id=${userInfo.id}`;
        } else {
          url = `/api/groceria/partner-merchants?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}`;
        }
      }
    }
    const response = await axiosInstance(token).get(url);
    dispatch({
      type: MERCHANTS.FETCH.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTS.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const createMenu = (payload) => async (dispatch, getState) => {
  const {
    userLoginReducer: { userInfo },
  } = getState();
  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).post(
      `/api/merchant/menus`,
      payload
    );

    return response;
  } catch (error) {
    console.log(">>> error:", error);
    return error;
  }
};

export const fetchAllMerchantTag = () => async (dispatch, getState) => {
  dispatch({ type: MERCHANTS.FETCHTAGS.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    let url = `/api/merchant-tags/all`;

    const response = await axiosInstance(token).get(url);
    dispatch({
      type: MERCHANTS.FETCHTAGS.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTS.FETCHTAGS.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const createFeaturedImage = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTS.CREATEFEATUREDIMAGE.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).post(
      `/api/groceria/partner-merchants/featured-image`,
      payload
    );
    dispatch({
      type: MERCHANTS.CREATEFEATUREDIMAGE.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTS.CREATEFEATUREDIMAGE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const updatePartnerMerchantStatus =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: MERCHANTS.UPDATE.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();

    try {
      let token = "";
      if (userInfo !== null) token = userInfo.token;

      const response = await axiosInstance(token).patch(
        `/api/groceria/partner-merchants/${payload.id}`,
        payload
      );
      dispatch({ type: MERCHANTS.UPDATE.SUCCESS, payload: response });
      return response;
    } catch (error) {
      dispatch({
        type: MERCHANTS.UPDATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const softDeletion = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTS.SOFTDELETION.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).put(
      `/api/groceria/partner-merchants/delete/${payload.id}?partner_id=${
        userInfo.id
      }&role=${userInfo.role}&page=${1}&limit=${15}`
    );
    dispatch({ type: MERCHANTS.SOFTDELETION.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTS.SOFTDELETION.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
