import actions from "../actionTypes";
const { STORESPRODUCTS } = actions;

const userInitialState = {
  modal: false,
  modalView: false,
  formLoading: false,
  data: [],
  form: {},
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
  modalImport: false,
  modalNoSku: false,
  dataNoSku: [],
};

export const partnerStoresProductsReducer = (
  state = userInitialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case STORESPRODUCTS.TOGGLE:
      return { ...state, modal: payload, error: null };
    case STORESPRODUCTS.TOGGLEVIEW:
      return { ...state, modalView: payload, error: null };
    case STORESPRODUCTS.TOGGLEIMPORT:
      return { ...state, modalImport: payload, error: null };
    case STORESPRODUCTS.TOGGLENOSKU:
      return { ...state, modalNoSku: payload, error: null };
    case STORESPRODUCTS.SETDATANOSKU:
      return {
        ...state,
        dataNoSku: payload.data.no_barcode_matched,
        error: null,
      };
    case STORESPRODUCTS.SET:
      return { ...state, form: payload };

    case STORESPRODUCTS.CREATE.REQUEST:
      return { ...state, formLoading: true };

    case STORESPRODUCTS.CREATE.SUCCESS:
      const newData = payload;
      return {
        ...state,
        formLoading: false,
        data: [...state.data, newData.data.data],
      };
    case STORESPRODUCTS.CREATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    case STORESPRODUCTS.FETCH.REQUEST:
      return { ...state, loading: true };
    case STORESPRODUCTS.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case STORESPRODUCTS.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case STORESPRODUCTS.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case STORESPRODUCTS.UPDATE.SUCCESS:
      return {
        ...state,
        formLoading: false,
        form: payload.data.data,
        data: state.data.map((d) =>
          d.products_id === payload.data.data.products_id
            ? payload.data.data
            : d
        ),
      };

    case STORESPRODUCTS.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
