import React, { useEffect } from "react";
import Wrapper from "../components/wrapper";
import { Tabs } from "antd";
import { useDispatch } from "react-redux";

//Actions
import { fetchSubOpsReferredRider } from "../redux/actions/subOperatorReferralRider";

// Components
import SubOperatorRiderTable from "../components/subOperator/subOperatorRiderTable";
import SubOperatorCustomerTable from "../components/subOperator/subOperatorCustomerTable";
import SubOperatorMerchantTable from "../components/subOperator/subOperatorMerchantTable";
import SubOperatorAffiliatesTable from "../components/subOperator/subOperatorAffiliatesTable";
import OperatorTable from "../components/subOperator/OperatorTable";
import SubOperatorTable from "../components/subOperator/subOperatorTable";

const SubOperatorReferral = () => {
  return <Wrapper content={<Content />} />;
};
export default SubOperatorReferral;

const Content = () => {
  const dispatch = useDispatch(); 

  const subOpsTabInfo = [
    { id: 1, tabname: "Rider", roles: "rider", component: <SubOperatorRiderTable /> },
    { id: 2, tabname: "Customer", roles: "customer", component: <SubOperatorCustomerTable /> },
    { id: 3, tabname: "Merchant", roles: "merchant", component: <SubOperatorMerchantTable /> },
    { id: 4, tabname: "Affiliate", roles: "affiliate", component: <SubOperatorAffiliatesTable /> },
    { id: 5, tabname: "Operator", roles: "operator", component: <OperatorTable /> },
    { id: 6, tabname: "Sub Operator", roles: "sub-operator", component: <SubOperatorTable /> },
  ];

  const handleTabChange = (key) => {
    const selectedTab = subOpsTabInfo.find((tab) => tab.id.toString() === key);
    if (selectedTab) {
      dispatch(fetchSubOpsReferredRider(selectedTab.roles));
    }
  };

  return (
    <Tabs
      defaultActiveKey="1"
      centered
      onChange={handleTabChange} 
      items={subOpsTabInfo.map((tab) => {
        return {
          label: tab.tabname,
          key: tab.id.toString(),
          children: tab.component,
        };
      })}
    />
  );
};
