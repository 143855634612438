import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { SUCCEEDINGRATE } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: SUCCEEDINGRATE.TOGGLE, payload });
  };

// export const toggleModalView =
//   (payload = true) =>
//   async (dispatch) => {
//     dispatch({ type: SUCCEEDINGRATE.TOGGLEVIEW, payload });
//   };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: SUCCEEDINGRATE.SET, payload });
};

export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: SUCCEEDINGRATE.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      "/api/succeeding-rate",
      payload
    );
    dispatch({ type: SUCCEEDINGRATE.CREATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: SUCCEEDINGRATE.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const fetchSucceedingRates = (payload) => async (dispatch, getState) => {
  dispatch({ type: SUCCEEDINGRATE.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token, role, id } = userInfo;
  try {
    let url = `/api/succeeding-rate`;

    if (payload !== undefined) {
      const { page, limit, searchText } = payload;
      url = `/api/succeeding-rate?page=${page}&limit=${limit}`;

      if (role === "operator_admin") {
        url += `&role=${role}&partnerId=${id}`;
      }

      // search for admin only
      if (page && limit && searchText) {
        url = `/api/succeeding-rate?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }

    const response = await axiosInstance(token).get(url);

    dispatch({
      type: SUCCEEDINGRATE.FETCH.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: SUCCEEDINGRATE.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const updateSucceedingRate = (payload) => async (dispatch, getState) => {
  dispatch({ type: SUCCEEDINGRATE.UPDATE.REQUEST });

  try {
    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;

    const response = await axiosInstance(token).patch(
      "/api/succeeding-rate",
      payload
    );
    dispatch({ type: SUCCEEDINGRATE.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: SUCCEEDINGRATE.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
