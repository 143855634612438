import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { FIXRATE } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: FIXRATE.TOGGLE, payload });
  };

// export const toggleModalView =
//   (payload = true) =>
//   async (dispatch) => {
//     dispatch({ type: FIXRATE.TOGGLEVIEW, payload });
//   };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: FIXRATE.SET, payload });
};

export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: FIXRATE.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post("/api/fix-rate", payload);
    dispatch({ type: FIXRATE.CREATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: FIXRATE.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const fetchAllAreasFixRate = (payload) => async (dispatch, getState) => {
  dispatch({ type: FIXRATE.FETCH.REQUEST });
  const { page, limit, searchText } = payload;

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  let { city } = userInfo;
  let { province } = userInfo;
  let { role } = userInfo;
  try {
    let url = `/api/fix-rate`;
    if (role === "operator_admin") {
      url = `/api/fix-rate?page=${page}&limit=${limit}&role=${role}&city=${city}&province=${province}`;
    } else {
      url = `/api/fix-rate?page=${page}&limit=${limit}`;
    }
    if (page && limit && searchText) {
      if (role === "operator_admin") {
        url = `/api/fix-rate?page=${page}&limit=${limit}&role=${role}&city=${city}&province=${province}&searchText=${searchText}`;
      } else {
        url = `/api/fix-rate?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }

    const response = await axiosInstance(token).get(url);

    dispatch({
      type: FIXRATE.FETCH.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: FIXRATE.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const updateFixRate = (payload) => async (dispatch, getState) => {
  dispatch({ type: FIXRATE.UPDATE.REQUEST });

  try {
    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;

    const response = await axiosInstance(token).patch("/api/fix-rate", payload);
    dispatch({ type: FIXRATE.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: FIXRATE.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
