import actions from "../actionTypes";
const { STORES } = actions;

const userInitialState = {
  modal: false,
  modalView: false,
  formLoading: false,
  data: [],
  form: {},
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
};

export const partnerStoresReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STORES.TOGGLE:
      return { ...state, modal: payload, error: null };
    case STORES.TOGGLEVIEW:
      return { ...state, modalView: payload, error: null };
    case STORES.SET:
      return { ...state, form: payload };

    case STORES.CREATE.REQUEST:
      return { ...state, formLoading: true };

    case STORES.CREATE.SUCCESS:
      const newData = payload;
      return {
        ...state,
        formLoading: false,
        data: [...state.data, newData.data.data[0] || newData.data.data],
      };
    case STORES.CREATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    case STORES.FETCH.REQUEST:
      return { ...state, loading: true };
    case STORES.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case STORES.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case STORES.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case STORES.UPDATE.SUCCESS:
      const updatedData = payload.data.data;
      const updatedItem = Array.isArray(updatedData)
        ? updatedData[0]
        : updatedData;
      return {
        ...state,
        formLoading: false,
        form: payload.data.data,
        data: state.data.map((d) =>
          d.id === updatedItem?.id ? updatedItem : d
        ),
      };

    case STORES.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
