import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col, Statistic, Radio, Spin, Button } from "antd";
import { Pie, Area, Bar } from "@ant-design/plots";
import { isEqual } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { FundProjectionScreenOutlined } from "@ant-design/icons";
// Components
import Wrapper from "../components/wrapper";
import DoryLogo from "../assets/images/DoryLogo.png";
import welcomeDory from "../assets/images/welcomeDory.png";
import howToLogIn from "../assets/images/howToLogIn.png";
import howToRegRider from "../assets/images/howToRegRider.png";
import legalitiesAndMarketing from "../assets/images/legalitiesAndMarketing.png";
import stantard from "../assets/images/stantard.png";
import ads from "../assets/images/ads.png";
import chatAutomation from "../assets/images/chatAutomation.png";
import waitForMore from "../assets/images/waitForMore.png";
// import config from "../space-config/config";
// // Utils
// import axiosInstance from "../helpers/axios";
// import axiosInstance from "../helpers/axios";

const { Content: LayoutContent } = Layout;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 44,
    }}
    spin
  />
);

const GetStatedScreen = () => {
  const [statusDateRangeKey, setStatusDateRangeKey] = useState("1d");
  const [statusStatData, setStatusStatData] = useState([]);
  const [statusStatDataLoading, setStatusStatDataLoading] = useState(false);
  const [totalStatusStat, setTotalStatusStat] = useState([]);

  const [volumeGrowthDateRangeKey, setVolumeGrowthDateRangeKey] =
    useState("7d");
  const [volumeGrowthData, setVolumeGrowthData] = useState([]);
  const [volumeGrowthDataLoading, setVolumeGrowthDataLoading] = useState(false);

  const [topCitiesDateRangeKey, settopCitiesDateRangeKey] = useState("1d");
  const [topCitiesData, settopCitiesData] = useState([]);
  const [topCitiesDataLoading, settopCitiesDataLoading] = useState(false);

  const [shippingFeeDateRangeKey, setShippingFeeDateRangeKey] = useState("1d");
  const [shippingFeeData, setShippingFeeData] = useState([]);
  const [shippingFeeDataLoading, setShippingFeeDataLoading] = useState(false);

  const { userInfo } = useSelector(
    ({ userLoginReducer }) => ({
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const pieConfig = {
    appendPadding: 10,
    data: statusStatData,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    height: 288,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: `total: ${totalStatusStat}`,
      },
    },
  };
  const areaConfig = {
    data: volumeGrowthData,
    xField: "date",
    yField: "rate",
    height: 288,
    xAxis: {
      tickCount: 5,
    },
    animation: true,
    slider: {
      trendCfg: {
        isArea: true,
      },
    },
  };
  const barConfig = {
    data: topCitiesData,
    xField: "value",
    yField: "city",
    seriesField: "city",
    height: 288,
    legend: {
      position: "top-left",
    },
  };

  // const [assetUrl, setAssetUrl] = useState("");

  // useEffect(() => {
  //   const spaceName = "groceria-storage";
  //   const objectKey = "assets/images/merchants/New Project (4).png";

  //   const cdnBaseUrl = `https://${spaceName}.sgp1.cdn.digitaloceanspaces.com`;
  //   const assetUrl = `${cdnBaseUrl}/${objectKey}`;

  //   setAssetUrl(assetUrl);
  // }, []);
  const Content = () => {
    return (
      <LayoutContent>
        <div style={{ justifyContent: "center", marginTop: 45 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                backgroundColor: "#3CB371",
                height: 280,
                borderRadius: 10,
                backgroundColor: "#00BF63",
                marginBottom: 20,
              }}
              width={300}
              src={DoryLogo}
              alt="Dory Logo"
            />
          </div>
          <h1
            style={{
              textAlign: "center",
              fontSize: 30,
              color: "black",
            }}
          >
            Welcome to Dory Platform
          </h1>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: 20,
                color: "gray",
                width: "25%",
              }}
            >
              Start your journey with our tutorials to learn about the Dory
              Platform.
            </p>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 22 }}
          >
            {/* <a
              href="https://dory-delivery-s-school.teachable.com/p/getting-started-with-dory-platform"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#3CB371", fontWeight: "bold", fontSize: 18 }}
            >
              Get Started
            </a> */}
            <Button
              style={{
                width: 150,
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#00BF63",
              }}
              size={"large"}
              href="https://dory-delivery-s-school.teachable.com/courses/getting-started-with-dory-platform/lectures/52676840"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Started
            </Button>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 55,
              alignItems: "baseline",
              marginLeft: 155,
            }}
          >
            <div
              style={{
                borderRadius: 20,
                marginBottom: 10,
              }}
            >
              <a
                href="https://dory-delivery-s-school.teachable.com/courses/getting-started-with-dory-platform/lectures/52676840"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    backgroundColor: "#3CB371",
                    border: "2px solid black",
                    height: 200,
                    borderRadius: 10,
                  }}
                  width={300}
                  src={welcomeDory}
                  alt="Dory Logo"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    alignItems: "flex-start",
                    marginTop: 5,
                    width: 300,
                  }}
                >
                  <h1 style={{ fontSize: 18 }}>
                    Introduction To Dory Platform
                  </h1>
                </div>
              </a>
            </div>
            <div
              style={{
                borderRadius: 20,
                marginBottom: 10,
              }}
            >
              <a
                href="https://dory-delivery-s-school.teachable.com/courses/getting-started-with-dory-platform/lectures/52676908"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    backgroundColor: "#3CB371",
                    border: "2px solid black",
                    height: 200,
                    borderRadius: 10,
                  }}
                  width={300}
                  src={howToLogIn}
                  alt="Dory Logo"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    alignItems: "flex-start",
                    marginTop: 5,
                    width: 300,
                  }}
                >
                  <h1 style={{ fontSize: 18 }}>
                    Login Access to Dory Platform
                  </h1>
                </div>
              </a>
            </div>
            <div
              style={{
                borderRadius: 20,
                marginBottom: 10,
              }}
            >
              <a
                href="https://dory-delivery-s-school.teachable.com/courses/getting-started-with-dory-platform/lectures/52676926"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    backgroundColor: "#3CB371",
                    border: "2px solid black",
                    height: 200,
                    borderRadius: 10,
                  }}
                  width={300}
                  src={howToRegRider}
                  alt="Dory Logo"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    alignItems: "flex-start",
                    marginTop: 5,
                    width: 300,
                  }}
                >
                  <h1 style={{ fontSize: 18 }}>How To Register My Rider</h1>
                </div>
              </a>
            </div>
            <div
              style={{
                borderRadius: 20,
                marginBottom: 10,
              }}
            >
              <a
                href="https://dory-delivery-s-school.teachable.com/courses/getting-started-with-dory-platform/lectures/52676955"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    backgroundColor: "#3CB371",
                    border: "2px solid black",
                    height: 200,
                    borderRadius: 10,
                  }}
                  width={300}
                  src={howToRegRider}
                  alt="Dory Logo"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    alignItems: "flex-start",
                    marginTop: 5,
                    width: 300,
                  }}
                >
                  <h1 style={{ fontSize: 18 }}>How To Register a Merhant</h1>
                </div>
              </a>
            </div>
            <div
              style={{
                borderRadius: 20,
                marginBottom: 10,
              }}
            >
              <a
                href="https://dory-delivery-s-school.teachable.com/courses/getting-started-with-dory-platform/lectures/52676958"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    backgroundColor: "#3CB371",
                    border: "2px solid black",
                    height: 200,
                    borderRadius: 10,
                  }}
                  width={300}
                  src={howToRegRider}
                  alt="Dory Logo"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    alignItems: "flex-start",
                    marginTop: 5,
                    width: 300,
                  }}
                >
                  <h1 style={{ fontSize: 18 }}>
                    How To Add Menu Items For My Merchants
                  </h1>
                </div>
              </a>
            </div>
            <div
              style={{
                borderRadius: 20,
                marginBottom: 10,
              }}
            >
              <a
                href="https://dory-delivery-s-school.teachable.com/courses/getting-started-with-dory-platform/lectures/52881769"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    backgroundColor: "#3CB371",
                    border: "2px solid black",
                    height: 200,
                    borderRadius: 10,
                  }}
                  width={300}
                  src={legalitiesAndMarketing}
                  alt="Dory Logo"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    alignItems: "flex-start",
                    marginTop: 5,
                    width: 300,
                  }}
                >
                  <h1 style={{ fontSize: 18 }}>Legalities & Marketing</h1>
                </div>
              </a>
            </div>
            <div
              style={{
                borderRadius: 20,
                marginBottom: 10,
              }}
            >
              <a
                href="https://dory-delivery-s-school.teachable.com/courses/getting-started-with-dory-platform/lectures/52881817"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    backgroundColor: "#3CB371",
                    border: "2px solid black",
                    height: 200,
                    borderRadius: 10,
                  }}
                  width={300}
                  src={stantard}
                  alt="Dory Logo"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    alignItems: "flex-start",
                    marginTop: 5,
                    width: 300,
                  }}
                >
                  <h1 style={{ fontSize: 18 }}>
                    Company stantards & Best Practices
                  </h1>
                </div>
              </a>
            </div>
            <div
              style={{
                borderRadius: 20,
                marginBottom: 10,
              }}
            >
              <a
                href="https://dory-delivery-s-school.teachable.com/courses/getting-started-with-dory-platform/lectures/52881906"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    backgroundColor: "#3CB371",
                    border: "2px solid black",
                    height: 200,
                    borderRadius: 10,
                  }}
                  width={300}
                  src={ads}
                  alt="Dory Logo"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    alignItems: "flex-start",
                    marginTop: 5,
                    width: 300,
                  }}
                >
                  <h1 style={{ fontSize: 18 }}>Social Media Ads</h1>
                </div>
              </a>
            </div>
            <div
              style={{
                borderRadius: 20,
                marginBottom: 10,
              }}
            >
              <a
                href="https://dory-delivery-s-school.teachable.com/courses/getting-started-with-dory-platform/lectures/53258947"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    backgroundColor: "#3CB371",
                    border: "2px solid black",
                    height: 200,
                    borderRadius: 10,
                  }}
                  width={300}
                  src={chatAutomation}
                  alt="Dory Logo"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    alignItems: "flex-start",
                    marginTop: 5,
                    width: 300,
                  }}
                >
                  <h1 style={{ fontSize: 18 }}>Page Automation</h1>
                </div>
              </a>
            </div>
            <div
              style={{
                borderRadius: 20,
                marginBottom: 10,
              }}
            >
              <img
                style={{
                  backgroundColor: "#3CB371",
                  border: "2px solid black",
                  height: 200,
                  borderRadius: 10,
                }}
                width={300}
                src={waitForMore}
                alt="Dory Logo"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  alignItems: "flex-start",
                  marginTop: 5,
                  width: 300,
                }}
              >
                <h1 style={{ fontSize: 18 }}>WAIT FOR MORE</h1>
              </div>
            </div>
          </div> */}
        </div>
      </LayoutContent>
    );
  };

  return <Wrapper content={<Content />} />;
};

export default GetStatedScreen;
