import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { APPEARNINGWITHDRAW } = actions;

export const fetchPendingWithdrawals =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: APPEARNINGWITHDRAW.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/wallet-transaction/app-earning-withdrawals`;
      if (payload !== undefined) {
        const { page, limit, searchText, dateFilter } = payload;
        url = `/api/wallet-transaction?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/wallet-transaction?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
        if (page && limit && dateFilter) {
          url = `/api/wallet-transaction?page=${page}&limit=${limit}&dateFilter=${dateFilter}`;
        }
      }
      const response = await axiosInstance(token).get(url);

      dispatch({
        type: APPEARNINGWITHDRAW.FETCH.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: APPEARNINGWITHDRAW.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const updateWithdrawalStatus =
  (payload, status) => async (dispatch, getState) => {
    dispatch({ type: APPEARNINGWITHDRAW.UPDATE.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      const response = await axiosInstance(token).put(
        `/api/wallet-transaction`,

        {
          status: status,
          user_id: payload.user_id,
          id: payload.id,
        }
      );

      dispatch({
        type: APPEARNINGWITHDRAW.UPDATE.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: APPEARNINGWITHDRAW.UPDATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
