import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { BOOKINGS } = actions;

export const toggleAlreadyAcceptedValue =
  (payload = false) =>
  async (dispatch) => {
    dispatch({ type: BOOKINGS.TOGGLE, payload });
  };

export const fetchBookings = (payload) => async (dispatch, getState) => {
  dispatch({ type: BOOKINGS.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  let { city } = userInfo;
  let { province } = userInfo;
  let { role } = userInfo;
  try {
    let url = `/api/bookings/fetch-bookings`;
    if (payload !== undefined) {
      const { page, limit, searchText, status, dateFilter, todateFilter } =
        payload;
      if (role === "operator_admin") {
        url = `/api/bookings/fetch-bookings?page=${page}&limit=${limit}&status=${status}&role=${role}&city=${city}&province=${province}&partner_id=${userInfo.id}`;
      } else {
        url = `/api/bookings/fetch-bookings?page=${page}&limit=${limit}&status=${status}`;
      }
      if (page && limit && searchText && status) {
        url = `/api/bookings/fetch-bookings?page=${page}&limit=${limit}&searchText=${searchText}&status=${status}`;
      }
      if (page && limit && dateFilter && todateFilter && status) {
        if (role === "operator_admin") {
          url = `/api/bookings/fetch-bookings?page=${page}&limit=${limit}&role=${role}&city=${city}&province=${province}&dateFilter=${dateFilter}&todateFilter=${todateFilter}&partner_id=${userInfo.id}&status=${status}`;
        } else {
          url = `/api/bookings/fetch-bookings?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}&status=${status}`;
        }
      }
    }

    const response = await axiosInstance(token).get(url);
    dispatch({
      type: BOOKINGS.FETCH.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: BOOKINGS.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const fetchBookingsAvailableOperators =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: BOOKINGS.FETCHOPERATORS.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      const { page, limit, deliveryAddressId } = payload;

      let url = `/api/bookings/fetch-booking-available-operator?page=${page}&limit=${limit}&customerDeliveryAddressId=${deliveryAddressId}`;

      //   if (payload !== undefined) {
      //     const { page, limit, searchText } = payload;
      //     url = `/api/bookings/fetch-bookings?page=${page}&limit=${limit}`;
      //     if (page && limit && searchText) {
      //       url = `/api/bookings/fetch-bookings?page=${page}&limit=${limit}&searchText=${searchText}`;
      //     }
      //   }

      const response = await axiosInstance(token).get(url);
      dispatch({
        type: BOOKINGS.FETCHOPERATORS.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: BOOKINGS.FETCHOPERATORS.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

// test
export const updateOrderStatus = (payload) => async (dispatch, getState) => {
  dispatch({ type: BOOKINGS.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).patch("/api/bookings", payload);
    dispatch({
      type: BOOKINGS.UPDATE.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: BOOKINGS.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const cancelBooking = (payload) => async (dispatch, getState) => {
  dispatch({ type: BOOKINGS.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).patch(
      "/api/bookings/booking-cancel",
      payload
    );
    dispatch({
      type: BOOKINGS.UPDATE.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: BOOKINGS.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
export const franchisee = (payload) => async (dispatch, getState) => {
  dispatch({ type: BOOKINGS.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).patch(
      "/api/bookings/franchisee",
      {
        order_number: payload.order_number,
        status: payload.status,
        franchiseeId: payload.id,
        city: payload.city,
        province: payload.province,
        page: 1,
        limit: 15,
      }
    );
    dispatch({
      type: BOOKINGS.UPDATE.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: BOOKINGS.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const availableRiders = (payload) => async (dispatch, getState) => {
  dispatch({ type: BOOKINGS.FETCHOAVAILABLERIDERS.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).get(`/api/super-admin/available-riders/${payload.orderNumber}`);

    dispatch({
      type: BOOKINGS.FETCHOAVAILABLERIDERS.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: BOOKINGS.FETCHOAVAILABLERIDERS.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

// test
