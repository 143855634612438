import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Form, Input } from "antd";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import { fetchAllReferredSubOperator } from "../redux/actions/referredSubOperator";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const ReferredSubOperator = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    referredSubOperatorData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(({ referredSubOperatorReducer }) => ({
    referredSubOperatorData: referredSubOperatorReducer.data,
    totalRecord: referredSubOperatorReducer.totalRecord,
    nextPage: referredSubOperatorReducer.nextPage,
    previousPage: referredSubOperatorReducer.previousPage,
    currentPage: referredSubOperatorReducer.currentPage,
    loading: referredSubOperatorReducer.loading,
  }));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      sub_operator: "sub_operator",
    };
    dispatch(fetchAllReferredSubOperator(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      sub_operator: "sub_operator",
    };

    dispatch(fetchAllReferredSubOperator(payload));
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      sub_operator: "sub_operator",
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllReferredSubOperator({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllReferredSubOperator({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllReferredSubOperator({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllReferredSubOperator({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>Referred Sub Operator</h1>
        {/* <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div> */}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (row) => `${row.fullName}`,
      },
      {
        Header: "Mobile number",
        accessor: "contact_number",
      },
      {
        Header: "Email",
        accessor: "email_address",
      },
      {
        Header: "Service City",
        accessor: "service_city",
      },
      {
        Header: "Service Province",
        accessor: "service_province",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllReferredSubOperator(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={referredSubOperatorData}
          loading={loading}
          serverSidePagination={{
            totalRecord: 0,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default ReferredSubOperator;
