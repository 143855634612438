import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Select,
  Segmented,
  ConfigProvider,
  Typography,
  Alert,
  Space,
  Button,
  Modal,
  DatePicker,
  notification,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import {
  LoadingOutlined,
  BackwardOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { FastBackwardFilled } from "@ant-design/icons";
import { Line, Bar } from "react-chartjs-2";
import { isEqual } from "lodash";
import "../trainingVideo.css";
// Components
import Wrapper from "../components/wrapper";
import formatCurrency from "../helpers/formatCurrency";

// REDUX / AXIOS
import {
  fetchPartnerDashBoardCollectedDeliveryFee,
  fetchPartnerDashBoardTransactions,
  fetchPartnerDashBoardTopMerchants,
  fetchPartnerCustomerGrowth,
} from "../redux/actions/partnerDashBoard";
import dayjs from "dayjs";
import axiosInstance from "../helpers/axios";

const { RangePicker } = DatePicker;
const { Content: LayoutContent } = Layout;
const { Title } = Typography;

const PartnerDashBoard = () => {
  const dispatch = useDispatch();

  const {
    dailyTransactionData,
    weeklyTransactionData,
    monthlyTransactionData,
    totalCollectedDeliveryFee,
    topMerchants,
    dailyCustomerGrowthData,
    weeklyCustomerGrowthData,
    monthlyCustomerGrowthData,
    userInfo,
  } = useSelector(
    ({ PartnerDashBoardReducer, userLoginReducer }) => ({
      dailyTransactionData: PartnerDashBoardReducer.dailyTransactionData,
      weeklyTransactionData: PartnerDashBoardReducer.weeklyTransactionData,
      monthlyTransactionData: PartnerDashBoardReducer.monthlyTransactionData,
      totalCollectedDeliveryFee: PartnerDashBoardReducer.collectedDeliveryFee,
      topMerchants: PartnerDashBoardReducer.topMerchants,
      dailyCustomerGrowthData: PartnerDashBoardReducer.dailyCustomerGrowthData,
      weeklyCustomerGrowthData:
        PartnerDashBoardReducer.weeklyCustomerGrowthData,
      monthlyCustomerGrowthData:
        PartnerDashBoardReducer.monthlyCustomerGrowthData,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );
  const videoRef = useRef(null);
  let lastSavedTime = useRef(0);
  const [status, setStatus] = useState("delivered");
  const [timeFrame, setTimeFrame] = useState("Daily");
  const [transactionGraphData, setTransactionGraphData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromDateTopMerchants, setFromDateTopMerchants] = useState(null);
  const [toDateTopMerchants, setToDateTopMerchants] = useState(null);
  const [customerGrowthGraphData, setCustomerGrowthGraphData] = useState([]);
  const [customerGrowthTimeFrame, setCustomerGrowthTimeFrame] =
    useState("Daily");
  const [modal, setModal] = useState(false);
  const [transactionCount, setTransactionCount] = useState(0);
  const [trainingStatus, setStrainingStatus] = useState(null);
  const [isFinished, setIsFinished] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const saveInterval = 60;
  const currentTimeRef = useRef(0);
  const openNotification = (message) => {
    notification.success({
      message: message,
    });
  };
  const checkPartnerTransaction = async () => {
    try {
      const res = await axiosInstance(userInfo.token).get(
        `/api/transaction-checker/${userInfo.id}`
      );
      setTransactionCount(res.data.totalCount);
      setStrainingStatus(res.data.training_status);
      setModal(true);
    } catch (error) {
      setModal(false);
      console.log("checkPartnerTransaction : >> ", error);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      if (
        Math.floor(videoRef.current.currentTime) - lastSavedTime.current >=
        saveInterval
      ) {
        localStorage.setItem(
          `userInfo_${userInfo.id}`,
          videoRef.current.currentTime
        );
        lastSavedTime.current = Math.floor(videoRef.current.currentTime);
      }
    }
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      const savedProgress = localStorage.getItem(`userInfo_${userInfo.id}`);
      if (savedProgress) {
        videoRef.current.currentTime = parseFloat(savedProgress);
      }
    }
  };

  const handleVideoEnd = async () => {
    try {
      setIsFinished(true);
      const res = await axiosInstance(userInfo.token).put(
        `/api/transaction-checker/${true}/${userInfo.id}`
      );
      localStorage.removeItem(`userInfo_${userInfo.id}`);
      openNotification(res.data.message);
      setIsFinished(false);
      setModal(false);
    } catch (error) {
      console.log("handleVideoEnd : >> ", error);
    }
  };

  useEffect(() => {
    if (userInfo.role === "operator_admin") {
      checkPartnerTransaction();
    }
  }, []);
  const handleRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setFromDate(dates[0]);
      setToDate(dates[1]);
    } else {
      setFromDate(null);
      setToDate(null);
    }
  };

  const handleBackward = () => {
    if (videoRef.current) {
      const newTime = Math.max(videoRef.current.currentTime - 10, 0);
      videoRef.current.currentTime = newTime;
      currentTimeRef.current = newTime;
    }
  };

  const handleRangeChangeTopMerchants = (dates) => {
    if (dates && dates.length === 2) {
      setFromDateTopMerchants(dates[0]);
      setToDateTopMerchants(dates[1]);
    } else {
      setFromDateTopMerchants(null);
      setToDateTopMerchants(null);
    }
  };

  useEffect(() => {
    if (customerGrowthTimeFrame === "Daily") {
      setCustomerGrowthGraphData(dailyCustomerGrowthData);
    }

    if (customerGrowthTimeFrame === "Weekly") {
      setCustomerGrowthGraphData(weeklyCustomerGrowthData);
    }
    if (customerGrowthTimeFrame === "Monthly") {
      setCustomerGrowthGraphData(monthlyCustomerGrowthData);
    }
  }, [
    customerGrowthTimeFrame,
    dailyCustomerGrowthData,
    weeklyCustomerGrowthData,
    monthlyCustomerGrowthData,
  ]);

  useEffect(() => {
    if (timeFrame === "Daily") {
      setTransactionGraphData(dailyTransactionData);
    }

    if (timeFrame === "Weekly") {
      setTransactionGraphData(weeklyTransactionData);
    }
    if (timeFrame === "Monthly") {
      setTransactionGraphData(monthlyTransactionData);
    }
  }, [
    timeFrame,
    dailyTransactionData,
    weeklyTransactionData,
    monthlyTransactionData,
  ]);

  const handleChange = (value) => {
    setStatus(value);
  };

  //transaction
  const defaultPage = useMemo(() => {
    return {
      status,
      timeFrame,
    };
  }, [status, timeFrame]);

  // customer growth
  const defaultPageCustomerGrowth = useMemo(() => {
    return {
      timeFrame: customerGrowthTimeFrame,
    };
  }, [customerGrowthTimeFrame]);

  useEffect(() => {
    dispatch(fetchPartnerDashBoardTransactions(defaultPage));
  }, [dispatch, defaultPage, status]);

  useEffect(() => {
    dispatch(fetchPartnerCustomerGrowth(defaultPageCustomerGrowth));
  }, [dispatch, defaultPageCustomerGrowth]);

  useEffect(() => {
    let payload = { defaultDate: true };
    if (fromDate !== null && toDate !== null) {
      const formatDate = (date) => {
        return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
      };

      payload = {
        fromDate:
          formatDate(fromDate) === "Invalid Date"
            ? undefined
            : formatDate(fromDate),
        toDate:
          formatDate(toDate) === "Invalid Date"
            ? undefined
            : formatDate(toDate),
        defaultDate: false,
      };
    }

    dispatch(fetchPartnerDashBoardCollectedDeliveryFee(payload));
  }, [dispatch, fromDate, toDate]);

  useEffect(() => {
    let payload = { defaultDate: true };
    if (fromDateTopMerchants !== null && toDateTopMerchants !== null) {
      const formatDate = (date) => {
        return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
      };

      payload = {
        fromDate:
          formatDate(fromDateTopMerchants) === "Invalid Date"
            ? undefined
            : formatDate(fromDateTopMerchants),
        toDate:
          formatDate(toDateTopMerchants) === "Invalid Date"
            ? undefined
            : formatDate(toDateTopMerchants),
        defaultDate: false,
      };
    }

    dispatch(fetchPartnerDashBoardTopMerchants(payload));
  }, [dispatch, fromDateTopMerchants, toDateTopMerchants]);

  const Content = () => {
    return (
      <LayoutContent>
        <Alert
          style={{ marginLeft: 38, marginRight: 38 }}
          message={
            <p className="mt-3">
              <b> Must read:</b> Please go to the "Get Started" section to learn
              more about this platform before proceeding to navigate this system
              or click the button
            </p>
          }
          type="warning"
          showIcon
          closable
          action={
            <Space direction="vertical">
              <Link to={`/getstarted`}>
                <Button size="small">Learn more about Dory Platform</Button>
              </Link>
            </Space>
          }
        />
        <div
          style={{
            height: "55vh",
            margin: 40,
            border: "1px solid #e8e8e8",
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              height: "5vh",
              display: "flex",
              gap: 20,
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 40,
            }}
          >
            <div>
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      itemSelectedBg: "dodgerblue",
                      itemSelectedColor: "white",
                    },
                  },
                }}
              >
                <Segmented
                  size="middle"
                  options={["Daily", "Weekly", "Monthly"]}
                  onChange={(value) => {
                    setTimeFrame(value);
                  }}
                  value={timeFrame}
                  defaultValue="Daily"
                />
              </ConfigProvider>
            </div>
            <div>
              <Select
                defaultValue={status}
                style={{
                  width: 120,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "delivered",
                    label: "Delivered",
                  },
                  {
                    value: "undelivered",
                    label: "Undelivered",
                  },
                  {
                    value: "cancelled",
                    label: "Cancelled",
                  },
                ]}
              />
            </div>
          </div>
          <div style={{ height: "45vh" }}>
            <Line
              data={{
                labels: transactionGraphData.map((data) => data?.order_date),
                datasets: [
                  {
                    label: "Transaction",
                    data: transactionGraphData.map(
                      (data) => data?.transaction_count
                    ),
                    backgroundColor: "#c9e4fe",
                    borderColor: "#064FF0",
                    pointStyle: "circle",
                    pointRadius: 6,
                    pointHoverRadius: 15,
                  },
                ],
              }}
              options={{
                height: "45vh",
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: false,
                  title: {
                    display: true,
                    text: () => "Transactions",
                    font: { size: 20 },
                  },
                },
                scales: {
                  y: {
                    title: {
                      color: "red",
                      display: true,
                      text: "No. of transaction",
                    },
                  },
                },
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",

            margin: 40,
            gap: 30,
            justifyContent: "space-evenly",
          }}
        >
          <div>
            <div
              style={{
                height: "40vh",
                width: "30vw",
                textAlign: "center",
                border: "1px solid #e8e8e8",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div style={{ display: "flex" }}>
                <RangePicker
                  onChange={handleRangeChange}
                  value={[fromDate, toDate]}
                />
              </div>
              <div style={{ alignContent: "center", paddingTop: 40 }}>
                <Title level={3} type="secondary">
                  Total Collected Delivery fee
                </Title>
                <Title level={2}>
                  {formatCurrency(totalCollectedDeliveryFee)}
                </Title>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "40vh",
              width: "50vw",
              border: "1px solid #e8e8e8",
              borderRadius: "8px",
              padding: "12px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/*  */}
            <RangePicker
              onChange={handleRangeChangeTopMerchants}
              value={[fromDateTopMerchants, toDateTopMerchants]}
            />
            {/*  */}
            <Bar
              data={{
                labels: topMerchants.map((data) => data?.name),

                datasets: [
                  {
                    label: "total transaction: ",
                    data: topMerchants.map((data) => data?.order_count),
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.2)",
                      "rgba(54, 162, 235, 0.2)",
                      "rgba(255, 205, 86, 0.2)",
                      "rgba(75, 192, 192, 0.2)",
                      "rgba(153, 102, 255, 0.2)",
                      "rgba(255, 159, 64, 0.2)",
                      "rgba(205, 92, 92, 0.2)",
                      "rgba(0, 206, 209, 0.2)",
                      "rgba(128, 0, 128, 0.2)",
                      "rgba(32, 178, 170, 0.2)",
                    ],
                    borderColor: [
                      "rgb(255, 99, 132)",
                      "rgb(54, 162, 235)",
                      "rgb(255, 205, 86)",
                      "rgb(75, 192, 192)",
                      "rgb(153, 102, 255)",
                      "rgb(255, 159, 64)",
                      "rgb(205, 92, 92)",
                      "rgb(0, 206, 209)",
                      "rgb(128, 0, 128)",
                      "rgb(32, 178, 170)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                height: "30vh",
                indexAxis: "y",
                plugins: {
                  legend: false,
                  title: {
                    display: true,
                    text: () => "Top 10 Merchants",
                    font: { size: 20 },
                  },
                },
                layout: {
                  padding: {
                    left: 30,
                    right: 30,
                    bottom: 30,
                  },
                },
              }}
            />
          </div>
        </div>
        <div
          style={{
            height: "55vh",
            margin: 40,
            border: "1px solid #e8e8e8",
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              height: "5vh",
              display: "flex",
              gap: 20,
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 40,
            }}
          >
            <div>
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      itemSelectedBg: "dodgerblue",
                      itemSelectedColor: "white",
                    },
                  },
                }}
              >
                <Segmented
                  size="middle"
                  options={["Daily", "Weekly", "Monthly"]}
                  onChange={(value) => {
                    setCustomerGrowthTimeFrame(value);
                  }}
                  value={customerGrowthTimeFrame}
                  defaultValue="Daily"
                />
              </ConfigProvider>
            </div>
          </div>
          <div style={{ height: "45vh" }}>
            <Line
              data={{
                labels: customerGrowthGraphData.map(
                  (data) => data?.register_date
                ),
                datasets: [
                  {
                    label: "new customer",
                    data: customerGrowthGraphData.map(
                      (data) => data?.customer_count
                    ),
                    backgroundColor: "#c9e4fe",
                    borderColor: "#064FF0",
                    pointStyle: "circle",
                    pointRadius: 6,
                    pointHoverRadius: 15,
                  },
                ],
              }}
              options={{
                height: "45vh",
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: false,
                  title: {
                    display: true,
                    text: () => "Customer Growth",
                    font: { size: 20 },
                  },
                },
              }}
            />
          </div>
        </div>
      </LayoutContent>
    );
  };

  return (
    <Wrapper
      modal={
        <>
          <Modal
            width={"80%"}
            open={trainingStatus !== 0 ? false : modal}
            closable={false}
            footer={transactionCount >= 10 && []}
          >
            <div className="my-4" style={{ flex: 1, justifyContent: "start" }}>
              <h2 style={{ fontSize: 22 }}>Onboarding Training</h2>
              <Alert
                message="Please watch the training video to gain a better understanding of
              our business and platform. It can only be closed once completed. Thank you!"
                type="info"
              />
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {isFinished ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <LoadingOutlined
                    style={{
                      fontSize: 90,
                      display: "flex",
                      justifyContent: "center",
                      color: "gray",
                    }}
                  />
                  <span style={{ fontSize: 19 }}>
                    Wait while the system is checking if you finished the
                    training video
                  </span>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <video
                    className="custom-video"
                    ref={videoRef}
                    width="100%"
                    controls
                    onEnded={handleVideoEnd}
                    onLoadedMetadata={handleLoadedMetadata} // Load saved progress when metadata is ready
                    onTimeUpdate={handleTimeUpdate}
                  >
                    <source
                      src="https://groceria-storage.sgp1.cdn.digitaloceanspaces.com/assets/video/partner-training-video/onboarding%20Training.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              )}
            </div>
            <br />
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                gap: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip title={"Play Backward (-10 seconds)"}>
                <button
                  onClick={handleBackward}
                  style={{
                    marginTop: "10px",
                    fontSize: 19,
                    fontWeight: "bold",
                  }}
                >
                  <BackwardOutlined style={{ fontSize: 60, color: "gray" }} />
                </button>
              </Tooltip>
              {transactionCount >= 10 && (
                <Button
                  style={{ marginTop: 10, height: 45 }}
                  key="cancel"
                  disabled={isFinished}
                  onClick={() => {
                    setModal(false);
                    if (videoRef.current) {
                      videoRef.current.pause();
                    }
                  }}
                >
                  Watch later
                </Button>
              )}
            </div>
          </Modal>
        </>
      }
      content={<Content />}
    />
  );
};

export default PartnerDashBoard;
