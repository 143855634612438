import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { ADMINACCOUNTS } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: ADMINACCOUNTS.TOGGLE, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: ADMINACCOUNTS.SET, payload });
};

export const fetchAllAdminAccounts =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: ADMINACCOUNTS.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/admin-account`;
      if (payload !== undefined) {
        const { page, limit, searchText, dateFilter } = payload;
        url = `/api/admin-account?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/admin-account?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
        if (page && limit && dateFilter) {
          url = `/api/admin-account?page=${page}&limit=${limit}&dateFilter=${dateFilter}`;
        }
      }
      const response = await axiosInstance(token).get(url);

      dispatch({
        type: ADMINACCOUNTS.FETCH.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ADMINACCOUNTS.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: ADMINACCOUNTS.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      "/api/admin-account/create",
      payload
    );

    dispatch({ type: ADMINACCOUNTS.CREATE.SUCCESS, payload: response });

    return response;
  } catch (error) {
    dispatch({
      type: ADMINACCOUNTS.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const update = (payload) => async (dispatch, getState) => {
  dispatch({ type: ADMINACCOUNTS.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).put(
      `/api/admin-account/update/${payload.id}`,
      payload
    );

    dispatch({ type: ADMINACCOUNTS.UPDATE.SUCCESS, payload: response });

    return response;
  } catch (error) {
    dispatch({
      type: ADMINACCOUNTS.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const updateContactNumber = (payload) => async (dispatch, getState) => {
  const {
    userLoginReducer: { userInfo },
  } = getState();
  try {
    const token = userInfo.token;
    const response = await axiosInstance(token).put(
      `/api/admin-account/change-contact-number`,
      payload
    );

    if (response?.data) {
      dispatch({
        type: ADMINACCOUNTS.UPDATE_CONTACT_NUMBER,
        payload: { ...userInfo, contact_number: payload.contact_number },
      });
    }

    return response;
  } catch (error) {
    dispatch({
      type: ADMINACCOUNTS.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};
