import axiosInstance from "../../helpers/axios";
import actions from "../actionTypes";
const { REFERREDSUBOPERATOR } = actions;

export const fetchAllReferredSubOperator =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: REFERREDSUBOPERATOR.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;

    try {
      const { page, limit, sub_operator } = payload;

      let url = `/api/sub-operator/fetch-referred-operator?partner_id=${userInfo.id}&type=${sub_operator}`;
      const response = await axiosInstance(token).get(url);
      console.log(response);
      dispatch({ type: REFERREDSUBOPERATOR.FETCH.SUCCESS, payload: response });
      return response;
    } catch (error) {
      dispatch({
        type: REFERREDSUBOPERATOR.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
