import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";

import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  notification,
  Alert,
  DatePicker,
  Input,
} from "antd";

// import { toggleModal, create } from "../../redux/actions/manageOperaotrs";
import {
  toggleModal,
  create,
  //   update,
  setForm,
} from "../../redux/actions/coupons";

// Utilities
import renderInput from "../utilities/inputForms";

import dayjs from "dayjs";
const CreateCouponCode = () => {
  const dispatch = useDispatch();

  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [isProvinceEdited, setIsProvinceEdited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [isBarangayEdited, setIsBarangayEdited] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const { modal, loading, error, form, createLoading, formLoading } =
    useSelector(
      ({ couponsReducer }) => ({
        modal: couponsReducer.modal,
        loading: couponsReducer.loading,
        error: couponsReducer.error,
        createLoading: couponsReducer.createLoading,
        form: couponsReducer.form,
        formLoading: couponsReducer.formLoading,
      }),
      isEqual
    );

  const schema = yup.object().shape({
    expiration_date: yup.date().required("Date of Birth is a required field"),
    start_date: yup.date().required("Date of Birth is a required field"),
    // code: yup.string().required("code is required field"),
    df_discount: yup.string().required("username is required field"),
    limit: yup.string().required("limit is required field"),
  });

  let defaultValues = {
    expiration_date: "",
    start_date: "",
    code: "",
    df_discount: "",
    limit: "",
  };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    // setIsProvinceEdited(false);
    // setChangePassword(false);
    // setIsCityEdited(false);
    // setIsBarangayEdited(false);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        // const newValue = {
        //   contact_number: values.contact_number,
        //   user_name: values.user_name,
        //   user_password: values.user_password,
        //   role: "operator_admin",
        //   province: province?.name,
        //   city: city,
        //   barangay: barangay,
        //   id: form.id,
        // };
        // const { data } = await dispatch(update(newValue));
        // if (data) {
        //   openNotification("SUCCESS", data.message);
        //   close();
        // }
      } else {
        const createData = {
          expiration_date: values.expiration_date,
          start_date: values.start_date,
          code: coupon,
          less_delivery_fee_pecentage: values.df_discount,
          limit: values.limit,
        };

        const { data } = await dispatch(create(createData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("Create/update partner merchant error:>>", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  const generateCouponCode = () => {
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    const couponCode = `Dory${randomNumber}`;
    setCoupon(couponCode);
  };

  return (
    <Modal
      title={"Create coupon code"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  placeholder="Dory coupon"
                  allowClear
                  value={coupon}
                  type="secondary"
                />
                <Button
                  style={{ marginLeft: 15, marginTop: 10, marginBottom: 15 }}
                  // htmlType="submit"
                  onClick={generateCouponCode}
                  type="primary"
                  // loading={formLoading}
                  // disabled={formLoading}
                  required={true}
                >
                  Generate Coupon code
                </Button>
              </div>
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Delivery Fee discount",
                  name: "df_discount",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Limit",
                  name: "limit",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>

            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  type: "date",
                  label: "Start Date",
                  name: "start_date",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  type: "date",
                  label: "Expiration Date",
                  name: "expiration_date",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
          </Row>
        </>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={formLoading}
            disabled={formLoading}
          >
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateCouponCode;
