import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import axiosInstance from "../helpers/axios";

import {
  userLoginReducer,
  userRegisterReducer,
  userReducer,
} from "../redux/reducers/users";

import { merchantsReducer } from "./reducers/partnerMerchants";
import { raffleTicketsReducer } from "./reducers/raffleTickets";

import { partnerStoresReducer } from "./reducers/partnerStores";

import { partnerStoresProductsReducer } from "./reducers/partnerStoreProducts";

import { allproductsReducer } from "./reducers/allproducst";

import { CategoryReducer } from "./reducers/merchantCategoryMenu";

import { MerchantMenuReducer } from "./reducers/merchantMenu";

import { operatorPerformanceReducer } from "./reducers/operatorPerformace";
import { notificationReducer } from "./reducers/notification";

import { bookingsReducer } from "./reducers/bookings";
import { operatorPayablesReducer } from "./reducers/operatorPayables";
import { operatorPriceApprovalReducer } from "./reducers/operatorPriceApproval";
import { customerReducer } from "./reducers/customers";

import { operatorWithdrawRequestReducer } from "./reducers/operatorWithdrawRequest";
import { merchantWithdrawRequestReducer } from "./reducers/merchantWithdrawRequest";

import { manageOperatorReducer } from "./reducers/manageOperaotrs";
import { fixRateReducer } from "./reducers/fixRates";

import { appEarningWithdrawalReducer } from "./reducers/appEarningWithdrawal";
import { accountsReceivableReducer } from "./reducers/accountReceivable";
import { tagsReducer } from "./reducers/merchantTags";

import { groupedAreasReducer } from "./reducers/groupedAreas";
import { adminAccountReducer } from "./reducers/adminAccounts";

import { franchiseePayablesReducer } from "./reducers/FranchiseePayables";
import { payablesStatusReducer } from "./reducers/payablesStatus";
import { allFranchiseePayablesReducer } from "./reducers/allFranchiseePayables";
import { couponsReducer } from "./reducers/coupons";
import { payableInfoReducer } from "./reducers/payablesInfo";
import { payableStatusInfoReducer } from "./reducers/payableStatusInfo";
import { succeedingRateReducer } from "./reducers/succeedingDistanceRate";
import { deliveryFeeSettingsReducer } from "./reducers/deliveryFeeSettings";

import { monitoringPayablesReducer } from "./reducers/monitoringPayables";

import { PartnerDashBoardReducer } from "./reducers/partnerDashBoard";
import { AdminDashBoardReducer } from "./reducers/adminDashBoard";
import { partnerCouponsReducer } from "./reducers/partnerCoupons";
import { riderReducer } from "./reducers/riderInMyArea";
import { riderAvailedPackageReducer } from "./reducers/riderPackageAvailed";

import { nationalMerchantsReducer } from "./reducers/nationalMerchants";
import { nationalMerchantMenuReducer } from "./reducers/nationalMerchantsMenu";
import { nationalMerchantCategoryReducer } from "./reducers/nationalMerchantMenuCategories";

import { localMerchantReducer } from "./reducers/merchantInMyArea";
import { localMerchantForApprovalReducer } from "./reducers/localMerchantsApproval";
import { localMerchantMenuForApprovalReducer } from "./reducers/MerchantsMenuApproval";
import { referredCustomerReducers } from "./reducers/referredCustomers";
import { newRidersReducers } from "./reducers/newRiders";
import { validateReportedRidersReducer } from "./reducers/validateReportedRiders";
import { partnerFinanceReducer } from "./reducers/partnerFinance";
import { riderDocumentsReducer } from "./reducers/riderDocuments";

import { riderPackagesReducers } from "./reducers/riderPackageMonitoring";

import { reportedBookingsReducer } from "./reducers/reportedBookings";
import { affiliatesReducer } from "./reducers/affiliates";

import { subOpsReferredRiderReducer } from "./reducers/subOperatorReferralRider";

import { referredOperatorReducer } from "./reducers/referredOperator";
import { referredSubOperatorReducer } from "./reducers/referredSubOperator";

import { decryptData, encryptData } from "../helpers/crypto";

let userInformationFromStorage = null;

try {
  const encryptedData = localStorage.getItem("userInfo");

  if (encryptedData) {
    userInformationFromStorage = decryptData(encryptedData);
  }
} catch (error) {
  console.log("Error decrypting user information:", error);
  localStorage.removeItem("userInfo");
}

const verifyToken = async () => {
  try {
    if (userInformationFromStorage && userInformationFromStorage.token) {
      const { data } = await axiosInstance(
        userInformationFromStorage.token
      ).post("/api/user/check-token", {
        token: userInformationFromStorage.token,
      });

      return data.token;
    } else {
      console.log("User information or token is missing");
    }
  } catch (error) {
    console.log(">>> error verifying token:", error);
  }
};

(async () => {
  if (userInformationFromStorage) {
    const newToken = await verifyToken();

    if (newToken) {
      userInformationFromStorage = {
        ...userInformationFromStorage,
        token: newToken,
      };

      localStorage.setItem("userInfo", encryptData(userInformationFromStorage));
    }
  }
})();

const reducer = combineReducers({
  userLoginReducer,
  userRegisterReducer,
  userReducer,
  merchantsReducer,
  raffleTicketsReducer,
  partnerStoresReducer,
  partnerStoresProductsReducer,
  allproductsReducer,
  CategoryReducer,
  MerchantMenuReducer,
  operatorPerformanceReducer,
  notificationReducer,
  bookingsReducer,
  operatorPayablesReducer,
  operatorPriceApprovalReducer,
  customerReducer,
  operatorWithdrawRequestReducer,
  merchantWithdrawRequestReducer,
  manageOperatorReducer,
  fixRateReducer,
  appEarningWithdrawalReducer,
  accountsReceivableReducer,
  tagsReducer,
  groupedAreasReducer,
  adminAccountReducer,
  franchiseePayablesReducer,
  payablesStatusReducer,
  allFranchiseePayablesReducer,
  couponsReducer,
  payableInfoReducer,
  payableStatusInfoReducer,
  succeedingRateReducer,
  deliveryFeeSettingsReducer,
  monitoringPayablesReducer,
  PartnerDashBoardReducer,
  AdminDashBoardReducer,
  partnerCouponsReducer,
  riderReducer,
  riderAvailedPackageReducer,
  nationalMerchantsReducer,
  nationalMerchantMenuReducer,
  nationalMerchantCategoryReducer,
  localMerchantReducer,
  localMerchantForApprovalReducer,
  localMerchantMenuForApprovalReducer,
  referredCustomerReducers,
  newRidersReducers,
  validateReportedRidersReducer,
  partnerFinanceReducer,
  riderDocumentsReducer,
  riderPackagesReducers,
  reportedBookingsReducer,
  affiliatesReducer,

  subOpsReferredRiderReducer,

  referredOperatorReducer,
  referredSubOperatorReducer,
});

const syncStorage = {
  userLoginReducer: { userInfo: userInformationFromStorage },
};

const middleware = [thunk];
const store = createStore(
  reducer,
  syncStorage,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
