import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Modal,
  DatePicker,
} from "antd";
import { CheckSquareOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import {
  fetchAllPriceForApproval,
  updatePriceApproval,
} from "../redux/actions/operatorPriceApproval";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const PriceApproval = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [rowData, setRowData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const {
    loading,
    operatorPriceApprovalData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ operatorPriceApprovalReducer }) => ({
      operatorPriceApprovalData: operatorPriceApprovalReducer.data,
      totalRecord: operatorPriceApprovalReducer.totalRecord,
      nextPage: operatorPriceApprovalReducer.nextPage,
      previousPage: operatorPriceApprovalReducer.previousPage,
      currentPage: operatorPriceApprovalReducer.currentPage,
      loading: operatorPriceApprovalReducer.loading,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      dateFilter: formattedDate === "Invalid Date" ? undefined : formattedDate,
    };
    dispatch(fetchAllPriceForApproval(payload));
  }, [delaySearchText, formattedDate, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllPriceForApproval(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Operator Payables</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
            <Form.Item label="Select Date">
              <DatePicker
                style={{ width: "15vw" }}
                value={selectedDate}
                onChange={(date, dateString) => {
                  setSelectedDate(date);
                  setFormattedDate(dayjs(dateString).format("YYYY-MM-DD"));
                }}
                placeholder="Select Date"
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllPriceForApproval({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllPriceForApproval({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllPriceForApproval({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllPriceForApproval({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  const handleOk = () => {
    setConfirmLoading(true);
    dispatch(updatePriceApproval(rowData));
    setUpdate((prevUpdate) => !prevUpdate);
    setOpenModal(false);
    setConfirmLoading(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button
              disabled={original.is_approved}
              onClick={() => {
                setRowData({
                  tbl_approval_id: original.id,
                  partner_stores_id: original.partner_stores_id,
                  products_id: original.products_id,
                  suggested_price: original.suggested_price,
                });
                setOpenModal(true);
              }} //edit me make function that update approval
              size="small"
              type="text"
            >
              <Tooltip title="Approve">
                <CheckSquareOutlined />
              </Tooltip>
            </Button>
          </>
        ),
        width: 100,
      },
      {
        Header: "Operator's Name",
        accessor: (row) => `${row.operator_fName} ${row.operator_lName}`,
      },
      {
        Header: "Product name",
        accessor: "product_name",
      },
      {
        Header: "Price for approval",
        accessor: "suggested_price",
      },
      {
        Header: "Partner Store Name",
        accessor: "partner_store_name",
      },
      {
        Header: "Store Location",
        accessor: (row) =>
          `${row.partner_store_city} ${row.partner_store_province}`,
      },
      {
        Header: "Status",
        accessor: "is_approved",
        Cell: ({ value }) => {
          return value === true || value === 1 ? (
            <Badge status="success" text="Approved" />
          ) : (
            <Badge status="error" text="Pending" />
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllPriceForApproval(defaultPage));
  }, [dispatch, defaultPage, update]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={operatorPriceApprovalData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          dateFilters={{ selectedDate }}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <Modal
              width={350}
              title="Approve this price update?"
              open={openModal}
              onOk={handleOk}
              footer={[
                <Button key="back" onClick={() => setOpenModal(false)}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={confirmLoading}
                  onClick={handleOk}
                >
                  Approve
                </Button>,
              ]}
            ></Modal>
          </>
        }
      />
    </>
  );
};

export default PriceApproval;
