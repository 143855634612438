import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Button,
  Tooltip,
  Form,
  Input,
  Collapse,
  Modal,
  List,
} from "antd";
import {
  FormOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import GroupedAreasModal from "../components/groupedAreas/modal";

// Actions
import {
  fetchAllGroupedAreas,
  setForm,
  toggleModal,
} from "../redux/actions/groupedAreas";

const { Content: LayoutContent } = Layout;
const { Search } = Input;
const { Panel } = Collapse;

const GroupedAreas = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupedProvince, setGroupedProvince] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const {
    loading,
    formLoading,
    groupedAreasData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ groupedAreasReducer }) => ({
      groupedAreasData: groupedAreasReducer.data,
      totalRecord: groupedAreasReducer.totalRecord,
      nextPage: groupedAreasReducer.nextPage,
      previousPage: groupedAreasReducer.previousPage,
      currentPage: groupedAreasReducer.currentPage,
      loading: groupedAreasReducer.loading,
      formLoading: groupedAreasReducer.formLoading,
    }),
    isEqual
  );

  const onChange = (status, id) => {
    // dispatch(updatePartnerStore({ status, id }));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAllGroupedAreas(payload));
  }, [delaySearchText, dispatch]);

  const onAdd = () => dispatch(toggleModal());
  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllGroupedAreas(payload));
  };
  const onEdit = ({ data }) => {
    dispatch(setForm(data));
    dispatch(toggleModal());
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>DORY Grouped Areas</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const extra = () => {
    const addButton = (
      <Button key="1" onClick={onAdd} className="mr-2">
        <PlusOutlined /> Add
      </Button>
    );

    return [addButton];
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllGroupedAreas({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllGroupedAreas({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllGroupedAreas({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllGroupedAreas({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button
              disabled={formLoading}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined />
              </Tooltip>
            </Button>

            <Button
              size="small"
              type="text"
              onClick={() => {
                setGroupedProvince(JSON.parse(original.municipalities));
                showModal(true);
              }}
            >
              <Tooltip title="Grouped details">
                <InfoCircleOutlined />
              </Tooltip>
            </Button>
          </>
        ),
        width: 100,
      },
      {
        Header: "Province",
        accessor: "province",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllGroupedAreas(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={groupedAreasData} //
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
          ]}
          customFilters={customFilters}
        />
        {/*  */}
        <Modal
          title="Basic Modal"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Collapse accordion>
            {groupedProvince.map((municipalityArray, index) => (
              <Panel key={index} header={`Group ${index + 1}`}>
                <List
                  size="small"
                  bordered
                  dataSource={municipalityArray}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </Panel>
            ))}
          </Collapse>
        </Modal>
        {/*  */}
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <GroupedAreasModal />
          </>
        }
      />
    </>
  );
};

export default GroupedAreas;
