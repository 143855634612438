import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Button,
  Tooltip,
  Form,
  Input,
  Image,
  Popconfirm,
  notification,
} from "antd";
import {
  CheckSquareOutlined,
  PlusOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
// Actions

import moment from "moment/moment";
import {
  fetchMenuMerchantsMenuForApproval,
  setForm,
  toggleModal,
  updateMerchantMenuApprovalStatus,
} from "../redux/actions/MerchantsMenuApproval";
import RejectMerchantMenuModal from "../components/localMerchantMenuApproval/modal";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const MerchantsMenusForApproval = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    formLoading,
    merchantsMenuForApprovalData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ localMerchantMenuForApprovalReducer }) => ({
      merchantsMenuForApprovalData: localMerchantMenuForApprovalReducer.data,
      totalRecord: localMerchantMenuForApprovalReducer.totalRecord,
      nextPage: localMerchantMenuForApprovalReducer.nextPage,
      previousPage: localMerchantMenuForApprovalReducer.previousPage,
      currentPage: localMerchantMenuForApprovalReducer.currentPage,
      loading: localMerchantMenuForApprovalReducer.loading,
      formLoading: localMerchantMenuForApprovalReducer.formLoading,
    }),
    isEqual
  );

  const { userInfo } = useSelector(({ userLoginReducer }) => ({
    userInfo: userLoginReducer.userInfo,
  }));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchMenuMerchantsMenuForApproval(payload));
  }, [delaySearchText, dispatch]);

  const onAdd = () => dispatch(toggleModal());
  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchMenuMerchantsMenuForApproval(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 35 }}>Menus for Approval</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchMenuMerchantsMenuForApproval({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchMenuMerchantsMenuForApproval({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchMenuMerchantsMenuForApproval({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchMenuMerchantsMenuForApproval({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };

  const onReject = (payload) => {
    dispatch(setForm(payload));
    dispatch(toggleModal());
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onApproved = async (value) => {
    try {
      const { data } = await dispatch(
        updateMerchantMenuApprovalStatus({
          status: "approved",
          merchant_menu_id: value.id,
        })
      );

      if (data) {
        openNotification("SUCCESS", "Succesfully Approved");
      }
    } catch (error) {
      console.log(">>>> error approving merchant");
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button
              disabled={formLoading}
              size="small"
              type="text"
              onClick={() => {
                onReject(original);
              }}
            >
              <Tooltip title="Reject">
                <CloseSquareOutlined />
              </Tooltip>
            </Button>

            {/*  */}
            <Popconfirm
              title="Are you sure you want to approve this menu?"
              description="Please review the image before proceeding."
              onConfirm={() => {
                onApproved(original);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button disabled={formLoading} size="small" type="text">
                <Tooltip title="Approve">
                  <CheckSquareOutlined />
                </Tooltip>
              </Button>
            </Popconfirm>
          </>
        ),
        width: 100,
      },
      {
        Header: "Menu name",
        accessor: "title",
      },
      {
        Header: "Image",

        accessor: (row) => {
          return (
            <Image
              width={50}
              src={`https://groceria-storage.sgp1.digitaloceanspaces.com/assets/images/partner-merchant-menu/${row.image_url}`}
            />
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchMenuMerchantsMenuForApproval(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={merchantsMenuForApprovalData} //
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <RejectMerchantMenuModal />
          </>
        }
      />
    </>
  );
};

export default MerchantsMenusForApproval;
