import actions from "../actionTypes";
const { REFERREDSUBOPERATOR } = actions;

const userInitialState = {
  data: [],
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
};

export const referredSubOperatorReducer = (
  state = userInitialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case REFERREDSUBOPERATOR.FETCH.REQUEST:
      return { ...state, loading: true };
    case REFERREDSUBOPERATOR.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data?.data?.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case REFERREDSUBOPERATOR.FETCH.FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
