import React from "react";
import { Layout } from "antd";
import "antd/dist/reset.css";

const { Footer } = Layout;

const FooterComponent = () => {
  return <Footer>Dory Platform</Footer>;
};

export default FooterComponent;
