import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { MERCHANTSMENUAPPROVAL } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTSMENUAPPROVAL.TOGGLE, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: MERCHANTSMENUAPPROVAL.SET, payload });
};

export const fetchMenuMerchantsMenuForApproval =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: MERCHANTSMENUAPPROVAL.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/local-merchants/merchant/menus`;

      if (payload !== undefined) {
        const { page, limit, searchText } = payload;
        url = `/api/local-merchants/merchant/menus?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/local-merchants/merchant/menus?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
      }

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: MERCHANTSMENUAPPROVAL.FETCH.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: MERCHANTSMENUAPPROVAL.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const updateMerchantMenuApprovalStatus =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: MERCHANTSMENUAPPROVAL.UPDATE.REQUEST });

    try {
      const {
        userLoginReducer: { userInfo },
      } = getState();
      let { token } = userInfo;

      const response = await axiosInstance(token).patch(
        "/api/local-merchants/merchant/menu/status",
        payload
      );
      dispatch({
        type: MERCHANTSMENUAPPROVAL.UPDATE.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: MERCHANTSMENUAPPROVAL.UPDATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
