import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Form, Input } from "antd";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import { fetchAllOperatorPerformance } from "../redux/actions/operatorPerformace";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const OperatorPerformace = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    operatorPerformaceData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ operatorPerformanceReducer }) => ({
      operatorPerformaceData: operatorPerformanceReducer.data,
      totalRecord: operatorPerformanceReducer.totalRecord,
      nextPage: operatorPerformanceReducer.nextPage,
      previousPage: operatorPerformanceReducer.previousPage,
      currentPage: operatorPerformanceReducer.currentPage,
      loading: operatorPerformanceReducer.loading,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAllOperatorPerformance(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllOperatorPerformance(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Riders Perfomance</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllOperatorPerformance({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllOperatorPerformance({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllOperatorPerformance({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllOperatorPerformance({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        // accessor: (row) => `${row.first_name} ${row.last_name}`,
        accessor: "fullName",
      },
      {
        Header: "Mobile number",
        accessor: "contact_number",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Province",
        accessor: "province",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "number of bookings",
        accessor: (row) => {
          return Number(row.delivered_count) + Number(row.undelivered_count);
        },
        Cell: ({ cell: { value } }) => (
          <span style={{ display: "block", textAlign: "center" }}>{value}</span>
        ),
      },
      {
        Header: "Delivered",
        accessor: "delivered_count",
        Cell: ({ cell: { value } }) => (
          <span style={{ display: "block", textAlign: "center" }}>{value}</span>
        ),
      },
      {
        Header: "Pending",
        accessor: "pending_count",
        Cell: ({ cell: { value } }) => (
          <span style={{ display: "block", textAlign: "center" }}>{value}</span>
        ),
      },
      {
        Header: "Canceled",
        accessor: "canceled_count",
        Cell: ({ cell: { value } }) => (
          <span style={{ display: "block", textAlign: "center" }}>{value}</span>
        ),
      },
      {
        Header: "Undelivered",
        accessor: "undelivered_count",
        Cell: ({ cell: { value } }) => (
          <span style={{ display: "block", textAlign: "center" }}>{value}</span>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllOperatorPerformance(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={operatorPerformaceData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default OperatorPerformace;
