import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Form, Input } from "antd";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import { fetchAllReferredOperator } from "../redux/actions/referredOperator";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const ReferredOperator = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    referredOperatorData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(({ referredOperatorReducer }) => ({
    referredOperatorData: referredOperatorReducer.data,
    totalRecord: referredOperatorReducer.totalRecord,
    nextPage: referredOperatorReducer.nextPage,
    previousPage: referredOperatorReducer.previousPage,
    currentPage: referredOperatorReducer.currentPage,
    loading: referredOperatorReducer.loading,
  }));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAllReferredOperator(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllReferredOperator(payload));
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      operator: "operator",
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllReferredOperator({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllReferredOperator({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllReferredOperator({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllReferredOperator({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  const customFilters = () => {
    return (
      <div className="mb-0">
        <h1 style={{ fontSize: 30 }}>Referred Operator</h1>
        {/* <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div> */}
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (row) => `${row.fullName}`,
      },
      {
        Header: "Mobile number",
        accessor: "contact_number",
      },
      {
        Header: "Email",
        accessor: "email_address",
      },
      // {
      //   Header: "Service City",
      //   accessor: "service_city",
      // },
      // {
      //   Header: "Service Province",
      //   accessor: "service_province",
      // },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllReferredOperator(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={referredOperatorData}
          loading={loading}
          serverSidePagination={{
            totalRecord: 0,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default ReferredOperator;
