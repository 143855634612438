import actions from "../actionTypes";
const { MERCHANTSAPPROVAL } = actions;

const userInitialState = {
  modal: false,
  modalView: false,
  formLoading: false,
  data: [],
  form: {},
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
  oldAccountModal: false,
};

export const localMerchantForApprovalReducer = (
  state = userInitialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case MERCHANTSAPPROVAL.TOGGLE:
      return { ...state, modal: payload, error: null };
    case MERCHANTSAPPROVAL.TOGGLEOLDACCOUNTMODAL:
      return { ...state, oldAccountModal: payload, error: null };
    case MERCHANTSAPPROVAL.SET:
      return { ...state, form: payload };

    case MERCHANTSAPPROVAL.FETCH.REQUEST:
      return { ...state, loading: true };
    case MERCHANTSAPPROVAL.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case MERCHANTSAPPROVAL.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case MERCHANTSAPPROVAL.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case MERCHANTSAPPROVAL.UPDATE.SUCCESS:
      const updatedData = payload.data.data;
      const isApproved = payload.data.isApproved;
      const updatedItem = Array.isArray(updatedData)
        ? updatedData[0]
        : updatedData;

      if (isApproved) {
        return {
          ...state,
          formLoading: false,
          form: payload.data.data,
          data: state.data.filter((d) => d.id !== updatedItem?.id),
        };
      } else {
        return {
          ...state,
          formLoading: false,
          form: payload.data.data,
          data: state.data.map((d) =>
            d.id === updatedItem?.id ? { ...d, note: payload.data.note } : d
          ),
        };
      }

    case MERCHANTSAPPROVAL.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
