import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { PAYABLESSTATUS } = actions;

export const fetchAllPayablesStatus =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: PAYABLESSTATUS.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/franchisee-payables/payableStatus`;
      if (payload !== undefined) {
        const { page, limit, searchText, dateFilter, todateFilter } = payload;
        url = `/api/franchisee-payables/payableStatus/${userInfo.id}?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/franchisee-payables/payableStatus/${userInfo.id}?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
        if (page && limit && dateFilter && todateFilter) {
          url = `/api/franchisee-payables/payableStatus/${userInfo.id}?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}`;
        }
      }
      const response = await axiosInstance(token).get(url);

      dispatch({
        type: PAYABLESSTATUS.FETCH.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: PAYABLESSTATUS.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
