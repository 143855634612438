const regionsWithPhMajorIsland = require("./data/regionsWithPhMajorIsland");

// get regions by province
const getRegionByProvince = (provinceObj) => {
  const region = regionsWithPhMajorIsland.filter(
    (region) => region.reg_code === provinceObj.reg_code
  )[0];
  return region;
};

module.exports = { getRegionByProvince };
