import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isEqual } from "lodash";
import { Layout, Button, Tooltip, Form, Input } from "antd";
import { FormOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import PartnerStoreProductsModal from "../components/partnerStoreProducts/modal";

// Actions
// import { fetchProducts } from "../redux/actions/partnerStores";
import BulkUploadModal from "../components/partnerStoreProducts/bulkUploadModal";
import {
  toggleModal,
  setForm,
  fetch,
  toggleImportModal,
} from "../redux/actions/partnerStoreProducts";

import { useCallback } from "react";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const PartnerStoreProducts = () => {
  const dispatch = useDispatch();
  const { storeId, city, province } = useParams();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    formLoading,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    fetchdata,
    modalNoSku,
    modal,
  } = useSelector(
    ({ partnerStoresProductsReducer }) => ({
      modal: partnerStoresProductsReducer.modal,
      modalNoSku: partnerStoresProductsReducer.modalNoSku,
      fetchdata: partnerStoresProductsReducer.data,
      loading: partnerStoresProductsReducer.loading,
      formLoading: partnerStoresProductsReducer.formLoading,
      totalRecord: partnerStoresProductsReducer.totalRecord,
      nextPage: partnerStoresProductsReducer.nextPage,
      previousPage: partnerStoresProductsReducer.previousPage,
      currentPage: partnerStoresProductsReducer.currentPage,
    }),
    isEqual
  );

  const onAdd = () => {
    dispatch(toggleModal());
  };

  const onClickBulkUpload = () => {
    dispatch(toggleImportModal());
  };

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      userId: storeId,
      searchText,
    };

    dispatch(fetch(payload));
  };

  const onEdit = useCallback(
    ({ data }) => {
      dispatch(setForm(data));
      dispatch(toggleModal());
    },

    [dispatch]
  );

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Groceria Partner Store</h1>
        <h1>Stores Product - {`${city} ${province}`}</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      userId: storeId,
    };
  }, [storeId]);

  const onClickNextPage = () => {
    dispatch(
      fetch({
        ...nextPage,
        userId: storeId,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetch({
        ...previousPage,
        userId: storeId,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetch({
        ...defaultPage,
        userId: storeId,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetch({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        userId: storeId,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "skuNum",
      },
      {
        Header: "Name",
        accessor: "product_name",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button
              disabled={formLoading}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined /> EDIT
              </Tooltip>
            </Button>
          </>
        ),
        width: 100,
      },
    ],
    [formLoading, onEdit]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    if (!modalNoSku || !modal) {
      dispatch(fetch(defaultPage));
    }
  }, [dispatch, defaultPage, modalNoSku, modal]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      userId: storeId,
      searchText: delaySearchText,
    };
    dispatch(fetch(payload));
  }, [delaySearchText, dispatch, storeId]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={fetchdata}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
            <Button key="1" onClick={onClickBulkUpload} className="mr-2">
              {/* edit onlick */}
              <UploadOutlined /> Bulk Create Products
            </Button>,
          ]}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <PartnerStoreProductsModal />
            <BulkUploadModal />
          </>
        }
      />
    </>
  );
};

export default PartnerStoreProducts;
