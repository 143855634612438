import actions from "../actionTypes";
const { ALLFRANCHISEEPAYABLES } = actions;

const userInitialState = {
  data: [],
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
};

export const allFranchiseePayablesReducer = (
  state = userInitialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case ALLFRANCHISEEPAYABLES.FETCH.REQUEST:
      return { ...state, loading: true };
    case ALLFRANCHISEEPAYABLES.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case ALLFRANCHISEEPAYABLES.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case ALLFRANCHISEEPAYABLES.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case ALLFRANCHISEEPAYABLES.UPDATE.SUCCESS:
      return {
        ...state,
        formLoading: false,
        form: payload.data.data,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };

    case ALLFRANCHISEEPAYABLES.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
