import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isEqual } from "lodash";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { Modal, notification, Select } from "antd";
import axiosInstance from "../../helpers/axios";
import {
  toggleOldAccountModal,
  updateMerchantApprovalStatus,
} from "../../redux/actions/localMerchantsApproval";
const OldAccountMOdal = ({
  changePassword = false,
  oldmerchantName = null,
  selectedNewMerchantId = null,
}) => {
  const dispatch = useDispatch();
  const { merchantId } = useParams();

  const [merchants, setMerchants] = useState([]);
  const [allOldMerchantAccounts, setAllOldMerchantAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOldMerchant, setSelectedOldMerchant] = useState({});
  const { modal, userInfo, oldAccountModal } = useSelector(
    ({
      MerchantMenuReducer,
      userLoginReducer,
      localMerchantForApprovalReducer,
    }) => ({
      modal: MerchantMenuReducer.modalDuplicate,
      userInfo: userLoginReducer.userInfo,
      oldAccountModal: localMerchantForApprovalReducer.oldAccountModal,
    }),
    isEqual
  );

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleChange = (value) => {
    setSelectedOldMerchant(value);
  };

  const close = () => {
    dispatch(toggleOldAccountModal(false));
    setSelectedOldMerchant({});
  };

  //   const fetchAllOldAccounts = useCallback(async () => {
  //     try {
  //       const response = await axiosInstance(userInfo.token).get(
  //         `/api/merchant/menus/available-merchants/duplicate`
  //       );
  //       setMerchants(response.data);
  //     } catch (error) {
  //       console.log(">>> error getting available merchants to duplicate:", error);
  //     }
  //   }, [userInfo]);

  const fetchAllOldAccounts = useCallback(async () => {
    try {
      const response = await axiosInstance(userInfo.token).get(
        `/api/local-merchants/old-accounts`
      );
      setAllOldMerchantAccounts(response.data.data);
    } catch (error) {
      console.log(">>> error getting available merchants to duplicate:", error);
    }
  }, [userInfo]);

  const retainData = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await axiosInstance(userInfo.token).put(
        `/api/local-merchants/${selectedOldMerchant.value}`,
        {
          oldMerchantId: selectedOldMerchant.value,
          newMerchantId: selectedNewMerchantId,
        }
      );
      if (response.data.update) {
        try {
          const { data } = await dispatch(
            updateMerchantApprovalStatus({
              isApproved: 1,
              id: response.data.id,
            })
          );

          if (data) {
            openNotification("SUCCESS", "Succesfully Approved");
            close();
          }
        } catch (error) {
          console.log(">>>> error approving merchant");
        }
      }

      setIsLoading(false);
    } catch (error) {
      openNotification("ERROR", error.message);
      console.log(">>> error duplicating menus:", error);
    }
  }, [merchantId, selectedOldMerchant, userInfo]);

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  useEffect(() => {
    fetchAllOldAccounts();
  }, [fetchAllOldAccounts]);

  return (
    <div>
      <Modal
        title={
          <div style={{ fontSize: 22 }}>
            {oldmerchantName} {selectedNewMerchantId}
          </div>
        }
        open={oldAccountModal}
        onCancel={() => close()}
        onOk={retainData}
        okText="Retain Data"
        confirmLoading={isLoading}
        okButtonProps={{
          disabled:
            !selectedOldMerchant ||
            Object.keys(selectedOldMerchant).length === 0,
        }}
        width={700}
      >
        <h1>Find the old merchant account</h1>

        <Select
          value={selectedOldMerchant?.label}
          showSearch
          optionFilterProp="children"
          onChange={handleChange}
          options={allOldMerchantAccounts.map((d) => ({
            value: d.id,
            label: `${d.name} - ${d.province}, ${d.city} ${
              d.landmark ? " - " + d.landmark : ""
            }`,
          }))}
          style={{ width: "100%" }}
          filterOption={filterOption}
          labelInValue
        />
      </Modal>
    </div>
  );
};

export default OldAccountMOdal;
