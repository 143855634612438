import actions from "../actionTypes";
const { PAYABLESSTATUS } = actions;

const userInitialState = {
  data: [],
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
};

export const payablesStatusReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PAYABLESSTATUS.FETCH.REQUEST:
      return { ...state, loading: true };
    case PAYABLESSTATUS.FETCH.SUCCESS:
      return {
        ...state,
        totalPlatformFee: payload.data.totalPlatformFeeResult,
        totalCollectedDf: payload.data.totalDfResult,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case PAYABLESSTATUS.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case PAYABLESSTATUS.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case PAYABLESSTATUS.UPDATE.SUCCESS:
      console.log(payload.data.data.result);
      return {
        ...state,
        formLoading: false,
        form: payload.data.data,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };

    case PAYABLESSTATUS.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
