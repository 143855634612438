import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { AiFillWarning } from "react-icons/ai";
import { Button, notification } from "antd";

import { onRequestDelete } from "../redux/actions/users";

const DeleteAccount = ({ location, history }) => {
  const dispatch = useDispatch();

  const { error, loading } = useSelector((state) => state.userLoginReducer);

  const { register, handleSubmit, errors } = useForm();

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onSubmit = async (data) => {
    const dataResponse = await dispatch(onRequestDelete(data));

    if (dataResponse.status === 201) {
      openNotification("SUCCESS", dataResponse.data.message);
    } else if (dataResponse.status === 400) {
      openNotification("ERROR", dataResponse.data.message);
    } else {
      openNotification("ERROR", "Something went wrong");
    }

    // history.push(location.search ? location.search.split("=")[1] : "/home");
  };

  return (
    <>
      <section className="p-4 mb-4" style={{}}>
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto">
          <a
            href="#"
            className="flex items-center mb-6 text-4xl font-semibold"
            style={{ color: "#38a34a" }}
          >
            DORY
          </a>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <p className="text-xl font-bold text-center leading-tight tracking-tight text-gray-900 dark:text-white">
                Request Account Deletion
              </p>
              <div className="p-6 space-y-4 bg-gray-100 rounded-xl">
                <p className="text-md leading-tight tracking-tight text-gray-900 dark:text-white">
                  If you've decided to part ways with DORY, please provide the
                  following details.
                </p>

                <div class="mb-4">
                  <h3 class="text-md font-semibold mb-2">Deleted Data:</h3>
                  <ul class="list-disc pl-8">
                    <li>
                      Personal Information: Name, address, phone number, email.
                    </li>
                    <li>Order History: Details of previous orders.</li>
                    <li>
                      Preferences: Any customized settings or preferences.
                    </li>
                  </ul>
                </div>

                <h2 class="text-md font-semibold mb-2">
                  Retention Period for Retained Data:
                </h2>
                <p>
                  Any retained data will be securely stored for a maximum period
                  of 3 days after account deletion. This ensures compliance with
                  legal and regulatory requirements while prioritizing user
                  privacy.
                </p>

                <p className="text-md leading-tight tracking-tight italic text-gray-900 dark:text-white">
                  If you've decided to part ways with DORY, please provide the
                  following details.
                </p>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-4 md:space-y-6"
                action="#"
              >
                {error && (
                  <div
                    className="mb-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <div className="flex flex-row">
                      <AiFillWarning className="mt-1" />
                      <span className="block sm:inline ml-2">{error}</span>
                    </div>
                  </div>
                )}
                <div>
                  <label
                    for="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Username
                  </label>
                  <input
                    required
                    type="text"
                    name="user_name"
                    id="username"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="dory123"
                    ref={register({ required: true })}
                  />
                  {errors.username && (
                    <span className="italic leading-10 text-red-500">
                      This field is required
                    </span>
                  )}
                </div>
                <div>
                  <label
                    for="contact_number"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Phone number
                  </label>
                  <input
                    required
                    type="text"
                    name="contact_number"
                    id="contact_number"
                    // placeholder=""
                    ref={register({ required: true })}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {errors.password && (
                    <span className="italic leading-10 text-red-500">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  {/* <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                        required=""
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        for="remember"
                        className="text-gray-500 dark:text-gray-300"
                      >
                        Remember me
                      </label>
                    </div>
                  </div> */}
                  {/* <a
                    href="#"
                    className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                  >
                    Forgot password?
                  </a> */}
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    size="large"
                    disabled={loading}
                    loading={loading}
                  >
                    Submit
                  </Button>
                </div>

                {/* <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Don’t have an account yet?{" "}
                  <a
                    href="#"
                    className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                  >
                    Sign up
                  </a>
                </p> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteAccount;
