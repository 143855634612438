import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  ShoppingOutlined,
  ContainerOutlined,
  DoubleRightOutlined,
  SettingOutlined,
  InboxOutlined,
  UserOutlined,
  // RotateRightOutlined,
} from "@ant-design/icons";
const { Sider } = Layout;
const { SubMenu } = Menu;

const HubSidebar = (props) => {
  const location = useLocation();
  const { pathname } = location;

  const renderMenuItem = (title, pathname, icon = "") => {
    return (
      <Menu.Item key={pathname} icon={icon}>
        <Link to={pathname}>{title}</Link>
      </Menu.Item>
    );
  };

  return (
    <>
      <Sider
        theme="dark"
        trigger={null}
        collapsible
        collapsed={props.collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[pathname]}
          defaultOpenKeys={[
            "inbounds",
            "transactions",
            "settings",
            "rtsTransactions",
            "rtsInbounds",
            "customerTransactions",
          ]}
        >
          {renderMenuItem(
            "Dashboard",
            "/operationAccount/hub/home",
            <DashboardOutlined />
          )}

          {/* "/operationAccount/hub/bookings", */}
          {renderMenuItem(
            "For Pickup / Print",
            "/operationAccount/hub/bookings/forpickupByRider",
            <ShoppingOutlined />
          )}

          <SubMenu
            key="customerTransactions"
            icon={<InboxOutlined />}
            title="Customer Transactions"
          >
            {renderMenuItem(
              "Hub Transactions",
              "/operationAccount/hub/waybill-transactions"
            )}
            {renderMenuItem(
              "RTS Transactions",
              "/operationAccount/hub/rts-transactions"
            )}
          </SubMenu>

          <SubMenu
            key="inbounds"
            icon={<DoubleRightOutlined />}
            title="Inbounds"
          >
            {renderMenuItem(
              "Clients (inbound)",
              "/operationAccount/hub/inbound/clients"
            )}
            {renderMenuItem(
              "Provincial Office (inbound)",
              "/operationAccount/hub/inbound/provincial"
            )}
          </SubMenu>

          <SubMenu
            key="transactions"
            icon={<ContainerOutlined />}
            title="Outbound Transactions"
          >
            {renderMenuItem(
              "Clients (outbound transactions)",
              "/operationAccount/hub/bookingTransactions"
            )}
            {renderMenuItem(
              "Provincial Office (outbound transactions)",
              "/operationAccount/hub/transactions-provincial"
            )}
          </SubMenu>

          {/* {renderMenuItem(
            "RTS",
            "/operationAccount/hub/rts-transactions",
            <RotateRightOutlined />
          )} */}

          <SubMenu
            key="rtsInbounds"
            icon={<DoubleRightOutlined />}
            title="RTS Inbounds"
          >
            {renderMenuItem(
              "Customers (inbound)",
              "/operationAccount/hub/inbound/rts-customers"
            )}
            {renderMenuItem(
              "Provincial Office (inbound)",
              "/operationAccount/hub/inbound/rts/provincial"
            )}
          </SubMenu>

          <SubMenu
            key="rtsTransactions"
            icon={<ContainerOutlined />}
            title="RTS Outbound Transactions"
          >
            {renderMenuItem(
              "Customers (outbound transactions)",
              "/operationAccount/hub/rts-customer-transactions"
            )}
            {renderMenuItem(
              "Provincial Office (outbound transactions)",
              "/operationAccount/hub/rts-transactions-provincial"
            )}
          </SubMenu>

          {/* {renderMenuItem(
            "Orders",
            "/operationAccount/hub/orders",
            <ShoppingOutlined />
          )} */}

          <SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
            {renderMenuItem("Clients", "/operationAccount/hub/clients")}
            {renderMenuItem("Riders", "/operationAccount/hub/riders")}
          </SubMenu>
          {renderMenuItem(
            "Account",
            "/operationAccount/hub/account",
            <UserOutlined />
          )}
        </Menu>
      </Sider>
    </>
  );
};

export default HubSidebar;
