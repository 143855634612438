import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Button, Tooltip, Form, Input } from "antd";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
// Actions
import { fetchReferredCustomer } from "../redux/actions/referredCustomers";
import moment from "moment/moment";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const ReferredCustomers = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    formLoading,
    referredCustomersData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ referredCustomerReducers }) => ({
      referredCustomersData: referredCustomerReducers.data,
      totalRecord: referredCustomerReducers.totalRecord,
      nextPage: referredCustomerReducers.nextPage,
      previousPage: referredCustomerReducers.previousPage,
      currentPage: referredCustomerReducers.currentPage,
      loading: referredCustomerReducers.loading,
      formLoading: referredCustomerReducers.formLoading,
    }),
    isEqual
  );

  console.log(">>>>> referredCustomersData:", referredCustomersData);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchReferredCustomer(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchReferredCustomer(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <h1 style={{ fontSize: 30 }}>Referred Customers </h1>
          <h1 style={{ fontSize: 20, fontWeight: "normal" }}>
            (by default rider)
          </h1>
        </div>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchReferredCustomer({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchReferredCustomer({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchReferredCustomer({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchReferredCustomer({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "fullName",
      },
      // {
      //   Header: "Contact Number",
      //   accessor: "contact_number",
      // },
      {
        Header: "Municipality",
        accessor: (row) => {
          if (row.municipality === null || row.municipality === "null") {
            return "--Not yet set--";
          } else {
            return row.municipality;
          }
        },
      },
      {
        Header: "Province",
        accessor: (row) => {
          if (row.province === null || row.province === "null") {
            return "--Not yet set--";
          } else {
            return row.province;
          }
        },
      },
      {
        Header: "Account Creation",
        accessor: (row) => {
          const formattedDate = moment(row.creation_date).format(
            "MMMM Do YYYY, h:mm a"
          );
          return formattedDate;
        },
      },
      // {
      //   Header: "Complete Address",
      //   accessor: (row) => {
      //     if (
      //       row.complete_address === null ||
      //       row.complete_address === "null"
      //     ) {
      //       return "--Not yet set--";
      //     } else {
      //       return row.complete_address;
      //     }
      //   },
      // },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchReferredCustomer(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={referredCustomersData} //
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default ReferredCustomers;
