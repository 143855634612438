import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Button,
  Tooltip,
  Form,
  Input,
  Modal,
  DatePicker,
  Switch,
  Descriptions,
  Popconfirm,
  Drawer,
} from "antd";
import {
  CheckSquareOutlined,
  MenuOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiFoodOutline } from "@mdi/js";
// Components
import Wrapper from "../../components/wrapper";
import ServerSideTable from "../../components/table/serverSideTable";

// Actions
import {
  toggleModal,
  toggleModalSwitch,
  fetchNationalMerchant,
} from "../../redux/actions/nationalMerchants";

import moment from "moment/moment";
import dayjs from "dayjs";
import NationalMerchantModal from "../../components/nationalMerchants/nationalMerchantModal";
import ToggleModal from "../../components/nationalMerchants/toggleModal";
import axiosInstance from "../../helpers/axios";
const { Content: LayoutContent } = Layout;
const { Search } = Input;
export const MyDrawer = ({ visible, onClose }) => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toRefreshTable, setToRefreshTable] = useState(false);
  const [toformattedDate, setToFormattedDate] = useState(null);
  const [nationalMerchantStatus, NationalMerchantStatus] = useState([]);
  const [modal, setModal] = useState(false);
  const [activateMoreArea, setActivateMoreArea] = useState(false);
  const [nationalMerchantData, setNationalMerchantData] = useState([]);

  const {
    loading,
    nationallMerchantsList,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    createLoading,
    userInfo,
    autoCreateLoading,
  } = useSelector(
    ({
      nationalMerchantsReducer,
      userLoginReducer,
      nationalMerchantMenuReducer,
    }) => ({
      nationallMerchantsList: nationalMerchantsReducer.data,
      totalRecord: nationalMerchantsReducer.totalRecord,
      nextPage: nationalMerchantsReducer.nextPage,
      previousPage: nationalMerchantsReducer.previousPage,
      currentPage: nationalMerchantsReducer.currentPage,
      loading: nationalMerchantsReducer.loading,
      createLoading: nationalMerchantsReducer.createLoading,
      autoCreateLoading: nationalMerchantMenuReducer.autoCreateLoading,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const checkNationalMerchantStatus = async () => {
    try {
      const result = await axiosInstance(userInfo.token).get(
        `/api/national-merchants/check-status/${userInfo.id}`
      );
      NationalMerchantStatus(result.data.data);
    } catch (error) {
      console.log("checkNationalMerchantStatus || GET || error", error);
    }
  };

  const onAdd = () => dispatch(toggleModal());
  const onSwitch = (data) => {
    setNationalMerchantData(data);
    dispatch(toggleModalSwitch());
    setActivateMoreArea(false);
  };
  const moreArea = (data) => {
    setNationalMerchantData(data);
    dispatch(toggleModalSwitch());
    setActivateMoreArea(true);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      dateFilter: formattedDate === "Invalid Date" ? undefined : formattedDate,
      todateFilter:
        toformattedDate === "Invalid Date" ? undefined : toformattedDate,
    };
    if (toformattedDate !== null) {
      dispatch(fetchNationalMerchant(payload));
    }
  }, [delaySearchText, toformattedDate, dispatch, formattedDate]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };
    dispatch(fetchNationalMerchant(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30, marginTop: -20 }}>National Merchants</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchNationalMerchant({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchNationalMerchant({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchNationalMerchant({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchNationalMerchant({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };
  const onCheck = async (payload) => {
    // dispatch(updatePayablesStatus(payload));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            {userInfo.role !== "admin" && (
              <>
                <Button
                  onClick={() => onSwitch(original)}
                  style={{
                    backgroundColor:
                      nationalMerchantStatus?.find(
                        (d) => d.national_merchant_id === original.id
                      )?.is_active === true
                        ? "#98FB98"
                        : "white",
                    fontSize: 14,
                    justifyContent: "center",
                  }}
                  disabled={
                    nationalMerchantStatus?.find(
                      (d) => d.national_merchant_id === original.id
                    )?.is_active === true
                  }
                >
                  {nationalMerchantStatus?.find(
                    (d) => d.national_merchant_id === original.id
                  )?.is_active === true
                    ? "Activated"
                    : "Activate"}
                </Button>

                {nationalMerchantStatus?.find(
                  (d) => d.national_merchant_id === original.id
                )?.is_active === true && (
                  <Button
                    style={{ marginLeft: 5, fontSize: 14, height: 30 }}
                    onClick={() => moreArea(original)}
                    size="small"
                  >
                    Activate more
                  </Button>
                )}
              </>
            )}
            {userInfo.role === "admin" && (
              <>
                <Link
                  to={`/national-merchant-menu/${original.id}/${original.name}`}
                >
                  <Button size="small" type="text">
                    <Tooltip title="View Menu">
                      <span className="anticon anticon-check-square">
                        <Icon path={mdiFoodOutline} size={0.7} />
                      </span>
                    </Tooltip>
                  </Button>
                </Link>

                <Link
                  to={`/national-merchant-categories/${original.id}/${original.name}`}
                >
                  <Button size="small" type="text">
                    <Tooltip title="Menu Categories">
                      <MenuOutlined />
                    </Tooltip>
                  </Button>
                </Link>
              </>
            )}
          </>
        ),
        width: 100,
      },
      {
        Header: "Name",
        accessor: "name",
      },
    ],
    [nationalMerchantStatus, autoCreateLoading]
  );

  useEffect(() => {
    dispatch(fetchNationalMerchant(defaultPage));
  }, [dispatch, defaultPage]);

  useEffect(() => {
    if (createLoading) {
      dispatch(fetchNationalMerchant(defaultPage));
    }
  }, [dispatch, defaultPage, createLoading, loading]);

  useEffect(() => {
    checkNationalMerchantStatus();
  }, []);

  useEffect(() => {
    if (visible) {
      setToRefreshTable(true);
    }
  }, [visible]);

  useEffect(() => {
    if (toRefreshTable) {
      dispatch(fetchNationalMerchant(defaultPage));
      setToRefreshTable(false);
    }
  }, [toRefreshTable, dispatch, defaultPage, createLoading, loading]);

  useEffect(() => {
    if (autoCreateLoading) {
      checkNationalMerchantStatus();
    }
  }, [autoCreateLoading, nationalMerchantStatus]);

  const renderContent = () => {
    return (
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Activate national merchants</div>
            <Button
              style={{
                backgroundColor: "tomato",
                color: "white",
              }}
              onClick={onClose}
            >
              Close
            </Button>
          </div>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={"32%"}
      >
        <LayoutContent>
          <ServerSideTable
            columns={columns}
            data={nationallMerchantsList}
            loading={loading || createLoading || toRefreshTable}
            serverSidePagination={{
              totalRecord,
              onClickNextPage,
              onClickPreviousPage,
              currentPage,
              nextPage,
              previousPage,
              onClickFirstPage,
              onClickLastPage,
            }}
            customFilters={customFilters}
            dateFilters={{ selectedDate }}
            extra={
              userInfo.role === "admin" && [
                <Button key="1" onClick={onAdd} className="mr-2">
                  <PlusOutlined /> Add National Merchant
                </Button>,
              ]
            }
          />
        </LayoutContent>
      </Drawer>
    );
  };

  return (
    <>
      {renderContent()}
      <>
        {userInfo.role === "admin" && <NationalMerchantModal />}

        {userInfo.role !== "admin" && (
          <ToggleModal
            nationalMerchantData={nationalMerchantData}
            isAddedMoreArea={activateMoreArea}
          />
        )}
      </>
      {/* <Wrapper
        content={renderContent()}
        modal={
          <>
            {userInfo.role === "admin" && <NationalMerchantModal />}

            {userInfo.role !== "admin" && (
              <ToggleModal nationalMerchantData={nationalMerchantData} />
            )}
          </>
        }
      /> */}
      {/* <Drawer
        title="Basic Drawer"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <p>Some contents...</p>
        <Button onClick={onClose}>Close</Button>
      </Drawer> */}
    </>
  );
};
