import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Form, Input, Image } from "antd";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

import { fetchRiderDocuments } from "../redux/actions/riderDocuments";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const RiderDocuments = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    riderDocumentsData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ riderDocumentsReducer }) => ({
      riderDocumentsData: riderDocumentsReducer.data,
      totalRecord: riderDocumentsReducer.totalRecord,
      nextPage: riderDocumentsReducer.nextPage,
      previousPage: riderDocumentsReducer.previousPage,
      currentPage: riderDocumentsReducer.currentPage,
      loading: riderDocumentsReducer.loading,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchRiderDocuments(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchRiderDocuments(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Rider Package Availed</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch();
    fetchRiderDocuments({
      ...nextPage,
      searchText,
    });
  };

  const onClickPreviousPage = () => {
    dispatch();
    fetchRiderDocuments({
      ...previousPage,
      searchText,
    });
  };

  const onClickFirstPage = () => {
    dispatch();
    fetchRiderDocuments({
      ...defaultPage,
      searchText,
    });
  };

  const onClickLastPage = () => {
    dispatch();
    fetchRiderDocuments({
      page: Math.ceil(totalRecord / 15),
      limit: 15,
      searchText,
    });
  };
  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "fullName",
      },
      {
        Header: "Driver's license",
        accessor: (row) => {
          return (
            <div style={{ display: "flex", gap: "10px" }}>
              <Image
                width={100}
                src={`https://groceria-storage.sgp1.digitaloceanspaces.com/${
                  row.front_image_license_path
                }?v=${new Date().getTime()}`}
              />
              <Image
                width={100}
                src={`https://groceria-storage.sgp1.digitaloceanspaces.com/${
                  row.back_image_license_path
                }?v=${new Date().getTime()}`}
              />
            </div>
          );
        },
      },
      {
        Header: "OR/CR",
        accessor: (row) => {
          return (
            <div style={{ display: "flex", gap: "10px" }}>
              <Image
                width={100}
                src={`https://groceria-storage.sgp1.digitaloceanspaces.com/${
                  row.or_image_path
                }?v=${new Date().getTime()}`}
              />
              <Image
                width={100}
                src={`https://groceria-storage.sgp1.digitaloceanspaces.com/${
                  row.cr_image_path
                }?v=${new Date().getTime()}`}
              />
            </div>
          );
        },
      },
      {
        Header: "Selfie",
        accessor: (row) => {
          return (
            <>
              <Image
                width={100}
                src={`https://groceria-storage.sgp1.digitaloceanspaces.com/${
                  row.selfie_image_path
                }?v=${new Date().getTime()}`}
              />
            </>
          );
        },
      },
      {
        Header: "Vehicle",
        accessor: (row) => {
          return (
            <div style={{ display: "flex", gap: "10px" }}>
              <Image
                width={100}
                src={`https://groceria-storage.sgp1.digitaloceanspaces.com/${
                  row.vehicle_image_path
                }?v=${new Date().getTime()}`}
              />
              <Image
                width={100}
                src={`https://groceria-storage.sgp1.digitaloceanspaces.com/${
                  row.vehicle_image_with_plate_number_path
                }?v=${new Date().getTime()}`}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchRiderDocuments(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={riderDocumentsData} //
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default RiderDocuments;
