import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  DoubleRightOutlined,
  ContainerOutlined,
  UserOutlined,
} from "@ant-design/icons";
const { Sider } = Layout;
const { SubMenu } = Menu;

const RegionalOfficeSidebar = (props) => {
  const location = useLocation();
  const { pathname } = location;

  const renderMenuItem = (title, pathname, icon = "") => {
    return (
      <Menu.Item key={pathname} icon={icon}>
        <Link to={pathname}>{title}</Link>
      </Menu.Item>
    );
  };

  return (
    <>
      <Sider
        theme="dark"
        trigger={null}
        collapsible
        collapsed={props.collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[pathname]}
          defaultOpenKeys={[
            "inbounds",
            "transactions",
            "rtsInbounds",
            "rtsTransactions",
          ]}
        >
          {renderMenuItem(
            "Dashboard",
            "/regionalOffice/home",
            <DashboardOutlined />
          )}

          <SubMenu
            key="inbounds"
            icon={<DoubleRightOutlined />}
            title="Inbounds"
          >
            {renderMenuItem(
              "Provincial (inbound)",
              "/regionalOffice/inbound/provincial"
            )}
            {renderMenuItem(
              "Regional (inbound)",
              "/regionalOffice/inbound/regional"
            )}
          </SubMenu>

          <SubMenu
            key="transactions"
            icon={<ContainerOutlined />}
            title="Outbound Transactions"
          >
            {renderMenuItem(
              "Provincial (outbound transactions)",
              "/regionalOffice/transactions/provincialOffices"
            )}
            {renderMenuItem(
              "Regional (outbound transactions)",
              "/regionalOffice/transactions/regionalOffices"
            )}
          </SubMenu>

          <SubMenu
            key="rtsInbounds"
            icon={<DoubleRightOutlined />}
            title="RTS Inbounds"
          >
            {renderMenuItem(
              "Provincial (inbound)",
              "/regionalOffice/inbound/rtsProvincial"
            )}
            {renderMenuItem(
              "Regional Office (inbound)",
              "/regionalOffice/inbound/rtsRegional"
            )}
          </SubMenu>

          <SubMenu
            key="rtsTransactions"
            icon={<ContainerOutlined />}
            title="RTS Outbound Transactions"
          >
            {renderMenuItem(
              "Provincial (outbound transactions)",
              "/regionalOffice/transactions/rts/provincialOffices"
            )}
            {renderMenuItem(
              "Regional (outbound transactions)",
              "/regionalOffice/transactions/rts/regionalOffices"
            )}
          </SubMenu>

          {renderMenuItem(
            "Account",
            "/regionalOffice/account",
            <UserOutlined />
          )}
        </Menu>
      </Sider>
    </>
  );
};

export default RegionalOfficeSidebar;
