import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { ACCOUNTSRECEIVABLE } = actions;

export const fetchAllAccountsReceivable =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: ACCOUNTSRECEIVABLE.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      const { page, limit } = payload;

      let url = `/api/operator/payables/operators/all?page=${page}&limit=${limit}`;
      //   if (payload !== undefined) {
      //     const { page, limit, searchText } = payload;
      //     url = `/api/operator/payables/operators/all?page=${page}&limit=${limit}`;
      //     if (page && limit && searchText) {
      //       url = `/api/operator/payables/operators/all?page=${page}&limit=${limit}&searchText=${searchText}`;
      //     }
      //   }

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: ACCOUNTSRECEIVABLE.FETCH.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: ACCOUNTSRECEIVABLE.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
