import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { PARTNERDASHBOARD } = actions;

export const fetchPartnerDashBoardTransactions =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: PARTNERDASHBOARD.FETCHTRANSACTION.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token, id } = userInfo;
    try {
      let url = `/api/dashboard/transaction/${id}/${payload.status}/${payload.timeFrame}`;

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: PARTNERDASHBOARD.FETCHTRANSACTION.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: PARTNERDASHBOARD.FETCHTRANSACTION.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const fetchPartnerDashBoardCollectedDeliveryFee =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: PARTNERDASHBOARD.FETCHCOLLECTEDDELIVERYFEE.REQUEST });

    let dateFilter = "";

    if (payload.defaultDate === false) {
      dateFilter = `?dateFrom=${payload.fromDate}&dateTo=${payload.toDate}`;
    }

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token, id } = userInfo;
    try {
      let url = `/api/dashboard/collected-delivery-fee/${id}${dateFilter}`;

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: PARTNERDASHBOARD.FETCHCOLLECTEDDELIVERYFEE.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: PARTNERDASHBOARD.FETCHCOLLECTEDDELIVERYFEE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const fetchPartnerDashBoardTopMerchants =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: PARTNERDASHBOARD.FETCHTOPMERCHANTS.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token, id } = userInfo;
    try {
      let dateFilter = "";

      if (payload.defaultDate === false) {
        dateFilter = `?dateFrom=${payload.fromDate}&dateTo=${payload.toDate}`;
      }

      let url = `/api/dashboard/top-merchants/${id}${dateFilter}`;

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: PARTNERDASHBOARD.FETCHTOPMERCHANTS.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: PARTNERDASHBOARD.FETCHTOPMERCHANTS.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const fetchPartnerCustomerGrowth =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: PARTNERDASHBOARD.FETCHCUSTOMERGROWTH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token, id } = userInfo;
    try {
      let url = `/api/dashboard/customer-growth/${id}/${payload.timeFrame}`;

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: PARTNERDASHBOARD.FETCHCUSTOMERGROWTH.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: PARTNERDASHBOARD.FETCHCUSTOMERGROWTH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
