import React from "react";
import { Modal, Descriptions } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { toggleNoSkuModal } from "../../redux/actions/partnerStoreProducts";

export const NoSkuModal = () => {
  const dispatch = useDispatch();
  const { modalNoSku, dataNoSku } = useSelector(
    ({ partnerStoresProductsReducer }) => ({
      modalNoSku: partnerStoresProductsReducer.modalNoSku,
      dataNoSku: partnerStoresProductsReducer.dataNoSku,
    }),
    isEqual
  );

  const onOk = () => {
    dispatch(toggleNoSkuModal(false));
  };

  return (
    <>
      <Modal
        title="No Bardcode Found"
        open={modalNoSku}
        onOk={onOk}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        {dataNoSku.map((item) => {
          return (
            <Descriptions>
              <Descriptions.Item label="Barcode">
                {item.barcode}
              </Descriptions.Item>
              <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
              <Descriptions.Item label="Price">{item.price}</Descriptions.Item>
            </Descriptions>
          );
        })}
      </Modal>
    </>
  );
};
