import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { MERCHANTMENU } = actions;

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTMENU.TOGGLEVIEW, payload });
  };

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTMENU.TOGGLE, payload });
  };

export const toggleDuplicateModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTMENU.TOGGLEDUPLICATE, payload });
  };
export const setForm = (payload) => (dispatch) => {
  dispatch({ type: MERCHANTMENU.SET, payload });
};

export const fetch = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTMENU.FETCH.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const { page, limit, searchText } = payload;
    let url = `/api/merchant/menus/${payload.userId}/v2`;

    if (payload !== undefined) {
      url = `/api/merchant/menus/${payload.userId}/v2?page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/merchant/menus/${payload.userId}/v2?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }
    const response = await axiosInstance(token).get(url);
    dispatch({
      type: MERCHANTMENU.FETCH.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTMENU.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTMENU.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      `/api/merchant/menus/v2`,
      payload
    );

    dispatch({ type: MERCHANTMENU.CREATE.SUCCESS, payload: response });

    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTMENU.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const update = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTMENU.UPDATE.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).put(
      `/api/merchant/menus/update`,
      payload
    );

    dispatch({ type: MERCHANTMENU.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTMENU.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
export const softDeletionMenu = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTMENU.SOFTDELETION.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).put(
      `/api/merchant/menus/delete/${payload.data.id}/${payload.merchantId}`
    );
    dispatch({ type: MERCHANTMENU.SOFTDELETION.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTMENU.SOFTDELETION.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const updateMenuStatus = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTMENU.UPDATE.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).put(
      `/api/merchant/menus/update_status/${payload.status}/${payload.id}`,
      payload
    );

    dispatch({ type: MERCHANTMENU.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTMENU.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
