import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Popconfirm,
  DatePicker,
} from "antd";
import { CheckSquareOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import {
  fetchPendingWithdrawals,
  updateWithdrawalStatus,
} from "../redux/actions/appEarningWithdrawal";
import moment from "moment/moment";
import { withSuccess } from "antd/es/modal/confirm";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const AppearningWithdrawalReq = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const { loading, data, totalRecord, nextPage, previousPage, currentPage } =
    useSelector(
      ({ appEarningWithdrawalReducer }) => ({
        data: appEarningWithdrawalReducer.data,
        totalRecord: appEarningWithdrawalReducer.totalRecord,
        nextPage: appEarningWithdrawalReducer.nextPage,
        previousPage: appEarningWithdrawalReducer.previousPage,
        currentPage: appEarningWithdrawalReducer.currentPage,
        loading: appEarningWithdrawalReducer.loading,
      }),
      isEqual
    );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      dateFilter: formattedDate === "Invalid Date" ? undefined : formattedDate,
    };
    dispatch(fetchPendingWithdrawals(payload));
  }, [delaySearchText, formattedDate, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchPendingWithdrawals(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>App Earnings</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
            <Form.Item label="Select Date">
              <DatePicker
                style={{ width: "15vw" }}
                value={selectedDate}
                onChange={(date, dateString) => {
                  setSelectedDate(date);
                  setFormattedDate(dayjs(dateString).format("YYYY-MM-DD"));
                }}
                placeholder="Select Date"
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const handleApprove = async (payload, status) => {
    dispatch(updateWithdrawalStatus(payload, status));
  };
  const handleForfiet = async (payload, status) => {
    dispatch(updateWithdrawalStatus(payload, status));
  };
  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchPendingWithdrawals({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchPendingWithdrawals({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchPendingWithdrawals({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchPendingWithdrawals({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <div className="flex gap-2">
            <Popconfirm
              title="Approve withdrawal request"
              description="Are you sure you want to approve this withdrawal request?"
              onConfirm={() => {
                handleApprove(original, "Completed");
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={
                  loading ||
                  original.status === "Completed" ||
                  original.status === "Forfeited" ||
                  original.status === "Cancelled"
                    ? true
                    : false
                }
                size="small"
              >
                <Tooltip title="Approve">Approve</Tooltip>
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Forfeit withdrawal request"
              description="Are you sure you want to Forfeit this withdrawal request?"
              onConfirm={() => {
                handleForfiet(original, "Forfeited");
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={
                  loading ||
                  original.status === "Completed" ||
                  original.status === "Forfeited" ||
                  original.status === "Cancelled"
                    ? true
                    : false
                }
                size="small"
              >
                <Tooltip title="Forfeit">Forfeit</Tooltip>
              </Button>
            </Popconfirm>
          </div>
        ),
        width: 100,
      },
      {
        Header: "Operator Name",
        accessor: (row) => `${row.first_name} ${row.last_name}`,
      },
      {
        Header: "Amount",
        accessor: "amount_deducted",
      },
      {
        Header: "type",
        accessor: "type",
      },
      {
        Header: "Account number",
        accessor: "cp_number",
      },
      {
        Header: "Request Date",
        accessor: (row) => {
          const utcTime = moment.utc(row.created_at).tz("Asia/Manila");
          return utcTime.format("MMM D, YYYY hh:mm A"); // Format the time as needed
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return value === "pending" ? (
            <Badge status="error" text="Pending" />
          ) : value === "Forfeited" ? (
            <Badge status="warning" text="Forfeited" />
          ) : (
            <Badge status="success" text="Success" />
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchPendingWithdrawals(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={data}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          dateFilters={{ selectedDate }}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default AppearningWithdrawalReq;
