import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";
import axios from "axios";

import { Form, Row, Col, Button, Modal, notification } from "antd";

// Actions
// import {
//   toggleModal,
//   create,
//   setForm,
//   update,
// } from "../../redux/actions/users";
import {
  toggleModal,
  create,
  setForm,
  update,
} from "../../redux/actions/partnerStores";

// Utilities
import renderInput from "../utilities/inputForms";
import { getRegionByProvince } from "../utilities/getPhillipineMajorIslands";
import { fetch } from "../../redux/actions/users";

const PartnerStoreModal = ({ changePassword = false, setChangePassword }) => {
  const dispatch = useDispatch();

  const [selectedImageName, setSelectedImageName] = useState("");
  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [isProvinceEdited, setIsProvinceEdited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [isBarangayEdited, setIsBarangayEdited] = useState(false);

  const { modal, formLoading, error, form } = useSelector(
    ({ partnerStoresReducer, userLoginReducer }) => ({
      modal: partnerStoresReducer.modal,
      formLoading: partnerStoresReducer.formLoading,
      error: partnerStoresReducer.error,
      form: partnerStoresReducer.form,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );
  const hasForm = form ? Object.keys(form).length !== 0 : false;
  const yourMaxSizeInBytes = 5 * 1024 * 1024;
  const schema = changePassword
    ? yup.object().shape({
        newPassword: yup.string().required("Password is required field"),
      })
    : yup.object().shape({
        name: yup.string().required("Name is required field"),
        province: yup.string().required(),
        city: yup.string().required(),
        barangay: yup.string().required(),
        username: yup.string().required("Username is required field"),
        longitude: yup.string().required("longitude is required field"),
        latitude: yup.string().required("latitude is required field"),
        password: hasForm
          ? ""
          : yup.string().required("Password is required field"),
        image: hasForm
          ? yup.string() // For existing records, accept the image as a string (file name)
          : yup
              .mixed()
              .required("Image is required field")
              .test("fileSize", "File size is too large", (value) => {
                // Add a test to validate the file size if needed
                if (!value) return true; // Skip validation if no file is selected
                return value && value[0].size <= yourMaxSizeInBytes;
              }),
      });

  let defaultValues = changePassword
    ? { password: "" }
    : {
        name: "",
        province: "",
        city: "",
        barangay: "",
        username: "",
        latitude: "",
        longitude: "",
        image: "",
      };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (hasForm) {
      const resultObjectProvince =
        phil.provinces.find((obj) => obj.name === form.province) || null;

      const cities = phil.getCityMunByProvince(resultObjectProvince.prov_code);

      setCities(cities);

      const resultObjectCity =
        cities.find((obj) => obj.name === form.city) || null;

      const barangays = phil.getBarangayByMun(resultObjectCity.mun_code);

      setBarangays(barangays);
    }
  }, [hasForm, form]);

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    setIsProvinceEdited(false);
    setIsCityEdited(false);
    setIsBarangayEdited(false);
    setChangePassword(false);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
    setIsProvinceEdited(true);
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    setBarangays(phil.getBarangayByMun(mun_code));
    setIsCityEdited(true);
  };

  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        if (changePassword) {
          const { data } = await dispatch(
            update({
              id: form.id,
              password: values.newPassword,
            })
          );

          close();
          return openNotification("SUCCESS", data.message);
        } else {
          const provinceCityBrgy = {
            province: isProvinceEdited
              ? phil.provinces.filter(
                  (province) => province.prov_code === values.province
                )[0].name
              : values.province,
            city: isCityEdited
              ? phil.city_mun.filter((city) => city.mun_code === values.city)[0]
                  .name
              : values.city,
            barangay: isBarangayEdited
              ? phil.barangays.filter(
                  (barangay) => barangay.brgy_code === values.barangay
                )[0].name
              : values.barangay,
          };

          const povinceObj = phil.provinces.filter(
            (province) => province.name === provinceCityBrgy.province
          )[0];

          const regionObj = getRegionByProvince(povinceObj);

          const newValues = {
            name: values?.name,
            region: regionObj.name,
            username: values.username,
            latitude: values.latitude,
            longitude: values.longitude,
            address: `${provinceCityBrgy.barangay} ${provinceCityBrgy.city} ${provinceCityBrgy.province}`,
            ...provinceCityBrgy,
          };

          const toMatch = omit(form, ["id"]);
          toMatch.majorIslandGroup = regionObj.major_island_group;

          const changesKeys = Object.keys(
            ObjectDiff(toMatch, { ...toMatch, ...newValues })
          );

          if (!changesKeys.length) {
            return openNotification("INFO", "No changes made.");
          }

          const { data } = await dispatch(
            update({
              ...newValues,
              id: form.id,
            })
          );
          if (data) {
            openNotification("SUCCESS", data.message);
            close();
          }
        }
      } else {
        const province = phil.provinces.filter(
          (province) => province.prov_code === values.province
        )[0];
        const city = phil.city_mun.filter(
          (city) => city.mun_code === values.city
        )[0].name;
        const barangay = phil.barangays.filter(
          (barangay) => barangay.brgy_code === values.barangay
        )[0].name;

        const imageFile = values.image[0];
        const fileName = imageFile.name;

        const newData = {
          name: values.name,
          province: province.name,
          city,
          barangay,
          username: values.username,
          password: values.password,
          address: barangay.toUpperCase() + " " + city + " " + province.name,
          latitude: values.latitude,
          longitude: values.longitude,
          image: fileName,
        };

        const { data } = await dispatch(create(newData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        {changePassword ? (
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Input New Password",
                name: "newPassword",
                errors: errors,
                type: "password",
              },
              control
            )}
          </Col>
        ) : (
          <>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Name",
                  name: "name",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>

            {/* <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Address",
                name: "address",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col> */}

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Province",
                    name: "province",
                    errors: errors,
                    required: "true",
                    type: "select",
                    options: phil.provinces,
                    valueKey: "prov_code",
                    valueText: "name",
                    onChangeOutside: onChangeProvince,
                  },
                  control
                )}
              </Col>
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "City",
                    name: "city",
                    errors: errors,
                    required: "true",
                    type: "select",
                    options: cities,
                    valueKey: "mun_code",
                    valueText: "name",
                    onChangeOutside: onChangeCity,
                  },
                  control
                )}
              </Col>
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Barangay",
                    name: "barangay",
                    errors: errors,
                    required: "true",
                    type: "select",
                    options: barangays,
                    valueKey: "brgy_code",
                    valueText: "name",
                    onChangeOutside: () => setIsBarangayEdited(true),
                  },
                  control
                )}
              </Col>
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "User name",
                    name: "username",
                    errors: errors,
                    required: "true",
                  },
                  control
                )}
              </Col>
              {!hasForm && (
                <Col className="gutter-row" span={24}>
                  {renderInput(
                    {
                      label: "Password",
                      name: "password",
                      errors: errors,
                      required: "true",
                      type: "password",
                    },
                    control
                  )}
                </Col>
              )}
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Latitude",
                    name: "latitude",
                    errors: errors,
                    required: "true",
                  },
                  control
                )}
              </Col>
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Longitude",
                    name: "longitude",
                    errors: errors,
                    required: "true",
                  },
                  control
                )}
              </Col>
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Image",
                    name: "image",
                    errors: errors,
                    required: "true",
                    type: "file",
                  },
                  control
                )}
              </Col>
            </Row>
          </>
        )}

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default PartnerStoreModal;
