import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { STORESPRODUCTS } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: STORESPRODUCTS.TOGGLE, payload });
  };

export const toggleImportModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: STORESPRODUCTS.TOGGLEIMPORT, payload });
  };

export const toggleNoSkuModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: STORESPRODUCTS.TOGGLENOSKU, payload });
  };

export const setNoSkuData = (payload) => (dispatch) => {
  dispatch({ type: STORESPRODUCTS.SETDATANOSKU, payload });
};

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: STORESPRODUCTS.TOGGLEVIEW, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: STORESPRODUCTS.SET, payload });
};

export const fetch = (payload) => async (dispatch, getState) => {
  dispatch({ type: STORESPRODUCTS.FETCH.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const { page, limit, searchText } = payload;
    let url = `/api/groceria/partner-store/products/${payload.userId}`;

    if (payload !== undefined) {
      url = `/api/groceria/partner-store/products/${payload.userId}?page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/groceria/partner-store/products/${payload.userId}?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }

    const response = await axiosInstance(token).get(url);

    dispatch({
      type: STORESPRODUCTS.FETCH.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: STORESPRODUCTS.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: STORESPRODUCTS.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      `/api/groceria/partner-store/products/${payload.storeId}`,
      payload
    );
    dispatch({ type: STORESPRODUCTS.CREATE.SUCCESS, payload: response });

    return response;
  } catch (error) {
    dispatch({
      type: STORESPRODUCTS.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const update = (payload) => async (dispatch, getState) => {
  dispatch({ type: STORESPRODUCTS.UPDATE.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).put(
      `/api/groceria/partner-store/${payload.storeId}/product/${payload.productId}`,
      payload
    );

    dispatch({ type: STORESPRODUCTS.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: STORESPRODUCTS.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
