import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";
import axios from "axios";

import { Form, Col, Button, Modal, notification, Select } from "antd";

import {
  toggleModal,
  createNotifcation,
} from "../../redux/actions/notification";

// Utilities
import renderInput from "../utilities/inputForms";

const NotificationModal = ({ changePassword = false, setChangePassword }) => {
  const dispatch = useDispatch();

  const [cities, setCities] = useState([]);
  const [selectedMunicipality, setSelectedMunicipality] = useState([]);
  const [selectedApp, setSelectedApp] = useState("dory");
  const [municipalityError, setMunicipalityError] = useState(false);
  const [appError, setAppError] = useState(false);

  const { modal, formLoading, error, userInfo } = useSelector(
    ({ notificationReducer, userLoginReducer }) => ({
      modal: notificationReducer.modal,
      formLoading: notificationReducer.formLoading,
      error: notificationReducer.error,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const schema = yup.object().shape({
    notification_message: yup.string().required("Message is required field"),
    notification_title: yup.string().required("Title is required field"),
  });

  let defaultValues = {
    notification_message: "",
    notification_title: "",
    municipality: "",
    app: "",
    image: "",
    imageFile: null,
  };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    setSelectedMunicipality([]);
    setSelectedApp("dory");
    setMunicipalityError(false);
    setAppError(false);
    dispatch(toggleModal(false));
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  useEffect(() => {
    setCities(phil.city_mun); //--all municipalites
    // setCities(phil.getCityMunByProvince("0231"));
  }, []);

  const onSubmit = async (values) => {
    if (selectedApp === "" || selectedMunicipality === "") {
      if (selectedApp === "") {
        setAppError(true);
      }
      if (selectedMunicipality === "") {
        setMunicipalityError(true);
      }
      return;
    }

    setMunicipalityError(false);
    setAppError(false);

    try {
      const formData = new FormData();

      formData.append("notif_body", values.notification_message);
      formData.append("notif_title", values.notification_title);
      formData.append("appName", selectedApp);

      if (values.image && values.image[0]) {
        const imageFile = values.image[0];
        const fileName = imageFile.name;
        formData.append("image", imageFile);
        formData.append("imageName", fileName);
      }

      if (userInfo.role === "operator_admin") {
        formData.append("municipalities", userInfo.grouped_areas);
        formData.append("province", userInfo.province);
        formData.append("role", userInfo.role);
        formData.append("partner_id", userInfo.id);
      } else {
        formData.append("municipalities", JSON.stringify(selectedMunicipality));
      }

      const { data } = await dispatch(createNotifcation(formData));
      if (data) {
        openNotification("SUCCESS", data.message);
        close();
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      title={"Send notification"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <>
          <Col className="gutter-row" span={24}>
            <h1>Select App</h1>
            <Select
              disabled={userInfo.role === "admin" ? false : true}
              value={selectedApp}
              defaultValue={selectedApp}
              showSearch
              placeholder="Select App"
              optionFilterProp="children"
              onSearch={onSearch}
              style={{ width: "100%" }}
              filterOption={filterOption}
              name="app"
              onChange={(value) => {
                setSelectedApp(value);
                setAppError(false);
              }}
              options={
                userInfo.role === "admin"
                  ? [
                      {
                        value: "dory",
                        label: "Dory",
                      },
                      {
                        value: "dory_operator",
                        label: "Dory Operator",
                      },
                      {
                        value: "dory-merchants",
                        label: "Dory Merchants",
                      },
                    ]
                  : [
                      {
                        value: "dory",
                        label: "Dory",
                      },
                    ]
              }
            />
            {appError && <p style={{ color: "red" }}>App is required</p>}
          </Col>

          {userInfo.role !== "operator_admin" && (
            <>
              <br />
              <Col className="gutter-row" span={24}>
                <h1>Select Municipality</h1>
                <Select
                  value={selectedMunicipality}
                  name="municipality"
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please Municipality"
                  onChange={(value) => {
                    setSelectedMunicipality(value);
                    setMunicipalityError(false);
                  }}
                  options={cities.map((city) => ({
                    value: city.name,
                    label: city.name,
                  }))}
                />
                {municipalityError && (
                  <p style={{ color: "red" }}>Municipality is required</p>
                )}
              </Col>
            </>
          )}

          <br />
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Title",
                name: "notification_title",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>

          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Message",
                name: "notification_message",
                errors: errors,
                required: "true",
                type: "textArea",
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Image (optional)",
                name: "image",
                errors: errors,
                required: "true",
                type: "file",
              },
              control
            )}
          </Col>
        </>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            {"Send"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default NotificationModal;
