import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { RAFFLETICKETS } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: RAFFLETICKETS.TOGGLE, payload });
  };

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: RAFFLETICKETS.TOGGLEVIEW, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: RAFFLETICKETS.SET, payload });
};

export const fetchAllRaffleTickets =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: RAFFLETICKETS.FETCH.REQUEST });
    const { page, limit, searchText } = payload;

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/groceria/partner-merchants`;

      if (payload !== undefined) {
        const { page, limit, searchText } = payload;
        url = `/api/raffle-tickets/raffle-ticket/verifications?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/raffle-tickets/raffle-ticket/verifications?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
      }

      const response = await axiosInstance(token).get(url);
      dispatch({
        type: RAFFLETICKETS.FETCH.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: RAFFLETICKETS.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const updateVerifiedTicketStatus =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: RAFFLETICKETS.UPDATE.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      const response = await axiosInstance(token).put(
        `/api/raffle-tickets/raffle-ticket/verifications/${payload.id}`
      );

      dispatch({
        type: RAFFLETICKETS.UPDATE.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: RAFFLETICKETS.UPDATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
