import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isEqual } from "lodash";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { Modal, notification, Select } from "antd";
import axiosInstance from "../../helpers/axios";
import { toggleDuplicateModal } from "../../redux/actions/merchantMenu";

const DuplicateModal = ({ changePassword = false }) => {
  const dispatch = useDispatch();
  const { merchantId } = useParams();

  const [merchants, setMerchants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState({});

  const { modal, userInfo } = useSelector(
    ({ MerchantMenuReducer, userLoginReducer }) => ({
      modal: MerchantMenuReducer.modalDuplicate,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleChange = (value) => {
    setSelectedMerchant(value);
  };

  const close = () => {
    dispatch(toggleDuplicateModal(false));
    setSelectedMerchant({});
  };

  const fetchAvailableMerchantsToDuplicate = useCallback(async () => {
    try {
      const response = await axiosInstance(userInfo.token).get(
        `/api/merchant/menus/available-merchants/duplicate`
      );
      setMerchants(response.data);
    } catch (error) {
      console.log(">>> error getting available merchants to duplicate:", error);
    }
  }, [userInfo]);

  const onDuplicate = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await axiosInstance(userInfo.token).post(
        "/api/merchant/menus/available-merchants/duplicate",
        {
          merchantNoCategoryMenusId: merchantId,
          merchantToDuplicateCategoryMenusId: selectedMerchant.value,
        }
      );

      if (response.data) {
        openNotification("SUCCESS", response.data.message);
        close();
      }

      setIsLoading(false);
    } catch (error) {
      openNotification("ERROR", error.message);
      console.log(">>> error duplicating menus:", error);
    }
  }, [merchantId, selectedMerchant, userInfo]);

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  useEffect(() => {
    fetchAvailableMerchantsToDuplicate();
  }, [fetchAvailableMerchantsToDuplicate]);

  return (
    <div>
    <Modal
      title={"Duplicate Menu"}
      open={modal}
      onCancel={() => close()}
      onOk={onDuplicate}
      okText="Duplicate"
      confirmLoading={isLoading}
      okButtonProps={{
        disabled:
          !selectedMerchant || Object.keys(selectedMerchant).length === 0,
      }}
      width={700}
    >
      <h1>I would like to replicate the menu of</h1>

      <Select
        value={selectedMerchant?.label}
        showSearch
        optionFilterProp="children"
        onChange={handleChange}
        options={merchants.map((d) => ({
          value: d.id,
          label: `${d.name} - ${d.province}, ${d.city} ${d.landmark ? " - " + d.landmark : ""}`,
        }))}
        style={{ width: "100%" }}
        filterOption={filterOption}
        labelInValue
      />
    </Modal>
    </div>
  );
};

export default DuplicateModal;
