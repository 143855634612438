import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Col, Button, Modal, notification, Alert, message } from "antd";

import {
  DownloadOutlined,
  FileExcelOutlined,
  FileSyncOutlined,
  UndoOutlined,
  InboxOutlined,
} from "@ant-design/icons";

import { NoSkuModal } from "./noSkuModal";

//utilities
// import renderInput from "../utilities/inputForms";
// Actions
import {
  toggleModal,
  setForm,
  toggleImportModal,
  setNoSkuData,
  toggleNoSkuModal,
} from "../../redux/actions/partnerStoreProducts";
import Dragger from "antd/es/upload/Dragger";
import axiosInstance from "../../helpers/axios";

const BulkUploadModal = () => {
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const [fileUploaded, setfileUploaded] = useState(false);
  const [fileStatus, setFileStatus] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const { modalImport, error, userInfo } = useSelector(
    ({ partnerStoresProductsReducer, userLoginReducer }) => ({
      modalImport: partnerStoresProductsReducer.modalImport,
      error: partnerStoresProductsReducer.error,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  //   const schema = yup.object().shape({
  //     price: yup
  //       .string()
  //       .required("price is required field")
  //       .matches(/^[0-9]+$/, "price is not valid"),
  //   });

  //   let defaultValues = {
  //     price: "",
  //   };

  //   const { control, handleSubmit, errors, setValue, reset } = useForm({
  //     resolver: yupResolver(schema),
  //     defaultValues,
  //   });

  const onChangeFile = (info) => {
    const { status } = info.file;
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setFileName(info.file.name);
      setfileUploaded(true);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onDownloadTemplate = async () => {
    window.open(
      "https://api.groceriacorporation.com/api/groceria/partner-stores/file/Upload?fileName=Products_List_Template.xlsx",
      "_blank"
    );
    message.success("Successfully Downloaded");
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const savedProducts = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance(userInfo.token).post(
        `/api/groceria/partner-stores/file/Upload/${fileName}/${storeId}`
      );

      if (response.data.result.fileStatus === "invalid") {
        setFileStatus("invalid");
        setLoading(false);
        return;
      }

      if (response.data.no_barcode_matched.length !== 0) {
        dispatch(setNoSkuData(response));
        dispatch(toggleNoSkuModal(true));
      }

      openNotification("SUCCESS", "Products Created Successfully!");
      close();
      setLoading(false);
    } catch (error) {
      if (error) openNotification("ERROR", error);
      close();
      setLoading(false);
    }
  };

  const props = useMemo(() => {
    return {
      name: "file",
      multiple: false,
      accept:
        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST",
        Authorization: `Bearer ${userInfo?.token}`,
      },
      action:
        "https://api.groceriacorporation.com/api/groceria/partner-stores/file/Upload",
    };
  }, [userInfo]);

  const close = () => {
    setFileName("");
    setFileStatus("");
    setfileUploaded(false);
    dispatch(toggleModal(false));
    dispatch(toggleImportModal(false));
    dispatch(setForm({}));
  };

  //   const onSubmit = async (values) => {
  //     try {
  //     } catch (error) {
  //       console.log("Create Product error:>>", error);
  //     }
  //   };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  return (
    <Modal
      title={"Create bulk Product"}
      open={modalImport}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      {!fileUploaded == true ? (
        <>
          <div className="flex justify-end mb-3">
            <Button type="link" onClick={onDownloadTemplate}>
              <DownloadOutlined /> Template
            </Button>
          </div>
          <div>
            <Dragger {...props} onChange={onChangeFile}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single upload. Please use the given template a
              </p>
            </Dragger>
          </div>
        </>
      ) : (
        <div className="mx-16 ">
          {fileStatus === "invalid" && (
            <Alert
              message="Invalid File Template"
              type="error"
              showIcon
              style={{ marginBottom: "16px" }}
            />
          )}
          <div className="block p-6 bg-white rounded-lg border border-gray-400 shadow-md text-center">
            <span>
              <FileExcelOutlined
                style={{ color: "#22ce88", fontSize: "24px" }}
              />
              {fileName}
            </span>
          </div>

          <div className="flex gap-4 mt-4">
            {fileStatus !== "invalid" ? (
              <Button
                type="dashed"
                block
                size="large"
                onClick={savedProducts}
                loading={loading}
                disabled={loading}
              >
                <FileSyncOutlined /> Save Products
              </Button>
            ) : (
              <Button
                type="dashed"
                block
                size="large"
                onClick={() => {
                  setFileStatus("");
                  setfileUploaded(false);
                }}
                loading={loading}
                disabled={loading}
              >
                <UndoOutlined /> Upload New One
              </Button>
            )}
          </div>
        </div>
      )}
      <NoSkuModal />
    </Modal>
  );
};

export default BulkUploadModal;
