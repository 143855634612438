const regionsWithPhMajorIsland = [
  {
    id: 1,
    psgc_code: "010000000",
    name: "REGION I (ILOCOS REGION)",
    reg_code: "01",
    major_island_group: "LUZON",
  },
  {
    id: 2,
    psgc_code: "020000000",
    name: "REGION II (CAGAYAN VALLEY)",
    reg_code: "02",
    major_island_group: "LUZON",
  },
  {
    id: 3,
    psgc_code: "030000000",
    name: "REGION III (CENTRAL LUZON)",
    reg_code: "03",
    major_island_group: "LUZON",
  },
  {
    id: 4,
    psgc_code: "040000000",
    name: "REGION IV-A (CALABARZON)",
    reg_code: "04",
    major_island_group: "LUZON",
  },
  {
    id: 5,
    psgc_code: "170000000",
    name: "REGION IV-B (MIMAROPA)",
    reg_code: "17",
    major_island_group: "LUZON",
  },
  {
    id: 6,
    psgc_code: "050000000",
    name: "REGION V (BICOL REGION)",
    reg_code: "05",
    major_island_group: "LUZON",
  },
  {
    id: 7,
    psgc_code: "060000000",
    name: "REGION VI (WESTERN VISAYAS)",
    reg_code: "06",
    major_island_group: "VISAYAS",
  },
  {
    id: 8,
    psgc_code: "070000000",
    name: "REGION VII (CENTRAL VISAYAS)",
    reg_code: "07",
    major_island_group: "VISAYAS",
  },
  {
    id: 9,
    psgc_code: "080000000",
    name: "REGION VIII (EASTERN VISAYAS)",
    reg_code: "08",
    major_island_group: "VISAYAS",
  },
  {
    id: 10,
    psgc_code: "090000000",
    name: "REGION IX (ZAMBOANGA PENINSULA)",
    reg_code: "09",
    major_island_group: "MINDANAO",
  },
  {
    id: 11,
    psgc_code: "100000000",
    name: "REGION X (NORTHERN MINDANAO)",
    reg_code: "10",
    major_island_group: "MINDANAO",
  },
  {
    id: 12,
    psgc_code: "110000000",
    name: "REGION XI (DAVAO REGION)",
    reg_code: "11",
    major_island_group: "MINDANAO",
  },
  {
    id: 13,
    psgc_code: "120000000",
    name: "REGION XII (SOCCSKSARGEN)",
    reg_code: "12",
    major_island_group: "MINDANAO",
  },
  {
    id: 14,
    psgc_code: "130000000",
    name: "NATIONAL CAPITAL REGION (NCR)",
    reg_code: "13",
    major_island_group: "LUZON",
  },
  {
    id: 15,
    psgc_code: "140000000",
    name: "CORDILLERA ADMINISTRATIVE REGION (CAR)",
    reg_code: "14",
    major_island_group: "LUZON",
  },
  {
    id: 16,
    psgc_code: "150000000",
    name: "AUTONOMOUS REGION IN MUSLIM MINDANAO (ARMM)",
    reg_code: "15",
    major_island_group: "MINDANAO",
  },
  {
    id: 17,
    psgc_code: "160000000",
    name: "REGION XIII (Caraga)",
    reg_code: "16",
    major_island_group: "MINDANAO",
  },
];

module.exports = regionsWithPhMajorIsland;
