import actions from "../actionTypes";
const { RIDEROPERATORREFERRAL } = actions;

const userInitialState = {
  loading: false,
  subOpsreferredRiderData: [],
  subOpsreferredRiderTotalRecord: 0,
  subOpsreferredRiderNextPage: undefined,
  subOpsreferredRiderPreviousPage: undefined,
  subOpsreferredRiderCurrentPage: undefined,
  error: null,
};

export const subOpsReferredRiderReducer = (state = userInitialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case RIDEROPERATORREFERRAL.FETCH.REQUEST:
      return { ...state, loading: true };
    case RIDEROPERATORREFERRAL.FETCH.SUCCESS:
      return {
        ...state,
        subOpsreferredRiderData: payload.data.data.result,
        subOpsreferredRiderTotalRecord: payload.data.count,
        subOpsreferredRiderNextPage: payload.data?.data?.next,
        subOpsreferredRiderPreviousPage: payload.data?.data?.previous,
        subOpsreferredRiderCurrentPage: payload.data?.currentPage,
        loading: false,
      };
    case RIDEROPERATORREFERRAL.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    default:
      return state;
    }
};