import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  DoubleRightOutlined,
  ContainerOutlined,
  UserOutlined,
} from "@ant-design/icons";
const { Sider } = Layout;
const { SubMenu } = Menu;

const ProvincialOfficeSidebar = (props) => {
  const location = useLocation();
  const { pathname } = location;

  const renderMenuItem = (title, pathname, icon = "") => {
    return (
      <Menu.Item key={pathname} icon={icon}>
        <Link to={pathname}>{title}</Link>
      </Menu.Item>
    );
  };

  return (
    <>
      <Sider
        theme="dark"
        trigger={null}
        collapsible
        collapsed={props.collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[pathname]}
          defaultOpenKeys={[
            "inbounds",
            "transactions",
            "rtsInbounds",
            "rtsTransactions",
          ]}
        >
          {renderMenuItem(
            "Dashboard",
            "/provincialOffice/home",
            <DashboardOutlined />
          )}
          <SubMenu
            key="inbounds"
            icon={<DoubleRightOutlined />}
            title="Inbounds"
          >
            {renderMenuItem("Hubs (inbound)", "/provincialOffice/inbound/hubs")}
            {renderMenuItem(
              "Regional Office (inbound)",
              "/provincialOffice/inbound/regionals"
            )}
          </SubMenu>

          <SubMenu
            key="transactions"
            icon={<ContainerOutlined />}
            title="Outbound Transactions"
          >
            {renderMenuItem(
              "Hubs (outbound transactions)",
              "/provincialOffice/transactions/hubs"
            )}
            {renderMenuItem(
              "Regional Office (outbound transactions)",
              "/provincialOffice/transactions/regional"
            )}
          </SubMenu>

          <SubMenu
            key="rtsInbounds"
            icon={<DoubleRightOutlined />}
            title="RTS Inbounds"
          >
            {renderMenuItem(
              "Hubs (inbound)",
              "/provincialOffice/inbound/rtsHubs"
            )}
            {renderMenuItem(
              "Regional Office (inbound)",
              "/provincialOffice/inbound/rts/regionals"
            )}
          </SubMenu>

          <SubMenu
            key="rtsTransactions"
            icon={<ContainerOutlined />}
            title="RTS Outbound Transactions"
          >
            {renderMenuItem(
              "Hubs (outbound transactions)",
              "/provincialOffice/rtsTransactions/hubs"
            )}
            {renderMenuItem(
              "Regional Office (outbound transactions)",
              "/provincialOffice/transactions/rts/regional"
            )}
          </SubMenu>

          {renderMenuItem(
            "Account",
            "/provincialOffice/account",
            <UserOutlined />
          )}
        </Menu>
      </Sider>
    </>
  );
};

export default ProvincialOfficeSidebar;
