import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { VALIDATEREPORTEDRIDERS } = actions;

export const fetchValidateReports = (payload) => async (dispatch, getState) => {
  dispatch({ type: VALIDATEREPORTEDRIDERS.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token, id } = userInfo;

  try {
    let url = `/api/rider/reported-accounts-rider`;

    if (payload !== undefined) {
      const { page, limit, searchText } = payload;
      url = `/api/rider/reported-accounts-rider?page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/rider/reported-accounts-rider?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }

    const response = await axiosInstance(token).get(url);

    dispatch({
      type: VALIDATEREPORTEDRIDERS.FETCH.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: VALIDATEREPORTEDRIDERS.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
