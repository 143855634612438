import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Tag, Form, Input, Tooltip } from "antd";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import { fetchRiderAvailedPackage } from "../redux/actions/riderPackageAvailed";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import formatCurrency from "../helpers/formatCurrency";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const formatDate = (date) => {
  const dateString = date;
  const formattedDate = moment
    .utc(dateString)
    .tz("Asia/Manila")
    .format("MMM D, YYYY hh:mm A");
  return formattedDate;
};

const RiderPackageAvailed = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    riderPackageAvailedData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ riderAvailedPackageReducer }) => ({
      riderPackageAvailedData: riderAvailedPackageReducer.data,
      totalRecord: riderAvailedPackageReducer.totalRecord,
      nextPage: riderAvailedPackageReducer.nextPage,
      previousPage: riderAvailedPackageReducer.previousPage,
      currentPage: riderAvailedPackageReducer.currentPage,
      loading: riderAvailedPackageReducer.loading,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchRiderAvailedPackage(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchRiderAvailedPackage(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Rider Package Availed</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchRiderAvailedPackage({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchRiderAvailedPackage({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchRiderAvailedPackage({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchRiderAvailedPackage({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "fullName",
      },
      {
        Header: "Contact number",
        accessor: "contact_number",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "preferred delivery area",
        accessor: (row) => {
          return (
            <p
              style={{ color: "black" }}
            >{`${row.service_province}, ${row.service_city}`}</p>
          );
        },
      },
      {
        Header: "partner availability",
        accessor: (row) => {
          if (row.service_partner_id !== null) {
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                With Partner
              </Tag>
            );
          } else {
            return (
              <Tag icon={<CloseCircleOutlined />} color="error">
                No Partner
              </Tag>
            );
          }
        },
      },
      {
        Header: "Package Name",
        accessor: (row) => {
          if (row.package_name === "BASIC") {
            return (
              <Tooltip title="Dory long sleeve">{row.package_name}</Tooltip>
            );
          } else if (row.package_name === "PLUS") {
            return <Tooltip title="Thermal bag">{row.package_name}</Tooltip>;
          } else if (row.package_name === "PRO") {
            return (
              <Tooltip
                title={
                  <>
                    <p style={{ margin: 0, padding: 0 }}>Dory long sleeve</p>
                    <p>Thermal bag</p>
                  </>
                }
              >
                {row.package_name}
              </Tooltip>
            );
          }
        },
      },
      {
        Header: "Size",
        accessor: "sleeve_size",
      },
      {
        Header: "Payment Status",
        accessor: (row) => {
          if (row.payment_status === "approved") {
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                Success
              </Tag>
            );
          } else if (row.payment_status === "pending") {
            return (
              <Tag icon={<SyncOutlined spin />} color="processing">
                Pending
              </Tag>
            );
          } else if (row.payment_status === "failed") {
            return (
              <Tag icon={<CloseCircleOutlined />} color="error">
                Failed
              </Tag>
            );
          }
        },
      },
      {
        Header: "Package Price",
        accessor: (row) => {
          return formatCurrency(row.package_price);
        },
      },

      {
        Header: "Date",
        accessor: (row) => formatDate(row.created_at),
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchRiderAvailedPackage(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={riderPackageAvailedData} //
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default RiderPackageAvailed;
