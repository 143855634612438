import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import phil from "../../external_node_modules/philippine-location-json-for-geer";
// import axios from "axios";

import { Form, Row, Col, Button, Modal, notification } from "antd";

// action
import {
  toggleModal,
  setForm,
  update,
  create,
} from "../../../redux/actions/allproducts";

// Utilities
import renderInput from "../../utilities/inputForms";
// import { getRegionByProvince } from "../utilities/getPhillipineMajorIslands";

const AllproductsModal = ({ changePassword = false, setChangePassword }) => {
  const dispatch = useDispatch();

  const [selectedImageName, setSelectedImageName] = useState("");
  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [isProvinceEdited, setIsProvinceEdited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [isBarangayEdited, setIsBarangayEdited] = useState(false);

  const { modal, formLoading, error, form } = useSelector(
    ({ allproductsReducer, userLoginReducer }) => ({
      modal: allproductsReducer.modal,
      formLoading: allproductsReducer.formLoading,
      error: allproductsReducer.error,
      form: allproductsReducer.form,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );
  const hasForm = form ? Object.keys(form).length !== 0 : false;
  const yourMaxSizeInBytes = 5 * 1024 * 1024;
  const schema = changePassword
    ? yup.object().shape({
        newPassword: yup.string().required("Password is required field"),
      })
    : yup.object().shape({
        skuNum: yup.string().required("Name is required field"),
        barcode: yup.string().required("Barcode is required field"),
        product_description: yup.string().required("Name is required field"),
        image_directory: yup.string().required("Barcode is required field"),
        product_name: yup
          .string()
          .required("Product Name/Description is required field"),
        cat_id: yup.string().required("Category is required field"),
        sub_cat_id: yup.string().required("Sub Category is required field"),
        // image: hasForm
        //   ? yup.string() // For existing records, accept the image as a string (file name)
        //   : yup
        //       .mixed()
        //       .required("Image is required field")
        //       .test("fileSize", "File size is too large", (value) => {
        //         // Add a test to validate the file size if needed
        //         if (!value) return true; // Skip validation if no file is selected
        //         return value && value[0].size <= yourMaxSizeInBytes;
        //       }),
      });

  let defaultValues = changePassword
    ? { password: "" }
    : {
        skuNum: "",
        barcode: "",
        product_name: "",
        cat_id: "",
        sub_cat_id: "",
        product_description: "",
        image_directory: "",
        // image: "",
      };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  // const onChangeProvince = (prov_code) => {
  //   setValue("city", "");
  //   setValue("barangay", "");
  //   setCities(phil.getCityMunByProvince(prov_code));
  //   setBarangays([]);
  //   setIsProvinceEdited(true);
  // };

  // const onChangeCity = (mun_code) => {
  //   setValue("barangay", "");
  //   setBarangays(phil.getBarangayByMun(mun_code));
  //   setIsCityEdited(true);
  // };

  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        if (changePassword) {
          // const { data } = await dispatch(
          //   update({
          //     id: form.id,
          //     password: values.newPassword,
          //   })
          // );
          // close();
          // return openNotification("SUCCESS", data.message);
        } else {
          const newValues = {
            skuNum: values?.skuNum,
            barcode: values?.barcode,
            product_name: values?.product_name,
            cat_id: values?.cat_id,
            sub_cat_id: values?.sub_cat_id,
            description: values?.product_description,
            directory: values?.image_directory,
          };
          const toMatch = omit(form, ["id"]);
          // toMatch.majorIslandGroup = regionObj.major_island_group;
          const changesKeys = Object.keys(
            ObjectDiff(toMatch, { ...toMatch, ...newValues })
          );
          if (!changesKeys.length) {
            return openNotification("INFO", "No changes made.");
          }
          const { data } = await dispatch(
            update({
              ...newValues,
              product_id: form.product_id,
            })
          );
          if (data) {
            openNotification("SUCCESS", data.message);
            close();
          }
        }
      } else {
        const newData = {
          skuNum: values.skuNum,
          barcode: values.barcode,
          product_name: values.product_name,
          cat_id: values.cat_id,
          sub_cat_id: values.sub_cat_id,
          description: values.product_description,
          directory: values.image_directory,
        };
        const { data } = await dispatch(create(newData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
    }
  };
  useEffect(() => {
    if (error) openNotification("ERROR", error);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Category ID",
                  name: "cat_id",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Sub Category ID",
                  name: "sub_cat_id",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Sku",
                  name: "skuNum",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Barcode",
                  name: "barcode",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Product",
                  name: "product_name",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Description",
                  name: "product_description",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Directory",
                  name: "image_directory",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            {/* <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Image",
                  name: "image",
                  errors: errors,
                  required: "true",
                  type: "file",
                },
                control
              )}
            </Col> */}
          </Row>
        </>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AllproductsModal;
