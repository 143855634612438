import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { PAYABLESINFO } = actions;

export const fetchPayablesInfo = (payload) => async (dispatch, getState) => {
  dispatch({ type: PAYABLESINFO.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    let url = `/api/franchisee-payables/rider-deliveryfee-collection/${userInfo.id}/${payload}`;
    const response = await axiosInstance(token).get(url);

    dispatch({
      type: PAYABLESINFO.FETCH.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: PAYABLESINFO.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
