import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Button,
  Tooltip,
  Form,
  Input,
  Image,
  Popconfirm,
  notification,
  Tag,
  Modal,
  Card,
  Descriptions,
} from "antd";
import {
  CheckSquareOutlined,
  PlusOutlined,
  CloseSquareOutlined,
} from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

import moment from "moment/moment";
// Actions

import { fetchValidateReports } from "../redux/actions/validateReportedRiders";
import OldAccountMOdal from "../components/oldMerchantAccounts/oldAccountModal";
import axiosInstance from "../helpers/axios";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const ValidateReportedRiders = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [modal, setModal] = useState(false);
  const [reportedRiderData, setReportedRiderData] = useState([]);
  const [closeloading, setCloseLoading] = useState(false);
  const {
    loading,
    data,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    userInfo,
  } = useSelector(
    ({ validateReportedRidersReducer, userLoginReducer }) => ({
      data: validateReportedRidersReducer.data,
      totalRecord: validateReportedRidersReducer.totalRecord,
      nextPage: validateReportedRidersReducer.nextPage,
      previousPage: validateReportedRidersReducer.previousPage,
      currentPage: validateReportedRidersReducer.currentPage,
      loading: validateReportedRidersReducer.loading,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchValidateReports(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchValidateReports(payload));
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 35 }}>Reported Riders</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchValidateReports({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchValidateReports({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchValidateReports({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchValidateReports({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };

  const reviewReason = (data) => {
    console.log(data);
    setReportedRiderData(data);
    setModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button onClick={() => reviewReason(original)} size="small">
              <Tooltip title="Approve">Review Reason</Tooltip>
            </Button>
          </>
        ),
        width: 100,
      },
      {
        Header: "Rider",
        accessor: "fullName",
      },
      {
        Header: "Contact Number",
        accessor: "rider_contact",
      },
      {
        Header: "Province",
        accessor: "pref_delivery_province",
      },

      {
        Header: "City",
        accessor: "pref_delivery_city",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchValidateReports(defaultPage));
  }, [dispatch, defaultPage]);

  useEffect(() => {
    if (closeloading) {
      dispatch(fetchValidateReports(defaultPage));
      setCloseLoading(false);
    }
  }, [dispatch, defaultPage, closeloading]);

  const reportValidate = async (data) => {
    try {
      const response = await axiosInstance(userInfo.token).put(
        `/api/rider/${data.data.user_id}`,
        {
          status: data.status,
        }
      );
      if (response.data.data) {
        openNotification("SUCCESS", response.data.message);
        setReportedRiderData([]);
        setModal(false);
        setCloseLoading(true);
      }
    } catch (error) {}
  };

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={data} //
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <Modal
              maskClosable={true}
              closable={true}
              open={modal}
              width={700}
              footer={[
                <Button
                  key="cancel"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Close
                </Button>,
                <Button
                  danger
                  key="invalid"
                  onClick={() => {
                    reportValidate({
                      data: reportedRiderData,
                      status: "invalid",
                    });
                  }}
                >
                  Invalid
                </Button>,
                <Button
                  key="valid"
                  type="primary"
                  onClick={() => {
                    reportValidate({
                      data: reportedRiderData,
                      status: "valid",
                    });
                  }}
                >
                  Valid
                </Button>,
              ]}
            >
              <Descriptions
                bordered
                column={1}
                title={"Validate Reported Rider"}
              >
                <Descriptions.Item label={"Rider name"}>
                  {reportedRiderData.fullName}
                </Descriptions.Item>
                <Descriptions.Item label={"Rider Contact"}>
                  {reportedRiderData.rider_contact}
                </Descriptions.Item>
                <Descriptions.Item label="Reason">
                  <p>{reportedRiderData.reason}</p>
                </Descriptions.Item>
                <Descriptions.Item label="Reported by">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      alignItems: "flex-start",
                    }}
                  >
                    <div>Partner name : {reportedRiderData.user_name}</div>
                    <div>Contact : {reportedRiderData.admin_contact}</div>
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </Modal>
          </>
        }
      />
    </>
  );
};

export default ValidateReportedRiders;
