import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Select,
  Switch,
  Popconfirm,
  DatePicker,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { FormOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

import {
  fetchOperators,
  approveOperator,
  verifiedOperator,
  activateOperator,
  toggleModal,
  setForm,
  softDeletion,
} from "../redux/actions/manageOperaotrs";
import OperatorAccountModal from "../components/manageOperator/modal";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const OperatorApproval = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [isApproved, setIsApproved] = useState(null);
  const [isVerified, setIsVerified] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [toformattedDate, setToFormattedDate] = useState(null);

  const {
    loading,
    operatorPerformaceData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    createLoading,
    userInfo,
  } = useSelector(
    ({ manageOperatorReducer, userLoginReducer }) => ({
      operatorPerformaceData: manageOperatorReducer.data,
      totalRecord: manageOperatorReducer.totalRecord,
      nextPage: manageOperatorReducer.nextPage,
      previousPage: manageOperatorReducer.previousPage,
      currentPage: manageOperatorReducer.currentPage,
      loading: manageOperatorReducer.loading,
      createLoading: manageOperatorReducer.createLoading,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const onAdd = () => dispatch(toggleModal());

  const onEdit = ({ data }) => {
    dispatch(setForm(data));
    dispatch(toggleModal());
  };

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchOperators(payload));
  };

  const handleChange = (value) => {
    setIsApproved(value);
  };
  const handleChange2 = (value) => {
    setIsVerified(value);
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchOperators({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchOperators({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchOperators({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchOperators({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  useEffect(() => {
    if (createLoading) {
      dispatch(fetchOperators(defaultPage));
    }
  }, [dispatch, defaultPage, createLoading, loading]);

  const onVerify = async (payload) => {
    dispatch(verifiedOperator(payload));
  };
  const onApprove = async (payload) => {
    dispatch(approveOperator(payload));
  };
  const onChange = async (status, id) => {
    dispatch(activateOperator({ status, id }));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      isApproved,
      isVerified,
      dateFilter: formattedDate === "Invalid Date" ? undefined : formattedDate,
      todateFilter:
        toformattedDate === "Invalid Date" ? undefined : toformattedDate,
    };
    // if (toformattedDate !== null) {
    dispatch(fetchOperators(payload));
    // }
  }, [
    delaySearchText,
    formattedDate,
    toformattedDate,
    dispatch,
    isApproved,
    isVerified,
  ]);

  const softDelete = (payload) => {
    dispatch(softDeletion(payload));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <div className="flex gap-2">
            {/* <Tooltip title="More details">
              <Button disabled={loading} size="small" type="text">
                <InfoCircleOutlined />
              </Button>
            </Tooltip> */}

            {/*  */}
            {userInfo.role === "operator_admin" &&
              original.isDefaultRider !== 1 && (
                <Popconfirm
                  title="Delete a Rider"
                  description="Are you sure you want to Delete this Rider?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    softDelete(original);
                  }}
                >
                  <Button size="small" type="text">
                    <Tooltip title="Delete">
                      <CloseOutlined />
                    </Tooltip>
                  </Button>
                </Popconfirm>
              )}
            <Button
              disabled={loading}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined />
              </Tooltip>
            </Button>
            {/*  */}

            {!original.is_verified && (
              <Popconfirm
                title="Verify"
                description="Are you sure you want to verify this operator?"
                onConfirm={() => onVerify(original)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  disabled={loading || !original.is_approved}
                  size="small"
                >
                  Verify
                </Button>
              </Popconfirm>
            )}

            {!original.is_approved && (
              <Popconfirm
                title="Approve"
                description="Are you sure you want to approve this operator?"
                onConfirm={() => onApprove(original)}
                okText="Yes"
                cancelText="No"
              >
                <Button disabled={loading} size="small">
                  Approve
                </Button>
              </Popconfirm>
            )}

            <div>
              <Tooltip title="Update active status">
                <Switch
                  size="small"
                  defaultChecked={original.access_status === "active"}
                  onChange={(checked) => {
                    onChange(checked, original.user_id);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        ),
        width: 300,
      },
      {
        Header: "Duty Status",
        accessor: (row) =>
          row.duty_date === null ? (
            <Badge status="error" text="Off Duty" />
          ) : (
            <Badge status="success" text="On Duty" />
          ),
      },
      {
        Header: "Time in",
        accessor: (row) => {
          const utcTime = moment(row.time_from, "HH:mm:ss").format("hh:mm A");
          return utcTime === "Invalid date" ? "--" : utcTime;
        },
      },
      {
        Header: "Time out",
        accessor: (row) => {
          const utcTime = moment(row.time_to, "HH:mm:ss").format("hh:mm A");
          return utcTime === "Invalid date" ? "--" : utcTime;
        },
      },
      {
        Header: "Name",
        accessor: "fullName",
        // accessor: (row) => `${row.first_name} ${row.last_name}`,
      },
      {
        Header: "User Name",
        accessor: "user_name",
        // accessor: (row) => `${row.first_name} ${row.last_name}`,
      },
      {
        Header: "Verified",
        accessor: "is_verified",
        Cell: ({ value }) => {
          return value === true || value === 1 ? (
            <Badge status="success" text="Verified" />
          ) : (
            <Badge status="error" text="Not yet Verified" />
          );
        },
      },
      {
        Header: "Approved",
        accessor: "is_approved",
        Cell: ({ value }) => {
          return value === true || value === 1 ? (
            <Badge status="success" text="Approved" />
          ) : (
            <Badge status="error" text="Not yet Pending" />
          );
        },
      },
      {
        Header: "commision (%)",
        accessor: "rider_comission",
      },
      {
        Header: "Account Status",
        accessor: "access_status",
        Cell: ({ value }) => {
          return value === "active" ? (
            <Badge status="success" text="Active" />
          ) : (
            <Badge status="error" text="Inactive" />
          );
        },
      },
      {
        Header: "Contact Number",
        accessor: "contact_number",
      },
      {
        Header: "Barangay",
        accessor: "barangay",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Province",
        accessor: "province",
      },
      {
        Header: "Application date",
        accessor: (row) => {
          const utcTime = moment.utc(row.application_date).tz("Asia/Manila");
          return utcTime.format("MMM D, YYYY hh:mm A"); // Format the time as needed
        },
      },
    ],
    []
  );

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 35 }}>Riders</h1>
        <div
          className="p-flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              {/* <Form.Item label="From:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={selectedDate}
                  onChange={(date, dateString) => {
                    setSelectedDate(date);
                    setFormattedDate(
                      dayjs(dateString)
                        .startOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item>
              <Form.Item label="To:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={toSelectedDate}
                  onChange={(date, dateString) => {
                    setToSelectedDate(date);
                    setToFormattedDate(
                      dayjs(dateString)
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item> */}
            </div>
          </Form>
        </div>
        {userInfo.role === "admin" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 10,
            }}
          >
            <Select
              defaultValue="All"
              style={{
                width: 120,
              }}
              onChange={handleChange2}
              options={[
                {
                  value: 1,
                  label: "Verified",
                },
                {
                  value: 0,
                  label: "Not yet Verified",
                },
                {
                  value: null,
                  label: "All",
                },
              ]}
            />
            <Select
              defaultValue="All"
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={[
                {
                  value: 0,
                  label: "Not yet Approved",
                },
                {
                  value: 1,
                  label: "Approved",
                },
                {
                  value: null,
                  label: "All",
                },
              ]}
            />
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={operatorPerformaceData}
          loading={loading || createLoading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          dateFilters={{ selectedDate }}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Create Rider Account
            </Button>,
          ]}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <OperatorAccountModal />
          </>
        }
      />
    </>
  );
};

export default OperatorApproval;
