import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { GROUPEDAREAS } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: GROUPEDAREAS.TOGGLE, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: GROUPEDAREAS.SET, payload });
};

export const fetchAllGroupedAreas = (payload) => async (dispatch, getState) => {
  dispatch({ type: GROUPEDAREAS.FETCH.REQUEST });
  const { page, limit, searchText } = payload;

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    let url = `/api/grouped-areas?page=${page}&limit=${limit}`;
    if (page && limit && searchText) {
      url = `/api/grouped-areas?page=${page}&limit=${limit}&searchText=${searchText}`;
    }

    const response = await axiosInstance(token).get(url);
    console.log(">>>> fetchAllGroupedAreas:", response.data);

    dispatch({
      type: GROUPEDAREAS.FETCH.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: GROUPEDAREAS.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const createGroupedAreas = (payload) => async (dispatch, getState) => {
  dispatch({ type: GROUPEDAREAS.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      "/api/grouped-areas",
      payload
    );
    dispatch({ type: GROUPEDAREAS.CREATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: GROUPEDAREAS.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const updateGroupedAreas = (payload) => async (dispatch, getState) => {
  dispatch({ type: GROUPEDAREAS.UPDATE.REQUEST });

  try {
    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;

    const response = await axiosInstance(token).patch(
      "/api/grouped-areas",
      payload
    );

    console.log(">>> response", response.data);
    dispatch({ type: GROUPEDAREAS.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: GROUPEDAREAS.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
