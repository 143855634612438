import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { PARTNERCOUPONS } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: PARTNERCOUPONS.TOGGLE, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: PARTNERCOUPONS.SET, payload });
};

export const fetchPartnerCoupoons = (payload) => async (dispatch, getState) => {
  dispatch({ type: PARTNERCOUPONS.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    let url = `/api/coupons/partner-couons/${userInfo.id}`;
    if (payload !== undefined) {
      const { page, limit, searchText, dateFilter, todateFilter } = payload;
      url = `/api/coupons/partner-couons/${userInfo.id}?page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/coupons/partner-couons/${userInfo.id}?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
      if (page && limit && dateFilter && todateFilter) {
        url = `/api/coupons/partner-couons/${userInfo.id}?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}`;
      }
    }
    const response = await axiosInstance(token).get(url);

    dispatch({
      type: PARTNERCOUPONS.FETCH.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: PARTNERCOUPONS.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
export const createPartnerCoupons = (payload) => async (dispatch, getState) => {
  dispatch({ type: PARTNERCOUPONS.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      `/api/coupons/create-coupons`,
      payload
    );

    dispatch({ type: PARTNERCOUPONS.CREATE.SUCCESS, payload: response });

    console.log(">>>>>>>>>>>>", response.data);

    return response;
  } catch (error) {
    dispatch({
      type: PARTNERCOUPONS.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};
