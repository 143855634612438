import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Button, Tooltip, Form, Input } from "antd";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import FixRatesModal from "../components/fixRates/modal";

// Actions
import {
  fetchAllAreasFixRate,
  toggleModal,
  setForm,
  updateFixRate,
} from "../redux/actions/fixRates";
import moment from "moment/moment";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const FixRates = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [changeFixRate, setChangeFixRate] = useState(false);

  const {
    loading,
    formLoading,
    fixRatesData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ fixRateReducer }) => ({
      fixRatesData: fixRateReducer.data,
      totalRecord: fixRateReducer.totalRecord,
      nextPage: fixRateReducer.nextPage,
      previousPage: fixRateReducer.previousPage,
      currentPage: fixRateReducer.currentPage,
      loading: fixRateReducer.loading,
      formLoading: fixRateReducer.formLoading,
    }),
    isEqual
  );

  const onChange = (status, id) => {
    // dispatch(updatePartnerStore({ status, id }));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAllAreasFixRate(payload));
  }, [delaySearchText, dispatch]);

  const onAdd = () => dispatch(toggleModal());
  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllAreasFixRate(payload));
  };
  const onEdit = ({ data }) => {
    setChangeFixRate(true);
    dispatch(setForm(data));
    dispatch(toggleModal());
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>Delivery fee - FIX RATES</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const extra = () => {
    const addButton = (
      <Button key="1" onClick={onAdd} className="mr-2">
        <PlusOutlined /> Add
      </Button>
    );

    return [addButton];
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllAreasFixRate({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllAreasFixRate({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllAreasFixRate({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllAreasFixRate({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button
              disabled={formLoading}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined />
              </Tooltip>
            </Button>
          </>
        ),
        width: 100,
      },
      {
        Header: "Fix Rate",
        accessor: "fix_rate",
      },
      {
        Header: "Province",
        accessor: "province",
      },
      {
        Header: "Municipality",
        accessor: "municipality",
      },
      {
        Header: "Barangay",
        accessor: "barangay",
      },
      {
        Header: "Distance centro to brgy. hall",
        accessor: "distance_centro_to_brgyHall",
        Cell: ({ value }) => {
          return `${parseFloat(value)} km`;
        },
      },
      {
        Header: "Location Type",
        accessor: "LocationType",
      },
      {
        Header: "Latitude",
        accessor: "latitude",
      },
      {
        Header: "Longitude",
        accessor: "longitude",
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        Cell: ({ value }) => {
          return moment(value).isValid()
            ? moment(value).tz("Asia/Manila").format("MMMM DD, YYYY hh:mm A")
            : "--";
        },
      },
      {
        Header: "Date Updated",
        accessor: "updated_at",
        Cell: ({ value }) => {
          return moment(value).isValid()
            ? moment(value).tz("Asia/Manila").format("MMMM DD, YYYY hh:mm A")
            : "--";
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllAreasFixRate(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={fixRatesData} //
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
          ]}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <FixRatesModal
              changeFixRates={changeFixRate}
              setChangeFixRates={setChangeFixRate}
            />
          </>
        }
      />
    </>
  );
};

export default FixRates;
