import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { ALLPRODUCTS } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: ALLPRODUCTS.TOGGLE, payload });
  };

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: ALLPRODUCTS.TOGGLEVIEW, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: ALLPRODUCTS.SET, payload });
};

export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: ALLPRODUCTS.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      "/api/all-products",
      payload
    );
    console.log(payload);
    dispatch({ type: ALLPRODUCTS.CREATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: ALLPRODUCTS.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const update = (payload) => async (dispatch, getState) => {
  dispatch({ type: ALLPRODUCTS.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).put(
      `/api/all-products`,
      payload
    );
    dispatch({ type: ALLPRODUCTS.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: ALLPRODUCTS.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const fetchAllProducts = (payload) => async (dispatch, getState) => {
  dispatch({ type: ALLPRODUCTS.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    let url = `/api/all-products`;
    if (payload !== undefined) {
      const { page, limit, searchText } = payload;
      url = `/api/all-products?page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/all-products?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }
    const response = await axiosInstance(token).get(url);
    dispatch({
      type: ALLPRODUCTS.FETCH.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: ALLPRODUCTS.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
