import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../helpers/axios";
import { Drawer, Button, Select, Input, notification, Alert, Card } from "antd";
import AllMenuItemsTableComponent from "./allMenuItemsTableComponent";
import AllCategoriesTableComponent from "./allCategoriesTableComponent";

const AddMarkupPriceComponent = ({
  data,
  visible,
  onClose,
  Viewtype,
  setDrawerType,
}) => {
  const [type, setType] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [markupValue, setMarkupValue] = useState("");
  const [loading, setLoading] = useState(false);

  const { userInfo } = useSelector(({ userLoginReducer }) => ({
    userInfo: userLoginReducer.userInfo,
  }));

  const onChange = (value) => {
    setType(value);
    setSelectedMenus([]);
  };

  const handleClose = () => {
    setType([]);
    setSelectedMenus([]);
    onClose();
    setDrawerType("");
    setMarkupValue("");
    setSelectedCategory([]);
  };

  const openNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const MarkUpPrice = async () => {
    try {
      if (type === "Menu items") {
        setLoading(true);
        const response = await axiosInstance(userInfo.token).put(
          `/api/mark-up/selected-menus/${data.id}`,
          {
            markUpValue: markupValue,
            menuId: selectedMenus,
          }
        );
        setLoading(false);
        if (response.data.data) {
          openNotification(response.data.message);
          handleClose();
        }
      } else if (type === "Categories") {
        setLoading(true);
        const response = await axiosInstance(userInfo.token).put(
          `/api/mark-up/selected-category/${data.id}`,
          {
            markUpValue: markupValue,
            categoryId: selectedCategory,
          }
        );
        setLoading(false);
        if (response.data.data) {
          openNotification(response.data.message);
          handleClose();
        }
      } else if (type === "All products") {
        setLoading(true);
        const response = await axiosInstance(userInfo.token).put(
          `/api/mark-up/all-products/${data.id}`,
          {
            markUpValue: markupValue,
          }
        );
        setLoading(false);
        if (response.data.data) {
          openNotification(response.data.message);
          handleClose();
        }
      }
    } catch (error) {
      console.log("menuMarkUpPrice : ERROR : >> ", error);
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (/^\d*\.?\d*$/.test(newValue)) {
      setMarkupValue(newValue);
    }
  };

  return (
    <>
      <Drawer width={"32%"} open={visible} onClose={handleClose}>
        <div>
          {Viewtype === "View" ? (
            <Alert message="Marked up products" type="info" showIcon />
          ) : (
            <Alert message="Mark up a product" type="info" showIcon />
          )}

          <h1 style={{ fontSize: 20, marginTop: 15, marginBottom: 15 }}>
            {data?.name}
          </h1>
          <p style={{ marginBottom: 5 }}>Select type to mark up</p>
          <Select
            value={type}
            style={{ width: "64%" }}
            showSearch
            placeholder="Select type to mark up"
            optionFilterProp="label"
            onChange={onChange}
            options={
              Viewtype !== "View"
                ? [
                    {
                      value: "All products",
                      label: "All products",
                    },
                    {
                      value: "Categories",
                      label: "Categories",
                    },
                    {
                      value: "Menu items",
                      label: "Menu items",
                    },
                  ]
                : [
                    {
                      value: "All products",
                      label: "All products",
                    },
                    {
                      value: "Categories",
                      label: "Categories",
                    },
                    {
                      value: "Menu items",
                      label: "Menu items",
                    },
                  ]
            }
          />
          <div style={{ marginTop: 15 }}>
            {(selectedMenus.length !== 0 && type !== "All products") ||
            (selectedCategory.length !== 0 && type !== "All products") ? (
              <Card style={{ width: "64%" }}>
                <p>
                  <b>Enter markup percentage</b> (maximum of 15%)
                </p>
                <Input
                  maxLength={15}
                  onChange={handleChange}
                  value={markupValue}
                  placeholder="Enter markup percentage maximum of 15%"
                />

                {markupValue > 15 && (
                  <p style={{ marginTop: 10, color: "tomato" }}>
                    Mark up maximum is 15%.
                  </p>
                )}
                <div
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                    gap: 20,
                    marginTop: 20,
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    loading={loading}
                    onClick={() => MarkUpPrice()}
                    type="primary"
                    disabled={markupValue > 15 || markupValue === ""}
                  >
                    Submit
                  </Button>

                  <Button onClick={() => handleClose()} danger>
                    Cancel
                  </Button>
                </div>
              </Card>
            ) : (
              <></>
            )}

            {type === "All products" && (
              <Card style={{ width: "60%" }}>
                <h3>
                  <b> Enter markup percentage</b> (maximum of 15%)
                </h3>
                <Input
                  maxLength={15}
                  onChange={handleChange}
                  value={markupValue}
                  placeholder="Enter markup percentage maximum of 15%"
                />

                {markupValue > 15 && (
                  <p style={{ color: "tomato" }}>Mark up maximum is 15%.</p>
                )}
                <div
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    display: "flex",
                    gap: 20,
                    marginTop: 20,
                  }}
                >
                  <Button
                    loading={loading}
                    onClick={() => MarkUpPrice()}
                    type="primary"
                    disabled={markupValue > 15 || markupValue === ""}
                  >
                    Submit
                  </Button>

                  <Button onClick={() => handleClose()} danger>
                    Cancel
                  </Button>
                </div>
              </Card>
            )}
          </div>
        </div>
        <div>
          {type === "Menu items" && (
            <AllMenuItemsTableComponent
              merchantId={data.id}
              setSelectedMenus={setSelectedMenus}
              Viewtype={Viewtype}
            />
          )}
        </div>
        <div>
          {type === "Categories" && (
            <AllCategoriesTableComponent
              merchantId={data.id}
              setSelectedCategory={setSelectedCategory}
              Viewtype={Viewtype}
            />
          )}
        </div>
      </Drawer>
    </>
  );
};

export default AddMarkupPriceComponent;
