import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { COUPONS } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: COUPONS.TOGGLE, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: COUPONS.SET, payload });
};

export const fetchCoupoons = (payload) => async (dispatch, getState) => {
  dispatch({ type: COUPONS.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    let url = `/api/coupons`;
    if (payload !== undefined) {
      const { page, limit, searchText, dateFilter, todateFilter } = payload;
      url = `/api/coupons?page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/coupons?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
      if (page && limit && dateFilter && todateFilter) {
        url = `/api/coupons?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}`;
      }
    }
    const response = await axiosInstance(token).get(url);

    dispatch({
      type: COUPONS.FETCH.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: COUPONS.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: COUPONS.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      `/api/coupons/create-coupons`,
      payload
    );

    dispatch({ type: COUPONS.CREATE.SUCCESS, payload: response });

    console.log(">>>>>>>>>>>>", response.data);

    return response;
  } catch (error) {
    dispatch({
      type: COUPONS.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};
