import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { PARTNERFINANCE } = actions;

export const fetchTotalBalance = (payload) => async (dispatch, getState) => {
  dispatch({ type: PARTNERFINANCE.FETCHTOTALBALANCE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).get(
      `/api/wallet/${userInfo.id}/balance/partner`
    );

    dispatch({
      type: PARTNERFINANCE.FETCHTOTALBALANCE.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: PARTNERFINANCE.FETCHTOTALBALANCE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
export const fetchPartnerTransactions =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: PARTNERFINANCE.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/wallet/9/transactions/partner`;
      if (payload !== undefined) {
        const { page, limit, searchText, fromdateFilter, todateFilter } =
          payload;
        if (page && limit) {
          url = `/api/wallet/${userInfo.id}/transactions/partner?latest=false&page=${page}&limit=${limit}`;
        }
        if (page && limit && searchText) {
          url = `/api/wallet/${userInfo.id}/transactions/partner?latest=false&page=${page}&limit=${limit}&searchText=${searchText}`;
        }
        if (page && limit && fromdateFilter && todateFilter) {
          url = `/api/wallet/${userInfo.id}/transactions/partner?latest=false&page=${page}&limit=${limit}&fromdateFilter=${fromdateFilter}&todateFilter=${todateFilter}`;
        }
      }
      const response = await axiosInstance(token).get(url);

      dispatch({
        type: PARTNERFINANCE.FETCH.SUCCESS,
        payload: response,
      });
      // console.log(url);
      // console.log(payload);
      // console.log("response.data :>>>>>>>>>> ", response.data);
      return response;
    } catch (error) {
      dispatch({
        type: PARTNERFINANCE.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const fetchAllCommission = (payload) => async (dispatch, getState) => {
  dispatch({ type: PARTNERFINANCE.FETCHALLCOMMISSION.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).get(
      `/api/wallet/${userInfo.id}/commission`
    );

    dispatch({
      type: PARTNERFINANCE.FETCHALLCOMMISSION.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: PARTNERFINANCE.FETCHALLCOMMISSION.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
