import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { AiFillWarning } from "react-icons/ai";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { register as registerUser } from "../redux/actions/users";

const Signup = ({ location, history }) => {
  const dispatch = useDispatch();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  const [passwordMismatch, setPasswordMismatched] = useState(false);

  const { userInfo, error, loading } = useSelector(
    (state) => state.userRegisterReducer
  );

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    setPasswordMismatched(false);
    if (data.password !== data.password_confirm)
      return setPasswordMismatched(true);

    delete data.password_confirm;
    dispatch(registerUser(data));
  };

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  return (
    <>
      <div class='mt-20 md:mt-10'>
        <div class='grid-cols-11 lg:grid'>
          <div class='col-span-3'></div>
          <div class='col-span-5'>
            <div class=' justify-center h-screen mt-10 sm:px-16 px-4'>
              <div class='mb-8'>
                <div class='text-center mb-1'>
                  <p class='text-3xl text-gray-600 font-semibold mb-2'>
                    Register
                  </p>
                  <p class='text-sm text-gray-600 mt-1'>
                    Hello there, we're glad to serve you!
                  </p>
                </div>

                {passwordMismatch && (
                  <div
                    className='mb-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-10'
                    role='alert'
                  >
                    <div className='flex flex-row'>
                      <AiFillWarning className='mt-1' />
                      <span className='block sm:inline ml-2'>
                        Password does not matched!
                      </span>
                    </div>
                  </div>
                )}
                {error && (
                  <div
                    className='mb-8 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-10'
                    role='alert'
                  >
                    <div className='flex flex-row'>
                      <AiFillWarning className='mt-1' />
                      <span className='block sm:inline ml-2'>{error}</span>
                    </div>
                  </div>
                )}

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div class='mb-4'>
                    <label
                      class='block text-gray-700 text-sm font-bold mb-2'
                      for='First name'
                    >
                      First name
                    </label>
                    <input
                      class='w-full sm:2/3 shadow-lg appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='First name'
                      type='text'
                      placeholder='First name'
                      name='firstName'
                      ref={register({ required: true })}
                    />
                    {errors.firstName && (
                      <span className='italic leading-10 text-red-500'>
                        This field is required
                      </span>
                    )}
                  </div>

                  <div class='mb-4'>
                    <label
                      class='block text-gray-700 text-sm font-bold mb-2'
                      for='Last name'
                    >
                      Middle name
                    </label>
                    <input
                      class='w-full sm:2/3 shadow-lg appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='Last name'
                      type='text'
                      placeholder='Last name'
                      name='middleName'
                      ref={register({ required: true })}
                    />
                    {errors.middleName && (
                      <span className='italic leading-10 text-red-500'>
                        This field is required
                      </span>
                    )}
                  </div>

                  <div class='mb-4'>
                    <label
                      class='block text-gray-700 text-sm font-bold mb-2'
                      for='Last name'
                    >
                      Last name
                    </label>
                    <input
                      class='w-full sm:2/3 shadow-lg appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='Last name'
                      type='text'
                      placeholder='Last name'
                      name='lastName'
                      ref={register({ required: true })}
                    />
                    {errors.lastName && (
                      <span className='italic leading-10 text-red-500'>
                        This field is required
                      </span>
                    )}
                  </div>

                  <div class='mb-4'>
                    <label
                      class='block text-gray-700 text-sm font-bold mb-2'
                      for='Email'
                    >
                      Email
                    </label>
                    <input
                      class='w-full sm:2/3 shadow-lg appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='Email'
                      type='email'
                      placeholder='Email'
                      name='email'
                      ref={register({ required: true })}
                    />
                    {errors.email && (
                      <span className='italic leading-10 text-red-500'>
                        This field is required
                      </span>
                    )}
                  </div>

                  <div class='mb-4'>
                    <label
                      class='block text-gray-700 text-sm font-bold mb-2'
                      for='Username'
                    >
                      Username
                    </label>
                    <input
                      class='w-full sm:2/3 shadow-lg appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='Username'
                      type='text'
                      placeholder='Username'
                      name='userName'
                      ref={register({ required: true })}
                    />
                    {errors.userName && (
                      <span className='italic leading-10 text-red-500'>
                        This field is required
                      </span>
                    )}
                  </div>

                  <div class='mb-4'>
                    <label
                      class='block text-gray-700 text-sm font-bold mb-2'
                      for='Phone number'
                    >
                      Phone number
                    </label>
                    <input
                      class='w-full sm:2/3 shadow-lg appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='Phone number'
                      type='number'
                      placeholder='09123456789'
                      name='phoneNumber'
                      ref={register({ required: true })}
                    />
                    {errors.phoneNumber && (
                      <span className='italic leading-10 text-red-500'>
                        This field is required
                      </span>
                    )}
                  </div>

                  <div class='mb-4'>
                    <label
                      class='block text-gray-700 text-sm font-bold mb-2'
                      for='Birth date'
                    >
                      Birth date
                    </label>
                    <input
                      class='w-full sm:2/3 shadow-lg appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='Birth date'
                      type='date'
                      placeholder='Birth date'
                      name='birthDate'
                      ref={register({ required: true })}
                    />
                    {errors.birthDate && (
                      <span className='italic leading-10 text-red-500'>
                        This field is required
                      </span>
                    )}
                  </div>

                  <div class='mb-4'>
                    <label
                      class='block text-gray-700 text-sm font-bold mb-2'
                      for='password'
                    >
                      Password
                    </label>
                    <input
                      class='w-full sm:2/3 shadow-lg appearance-none border rounded py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                      id='password'
                      type='password'
                      placeholder='******************'
                      name='password'
                      ref={register({ required: true })}
                    />
                    {errors.password && (
                      <span className='italic leading-10 text-red-500'>
                        This field is required
                      </span>
                    )}
                  </div>

                  <div class='mb-5'>
                    <label
                      class='block text-gray-700 text-sm font-bold mb-2'
                      for='password_confirm'
                    >
                      Retype Password
                    </label>
                    <input
                      class='w-full sm:2/3 shadow-lg appearance-none border rounded py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
                      id='password_confirm'
                      type='password'
                      placeholder='******************'
                      name='password_confirm'
                      ref={register({ required: true })}
                    />
                    {errors.firstName && (
                      <span className='italic leading-10 text-red-500'>
                        This field is required
                      </span>
                    )}
                  </div>

                  <div class='flex justify-between'>
                    <span class='text-gray-700'>
                      Already have an account?{" "}
                      <Link to={"/login"} className='text-blue-400'>
                        Login
                      </Link>
                    </span>
                    <button
                      type='submit'
                      class='float-right bg-black hover:bg-blue-400 text-white font-bold py-2 px-8'
                      disabled={loading}
                    >
                      Sign up {loading && <LoadingOutlined className='ml-2' />}
                    </button>
                  </div>
                </form>
              </div>
              <div class='col-span-10 border'></div>
              <div class='mt-4 text-center'>
                <p class='font-semibold text-xs text-gray-600'>
                  By signing up, you agree to BUDGE
                </p>
                <p class='font-semibold text-xs'>
                  <a href='#'>Terms and Conditions</a>{" "}
                  <span class='text-gray-600'>&</span>{" "}
                  <a href='#'>Privacy Policy</a>
                </p>
              </div>
            </div>
          </div>
          <div class='col-span-3'></div>
        </div>
      </div>
    </>
  );
};

export default Signup;
