import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { OPERATORWITHDRAW } = actions;

export const fetchAllOperatorWithdrawRequest =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: OPERATORWITHDRAW.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/operator-wallet/transaction`;

      if (payload !== undefined) {
        const { page, limit, searchText, dateFilter, todateFilter } = payload;
        url = `/api/operator-wallet/transaction?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/operator-wallet/transaction?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
        if (page && limit && dateFilter && todateFilter) {
          url = `/api/operator-wallet/transaction?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}`;
        }
      }

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: OPERATORWITHDRAW.FETCH.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: OPERATORWITHDRAW.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const updateWithdrawalStatus =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: OPERATORWITHDRAW.UPDATE.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      const response = await axiosInstance(token).patch(
        `/api/operator-wallet/transaction`,
        payload
      );

      dispatch({
        type: OPERATORWITHDRAW.UPDATE.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: OPERATORWITHDRAW.UPDATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
