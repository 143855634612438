import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { CUSTOMERS } = actions;

export const fetchAllCustomers = (payload) => async (dispatch, getState) => {
  dispatch({ type: CUSTOMERS.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  let { city } = userInfo;
  let { province } = userInfo;
  let { role } = userInfo;
  try {
    let url = `/api/customer`;

    if (payload !== undefined) {
      const { page, limit, searchText, dateFilter, todateFilter, filterCity } =
        payload;
      if (role === "operator_admin") {
        url = `/api/customer?page=${page}&limit=${limit}&role=${role}&city=${city}&province=${province}&partner_id=${userInfo.id}`;
      } else {
        url = `/api/customer?page=${page}&limit=${limit}&city=${filterCity}`;
      }

      if (page && limit && searchText) {
        if (role) {
          url = `/api/customer?page=${page}&limit=${limit}&searchText=${searchText}&role=${role}&city=${city}&province=${province}&partner_id=${userInfo.id}`;
        } else {
          url = `/api/customer?page=${page}&limit=${limit}&searchText=${searchText}&city=${filterCity}`;
        }
      }
      if (page && limit && dateFilter && todateFilter) {
        if (role) {
          url = `/api/customer?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}`;
        } else {
          url = `/api/customer?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}&city=${filterCity}`;
        }
      }
    }

    const response = await axiosInstance(token).get(url);
    dispatch({
      type: CUSTOMERS.FETCH.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: CUSTOMERS.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const resetPassword = (payload) => async (dispatch, getState) => {
  dispatch({ type: CUSTOMERS.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).patch("/api/customer", payload);
    dispatch({ type: CUSTOMERS.UPDATE.SUCCESS, payload: response });

    return response;
  } catch (error) {
    dispatch({
      type: CUSTOMERS.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const fetchFilterCity = (payload) => async (dispatch, getState) => {
  dispatch({ type: CUSTOMERS.FETCHFILTERCITY.REQUEST });

  // export const updateAccessStatus = (payload) => async (dispatch, getState) => {
  //   dispatch({ type: CUSTOMERS.UPDATEACCESS.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).get(
      "/api/customer/filter-city",
      payload
    );
    dispatch({ type: CUSTOMERS.FETCHFILTERCITY.SUCCESS, payload: response });

    //     const response = await axiosInstance(token).patch(
    //       "/api/user/deactivate-user",
    //       payload
    //     );
    //     dispatch({ type: CUSTOMERS.UPDATEACCESS.SUCCESS, payload: response });

    return response;
  } catch (error) {
    dispatch({
      type: CUSTOMERS.FETCHFILTERCITY.FAILURE,

      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const updateAccessStatus = (payload) => async (dispatch, getState) => {
  dispatch({ type: CUSTOMERS.UPDATEACCESS.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).patch(
      "/api/user/deactivate-user",
      payload
    );
    dispatch({ type: CUSTOMERS.UPDATEACCESS.SUCCESS, payload: response });

    return response;
  } catch (error) {
    dispatch({
      type: CUSTOMERS.FETCHFILTERCITY.FAILURE,

      payload: error.response ? error.response.data : error.message,
    });
  }
};
