import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Form, Input } from "antd";
import { WarningOutlined } from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import { fetchAllReferredOperator } from "../redux/actions/referredOperator";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const AreaSubOperator = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    referredOperatorData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(({ referredOperatorReducer }) => ({
    referredOperatorData: referredOperatorReducer.data,
    totalRecord: referredOperatorReducer.totalRecord,
    nextPage: referredOperatorReducer.nextPage,
    previousPage: referredOperatorReducer.previousPage,
    currentPage: referredOperatorReducer.currentPage,
    loading: referredOperatorReducer.loading,
  }));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAllReferredOperator(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllReferredOperator(payload));
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllReferredOperator({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllReferredOperator({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllReferredOperator({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllReferredOperator({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };

  const CustomFilters = () => {
    return (
      <div className="mb-2">
        <div className="p-2 text-center">
          <WarningOutlined className="text-6xl" />
          <h1 className="text-black text-2xl">
            "We will update this page as soon as possible"
          </h1>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <h1 className="" style={{ fontSize: 30 }}>
          Area Sub Operator
        </h1>
        <LayoutContent className="flex justify-center items-center">
          <CustomFilters />
        </LayoutContent>
      </>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default AreaSubOperator;
