import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isEqual } from "lodash";
import { Layout, Button, Tooltip, Form, Input } from "antd";
import { FormOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import { Link } from "react-router-dom";
// import { FormOutlined, PlusOutlined } from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import AllproductsModal from "../components/layout/allproducts.js/modal";

// Actions
// import { fetchProducts } from "../redux/actions/partnerStores";

// import {
//   toggleModal,
//   setForm,
//   fetch,
//   toggleImportModal,
// } from "../redux/actions/partnerStoreProducts";

import {
  toggleModal,
  setForm,
  fetchAllProducts,
} from "../redux/actions/allproducts";

import { useCallback } from "react";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const AllProducts = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    formLoading,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    fetchdata,
    modal,
  } = useSelector(
    ({ allproductsReducer }) => ({
      modal: allproductsReducer.modal,
      fetchdata: allproductsReducer.data,
      loading: allproductsReducer.loading,
      formLoading: allproductsReducer.formLoading,
      totalRecord: allproductsReducer.totalRecord,
      nextPage: allproductsReducer.nextPage,
      previousPage: allproductsReducer.previousPage,
      currentPage: allproductsReducer.currentPage,
    }),
    isEqual
  );
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAllProducts(payload));
  }, [delaySearchText, dispatch]);

  const onAdd = () => {
    dispatch(toggleModal());
  };

  //   const onClickBulkUpload = () => {
  //     dispatch(toggleImportModal());
  //   };

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllProducts(payload));
  };

  const onEdit = useCallback(
    ({ data }) => {
      dispatch(setForm(data));
      dispatch(toggleModal());
    },
    [dispatch]
  );

  // const onView = ({ data }) => {
  //   setViewMerchantsDetails(true);
  //   dispatch(setForm(data));
  //   dispatch(toggleModal());
  // };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>All products</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  //   const extra = () => {
  //     const addButton = (
  //       <Button key="1" onClick={onAdd} className="mr-2">
  //         <PlusOutlined /> Add
  //       </Button>
  //     );

  //     return [addButton];
  //   };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllProducts({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllProducts({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllProducts({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllProducts({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "cat_id",
      },
      {
        Header: "Sub category id",
        accessor: "sub_cat_id",
      },
      {
        Header: "SKU",
        accessor: "skuNum",
      },
      {
        Header: "Name",
        accessor: "product_name",
      },
      {
        Header: "Barcode",
        accessor: "barcode",
      },
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button
              disabled={formLoading}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined /> EDIT
              </Tooltip>
            </Button>
          </>
        ),
        width: 100,
      },
    ],
    [formLoading, onEdit]
  );

  useEffect(() => {
    if (!modal) {
      dispatch(fetchAllProducts(defaultPage));
    }
  }, [dispatch, defaultPage, modal]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={fetchdata}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
            // <Button key="1" onClick={onClickBulkUpload} className="mr-2">
            //   {/* edit onlick */}
            //   <UploadOutlined /> Bulk Create Products
            // </Button>,
          ]}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <AllproductsModal />
          </>
        }
      />
    </>
  );
};

export default AllProducts;
