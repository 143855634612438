import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Select, Segmented, ConfigProvider, DatePicker } from "antd";

import { isEqual } from "lodash";

// Components
import Wrapper from "../components/wrapper";
import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import {
  fetchAdminDashBoardTransactions,
  fetchAdminDashBoardTopPerformingAreas,
  fetchAdminDashBoardTopPerformingMerchants,
  fetchAdminDashBoardCustomerGrowth,
  fetchAdminDashBoardTopCity,
} from "../redux/actions/adminDashBoard";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { Content: LayoutContent } = Layout;

const AdminDashBoard = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    dailyTransactionData,
    weeklyTransactionData,
    monthlyTransactionData,
    topPerformingPartners,
    topPerformingMerchants,
    dailyCustomerGrowthData,
    weeklyCustomerGrowthData,
    monthlyCustomerGrowthData,
    userInfo,
    topAreasData,
  } = useSelector(
    ({ AdminDashBoardReducer, userLoginReducer }) => ({
      dailyTransactionData: AdminDashBoardReducer.dailyTransactionData,
      weeklyTransactionData: AdminDashBoardReducer.weeklyTransactionData,
      monthlyTransactionData: AdminDashBoardReducer.monthlyTransactionData,
      topPerformingPartners: AdminDashBoardReducer.topPerformingPartners,
      topPerformingMerchants: AdminDashBoardReducer.topPerformingMerchants,
      dailyCustomerGrowthData: AdminDashBoardReducer.dailyCustomerGrowthData,
      weeklyCustomerGrowthData: AdminDashBoardReducer.weeklyCustomerGrowthData,

      monthlyCustomerGrowthData:
        AdminDashBoardReducer.monthlyCustomerGrowthData,
      userInfo: userLoginReducer.userInfo,
      topAreasData: AdminDashBoardReducer.topAreasData,
    }),
    isEqual
  );

  const [status, setStatus] = useState("delivered");
  const [timeFrame, setTimeFrame] = useState("Daily");
  const [transactionGraphData, setTransactionGraphData] = useState([]);
  const [customerGrowthGraphData, setCustomerGrowthGraphData] = useState([]);
  const [customerGrowthTimeFrame, setCustomerGrowthTimeFrame] =
    useState("Daily");

  const [fromDatePerformingPartners, setFromDatePerformingPartners] =
    useState(null);
  const [toDatePerformingPartners, setToDatePerformingPartners] =
    useState(null);

  const [fromDatePerformingMerchants, setFromDatePerformingMerchants] =
    useState(null);
  const [toDatePerformingMerchants, setToDatePerformingMerchants] =
    useState(null);

  const [fromDatePerformingCity, setFromDatePerformingCity] = useState(null);
  const [toDatePerformingCity, setToDatePerformingCity] = useState(null);

  const handleRangeChangePerformingPartners = (dates) => {
    if (dates && dates.length === 2) {
      setFromDatePerformingPartners(dates[0]);
      setToDatePerformingPartners(dates[1]);
    } else {
      setFromDatePerformingPartners(null);
      setToDatePerformingPartners(null);
    }
  };

  const handleRangeChangePerformingMerchants = (dates) => {
    if (dates && dates.length === 2) {
      setFromDatePerformingMerchants(dates[0]);
      setToDatePerformingMerchants(dates[1]);
    } else {
      setFromDatePerformingMerchants(null);
      setToDatePerformingMerchants(null);
    }
  };

  const handleRangeChangepPerformingCity = (dates) => {
    if (dates && dates.length === 2) {
      setFromDatePerformingCity(dates[0]);
      setToDatePerformingCity(dates[1]);
    } else {
      setFromDatePerformingCity(null);
      setToDatePerformingCity(null);
    }
  };

  useEffect(() => {
    if (userInfo.role !== "admin") history.push("/getstarted");
  }, [userInfo.role, history]);

  useEffect(() => {
    if (customerGrowthTimeFrame === "Daily") {
      setCustomerGrowthGraphData(dailyCustomerGrowthData);
    }

    if (customerGrowthTimeFrame === "Weekly") {
      setCustomerGrowthGraphData(weeklyCustomerGrowthData);
    }
    if (customerGrowthTimeFrame === "Monthly") {
      setCustomerGrowthGraphData(monthlyCustomerGrowthData);
    }
  }, [
    customerGrowthTimeFrame,
    dailyCustomerGrowthData,
    weeklyCustomerGrowthData,
    monthlyCustomerGrowthData,
  ]);

  useEffect(() => {
    if (timeFrame === "Daily") {
      setTransactionGraphData(dailyTransactionData);
    }

    if (timeFrame === "Weekly") {
      setTransactionGraphData(weeklyTransactionData);
    }
    if (timeFrame === "Monthly") {
      setTransactionGraphData(monthlyTransactionData);
    }
  }, [
    timeFrame,
    dailyTransactionData,
    weeklyTransactionData,
    monthlyTransactionData,
  ]);

  const handleChange = (value) => {
    setStatus(value);
  };

  // transaction
  const defaultPage = useMemo(() => {
    return {
      status,
      timeFrame,
    };
  }, [status, timeFrame]);

  const defaultPageCustomerGrowth = useMemo(() => {
    return {
      timeFrame: customerGrowthTimeFrame,
    };
  }, [customerGrowthTimeFrame]);

  useEffect(() => {
    dispatch(fetchAdminDashBoardTransactions(defaultPage));
  }, [dispatch, defaultPage, status]);

  useEffect(() => {
    //
    let payload = { defaultDate: true };
    if (
      fromDatePerformingPartners !== null &&
      toDatePerformingPartners !== null
    ) {
      const formatDate = (date) => {
        return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
      };

      payload = {
        fromDate:
          formatDate(fromDatePerformingPartners) === "Invalid Date"
            ? undefined
            : formatDate(fromDatePerformingPartners),
        toDate:
          formatDate(toDatePerformingPartners) === "Invalid Date"
            ? undefined
            : formatDate(toDatePerformingPartners),
        defaultDate: false,
      };
    }
    //

    dispatch(fetchAdminDashBoardTopPerformingAreas(payload));
  }, [dispatch, toDatePerformingPartners, fromDatePerformingPartners]);

  useEffect(() => {
    //
    let payload = { defaultDate: true };
    if (
      fromDatePerformingMerchants !== null &&
      toDatePerformingMerchants !== null
    ) {
      const formatDate = (date) => {
        return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
      };

      payload = {
        fromDate:
          formatDate(fromDatePerformingMerchants) === "Invalid Date"
            ? undefined
            : formatDate(fromDatePerformingMerchants),
        toDate:
          formatDate(toDatePerformingMerchants) === "Invalid Date"
            ? undefined
            : formatDate(toDatePerformingMerchants),
        defaultDate: false,
      };
    }
    //

    dispatch(fetchAdminDashBoardTopPerformingMerchants(payload));
  }, [dispatch, toDatePerformingMerchants, fromDatePerformingMerchants]);

  useEffect(() => {
    dispatch(fetchAdminDashBoardCustomerGrowth(defaultPageCustomerGrowth));
  }, [dispatch, defaultPageCustomerGrowth]);

  useEffect(() => {
    //
    let payload = { defaultDate: true };
    if (fromDatePerformingCity !== null && toDatePerformingCity !== null) {
      const formatDate = (date) => {
        return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
      };

      payload = {
        fromDate:
          formatDate(fromDatePerformingCity) === "Invalid Date"
            ? undefined
            : formatDate(fromDatePerformingCity),
        toDate:
          formatDate(toDatePerformingCity) === "Invalid Date"
            ? undefined
            : formatDate(toDatePerformingCity),
        defaultDate: false,
      };
    }
    //

    dispatch(fetchAdminDashBoardTopCity(payload));
  }, [dispatch, fromDatePerformingCity, toDatePerformingCity]);

  const Content = () => {
    return (
      <LayoutContent>
        <div
          style={{
            height: "55vh",
            margin: 40,
            border: "1px solid #e8e8e8",
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              height: "5vh",
              display: "flex",
              gap: 20,
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 40,
            }}
          >
            <div>
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      itemSelectedBg: "dodgerblue",
                      itemSelectedColor: "white",
                    },
                  },
                }}
              >
                <Segmented
                  size="middle"
                  options={["Daily", "Weekly", "Monthly"]}
                  onChange={(value) => {
                    setTimeFrame(value);
                  }}
                  value={timeFrame}
                  defaultValue="Daily"
                />
              </ConfigProvider>
            </div>
            <div>
              <Select
                defaultValue={status}
                style={{
                  width: 120,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "delivered",
                    label: "Delivered",
                  },
                  {
                    value: "undelivered",
                    label: "Undelivered",
                  },
                  {
                    value: "cancelled",
                    label: "Cancelled",
                  },
                ]}
              />
            </div>
          </div>
          <div style={{ height: "45vh" }}>
            <Line
              data={{
                labels: transactionGraphData.map((data) => data?.order_date),
                datasets: [
                  {
                    label: "Transaction",
                    data: transactionGraphData.map(
                      (data) => data?.transaction_count
                    ),
                    backgroundColor: "#c9e4fe",
                    borderColor: "#064FF0",
                    pointStyle: "circle",
                    pointRadius: 6,
                    pointHoverRadius: 15,
                  },
                ],
              }}
              options={{
                height: "45vh",
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: false,
                  title: {
                    display: true,
                    text: () => "Transactions",
                    font: { size: 20 },
                  },
                },
                scales: {
                  y: {
                    title: {
                      color: "red",
                      display: true,
                      text: "No. of transaction",
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        {/*  */}
        <div
          style={{
            height: "55vh",
            margin: 40,
            border: "1px solid #e8e8e8",
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div style={{ display: "flex" }}>
            <RangePicker
              onChange={handleRangeChangepPerformingCity}
              value={[fromDatePerformingCity, toDatePerformingCity]}
            />
          </div>
          <Bar
            data={{
              labels: topAreasData.map((data) => data?.area),

              datasets: [
                {
                  label: "total delivered",
                  data: topAreasData.map((data) => data?.total_delivered_count),
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 205, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                    "rgba(255, 159, 64, 0.2)",
                    "rgba(205, 92, 92, 0.2)",
                    "rgba(0, 206, 209, 0.2)",
                    "rgba(128, 0, 128, 0.2)",
                    "rgba(32, 178, 170, 0.2)",
                  ],
                  borderColor: [
                    "rgb(255, 99, 132)",
                    "rgb(54, 162, 235)",
                    "rgb(255, 205, 86)",
                    "rgb(75, 192, 192)",
                    "rgb(153, 102, 255)",
                    "rgb(255, 159, 64)",
                    "rgb(205, 92, 92)",
                    "rgb(0, 206, 209)",
                    "rgb(128, 0, 128)",
                    "rgb(32, 178, 170)",
                  ],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              height: "30vh",
              indexAxis: "y",
              plugins: {
                legend: false,
                title: {
                  display: true,
                  text: () => "Top 10 Performing Area",
                  font: { size: 20 },
                },
              },
              layout: {
                padding: {
                  left: 30,
                  right: 30,
                  bottom: 30,
                },
              },
            }}
          />
        </div>
        {/*  */}
        <div
          style={{
            display: "flex",
            margin: 40,
            gap: 50,
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              height: "55vh",
              width: "40vw",
              textAlign: "center",
              border: "1px solid #e8e8e8",
              borderRadius: "8px",
              padding: "16px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div style={{ display: "flex" }}>
              <RangePicker
                onChange={handleRangeChangePerformingPartners}
                value={[fromDatePerformingPartners, toDatePerformingPartners]}
              />
            </div>
            <Bar
              data={{
                labels: topPerformingPartners.map((data) => data?.partner_name),

                datasets: [
                  {
                    label: "total delivered",
                    data: topPerformingPartners.map(
                      (data) => data?.order_count
                    ),
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.2)",
                      "rgba(54, 162, 235, 0.2)",
                      "rgba(255, 205, 86, 0.2)",
                      "rgba(75, 192, 192, 0.2)",
                      "rgba(153, 102, 255, 0.2)",
                      "rgba(255, 159, 64, 0.2)",
                      "rgba(205, 92, 92, 0.2)",
                      "rgba(0, 206, 209, 0.2)",
                      "rgba(128, 0, 128, 0.2)",
                      "rgba(32, 178, 170, 0.2)",
                    ],
                    borderColor: [
                      "rgb(255, 99, 132)",
                      "rgb(54, 162, 235)",
                      "rgb(255, 205, 86)",
                      "rgb(75, 192, 192)",
                      "rgb(153, 102, 255)",
                      "rgb(255, 159, 64)",
                      "rgb(205, 92, 92)",
                      "rgb(0, 206, 209)",
                      "rgb(128, 0, 128)",
                      "rgb(32, 178, 170)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                height: "30vh",
                indexAxis: "y",
                plugins: {
                  legend: false,
                  title: {
                    display: true,
                    text: () => "Top 10 Performing Partners",
                    font: { size: 20 },
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        var label = context.dataset.label || "";
                        if (label) {
                          label += ": ";
                        }
                        if (context.parsed.x !== null) {
                          label += context.parsed.x;
                        }
                        label +=
                          " - Area: " +
                          topPerformingPartners[context.dataIndex]
                            ?.service_areas;

                        return label;
                      },
                    },
                  },
                },
                layout: {
                  padding: {
                    left: 30,
                    right: 30,
                    bottom: 30,
                  },
                },
              }}
            />
          </div>
          <div
            style={{
              height: "55vh",
              width: "40vw",
              border: "1px solid #e8e8e8",
              borderRadius: "8px",
              padding: "16px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div style={{ display: "flex" }}>
              <RangePicker
                onChange={handleRangeChangePerformingMerchants}
                value={[fromDatePerformingMerchants, toDatePerformingMerchants]}
              />
            </div>
            <Bar
              data={{
                labels: topPerformingMerchants.map((data) => data?.name),

                datasets: [
                  {
                    label: "total delivered:",
                    data: topPerformingMerchants.map(
                      (data) => data?.order_count
                    ),
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.2)",
                      "rgba(54, 162, 235, 0.2)",
                      "rgba(255, 205, 86, 0.2)",
                      "rgba(75, 192, 192, 0.2)",
                      "rgba(153, 102, 255, 0.2)",
                      "rgba(255, 159, 64, 0.2)",
                      "rgba(205, 92, 92, 0.2)",
                      "rgba(0, 206, 209, 0.2)",
                      "rgba(128, 0, 128, 0.2)",
                      "rgba(32, 178, 170, 0.2)",
                    ],
                    borderColor: [
                      "rgb(255, 99, 132)",
                      "rgb(54, 162, 235)",
                      "rgb(255, 205, 86)",
                      "rgb(75, 192, 192)",
                      "rgb(153, 102, 255)",
                      "rgb(255, 159, 64)",
                      "rgb(205, 92, 92)",
                      "rgb(0, 206, 209)",
                      "rgb(128, 0, 128)",
                      "rgb(32, 178, 170)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                height: "30vh",
                indexAxis: "y",
                plugins: {
                  legend: false,
                  title: {
                    display: true,
                    text: () => "Top 10 Performing Merchants",
                    font: { size: 20 },
                  },
                },
                layout: {
                  padding: {
                    left: 30,
                    right: 30,
                    bottom: 30,
                  },
                },
              }}
            />
          </div>
        </div>
        <div
          style={{
            height: "55vh",
            margin: 40,
            border: "1px solid #e8e8e8",
            borderRadius: "8px",
            padding: "16px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              height: "5vh",
              display: "flex",
              gap: 20,
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 40,
            }}
          >
            <div>
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      itemSelectedBg: "dodgerblue",
                      itemSelectedColor: "white",
                    },
                  },
                }}
              >
                <Segmented
                  size="middle"
                  options={["Daily", "Weekly", "Monthly"]}
                  onChange={(value) => {
                    setCustomerGrowthTimeFrame(value);
                  }}
                  value={customerGrowthTimeFrame}
                  defaultValue="Daily"
                />
              </ConfigProvider>
            </div>
          </div>
          <div style={{ height: "45vh" }}>
            <Line
              data={{
                labels: customerGrowthGraphData.map(
                  (data) => data?.register_date
                ),
                datasets: [
                  {
                    label: "new customer",
                    data: customerGrowthGraphData.map(
                      (data) => data?.customer_count
                    ),
                    backgroundColor: "#c9e4fe",
                    borderColor: "#064FF0",
                    pointStyle: "circle",
                    pointRadius: 6,
                    pointHoverRadius: 15,
                  },
                ],
              }}
              options={{
                height: "45vh",
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: false,
                  title: {
                    display: true,
                    text: () => "Customer Growth",
                    font: { size: 20 },
                  },
                },
              }}
            />
          </div>
        </div>
      </LayoutContent>
    );
  };

  return <Wrapper content={<Content />} />;
};

export default AdminDashBoard;
