import actions from "../actionTypes";
const { PARTNERDASHBOARD } = actions;

const userInitialState = {
  dailyTransactionData: [],
  weeklyTransactionData: [],
  monthlyTransactionData: [],
  collectedDeliveryFee: 0,
  topMerchants: [],
  loading: false,
  dailyCustomerGrowthData: [],
  weeklyCustomerGrowthData: [],
  monthlyCustomerGrowthData: [],
};

export const PartnerDashBoardReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PARTNERDASHBOARD.FETCHTRANSACTION.REQUEST:
      return { ...state, loading: true };
    case PARTNERDASHBOARD.FETCHTRANSACTION.SUCCESS:
      let newDataToUpdate = {};

      if (payload.data.timeFrame === "Daily") {
        newDataToUpdate = { dailyTransactionData: payload.data.data };
      } else if (payload.data.timeFrame === "Weekly") {
        newDataToUpdate = { weeklyTransactionData: payload.data.data };
      } else {
        newDataToUpdate = { monthlyTransactionData: payload.data.data };
      }

      return {
        ...state,
        ...newDataToUpdate,
        loading: false,
      };
    case PARTNERDASHBOARD.FETCHTRANSACTION.FAILURE:
      return { ...state, error: payload, loading: false };

    case PARTNERDASHBOARD.FETCHCOLLECTEDDELIVERYFEE.REQUEST:
      return { ...state, loading: true };
    case PARTNERDASHBOARD.FETCHCOLLECTEDDELIVERYFEE.SUCCESS:
      return {
        ...state,
        collectedDeliveryFee: payload.data.totalDeliveryFee, // total collected dfee
        loading: false,
      };
    case PARTNERDASHBOARD.FETCHCOLLECTEDDELIVERYFEE.FAILURE:
      return { ...state, error: payload, loading: false };

    case PARTNERDASHBOARD.FETCHTOPMERCHANTS.REQUEST:
      return { ...state, loading: true };
    case PARTNERDASHBOARD.FETCHTOPMERCHANTS.SUCCESS:
      return {
        ...state,
        topMerchants: payload.data.topMerchants,
        loading: false,
      };
    case PARTNERDASHBOARD.FETCHTOPMERCHANTS.FAILURE:
      return { ...state, error: payload, loading: false };

    case PARTNERDASHBOARD.FETCHCUSTOMERGROWTH.REQUEST:
      return { ...state, loading: true };
    case PARTNERDASHBOARD.FETCHCUSTOMERGROWTH.SUCCESS:
      let newDataToUpdateCustomerGrowth = {};

      if (payload.data.timeFrame === "Daily") {
        newDataToUpdateCustomerGrowth = {
          dailyCustomerGrowthData: payload.data.data,
        };
      } else if (payload.data.timeFrame === "Weekly") {
        newDataToUpdateCustomerGrowth = {
          weeklyCustomerGrowthData: payload.data.data,
        };
      } else {
        newDataToUpdateCustomerGrowth = {
          monthlyCustomerGrowthData: payload.data.data,
        };
      }

      return {
        ...state,
        ...newDataToUpdateCustomerGrowth,
        loading: false,
      };
    case PARTNERDASHBOARD.FETCHCUSTOMERGROWTH.FAILURE:
      return { ...state, error: payload, loading: false };

    default:
      return state;
  }
};
