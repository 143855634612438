import axiosInstance from "../../helpers/axios";
import actions from "../actionTypes";
const { REFERREDOPERATOR } = actions;

export const fetchAllReferredOperator =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: REFERREDOPERATOR.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token, id } = userInfo;

    try {
      const { page, limit, operator } = payload;
      let url = `/api/sub-operator/fetch-referred-operator?partner_id=${userInfo.id}&type=${operator}`;
      const response = await axiosInstance(token).get(url);
      console.log(response);
      dispatch({ type: REFERREDOPERATOR.FETCH.SUCCESS, payload: response });
      return response;
    } catch (error) {
      dispatch({
        type: REFERREDOPERATOR.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
