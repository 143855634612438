import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Form,
  Col,
  Button,
  Modal,
  notification,
  Row,
  Select,
  Image,
} from "antd";

//utilities
import renderInput from "../utilities/inputForms";

// Actions
import {
  toggleModal,
  setForm,
  create,
  update,
} from "../../redux/actions/partnerStoreProducts";

// hepers
import axiosInstance from "../../helpers/axios";

const PartnerStoreProductsModal = () => {
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const [selectedItem, setSelectedItem] = useState({});
  const [groceriaProducts, setGroceriaProducts] = useState([]);
  // const [groceriaProductsLoading, setGroceriaProductsLoading] = useState([]);

  const { modal, formLoading, error, form, userInfo } = useSelector(
    ({ partnerStoresProductsReducer, userLoginReducer }) => ({
      modal: partnerStoresProductsReducer.modal,
      formLoading: partnerStoresProductsReducer.formLoading,
      error: partnerStoresProductsReducer.error,
      form: partnerStoresProductsReducer.form,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  const schema = yup.object().shape({
    price: yup.number().required("price is required field"),
  });

  let defaultValues = {
    price: "",
  };

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    setSelectedItem({});
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const getGroceriaProducts = useCallback(async () => {
    try {
      // setGroceriaProductsLoading(true);
      const response = await axiosInstance(userInfo.token).get(
        "/api/products/groceria/all"
      );

      if (response?.data || response.data.data.length) {
        setGroceriaProducts(response.data.data);
      }
      // setGroceriaProductsLoading(false);
    } catch (error) {
      // setGroceriaProductsLoading(false);
      console.log(">>> error getting groceria products:", error);
    }
  }, [userInfo]);

  const onSelectProduct = (value) => {
    const selected = groceriaProducts.find((d) => d.product_name === value);
    setSelectedItem(selected);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        const newValues = {
          productId: form.products_id,
          price: parseFloat(values?.price),
          storeId: parseInt(storeId),
        };
        const toMatch = omit(form, ["id"]);
        const changesKeys = Object.keys(
          ObjectDiff(toMatch, { ...toMatch, ...newValues })
        );
        if (!changesKeys.length) {
          return openNotification("INFO", "No changes made.");
        }
        const { data } = await dispatch(
          update({
            ...newValues,
            id: form.id,
          })
        );
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      } else {
        const newData = {
          storeId: storeId,
          productId: selectedItem.product_id,
          price: values.price,
        };

        const { data } = await dispatch(create(newData));

        if (data) {
          if (data.message === "Product already exist") {
            openNotification("SUCCESS", data.message);
            close();
          } else {
            openNotification("SUCCESS", data.message);
            close();
          }
        }
      }
    } catch (error) {
      console.log(">>> error saving product to store:", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  useEffect(() => {
    if (hasForm) {
      reset(form);
      setSelectedItem({ ...form, price: parseFloat(form.price).toFixed(2) });
    }
  }, [form, hasForm, reset]);

  useEffect(() => {
    getGroceriaProducts();
  }, [getGroceriaProducts]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add Product"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        <>
          <br />
          <h1>Select Product</h1>

          <Select
            showSearch
            placeholder="Select a product to add"
            optionFilterProp="children"
            onChange={onSelectProduct}
            options={groceriaProducts.map((d) => ({
              value: d.product_name,
              label: d.product_name,
            }))}
            style={{ width: "100%" }}
            value={selectedItem.product_name}
            disabled={hasForm}
            filterOption={filterOption}
          />

          {Object.values(selectedItem).length !== 0 && (
            <Col className="gutter-row" span={24}>
              <div
                style={{
                  width: "100%",
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                  padding: "10px",
                  marginTop: "26px",
                }}
              >
                <Row>
                  <Col span={14}>
                    <p>Name: {selectedItem.product_name}</p>
                    <p>Sku: {selectedItem.skuNum}</p>
                  </Col>
                  <Col span={10}>
                    <Image
                      width={150}
                      style={{ borderRadius: "5px" }}
                      src={`https://abs-groceria.b-cdn.net/${selectedItem.directory}`}
                    />
                  </Col>
                </Row>
              </div>

              <br />

              {selectedItem.product_name !== undefined ? (
                renderInput(
                  {
                    label: "Price",
                    name: "price",
                    errors: errors,
                    required: "true",
                  },
                  control
                )
              ) : (
                <>
                  {hasForm &&
                    renderInput(
                      {
                        label: "Price",
                        name: "price",
                        errors: errors,
                        required: "true",
                      },
                      control
                    )}
                </>
              )}
            </Col>
          )}
        </>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default PartnerStoreProductsModal;
