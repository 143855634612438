import axiosInstance from "../../helpers/axios";
import actions from "../actionTypes.js";

const { SENDPACKAGE, FETCH } = actions.RIDER_PACKAGES;


export const fetchAllRidersPackages = (payload) => async (dispatch, getState) => {
  dispatch({ type: FETCH.REQUEST }); 

  const {
    userLoginReducer: { userInfo },
  } = getState(); 
  let { token } = userInfo; 
  try {
    let url = `/api/rider/fetchOrderPackage`; 

    if (payload !== undefined) {
      const { page, limit, searchText, dateFilter, todateFilter } = payload;
      url += `?page=${page}&limit=${limit}`; 
      if (searchText) {
        url += `&searchText=${searchText}`; 
      }
      if (dateFilter && todateFilter) {
        url += `&dateFilter=${dateFilter}&toDateFilter=${todateFilter}`; 
      }
    }
    const response = await axiosInstance(token).get(url);
    dispatch({
      type: FETCH.SUCCESS,
      payload: {
        data: response.data.data.result, 
        totalRecord: response.data.count, 
        nextPage: response.data.data.next, 
        previousPage: response.data.data.previous, 
        currentPage: response.data.currentPage, 
      },
    });

    return response; 
  } catch (error) {
    dispatch({
      type: FETCH.FAILURE,
      payload: error.response ? error.response.data.message : error.message,
    });
  }
};


export const sendRidersPackage = (payload) => async (dispatch, getState) => {
    dispatch({ type: SENDPACKAGE.REQUEST });
    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
  
    try {
      const response = await axiosInstance(token).post(`/api/rider/send-package-notification`, payload);

      console.log("payload", payload)
  
      dispatch({
        type: SENDPACKAGE.SUCCESS,
        payload: {
          data: response.data,
          message: response.data.message, 
        },
      });

      console.log("response.data.message", response.data.message);
      
      return response;
    } catch (error) {
      dispatch({
        type: SENDPACKAGE.FAILURE,
        payload: error.response ? error.response.data.message : error.message,
      });
    }
  };
