import React, { useState } from "react";
import { Button, Modal, notification, Form, Col } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import axiosInstance from "../../helpers/axios";

import renderInput from "../utilities/inputForms";

const openNotification = (title, message) => {
  notification[title.toLowerCase()]({
    message: title,
    description: message,
    duration: 7,
  });
};

const ChangePasswordModal = ({ modal, setModal, userInfo }) => {
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required field")
      .min(6, "too short")
      .max(16, "too long"),
  });

  let defaultValues = {
    password: "",
  };

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axiosInstance(userInfo.token).put(
        `/api/admin-account/change-password`,
        { password: values.password, id: userInfo.id }
      );

      if (response?.data) {
        openNotification("SUCCESS", response.data.message);
        setModal(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(">>> error changing password", error);
      openNotification("ERROR", "Something went wrong");
      setLoading(false);
    }
  };

  return (
    <>
      <Modal title="Change Password" open={modal} footer={null}>
        <Form
          layout="vertical"
          initialValues={{
            requiredMarkValue: true,
          }}
          requiredMark={true}
          onFinish={handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Password",
                  name: "password",
                  errors: errors,
                  required: "true",
                  type: "password",
                },
                control
              )}
            </Col>
          </>

          <div className="text-right mt-5">
            <Button
              key="back"
              style={{ marginRight: "12px" }}
              onClick={() => setModal(false)}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default ChangePasswordModal;
