import actions from "../actionTypes";
const { NATIONALMERCHANTS } = actions;

const userInitialState = {
  modal: false,
  modalView: false,
  modalAddMenu: false,
  formLoading: false,
  data: [],
  form: {},
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
  menu: [],
  tags: [],
  modalFeaturedImage: false,
};
export const nationalMerchantsReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NATIONALMERCHANTS.TOGGLE:
      return { ...state, modal: payload, error: null };
    case NATIONALMERCHANTS.CLEARERROR:
      return { ...state, error: null };
    case NATIONALMERCHANTS.TOGGLEVIEW:
      return { ...state, modalView: payload, error: null };
    case NATIONALMERCHANTS.TOGGLEMENUMODAL:
      return { ...state, modalAddMenu: payload, error: null };
    case NATIONALMERCHANTS.SET:
      return { ...state, form: payload };

    case NATIONALMERCHANTS.CREATE.REQUEST:
      return { ...state, formLoading: true, createLoading: true };

    case NATIONALMERCHANTS.CREATE.SUCCESS:
      const newData = payload;
      console.log("newData.data.data:>>>", newData.data.data);
      return {
        ...state,
        formLoading: false,
        createLoading: false,
      };
    case NATIONALMERCHANTS.CREATE.FAILURE:
      return { ...state, formLoading: false, error: payload.message };

    case NATIONALMERCHANTS.FETCH.REQUEST:
      return { ...state, loading: true };
    case NATIONALMERCHANTS.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case NATIONALMERCHANTS.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case NATIONALMERCHANTS.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case NATIONALMERCHANTS.UPDATE.SUCCESS:
      return {
        ...state,
        formLoading: false,
        form: payload.data.data,
        data: state.data.map((d) =>
          d.id === payload.data.data.id ? payload.data.data : d
        ),
      };

    case NATIONALMERCHANTS.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    case NATIONALMERCHANTS.SOFTDELETION.REQUEST:
      return { ...state, formLoading: true };
    case NATIONALMERCHANTS.SOFTDELETION.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        formLoading: false,
      };

    case NATIONALMERCHANTS.SOFTDELETION.FAILURE:
      return { ...state, formLoading: false, error: payload };

    // TAGS
    case NATIONALMERCHANTS.FETCHTAGS.REQUEST:
      return { ...state, loading: true };
    case NATIONALMERCHANTS.FETCHTAGS.SUCCESS:
      const tags = payload.data.tags;
      const restructuredTags = tags.map((tag) => {
        return { label: tag.tag, value: tag.id };
      });
      return {
        ...state,
        tags: restructuredTags,
      };
    case NATIONALMERCHANTS.FETCHTAGS.FAILURE:
      return { ...state, error: payload, loading: false };

    case NATIONALMERCHANTS.TOGGLEFEATUREDIMAGE:
      return { ...state, modalFeaturedImage: payload, error: null };

    // create image
    case NATIONALMERCHANTS.CREATEFEATUREDIMAGE.REQUEST:
      return { ...state, formLoading: true };

    case NATIONALMERCHANTS.CREATEFEATUREDIMAGE.SUCCESS:
      return {
        ...state,
        formLoading: false,
      };
    case NATIONALMERCHANTS.CREATEFEATUREDIMAGE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
