import actions from "../actionTypes";
const { MERCHANTSMENUAPPROVAL } = actions;

const userInitialState = {
  modal: false,
  modalView: false,
  formLoading: false,
  data: [],
  form: {},
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
};

export const localMerchantMenuForApprovalReducer = (
  state = userInitialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case MERCHANTSMENUAPPROVAL.TOGGLE:
      return { ...state, modal: payload, error: null };

    case MERCHANTSMENUAPPROVAL.SET:
      return { ...state, form: payload };

    case MERCHANTSMENUAPPROVAL.FETCH.REQUEST:
      return { ...state, loading: true };
    case MERCHANTSMENUAPPROVAL.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case MERCHANTSMENUAPPROVAL.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case MERCHANTSMENUAPPROVAL.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case MERCHANTSMENUAPPROVAL.UPDATE.SUCCESS:
      return {
        ...state,
        formLoading: false,
        form: payload.data.data,
        data: state.data.filter((d) => d.id !== payload.data.merchant_menu_id),
      };

    case MERCHANTSMENUAPPROVAL.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
