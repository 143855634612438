import actions from "../actionTypes";
const { LOCALMERCHANTS } = actions;

const userInitialState = {
  merchantInMyAreaData: [],
  merchantInMyAreaTotalRecord: 0,
  merchantInMyAreaNextPage: undefined,
  merchantInMyAreaPreviousPage: undefined,
  merchantInMyAreaCurrentPage: undefined,

  loading: false,
  error: null,

  referredMerchantData: [],
  referredMerchantTotalRecord: 0,
  referredMerchantNextPage: undefined,
  referredMerchantPreviousPage: undefined,
  referredMerchantCurrentPage: undefined,
};

export const localMerchantReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOCALMERCHANTS.FETCHLOCALMERCHANTINMYAREA.REQUEST:
      return { ...state, loading: true };
    case LOCALMERCHANTS.FETCHLOCALMERCHANTINMYAREA.SUCCESS:
      return {
        ...state,
        merchantInMyAreaData: payload.data.data.result,
        merchantInMyAreaTotalRecord: payload.data.count,
        merchantInMyAreaNextPage: payload.data?.data?.next,
        merchantInMyAreaPreviousPage: payload.data?.data?.previous,
        merchantInMyAreaCurrentPage: payload.data?.currentPage,
        loading: false,
      };
    case LOCALMERCHANTS.FETCHLOCALMERCHANTINMYAREA.FAILURE:
      return { ...state, error: payload, loading: false };
    //   ==============================================================

    case LOCALMERCHANTS.FETCHREFERREDLOCALMERCHANT.REQUEST:
      return { ...state, loading: true };
    case LOCALMERCHANTS.FETCHREFERREDLOCALMERCHANT.SUCCESS:
      return {
        ...state,
        referredMerchantData: payload.data.data.result,
        referredMerchantTotalRecord: payload.data.count,
        referredMerchantNextPage: payload.data?.data?.next,
        referredMerchantPreviousPage: payload.data?.data?.previous,
        referredMerchantCurrentPage: payload.data?.currentPage,
        loading: false,
      };
    case LOCALMERCHANTS.FETCHREFERREDLOCALMERCHANT.FAILURE:
      return { ...state, error: payload, loading: false };

    default:
      return state;
  }
};
