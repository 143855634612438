import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isEqual } from "lodash";
import { Layout, Button, Tooltip, Form, Input } from "antd";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import MerchantTagsModal from "../components/merchantTags/modal";
// Actions
// import { fetchProducts } from "../redux/actions/partnerStores";

import { Link } from "react-router-dom";
import { toggleModal, setForm, fetch } from "../redux/actions/merchantTags";

import { useCallback } from "react";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const MerchantTags = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    formLoading,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    data,
  } = useSelector(
    ({ tagsReducer }) => ({
      data: tagsReducer.data,
      loading: tagsReducer.loading,
      formLoading: tagsReducer.formLoading,
      totalRecord: tagsReducer.totalRecord,
      nextPage: tagsReducer.nextPage,
      previousPage: tagsReducer.previousPage,
      currentPage: tagsReducer.currentPage,
    }),
    isEqual
  );
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
    };
    dispatch(fetch(payload));
  }, [delaySearchText, dispatch]);
  const onAdd = () => {
    dispatch(toggleModal());
  };

  //   const onClickBulkUpload = () => {
  //     dispatch(toggleImportModal());
  //   };

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
    };

    dispatch(fetch(payload));
  };

  const onEdit = useCallback(
    ({ data }) => {
      dispatch(setForm(data));
      dispatch(toggleModal());
    },
    [dispatch]
  );

  // const onView = ({ data }) => {
  //   setViewMerchantsDetails(true);
  //   dispatch(setForm(data));
  //   dispatch(toggleModal());
  // };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Merchant Tags</h1>
        {/* <h1>Merchant Menu - {`${city} ${province}`}</h1> */}
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  //   const extra = () => {
  //     const addButton = (
  //       <Button key="1" onClick={onAdd} className="mr-2">
  //         <PlusOutlined /> Add
  //       </Button>
  //     );

  //     return [addButton];
  //   };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetch({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetch({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetch({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetch({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button
              disabled={formLoading}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined />
              </Tooltip>
            </Button>
          </>
        ),
        width: 100,
      },
      {
        Header: "Tag",
        accessor: "tag",
      },
    ],
    [formLoading, onEdit]
  );

  useEffect(() => {
    dispatch(fetch(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={data}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
          ]}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <MerchantTagsModal />
          </>
        }
      />
    </>
  );
};

export default MerchantTags;
