import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { FRANCHISEEPAYABLES } = actions;

export const fetchAllFranchiseePayables =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: FRANCHISEEPAYABLES.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/franchisee-payables`;
      if (payload !== undefined) {
        const { page, limit, searchText, dateFilter, todateFilter } = payload;
        url = `/api/franchisee-payables/${userInfo.id}?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/franchisee-payable/${userInfo.id}?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
        if (page && limit && dateFilter && todateFilter) {
          url = `/api/franchisee-payables/${userInfo.id}?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}`;
        }
      }
      const response = await axiosInstance(token).get(url);

      dispatch({
        type: FRANCHISEEPAYABLES.FETCH.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: FRANCHISEEPAYABLES.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const UpdateFranchiseePayables =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: FRANCHISEEPAYABLES.UPDATE.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      console.log("payload:>>>", payload);
      const response = await axiosInstance(token).post(
        `/api/franchisee-payables/deposit`,
        payload
      );
      dispatch({
        type: FRANCHISEEPAYABLES.UPDATE.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: FRANCHISEEPAYABLES.UPDATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
