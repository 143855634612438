import actions from "../actionTypes";
const { ADMINDASHBOARD } = actions;

const userInitialState = {
  dailyTransactionData: [],
  weeklyTransactionData: [],
  monthlyTransactionData: [],
  topPerformingPartners: [],
  topPerformingMerchants: [],
  loading: false,
  dailyCustomerGrowthData: [],
  weeklyCustomerGrowthData: [],
  monthlyCustomerGrowthData: [],
  topAreasData: [],
};

export const AdminDashBoardReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADMINDASHBOARD.FETCHTRANSACTION.REQUEST:
      return { ...state, loading: true };
    case ADMINDASHBOARD.FETCHTRANSACTION.SUCCESS:
      let newDataToUpdateTransaction = {};

      if (payload.data.timeFrame === "Daily") {
        newDataToUpdateTransaction = {
          dailyTransactionData: payload.data.data,
        };
      } else if (payload.data.timeFrame === "Weekly") {
        newDataToUpdateTransaction = {
          weeklyTransactionData: payload.data.data,
        };
      } else {
        newDataToUpdateTransaction = {
          monthlyTransactionData: payload.data.data,
        };
      }

      return {
        ...state,
        ...newDataToUpdateTransaction,
        loading: false,
      };
    case ADMINDASHBOARD.FETCHTRANSACTION.FAILURE:
      return { ...state, error: payload, loading: false };

    case ADMINDASHBOARD.FETCHTOPPARTNERS.REQUEST:
      return { ...state, loading: true };
    case ADMINDASHBOARD.FETCHTOPPARTNERS.SUCCESS:
      return {
        ...state,
        topPerformingPartners: payload.data.topPerformingAreas,
        loading: false,
      };
    case ADMINDASHBOARD.FETCHTOPPARTNERS.FAILURE:
      return { ...state, error: payload, loading: false };

    case ADMINDASHBOARD.FETCHTOPMERCHANTS.REQUEST:
      return { ...state, loading: true };
    case ADMINDASHBOARD.FETCHTOPMERCHANTS.SUCCESS:
      return {
        ...state,
        topPerformingMerchants: payload.data.topPerformingMerchant,
        loading: false,
      };
    case ADMINDASHBOARD.FETCHTOPMERCHANTS.FAILURE:
      return { ...state, error: payload, loading: false };

    case ADMINDASHBOARD.FETCHCUSTOMERGROWTH.REQUEST:
      return { ...state, loading: true };
    case ADMINDASHBOARD.FETCHCUSTOMERGROWTH.SUCCESS:
      let newDataToUpdateCustomerGrowth = {};

      if (payload.data.timeFrame === "Daily") {
        newDataToUpdateCustomerGrowth = {
          dailyCustomerGrowthData: payload.data.data,
        };
      } else if (payload.data.timeFrame === "Weekly") {
        newDataToUpdateCustomerGrowth = {
          weeklyCustomerGrowthData: payload.data.data,
        };
      } else {
        newDataToUpdateCustomerGrowth = {
          monthlyCustomerGrowthData: payload.data.data,
        };
      }

      return {
        ...state,
        ...newDataToUpdateCustomerGrowth,
        loading: false,
      };
    case ADMINDASHBOARD.FETCHCUSTOMERGROWTH.FAILURE:
      return { ...state, error: payload, loading: false };

    case ADMINDASHBOARD.FETCHTOPAREAS.REQUEST:
      return { ...state, loading: true };
    case ADMINDASHBOARD.FETCHTOPAREAS.SUCCESS:
      return {
        ...state,
        topAreasData: payload.data.topAreas,
        loading: false,
      };
    case ADMINDASHBOARD.FETCHTOPAREAS.FAILURE:
      return { ...state, error: payload, loading: false };

    default:
      return state;
  }
};
