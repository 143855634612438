import actions from "../actionTypes";
const { BOOKINGS } = actions;

const userInitialState = {
  data: [],
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
  //
  bookingAvailableRidersData: [],
  bookingLoading: false,
  bookingError: null,
  bookingTotalRecord: 0,
  bookingNextPage: undefined,
  bookingPreviousPage: undefined,
  bookingCurrentPage: undefined,
  alreadyAccepted: false,
  showAvailableRidersData: [],
  availableRidersLoading: false,
};

export const bookingsReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case BOOKINGS.FETCH.REQUEST:
      return { ...state, loading: true };
    case BOOKINGS.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case BOOKINGS.FETCH.FAILURE:
      return { ...state, bookingError: payload, bookingLoading: false };

    //
    case BOOKINGS.FETCHOPERATORS.REQUEST:
      return { ...state, bookingLoading: true };
    case BOOKINGS.FETCHOPERATORS.SUCCESS:
      return {
        ...state,
        bookingAvailableRidersData: payload.data.data.result,
        bookingTotalRecord: payload.data.count,
        bookingNextPage: payload.data?.data?.next,
        bookingPreviousPage: payload.data?.data?.previous,
        bookingCurrentPage: payload.data?.currentPage,
        bookingLoading: false,
      };
    case BOOKINGS.FETCHOPERATORS.FAILURE:
      return { ...state, bookingError: payload, bookingLoading: false };

    case BOOKINGS.UPDATE.REQUEST:
      return { ...state, formLoading: true, loading: true };
    case BOOKINGS.UPDATE.SUCCESS:
      return {
        ...state,
        formLoading: false,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
        alreadyAccepted: payload.data.alreadyAccepted,
      };

    case BOOKINGS.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };
    
    case BOOKINGS.FETCHOAVAILABLERIDERS.REQUEST:
      return { ...state, availableRidersLoading: true };
    case BOOKINGS.FETCHOAVAILABLERIDERS.SUCCESS:
      return {
        ...state,
        showAvailableRidersData: payload.data.riders,
        availableRidersLoading: false
      };

    case BOOKINGS.TOGGLE:
      return { ...state, alreadyAccepted: payload, error: null };

    default:
      return state;
  }
};
