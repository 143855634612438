import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Popconfirm,
  DatePicker,
  Modal,
  Image,
  notification,
} from "antd";
import {
  CheckSquareOutlined,
  CloseOutlined,
  EyeOutlined,
} from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// Actions
import {
  fetchAllFranchiseePayables,
  updateFranchiseePayablesStatus,
} from "../redux/actions/allFranchiseePayables";
import moment from "moment/moment";
import dayjs from "dayjs";
const { Content: LayoutContent } = Layout;
const { Search } = Input;

const ApproveFranchiseePayables = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [toformattedDate, setToFormattedDate] = useState(null);
  const [payablesInfoModal, setPayableInfoModal] = useState(false);
  const [viewImage, setViewImage] = useState(false);
  const [imageName, setImageName] = useState(null);
  const [disapprovedData, setDisapprovedData] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { partnerId } = useParams();
  const {
    loading,
    operatorPerformaceData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ allFranchiseePayablesReducer }) => ({
      operatorPerformaceData: allFranchiseePayablesReducer.data,
      totalRecord: allFranchiseePayablesReducer.totalRecord,
      nextPage: allFranchiseePayablesReducer.nextPage,
      previousPage: allFranchiseePayablesReducer.previousPage,
      currentPage: allFranchiseePayablesReducer.currentPage,
      loading: allFranchiseePayablesReducer.loading,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      id: partnerId,
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      dateFilter: formattedDate === "Invalid Date" ? undefined : formattedDate,
      todateFilter:
        toformattedDate === "Invalid Date" ? undefined : toformattedDate,
    };
    if (toformattedDate !== null) {
      dispatch(fetchAllFranchiseePayables(payload));
    }
  }, [delaySearchText, toformattedDate, dispatch, formattedDate]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      id: partnerId,
    };

    dispatch(fetchAllFranchiseePayables(payload));
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Partner Payables</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Form.Item label="From:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={selectedDate}
                  onChange={(date, dateString) => {
                    setSelectedDate(date);
                    setFormattedDate(
                      dayjs(dateString)
                        .startOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item>
              <Form.Item label="To:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={toSelectedDate}
                  onChange={(date, dateString) => {
                    setToSelectedDate(date);
                    setToFormattedDate(
                      dayjs(dateString)
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      id: partnerId,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllFranchiseePayables({
        ...nextPage,
        id: partnerId,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllFranchiseePayables({
        ...previousPage,
        id: partnerId,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllFranchiseePayables({
        ...defaultPage,
        id: partnerId,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllFranchiseePayables({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        id: partnerId,
      })
    );
  };
  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };
  const onCheck = async (payload) => {
    const { data } = await dispatch(updateFranchiseePayablesStatus(payload));
    if (data) {
      openNotification("SUCCESS", data.message);
      setPayableInfoModal(false);
    }
  };

  const viewProofOfTransac = (payload) => {
    setImageName(payload);
    setViewImage(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Button
              onClick={() => viewProofOfTransac(original.imageUrl)}
              size="small"
              type="text"
            >
              <Tooltip title="View proof of transaction">
                <EyeOutlined />
              </Tooltip>
            </Button>

            <Popconfirm
              title="Approve"
              description="Are you sure you want to tag this payables as Approve
              ?"
              onConfirm={() => {
                onCheck({ original, status: "approved" });
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={
                  loading ||
                  original.status === "approved" ||
                  original.status === "disapproved"
                }
                onClick={() => {}}
                size="small"
                type="text"
              >
                <Tooltip title="Verify">
                  <CheckSquareOutlined />
                </Tooltip>
              </Button>
            </Popconfirm>

            <Popconfirm
              title="Disapprove"
              description="Are you sure you want to tag this payables as Disapprove
              ?"
              onConfirm={() => {
                setPayableInfoModal(true);
                setDisapprovedData({
                  original,
                  status: "disapproved",
                });
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={
                  loading ||
                  original.status === "approved" ||
                  original.status === "disapproved"
                }
                size="small"
                type="text"
              >
                <Tooltip title="Disapprove">
                  <CloseOutlined />
                </Tooltip>
              </Button>
            </Popconfirm>
          </>
        ),
        width: 100,
      },
      {
        Header: "Ref No.",
        accessor: (row) => {
          if (row.Reference_number === null || row.Reference_number === "") {
            return "---";
          } else {
            return row.Reference_number;
          }
        },
      },
      {
        Header: "Total Collection",
        accessor: "Daily_Collection",
      },
      {
        Header: "Date",
        accessor: (row) => {
          const utcTime = moment.utc(row.created_at).tz("Asia/Manila");
          return utcTime.format("MMM D, YYYY hh:mm A"); // Format the time as needed
        },
      },
      {
        Header: "Total Payables",
        accessor: "Total_payables",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "User name",
        accessor: "user_name",
      },
      {
        Header: "Note",
        accessor: (row) => {
          if (row.note === null) {
            return "--";
          } else {
            return row.note;
          }
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllFranchiseePayables(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={operatorPerformaceData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          // dateFilters={{ selectedDate }}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <Modal
              okButtonProps={{ disabled: inputValue === "" }}
              open={payablesInfoModal}
              onOk={() => {
                onCheck({ ...disapprovedData, note: inputValue });
              }}
              onCancel={() => {
                setPayableInfoModal(false);
              }}
              title="Leave a reason"
            >
              <div
                style={{
                  marginTop: 15,
                  marginLeft: 5,
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Reason"
                  allowClear
                  value={inputValue}
                  type="secondary"
                  onChange={handleChange}
                />
              </div>
            </Modal>
            <Modal
              open={viewImage}
              onOk={() => {
                setViewImage(false);
                setImageName(null);
              }}
              onCancel={() => {
                setViewImage(false);
                setImageName(null);
              }}
              title="Proof of transaction"
            >
              <div>
                {imageName !== null ? (
                  <img
                    src={`https://groceria-storage.sgp1.cdn.digitaloceanspaces.com/assets/images/proof-of-transactions-platform-fee/${imageName}`}
                    alt="avatar"
                    style={{
                      width: "100%",
                      borderRadius: 10,
                    }}
                  />
                ) : (
                  <h1>
                    --NO PROOF OF TRANSACTION PLEASE CHECK THE REF NUMBER--{" "}
                  </h1>
                )}
              </div>
            </Modal>
          </>
        }
      />
    </>
  );
};

export default ApproveFranchiseePayables;
