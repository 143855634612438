import axiosInstance from "../../helpers/axios.js";

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
} from "../actionTypes.js";

import actions from "../actionTypes";
const { USER } = actions;

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: USER.TOGGLE, payload });
  };

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: USER.TOGGLEVIEW, payload });
  };

export const setForm = (payload) => (dispatch) => {
  dispatch({ type: USER.SET, payload });
};

export const setClient = (payload) => (dispatch) => {
  dispatch({ type: USER.SETCLIENT, payload });
};

export const customUpdate = (payload) => (dispatch) => {
  dispatch({ type: USER.CUSTOMUPDATE, payload }); // payload: {id: 1, key: name, value: 'josua'}
};

// export const login =
//   ({ username, password }) =>
//   async (dispatch) => {
//     try {
//       dispatch({ type: USER_LOGIN_REQUEST });

//       const { data } = await axiosInstance().post("/api/user/login", {
//         username,
//         password,
//       });

//       dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
//       localStorage.setItem("userInfo", JSON.stringify(data));
//       return data;
//     } catch (error) {
//       console.log(">>> login error:", error);
//       dispatch({
//         type: USER_LOGIN_FAIL,
//         payload: error.response ? error.response.data : error.message,
//       });
//     }
//   };

export const onRequestDelete = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: USER.DELETEACCOUNT.REQUEST,
    });

    const response = await axiosInstance().post("/api/account", payload);

    dispatch({ type: USER.DELETEACCOUNT.SUCCESS });
    return response;
  } catch (error) {
    console.log(">>> error saving on request delete", error);
    return error.response;
  }
};

export const login =
  ({ username, password }) =>
  async (dispatch) => {
    try {
      dispatch({ type: USER_LOGIN_REQUEST });

      const { data } = await axiosInstance().post("/api/user/login", {
        username,
        password,
      });

      if (data.role === "admin") {
        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      } else {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: "Account is not an admin",
        });
      }

      try {
        localStorage.setItem("userInfo", JSON.stringify(data));
      } catch (e) {
        console.log(">>> error saving values from userInfo key");
      }
      return data;
    } catch (error) {
      let message = "";
      if (error.response?.status === 401) {
        message = "Invalid Username or Password";
      } else {
        console.log("An error occurred:", error);
        message = "Something Went Wrong";
      }

      dispatch({
        type: USER_LOGIN_FAIL,
        payload: message,
      });
    }
  };

export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
};

export const register = (formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST });

    const { data } = await axiosInstance().post("/api/user", formData);

    if (data) {
      dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const fetch = (payload) => async (dispatch, getState) => {
  dispatch({ type: USER.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
    operationAccountReducer: { authInfo },
  } = getState();

  try {
    let url = "/api/user";
    if (payload !== undefined) {
      const { userType, subAccountParentId, city, approvalStatus } = payload;
      url = `/api/user?userType=${userType}`;

      if (userType && subAccountParentId && approvalStatus) {
        url = `/api/user?userType=${userType}&subAccountParentId=${subAccountParentId}&approvalStatus=${approvalStatus}`;
      }
      if (userType && city) {
        url = `/api/user?userType=${userType}&city=${city}`;
      }
    }

    let token = "";
    if (userInfo !== null) token = userInfo.token;
    if (authInfo !== null) token = authInfo.token;

    const response = await axiosInstance(token).get(url);
    dispatch({ type: USER.FETCH.SUCCESS, payload: response });
  } catch (error) {
    dispatch({
      type: USER.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const fetchSingle = (payload) => async (dispatch, getState) => {
  dispatch({ type: USER.FETCHSINGLE.REQUEST });

  const {
    userLoginReducer: { userInfo },
    operationAccountReducer: { authInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    if (authInfo !== null) token = authInfo.token;

    const response = await axiosInstance(token).get(
      `/api/user/fetchSingle?clientId=${payload.clientId}`
    );
    dispatch({ type: USER.FETCHSINGLE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: USER.FETCHSINGLE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: USER.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      "/api/groceria/partner-merchants",
      {
        ...payload,
        user_id: userInfo?.user_id,
      }
    );

    dispatch({ type: USER.CREATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: USER.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const update = (payload) => async (dispatch, getState) => {
  dispatch({ type: USER.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;

    const response = await axiosInstance(token).put(
      `/api/groceria/partner-merchants/${payload.id}`,
      payload
    );
    dispatch({ type: USER.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: USER.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const fetchReferralLinksAndDefaultRider =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: USER.FETCHREFERRALLINK.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();

    try {
      let token = "";
      if (userInfo !== null) token = userInfo.token;

      const response = await axiosInstance(token).get(
        `/api/admin-account/account-settings/${userInfo.id}`
      );

      dispatch({ type: USER.FETCHREFERRALLINK.SUCCESS, payload: response });
      return response;
    } catch (error) {
      dispatch({
        type: USER.FETCHREFERRALLINK.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
