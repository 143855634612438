import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { LOCALMERCHANTS } = actions;

export const fetchLocalMerchantInMyArea =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: LOCALMERCHANTS.FETCHLOCALMERCHANTINMYAREA.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    let { id } = userInfo;

    try {
      let url = `/api/local-merchants/merchants-in-my-area`;

      if (payload !== undefined) {
        const { page, limit, searchText } = payload;
        url = `/api/local-merchants/merchants-in-my-area?partner_id=${id}&page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/local-merchants/merchants-in-my-area?partner_id=${id}&page=${page}&limit=${limit}&searchText=${searchText}`;
        }
      }

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: LOCALMERCHANTS.FETCHLOCALMERCHANTINMYAREA.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: LOCALMERCHANTS.FETCHLOCALMERCHANTINMYAREA.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const fetchReferredLocalMerchant =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: LOCALMERCHANTS.FETCHREFERREDLOCALMERCHANT.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    let { id } = userInfo;

    try {
      let url = `/api/local-merchants/referred-merchants`;

      if (payload !== undefined) {
        const { page, limit, searchText } = payload;
        url = `/api/local-merchants/referred-merchants?partner_id=${id}&page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/local-merchants/referred-merchants?partner_id=${id}&page=${page}&limit=${limit}&searchText=${searchText}`;
        }
      }

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: LOCALMERCHANTS.FETCHREFERREDLOCALMERCHANT.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: LOCALMERCHANTS.FETCHREFERREDLOCALMERCHANT.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
