import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Form,
  Input,
  Badge,
  Tooltip,
  Button,
  Popconfirm,
  DatePicker,
} from "antd";
import { CheckSquareOutlined } from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import {
  fetchAllOperatorPayables,
  updatePayablesStatus,
} from "../redux/actions/operatorPayables";
import moment from "moment/moment";
import dayjs from "dayjs";
const { Content: LayoutContent } = Layout;
const { Search } = Input;

const OperatorPayables = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [toformattedDate, setToFormattedDate] = useState(null);

  const {
    loading,
    operatorPerformaceData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ operatorPayablesReducer }) => ({
      operatorPerformaceData: operatorPayablesReducer.data,
      totalRecord: operatorPayablesReducer.totalRecord,
      nextPage: operatorPayablesReducer.nextPage,
      previousPage: operatorPayablesReducer.previousPage,
      currentPage: operatorPayablesReducer.currentPage,
      loading: operatorPayablesReducer.loading,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      dateFilter: formattedDate === "Invalid Date" ? undefined : formattedDate,
      todateFilter:
        toformattedDate === "Invalid Date" ? undefined : toformattedDate,
    };
    if (toformattedDate !== null) {
      dispatch(fetchAllOperatorPayables(payload));
    }
  }, [delaySearchText, toformattedDate, dispatch, formattedDate]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllOperatorPayables(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Operator Payables</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <Form.Item label="From:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={selectedDate}
                  onChange={(date, dateString) => {
                    setSelectedDate(date);
                    setFormattedDate(
                      dayjs(dateString)
                        .startOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item>
              <Form.Item label="To:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={toSelectedDate}
                  onChange={(date, dateString) => {
                    setToSelectedDate(date);
                    setToFormattedDate(
                      dayjs(dateString)
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllOperatorPayables({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllOperatorPayables({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllOperatorPayables({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllOperatorPayables({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };
  const onCheck = async (payload) => {
    dispatch(updatePayablesStatus(payload));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            <Popconfirm
              title="Approval Receive"
              description="Are you sure you want to tag this payables as received?"
              onConfirm={() => {
                onCheck(original);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={loading || original.is_approved}
                onClick={() => {}}
                size="small"
                type="text"
              >
                <Tooltip title="Verified">
                  <CheckSquareOutlined />
                </Tooltip>
              </Button>
            </Popconfirm>
          </>
        ),
        width: 100,
      },
      {
        Header: "Name",
        // accessor: (row) => `${row.first_name} ${row.last_name}`,
        accessor: "fullName",
      },
      {
        Header: "Referrence Number",
        accessor: "referrence_number",
      },
      {
        Header: "Collected Delivery Fee",
        accessor: "collected_delivery_fee",
      },
      {
        Header: "Merchant Product Value",
        accessor: "merchant_product_value",
      },
      {
        Header: "Total Amount Deposit",
        accessor: "total_deposit_amount",
      },
      {
        Header: "Date Deposited",
        accessor: "created_at",
        Cell: ({ value }) => {
          const formattedDate = moment
            .utc(value, "YYYY-MM-DDTHH:mm:ss.SSSZ")
            .format("MMMM DD, YYYY hh:mm:ss A");
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "Date Approved",
        accessor: "updated_at",
        Cell: ({ value }) => {
          const formattedDate = value
            ? moment
                .utc(value, "YYYY-MM-DDTHH:mm:ss.SSSZ")
                .format("MMMM DD, YYYY hh:mm:ss A")
            : "--";
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "Status",
        accessor: "is_approved",
        Cell: ({ value }) => {
          return value === true || value === 1 ? (
            <Badge status="success" text="Approved" />
          ) : (
            <Badge status="error" text="Pending" />
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllOperatorPayables(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={operatorPerformaceData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          dateFilters={{ selectedDate }}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default OperatorPayables;
