import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Form, Row, Col, Button, Modal, notification } from "antd";

// Actions
import {
  toggleModal,
  setForm,
  create,
  update,
} from "../../redux/actions/merchantTags";

// Utilities
import renderInput from "../utilities/inputForms";

const MerchantTagsModal = ({ changePassword = false }) => {
  const dispatch = useDispatch();

  const { modal, formLoading, error, form } = useSelector(
    ({ tagsReducer, userLoginReducer }) => ({
      modal: tagsReducer.modal,
      formLoading: tagsReducer.formLoading,
      error: tagsReducer.error,
      form: tagsReducer.form,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  const schema = yup.object().shape({
    tag: yup.string().required("Name is required field"),
  });

  let defaultValues = {
    tag: "",
  };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        const newValues = {
          tag: values.tag,
        };
        const toMatch = omit(form, ["id"]);
        const changesKeys = Object.keys(
          ObjectDiff(toMatch, { ...toMatch, ...newValues })
        );
        if (!changesKeys.length) {
          return openNotification("INFO", "No changes made.");
        }

        const { data } = await dispatch(
          update({
            ...newValues,
            id: form.id,
          })
        );
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      } else {
        const newData = {
          tag: values.tag,
        };
        const { data } = await dispatch(create(newData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        {changePassword ? (
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Input New Password",
                name: "newPassword",
                errors: errors,
                type: "password",
              },
              control
            )}
          </Col>
        ) : (
          <>
            {/* <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Address",
                name: "address",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col> */}

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Tag",
                    name: "tag",
                    errors: errors,
                    required: "true",
                  },
                  control
                )}
              </Col>
            </Row>
          </>
        )}

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default MerchantTagsModal;
