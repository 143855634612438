import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { OPERATORPAYABLES } = actions;

export const fetchAllOperatorPayables =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: OPERATORPAYABLES.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/operator/payables/operator/approval`;
      if (payload !== undefined) {
        const { page, limit, searchText, dateFilter, todateFilter } = payload;
        url = `/api/operator/payables/operator/approval?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/operator/payables/operator/approval?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
        if (page && limit && dateFilter && todateFilter) {
          url = `/api/operator/payables/operator/approval?page=${page}&limit=${limit}&dateFilter=${dateFilter}&todateFilter=${todateFilter}`;
        }
      }
      const response = await axiosInstance(token).get(url);

      dispatch({
        type: OPERATORPAYABLES.FETCH.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: OPERATORPAYABLES.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const updatePayablesStatus = (payload) => async (dispatch, getState) => {
  dispatch({ type: OPERATORPAYABLES.UPDATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).patch(
      `/api/operator/payables/operator/approval/${payload.operator_payables_id}`,
      payload
    );

    dispatch({
      type: OPERATORPAYABLES.UPDATE.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: OPERATORPAYABLES.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
