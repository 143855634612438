import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Layout, Form, Input, Tag } from "antd";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import { fetchReferredLocalMerchant } from "../redux/actions/merchantInMyArea";
import moment from "moment";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const ReferredLocalMerchants = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    referredMerchantData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(({ localMerchantReducer }) => ({
    referredMerchantData: localMerchantReducer.referredMerchantData,
    totalRecord: localMerchantReducer.referredMerchantTotalRecord,
    nextPage: localMerchantReducer.referredMerchantNextPage,
    previousPage: localMerchantReducer.referredMerchantPreviousPage,
    currentPage: localMerchantReducer.referredMerchantCurrentPage,
    loading: localMerchantReducer.loading,
  }));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchReferredLocalMerchant(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchReferredLocalMerchant(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>Referred Merchants</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchReferredLocalMerchant({
        ...nextPage,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchReferredLocalMerchant({
        ...previousPage,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchReferredLocalMerchant({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchReferredLocalMerchant({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Merchant Name",
        accessor: "name",
      },
      {
        Header: "Province",
        accessor: "province",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Barangay",
        accessor: "barangay",
      },
      {
        Header: "Contact Number",
        accessor: "contact_number",
      },
      {
        Header: "Status",
        accessor: (row) => {
          if (row.isActive === 1) {
            return <Tag color="success">Active</Tag>;
          } else {
            return <Tag color="default">Inactive</Tag>;
          }
        },
      },
      {
        Header: "Registration Date",
        accessor: (row) => {
          return moment(row.created_at).format("MMMM D, YYYY");
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchReferredLocalMerchant(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={referredMerchantData} //
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default ReferredLocalMerchants;
