import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { ADMINDASHBOARD } = actions;

export const fetchAdminDashBoardTransactions =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: ADMINDASHBOARD.FETCHTRANSACTION.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/dashboard/admin/transaction/${payload.status}/${payload.timeFrame}`;

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: ADMINDASHBOARD.FETCHTRANSACTION.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: ADMINDASHBOARD.FETCHTRANSACTION.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const fetchAdminDashBoardTopPerformingAreas =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: ADMINDASHBOARD.FETCHTOPPARTNERS.REQUEST });

    let dateFilter = "";

    if (payload.defaultDate === false) {
      dateFilter = `?dateFrom=${payload.fromDate}&dateTo=${payload.toDate}`;
    }

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/dashboard/admin/top-performing-areas${dateFilter}`;

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: ADMINDASHBOARD.FETCHTOPPARTNERS.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: ADMINDASHBOARD.FETCHTOPPARTNERS.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const fetchAdminDashBoardTopPerformingMerchants =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: ADMINDASHBOARD.FETCHTOPMERCHANTS.REQUEST });

    let dateFilter = "";

    if (payload.defaultDate === false) {
      dateFilter = `?dateFrom=${payload.fromDate}&dateTo=${payload.toDate}`;
    }

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/dashboard/admin/top-performing-merchant${dateFilter}`;

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: ADMINDASHBOARD.FETCHTOPMERCHANTS.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: ADMINDASHBOARD.FETCHTOPMERCHANTS.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const fetchAdminDashBoardCustomerGrowth =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: ADMINDASHBOARD.FETCHCUSTOMERGROWTH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/dashboard/admin/customer-growth/${payload.timeFrame}`;

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: ADMINDASHBOARD.FETCHCUSTOMERGROWTH.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: ADMINDASHBOARD.FETCHCUSTOMERGROWTH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const fetchAdminDashBoardTopCity =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: ADMINDASHBOARD.FETCHTOPAREAS.REQUEST });

    let dateFilter = "";

    if (payload.defaultDate === false) {
      dateFilter = `?dateFrom=${payload.fromDate}&dateTo=${payload.toDate}`;
    }

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      let url = `/api/dashboard/admin/top-performing-city${dateFilter}`;

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: ADMINDASHBOARD.FETCHTOPAREAS.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: ADMINDASHBOARD.FETCHTOPAREAS.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
