import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
//

import { Layout, Form, Input } from "antd";

// Components

import ServerSideTable from "../table/serverSideTable";

import { fetch, updateMenuStatus } from "../../redux/actions/merchantMenu";
//Actions
import { fetchSubOpsReferredRider } from "../../redux/actions/subOperatorReferralRider";

const { Content: LayoutContent } = Layout;
const { Search } = Input;
const SubOperatorAffiliatesTable = ({ merchantId }) => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [checkedMenu, setCheckedMenu] = useState([]);
  const {
    loading,
    subOpsreferredRiderData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(({ subOpsReferredRiderReducer }) => ({
    subOpsreferredRiderData: subOpsReferredRiderReducer.subOpsreferredRiderData,
    totalRecord: subOpsReferredRiderReducer.subOpsreferredRiderTotalRecord,
    nextPage: subOpsReferredRiderReducer.subOpsreferredRiderNextPage,
    previousPage: subOpsReferredRiderReducer.subOpsreferredRiderPreviousPage,
    currentPage: subOpsReferredRiderReducer.subOpsreferredRiderCurrentPage,
    loading: subOpsReferredRiderReducer.loading,
  }));

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      role: "affiliate",
    };

    dispatch(fetchSubOpsReferredRider(payload));
  };

  // const checkBox = (item) => {
  //   setCheckedMenu((prevCheckedMenu) => {
  //     const isItemChecked = prevCheckedMenu.some(
  //       (checkedItem) => checkedItem === item
  //     );
  //     if (!isItemChecked) {
  //       return [...prevCheckedMenu, item];
  //     } else {
  //       return prevCheckedMenu.filter((checkedItem) => checkedItem !== item);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   setSelectedMenus(checkedMenu);
  // }, [checkedMenu]);

  const customFilters = () => {
    return (
      <div className="mb-2">
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      userId: merchantId,
      role: "affiliate",
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        ...nextPage,
        userId: merchantId,
        role: "affiliate",
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        ...previousPage,
        userId: merchantId,
        role: "affiliate",
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        ...defaultPage,
        userId: merchantId,
        role: "affiliate",
      })
    );
  };

  useEffect(() => {
    dispatch(fetchSubOpsReferredRider(defaultPage));
  }, [dispatch, defaultPage]);

  const onClickLastPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        userId: merchantId,
        role: "affiliate",
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (row) => `${row.first_name} ${row.last_name}`,
        width: 100,
      },
      {
        Header: "Contact Number",
        accessor: "contact_number",
      },
      {
        Header: "Email Address",
        accessor: "email_address",
      },
      {
        Header: "Registration Date",
        accessor: (row) => {
          return moment(row.created_at).format("MMMM D, YYYY");
        },
      },
    ],
    [checkedMenu]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      role: "affiliate",
      searchText: delaySearchText,
    };
    dispatch(fetchSubOpsReferredRider(payload));
  }, [delaySearchText, dispatch]);
  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={subOpsreferredRiderData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          extra={
            [
              // <Button key="1" onClick={onDuplicate} className="mr-2">
              //   <CopyOutlined /> Duplicate menu
              // </Button>,
            ]
          }
        />
      </LayoutContent>
    );
  };
  return <>{renderContent()}</>;
};

export default SubOperatorAffiliatesTable;
