import React, { useEffect, useMemo, useState,  } from "react";
import { useDispatch, useSelector } from "react-redux";
//
import { Layout, Form, Input } from "antd";
import moment from "moment";

// Components
import ServerSideTable from "../table/serverSideTable";
//Actions
import { fetchSubOpsReferredRider } from "../../redux/actions/subOperatorReferralRider";

const { Content: LayoutContent } = Layout;
const { Search } = Input;
const SubOperatorCustomerTable = ({
  merchantId,
}) => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [checkedMenu, setCheckedMenu] = useState([]);
  const {
    loading,
    subOpsreferredRiderData,
    totalRecord,
    nextPage,
    previousPage, 
    currentPage,
  } = useSelector(({ subOpsReferredRiderReducer }) => ({
    subOpsreferredRiderData: subOpsReferredRiderReducer.subOpsreferredRiderData,
    totalRecord: subOpsReferredRiderReducer.subOpsreferredRiderTotalRecord,
    nextPage: subOpsReferredRiderReducer.subOpsreferredRiderNextPage,
    previousPage: subOpsReferredRiderReducer.subOpsreferredRiderPreviousPage,
    currentPage: subOpsReferredRiderReducer.subOpsreferredRiderCurrentPage,
    loading: subOpsReferredRiderReducer.loading,
  }));


  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      userId: merchantId,
      role: "customer",
    };

    dispatch(fetchSubOpsReferredRider(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      role: "customer",
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        ...nextPage,
        role: "customer",
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        ...previousPage,
        role: "customer",
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        ...defaultPage,
        role: "customer",
      })
    );
  };

  useEffect(() => {
    dispatch(fetchSubOpsReferredRider(defaultPage));
  }, [dispatch, defaultPage]);

  const onClickLastPage = () => {
    dispatch(
      fetchSubOpsReferredRider({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        role: "customer",
      })
    );
  };
  const columns = useMemo(
    () => [
        {
          Header: "Customer Name",
          accessor: "fullName",
        },
        {
          Header: "Municipality",
          accessor: (row) => {
            if (row.municipality === null || row.municipality === "null") {
              return "--Not yet set--";
            } else {
              return row.municipality;
            }
          },
        },
        {
          Header: "Province",
          accessor: (row) => {
            if (row.province === null || row.province === "null") {
              return "--Not yet set--";
            } else {
              return row.province;
            }
          },
        },
        {
          Header: "Account Creation",
          accessor: (row) => {
            const formattedDate = moment(row.creation_date).format(
              "MMMM Do YYYY, h:mm a"
            );
            return formattedDate;
          },
        },
    ],
    [checkedMenu]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      userId: merchantId,
      searchText: delaySearchText,
    };
    dispatch(fetchSubOpsReferredRider(payload));
  }, [delaySearchText, dispatch, merchantId]);
  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={subOpsreferredRiderData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          extra={
            [
              // <Button key="1" onClick={onDuplicate} className="mr-2">
              //   <CopyOutlined /> Duplicate menu
              // </Button>,
            ]
          }
        />
      </LayoutContent>
    );
  };
  return <>{renderContent()}</>;
};

export default SubOperatorCustomerTable;
