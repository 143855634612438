import React from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col, Statistic, Radio, Spin } from "antd";
import { Pie, Area, Bar } from "@ant-design/plots";
import { isEqual } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { FundProjectionScreenOutlined } from "@ant-design/icons";
import Wrapper from "../components/wrapper";
const partnerPushNotif = () => {
  const Content = () => {
    return (
      <div>
        <div style={{ justifyContent: "center", marginTop: 45 }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FundProjectionScreenOutlined
              style={{ fontSize: 76, color: "gray" }}
            />
          </div>
          <h1 style={{ textAlign: "center", fontSize: 45, color: "gray" }}>
            THIS PAGE IS
          </h1>
          <h1 style={{ textAlign: "center", fontSize: 45, color: "gray" }}>
            UNDER CONSTRUCTION
          </h1>
        </div>
      </div>
    );
  };

  return <Wrapper content={<Content />} />;
};

export default partnerPushNotif;
