import actions from "../actionTypes";
const { MERCHANTS } = actions;

const userInitialState = {
  modal: false,
  modalView: false,
  modalAddMenu: false,
  formLoading: false,
  data: [],
  form: {},
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
  menu: [],
  tags: [],
  modalFeaturedImage: false,
};
export const merchantsReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MERCHANTS.TOGGLE:
      return { ...state, modal: payload, error: null };
    case MERCHANTS.CLEARERROR:
      return { ...state, error: null };
    case MERCHANTS.TOGGLEVIEW:
      return { ...state, modalView: payload, error: null };
    case MERCHANTS.TOGGLEMENUMODAL:
      return { ...state, modalAddMenu: payload, error: null };
    case MERCHANTS.SET:
      return { ...state, form: payload };

    // case MERCHANTS.SETMENU:
    //   return { ...state, menu: payload };

    case MERCHANTS.CREATE.REQUEST:
      return { ...state, formLoading: true };

    case MERCHANTS.CREATE.SUCCESS:
      const newData = payload;

      return {
        ...state,
        formLoading: false,
        data: [...state.data, newData.data.data],
      };
    case MERCHANTS.CREATE.FAILURE:
      return { ...state, formLoading: false, error: payload.message };

    case MERCHANTS.FETCH.REQUEST:
      return { ...state, loading: true };
    case MERCHANTS.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case MERCHANTS.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case MERCHANTS.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case MERCHANTS.UPDATE.SUCCESS:
      return {
        ...state,
        formLoading: false,
        form: payload.data.data,
        data: state.data.map((d) =>
          d.id === payload.data.data.id ? payload.data.data : d
        ),
      };

    case MERCHANTS.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    case MERCHANTS.SOFTDELETION.REQUEST:
      return { ...state, formLoading: true };
    case MERCHANTS.SOFTDELETION.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        formLoading: false,
      };

    case MERCHANTS.SOFTDELETION.FAILURE:
      return { ...state, formLoading: false, error: payload };

    // TAGS
    case MERCHANTS.FETCHTAGS.REQUEST:
      return { ...state, loading: true };
    case MERCHANTS.FETCHTAGS.SUCCESS:
      const tags = payload.data.tags;
      const restructuredTags = tags.map((tag) => {
        return { label: tag.tag, value: tag.id };
      });
      return {
        ...state,
        tags: restructuredTags,
      };
    case MERCHANTS.FETCHTAGS.FAILURE:
      return { ...state, error: payload, loading: false };

    case MERCHANTS.TOGGLEFEATUREDIMAGE:
      return { ...state, modalFeaturedImage: payload, error: null };

    // create image
    case MERCHANTS.CREATEFEATUREDIMAGE.REQUEST:
      return { ...state, formLoading: true };

    case MERCHANTS.CREATEFEATUREDIMAGE.SUCCESS:
      return {
        ...state,
        formLoading: false,
      };
    case MERCHANTS.CREATEFEATUREDIMAGE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
