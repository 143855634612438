import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { MERCHANTTAGS } = actions;

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTTAGS.TOGGLEVIEW, payload });
  };

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: MERCHANTTAGS.TOGGLE, payload });
  };
export const setForm = (payload) => (dispatch) => {
  dispatch({ type: MERCHANTTAGS.SET, payload });
};

export const fetch = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTTAGS.FETCH.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const { page, limit } = payload;

    let url = `/api/merchant-tags?page=${page}&limit=${limit}`;

    const response = await axiosInstance(token).get(url);
    dispatch({
      type: MERCHANTTAGS.FETCH.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTTAGS.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const create = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTTAGS.CREATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();

  try {
    let token = "";
    if (userInfo !== null) token = userInfo.token;
    const response = await axiosInstance(token).post(
      `/api/merchant-tags`,
      payload
    );
    dispatch({ type: MERCHANTTAGS.CREATE.SUCCESS, payload: response });

    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTTAGS.CREATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
    return error;
  }
};

export const update = (payload) => async (dispatch, getState) => {
  dispatch({ type: MERCHANTTAGS.UPDATE.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const response = await axiosInstance(token).patch(
      `/api/merchant-tags`,
      payload
    );

    dispatch({ type: MERCHANTTAGS.UPDATE.SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({
      type: MERCHANTTAGS.UPDATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
