import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isEqual } from "lodash";
import { Layout, Button, Tooltip, Form, Input, Switch, Popconfirm } from "antd";
import {
  FormOutlined,
  PlusOutlined,
  UploadOutlined,
  ShopOutlined,
  LeftOutlined,
  CloseOutlined,
} from "@ant-design/icons";

// import { FormOutlined, PlusOutlined } from "@ant-design/icons";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import MerchantMenuModal from "../components/merchantCategoryMenu/modal";

// Actions
// import { fetchProducts } from "../redux/actions/partnerStores";

// import BulkUploadModal from "../components/partnerStoreProducts/bulkUploadModal";
import { Link } from "react-router-dom";
import {
  toggleModal,
  setForm,
  fetch,
  updateCategoryStatus,
  softDeletionCategory,
  //   toggleImportModal,
} from "../redux/actions/merchantCategoryMenu";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const MerchantCategoryMenu = () => {
  const dispatch = useDispatch();
  const { merchantId, categorryId, merchantName } = useParams();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const history = useHistory();
  const {
    loading,
    formLoading,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    data,
    userInfo,
  } = useSelector(
    ({ CategoryReducer, userLoginReducer }) => ({
      data: CategoryReducer.data,
      loading: CategoryReducer.loading,
      formLoading: CategoryReducer.formLoading,
      totalRecord: CategoryReducer.totalRecord,
      nextPage: CategoryReducer.nextPage,
      previousPage: CategoryReducer.previousPage,
      currentPage: CategoryReducer.currentPage,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      userId: merchantId,
      searchText: delaySearchText,
    };
    dispatch(fetch(payload));
  }, [delaySearchText, dispatch, merchantId]);
  const onAdd = () => {
    dispatch(toggleModal());
  };

  //   const onClickBulkUpload = () => {
  //     dispatch(toggleImportModal());
  //   };

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetch(payload));
  };

  const onEdit = useCallback(
    ({ data }) => {
      dispatch(setForm(data));
      dispatch(toggleModal());
    },
    [dispatch]
  );

  // const onView = ({ data }) => {
  //   setViewMerchantsDetails(true);
  //   dispatch(setForm(data));
  //   dispatch(toggleModal());
  // };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "baseline",
            marginLeft: 10,
          }}
        >
          <LeftOutlined
            onClick={goBack}
            style={{
              fontSize: 20,
              color: "gray",
            }}
          />
          <h1 onClick={goBack} style={{ fontSize: 23 }}>
            {merchantName?.toUpperCase()}
          </h1>
        </div>
        <h1>Merchant Categories Menu</h1>
        {/* <h1>Merchant Menu - {`${city} ${province}`}</h1> */}
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  //   const extra = () => {
  //     const addButton = (
  //       <Button key="1" onClick={onAdd} className="mr-2">
  //         <PlusOutlined /> Add
  //       </Button>
  //     );

  //     return [addButton];
  //   };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      userId: merchantId,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetch({
        ...nextPage,
        userId: merchantId,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetch({
        ...previousPage,
        userId: merchantId,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetch({
        ...defaultPage,
        userId: merchantId,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetch({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        userId: merchantId,
      })
    );
  };

  const goBack = () => {
    history.goBack();
  };

  const onUpdateCategoryStatus = (isActive, categoryId) => {
    dispatch(updateCategoryStatus({ isActive, categoryId }));
  };

  const softDelete = (payload) => {
    dispatch(softDeletionCategory(payload));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            {/* <Link to={`/merchantMenu/${merchantId}/${original.id}`}>
              <Button disabled={formLoading} size="small" type="text">
                <Tooltip title="View Menu">
                  <ShopOutlined />
                </Tooltip>
              </Button>
            </Link> */}
            {/* {userInfo.role === "operator_admin" && (
              <Popconfirm
                title="Delete a Category"
                description="If you delete this Category, The menu items that have this Category will also be deteled."
                onConfirm={() => {
                  softDelete({ data: original, merchantId: merchantId });
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button disabled={formLoading} size="small" type="text">
                  <Tooltip title="Delete">
                    <CloseOutlined />
                  </Tooltip>
                </Button>
              </Popconfirm>
            )} */}
            <Button
              disabled={formLoading}
              onClick={() => onEdit({ data: original })}
              size="small"
              type="text"
            >
              <Tooltip title="Edit">
                <FormOutlined />
              </Tooltip>
            </Button>
            <Switch
              size="small"
              defaultChecked={original.isActive}
              onChange={(checked) => {
                onUpdateCategoryStatus(checked, original.id);
              }}
            />
          </>
        ),
        width: 100,
      },
      {
        Header: "Title",
        accessor: "title",
      },
    ],
    [formLoading, onEdit]
  );

  useEffect(() => {
    dispatch(fetch(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={data}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          extra={[
            <Button key="1" onClick={onAdd} className="mr-2">
              <PlusOutlined /> Add
            </Button>,
          ]}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <MerchantMenuModal />
          </>
        }
      />
    </>
  );
};

export default MerchantCategoryMenu;
