import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Form, Input } from "antd";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import { fetchAllAccountsReceivable } from "../redux/actions/accountReceivable";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const AccountsReceivable = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    accountReceivableData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ accountsReceivableReducer }) => ({
      accountReceivableData: accountsReceivableReducer.data,
      totalRecord: accountsReceivableReducer.totalRecord,
      nextPage: accountsReceivableReducer.nextPage,
      previousPage: accountsReceivableReducer.previousPage,
      currentPage: accountsReceivableReducer.currentPage,
      loading: accountsReceivableReducer.loading,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAllAccountsReceivable(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllAccountsReceivable(payload));
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAllAccountsReceivable({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllAccountsReceivable({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllAccountsReceivable({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllAccountsReceivable({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (row) => `${row.operator_fname} ${row.operator_lname}`,
      },
      {
        Header: "Mobile number",
        accessor: "operator_contact",
      },
      {
        Header: "Date",
        accessor: "ordersDate",
      },
      {
        Header: "Collected Delivery Fee",
        accessor: "collectedShippingFee",
      },
      {
        Header: "Merchant Product Value",
        accessor: "merchantProductValue",
      },
      {
        Header: "Total amount to deposit",
        accessor: (row) => {
          return (
            parseFloat(row.collectedShippingFee) +
            parseFloat(row.merchantProductValue)
          ).toFixed(2);
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllAccountsReceivable(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={accountReceivableData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default AccountsReceivable;
