import actions from "../actionTypes";
const { APPEARNINGWITHDRAW } = actions;

const userInitialState = {
  data: [],
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
};

export const appEarningWithdrawalReducer = (
  state = userInitialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case APPEARNINGWITHDRAW.FETCH.REQUEST:
      return { ...state, loading: true };
    case APPEARNINGWITHDRAW.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case APPEARNINGWITHDRAW.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case APPEARNINGWITHDRAW.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case APPEARNINGWITHDRAW.UPDATE.SUCCESS:
      return {
        ...state,
        formLoading: false,
        form: payload.data.data,
        data: state.data.map((d) =>
          d.id === payload.data.data[0].id ? payload.data.data[0] : d
        ),
      };

    case APPEARNINGWITHDRAW.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
