import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col, Statistic, Radio, Spin, Button } from "antd";
import { Pie, Area, Bar } from "@ant-design/plots";
import { isEqual } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { FundProjectionScreenOutlined, WarningOutlined } from "@ant-design/icons";
import { useHistory  } from 'react-router-dom';

// Components
import Wrapper from "../components/wrapper";
// import config from "../space-config/config";
// // Utils
// import axiosInstance from "../helpers/axios";
// import axiosInstance from "../helpers/axios";

const { Content: LayoutContent } = Layout;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 44,
    }}
    spin
  />
);

const Dashboard = () => {
  const history = useHistory();
  const [statusDateRangeKey, setStatusDateRangeKey] = useState("1d");
  const [statusStatData, setStatusStatData] = useState([]);
  const [statusStatDataLoading, setStatusStatDataLoading] = useState(false);
  const [totalStatusStat, setTotalStatusStat] = useState([]);

  const [volumeGrowthDateRangeKey, setVolumeGrowthDateRangeKey] =
    useState("7d");
  const [volumeGrowthData, setVolumeGrowthData] = useState([]);
  const [volumeGrowthDataLoading, setVolumeGrowthDataLoading] = useState(false);

  const [topCitiesDateRangeKey, settopCitiesDateRangeKey] = useState("1d");
  const [topCitiesData, settopCitiesData] = useState([]);
  const [topCitiesDataLoading, settopCitiesDataLoading] = useState(false);

  const [shippingFeeDateRangeKey, setShippingFeeDateRangeKey] = useState("1d");
  const [shippingFeeData, setShippingFeeData] = useState([]);
  const [shippingFeeDataLoading, setShippingFeeDataLoading] = useState(false);

  const { userInfo } = useSelector(
    ({ userLoginReducer }) => ({
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const pieConfig = {
    appendPadding: 10,
    data: statusStatData,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    height: 288,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: `total: ${totalStatusStat}`,
      },
    },
  };
  const areaConfig = {
    data: volumeGrowthData,
    xField: "date",
    yField: "rate",
    height: 288,
    xAxis: {
      tickCount: 5,
    },
    animation: true,
    slider: {
      trendCfg: {
        isArea: true,
      },
    },
  };
  const barConfig = {
    data: topCitiesData,
    xField: "value",
    yField: "city",
    seriesField: "city",
    height: 288,
    legend: {
      position: "top-left",
    },
  };

  // const [assetUrl, setAssetUrl] = useState("");

  // useEffect(() => {
  //   const spaceName = "groceria-storage";
  //   const objectKey = "assets/images/merchants/New Project (4).png";

  //   const cdnBaseUrl = `https://${spaceName}.sgp1.cdn.digitaloceanspaces.com`;
  //   const assetUrl = `${cdnBaseUrl}/${objectKey}`;

  //   setAssetUrl(assetUrl);
  // }, []);

  const handleNavigateGetStarted = (e) => {
    history.push("/getstarted");
  }

  const Content = () => {
    return (
      <LayoutContent>
        <div style={{ justifyContent: "center", marginTop: 45 }}>
          <div className="mt-12 shadow-md p-8 rounded-2xl">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FundProjectionScreenOutlined
                style={{ fontSize: 76, color: "gray" }}
              />
            </div>
            <h1 style={{ textAlign: "center", fontSize: 30, color: "gray" }}>
              Dashboard Analytics cominng soon!
            </h1>
          </div>

          <div className="mt-12 shadow-md p-8 rounded-2xl">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <WarningOutlined style={{ fontSize: 76, color: "orange" }} />
            </div>
            <h1 style={{ textAlign: "center", fontSize: 30, color: "gray", marginTop: 12 }}>
            Must read!
            </h1>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  color: "gray",
                  width: "50%",
                }}
              >
              Please go to the "Get Started" section to learn more about this platform before proceeding to navigate this system or click the button below
              </p>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12 }}>
              <Button
                style={{
                  fontWeight: "bold",
                  color: "white",
                  backgroundColor: "#3CB371",
                }}
                size={"large"}
                onClick={handleNavigateGetStarted}
              >
                Learn more about Dory Platform
              </Button>
            </div>
          </div>
        </div>
      </LayoutContent>
    );
  };

  return <Wrapper content={<Content />} />;
};

export default Dashboard;
