import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Layout, Form, Input } from "antd";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import { fetchBookingsAvailableOperators } from "../redux/actions/bookings";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const BookingAvailableRiders = () => {
  const dispatch = useDispatch();
  const { deliveryAddressId } = useParams();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    bookingAvailableRidersData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
  } = useSelector(
    ({ bookingsReducer }) => ({
      bookingAvailableRidersData: bookingsReducer.bookingAvailableRidersData,
      totalRecord: bookingsReducer.bookingTotalRecord,
      nextPage: bookingsReducer.bookingNextPage,
      previousPage: bookingsReducer.bookingPreviousPage,
      currentPage: bookingsReducer.bookingCurrentPage,
      loading: bookingsReducer.bookingLoading,
    }),
    isEqual
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      deliveryAddressId,
    };
    dispatch(fetchBookingsAvailableOperators(payload));
  }, [delaySearchText, dispatch, deliveryAddressId]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      deliveryAddressId,
    };

    dispatch(fetchBookingsAvailableOperators(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1>Booking Available Riders</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          {/* <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="Enter order number or waybill number"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="small"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form> */}
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      deliveryAddressId,
    };
  }, [deliveryAddressId]);

  const onClickNextPage = () => {
    dispatch(
      fetchBookingsAvailableOperators({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchBookingsAvailableOperators({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchBookingsAvailableOperators({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchBookingsAvailableOperators({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        deliveryAddressId,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Operator Name",
        // accessor: (row) => `${row.first_name} ${row.last_name}`,
        accessor: "fullName",
      },
      {
        Header: "Mobile Number",
        accessor: "contact_number",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Province",
        accessor: "province",
      },
      {
        Header: "Address",
        accessor: "address",
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchBookingsAvailableOperators(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={bookingAvailableRidersData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default BookingAvailableRiders;
