import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { NATIONALMERCHANTCATEGORY } = actions;

export const toggleModalView =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: NATIONALMERCHANTCATEGORY.TOGGLEVIEW, payload });
  };

export const toggleModal =
  (payload = true) =>
  async (dispatch) => {
    dispatch({ type: NATIONALMERCHANTCATEGORY.TOGGLE, payload });
  };
export const setForm = (payload) => (dispatch) => {
  dispatch({ type: NATIONALMERCHANTCATEGORY.SET, payload });
};

export const fetchNationalMerchantCategories =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: NATIONALMERCHANTCATEGORY.FETCH.REQUEST });
    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      const { page, limit, searchText } = payload;
      let url = `/api/national-merchants/fetch-categories/${payload.userId}`;
      if (payload !== undefined) {
        url = `/api/national-merchants/fetch-categories/${payload.userId}?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/national-merchants/fetch-categories/${payload.userId}?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
      }

      const response = await axiosInstance(token).get(url);
      console.log(response);
      dispatch({
        type: NATIONALMERCHANTCATEGORY.FETCH.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: NATIONALMERCHANTCATEGORY.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const createNationalMerchantCategories =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: NATIONALMERCHANTCATEGORY.CREATE.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();

    try {
      let token = "";
      if (userInfo !== null) token = userInfo.token;
      const response = await axiosInstance(token).post(
        `/api/national-merchants/create-categories`,
        payload
      );
      dispatch({
        type: NATIONALMERCHANTCATEGORY.CREATE.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: NATIONALMERCHANTCATEGORY.CREATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
      return error;
    }
  };

export const updateNationalMerchantCategories =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: NATIONALMERCHANTCATEGORY.UPDATE.REQUEST });
    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    try {
      const response = await axiosInstance(token).put(
        `/api/national-merchants/update-categories`,
        payload
      );

      dispatch({
        type: NATIONALMERCHANTCATEGORY.UPDATE.SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: NATIONALMERCHANTCATEGORY.UPDATE.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const updateCategoryStatus = (payload) => async (dispatch, getState) => {
  dispatch({ type: NATIONALMERCHANTCATEGORY.UPDATESTATUS.REQUEST });
  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    const status = payload.isActive ? "activate" : "deactivate";

    const response = await axiosInstance(token).put(
      `/api/national-merchants/update-menu-categories-status/${status}/${payload.categoryId}`,
      payload
    );

    dispatch({
      type: NATIONALMERCHANTCATEGORY.UPDATESTATUS.SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: NATIONALMERCHANTCATEGORY.UPDATESTATUS.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
