import React, { useMemo } from "react";
import { Button, Input, Alert, Skeleton, Form } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import TableScrollbar from "react-table-scrollbar";
import { DownSquareOutlined, UpSquareOutlined } from "@ant-design/icons";

const { Search } = Input;

const ServerSideTable = ({
  title,
  columns,
  data,
  loading,
  options = { onAdd: null },
  style = {},
  extra = [],
  search = true,
  pagination = true,
  alert = { value: false, message: "", type: "" },
  customFilters = false,
  serverSidePagination = {},
}) => {
  const tableInstance = useTable(
    { columns, data, manualPagination: true },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter } = state;

  const isServerSide = Object.keys(serverSidePagination).length ? true : false;

  const totalRecord = useMemo(() => {
    if (isServerSide) {
      return serverSidePagination.totalRecord;
    } else {
      return data.length.toLocaleString();
    }
  }, [serverSidePagination]);

  return (
    <>
      <div className="site-page-header-ghost-wrapper">
        {!customFilters ? (
          <>
            <PageHeader
              ghost={false}
              title={title}
              subTitle={`(showing total record of ${totalRecord})`}
            ></PageHeader>
            <div
              className="p-4 mb-2 flex justify-between"
              style={{ backgroundColor: "#fafafa" }}
            >
              <Form layout="inline">
                <Form.Item label="Search">
                  {search && (
                    <Search
                      placeholder="input search text"
                      allowClear
                      style={{ width: "20VW" }}
                      value={globalFilter || ""}
                      onChange={(e) => setGlobalFilter(e.target.value)}
                      type="secondary"
                      size="small"
                    />
                  )}
                </Form.Item>
              </Form>
              <div>
                {options.onAdd && (
                  <Button key="1" onClick={options.onAdd}>
                    ADD
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-4">
              <div className="mb-3">
                <div className="text-lg font-semibold">{title}</div>
              </div>
              <div className="flex justify-end mb-2 col-span-3">{extra}</div>
            </div>

            {customFilters()}
          </>
        )}
      </div>

      {alert.value && (
        <div className="mb-2">
          <Alert message={alert.message} type={alert.type} showIcon />
        </div>
      )}

      {data.length === 0 ? (
        <Alert message="No data found!" type="info" showIcon />
      ) : (
        <>
          <div className="ant-table  ant-table-bordered">
            <div className="ant-table-container">
              <div
                className="ant-table-content"
                style={{ borderBottom: "1px solid #f0f0f0" }}
              >
                <TableScrollbar rows={15}>
                  <table
                    {...getTableProps()}
                    style={{
                      ...style,
                      tableLayout: "auto",
                    }}
                  >
                    <thead className="ant-table-thead bg-white border-b">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              className="ant-table-cell text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <DownSquareOutlined
                                      style={{
                                        marginLeft: "5px",
                                        color: "#6B7280",
                                      }}
                                    />
                                  ) : (
                                    <UpSquareOutlined
                                      style={{
                                        marginLeft: "5px",
                                        color: "#6B7280",
                                      }}
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="ant-table-tbody" {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            className="ant-table-row ant-table-row-level-0 bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  className="ant-table-cell text-sm text-gray-900 px-6 py-4 whitespace-nowrap"
                                  {...cell.getCellProps()}
                                >
                                  {loading ? (
                                    <Skeleton.Button
                                      size="small"
                                      active={true}
                                    />
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </TableScrollbar>
              </div>
            </div>

            {pagination && (
              <div className="grid grid-cols-2 gap-4 mt-3">
                <div className="text-left">
                  <span>
                    Page{" "}
                    <strong>
                      {serverSidePagination.currentPage?.page} of{" "}
                      {Math.ceil(
                        totalRecord / serverSidePagination.currentPage?.limit
                      )}
                    </strong>
                    <span className="mx-2">|</span>
                  </span>
                  <span>
                    Total<strong> {totalRecord} </strong>
                  </span>
                </div>

                <div className="text-right">
                  <Button
                    onClick={serverSidePagination.onClickFirstPage}
                    disabled={serverSidePagination.currentPage?.page === 1}
                  >
                    {"<<"}
                  </Button>{" "}
                  <Button
                    onClick={serverSidePagination.onClickPreviousPage}
                    disabled={serverSidePagination.previousPage === undefined}
                  >
                    Previous
                  </Button>{" "}
                  <Button
                    onClick={serverSidePagination.onClickNextPage}
                    disabled={serverSidePagination.nextPage === undefined}
                  >
                    Next
                  </Button>{" "}
                  <Button
                    onClick={serverSidePagination.onClickLastPage}
                    disabled={
                      serverSidePagination.currentPage?.page *
                        serverSidePagination.currentPage?.limit >=
                      totalRecord
                    }
                  >
                    {">>"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ServerSideTable;
