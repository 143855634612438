import actions from "../actionTypes";
const { NATIONALMERCHANTMENU } = actions;

const userInitialState = {
  modal: false,
  modalView: false,
  formLoading: false,
  data: [],
  form: {},
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
  modalDuplicate: false,
};

export const nationalMerchantMenuReducer = (
  state = userInitialState,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case NATIONALMERCHANTMENU.CLEARERROR:
      return { ...state, error: null };
    case NATIONALMERCHANTMENU.TOGGLE:
      return { ...state, modal: payload, error: null };
    case NATIONALMERCHANTMENU.TOGGLEVIEW:
      return { ...state, modalView: payload, error: null };
    case NATIONALMERCHANTMENU.TOGGLEDUPLICATE:
      return { ...state, modalDuplicate: payload, error: null };

    case NATIONALMERCHANTMENU.SET:
      return { ...state, form: payload };

    case NATIONALMERCHANTMENU.CREATE.REQUEST:
      return { ...state, formLoading: true };

    case NATIONALMERCHANTMENU.CREATE.SUCCESS:
      const newData = payload;
      return {
        ...state,
        formLoading: false,
        data: [...state.data, newData.data.data[0]],
      };
    case NATIONALMERCHANTMENU.CREATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    case NATIONALMERCHANTMENU.CREATEMERCHANT.REQUEST:
      return { ...state, formLoading: true, autoCreateLoading: true };

    case NATIONALMERCHANTMENU.CREATEMERCHANT.SUCCESS:
      // const newData = payload;
      return {
        ...state,
        formLoading: false,
        autoCreateLoading: false,
        // data: [...state.data, newData.data.data[0]],
      };
    case NATIONALMERCHANTMENU.CREATEMERCHANT.FAILURE:
      return { ...state, formLoading: false, error: payload };

    case NATIONALMERCHANTMENU.FETCH.REQUEST:
      return { ...state, loading: true };
    case NATIONALMERCHANTMENU.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
      };
    case NATIONALMERCHANTMENU.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case NATIONALMERCHANTMENU.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case NATIONALMERCHANTMENU.UPDATE.SUCCESS:
      return {
        ...state,
        formLoading: false,
        form: payload.data.data,
        data: state.data.map((d) =>
          d.id === payload.data.data.id ? payload.data.data : d
        ),
      };

    case NATIONALMERCHANTMENU.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    // case NATIONALMERCHANTMENU.SOFTDELETION.REQUEST:
    //   return { ...state, formLoading: true };
    // case NATIONALMERCHANTMENU.SOFTDELETION.SUCCESS:
    //   return {
    //     ...state,
    //     data: payload.data.data.result,
    //     totalRecord: payload.data.count,
    //     nextPage: payload.data?.data?.next,
    //     previousPage: payload.data?.data?.previous,
    //     currentPage: payload.data?.currentPage,
    //     formLoading: false,
    //   };

    // case NATIONALMERCHANTMENU.SOFTDELETION.FAILURE:
    //   return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
