import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { REPORTEDBOOKINGS } = actions;

export const fetchAllReportedBookings =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: REPORTEDBOOKINGS.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token, id } = userInfo;

    try {
      let url = `/api/bookings/reported-bookings`;

      if (payload !== undefined) {
        const { page, limit, searchText, filterValue } = payload;
        url = `/api/bookings/reported-bookings?page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/bookings/reported-bookings?page=${page}&limit=${limit}&searchText=${searchText}`;
        }
        if (page && limit && filterValue) {
          url = `/api/bookings/reported-bookings?page=${page}&limit=${limit}&filterValue=${filterValue}`;
        }
      }

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: REPORTEDBOOKINGS.FETCH.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: REPORTEDBOOKINGS.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
