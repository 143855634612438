import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Form, Col, Button, Modal, notification } from "antd";

// Actions
import {
  toggleModal,
  setForm,
  updateMerchantApprovalStatus,
} from "../../redux/actions/localMerchantsApproval";

// Utilities
import renderInput from "../utilities/inputForms";

const RejectMerchantModal = () => {
  const dispatch = useDispatch();

  const { modal, formLoading, error, form } = useSelector(
    ({ localMerchantForApprovalReducer }) => ({
      modal: localMerchantForApprovalReducer.modal,
      formLoading: localMerchantForApprovalReducer.formLoading,
      error: localMerchantForApprovalReducer.error,
      form: localMerchantForApprovalReducer.form,
    }),
    isEqual
  );

  const schema = yup.object().shape({
    rejectionNote: yup.string().required(),
  });

  let defaultValues = {
    rejectionNote: "",
  };

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onSubmit = async (values) => {
    try {
      const { data } = await dispatch(
        updateMerchantApprovalStatus({
          isApproved: 0,
          id: form.id,
          note: values.rejectionNote,
        })
      );

      if (data) {
        openNotification("SUCCESS", "Reason for rejection succesfully saved");
        close();
      }
    } catch (error) {
      console.log(">>>> error rejecting with note");
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  return (
    <Modal
      title={"Reason for Rejection"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        <Col className="gutter-row" span={24}>
          {renderInput(
            {
              label: "Reason for rejection (this will show in merchant app)",
              name: "rejectionNote",
              errors: errors,
              required: "true",
            },
            control
          )}
        </Col>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default RejectMerchantModal;
