import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isEqual } from "lodash";
import { Layout, Form, Input, Checkbox } from "antd";

import ServerSideTable from "../table/serverSideTable";

import {
  fetch,

  //   toggleImportModal,
} from "../../redux/actions/merchantCategoryMenu";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";

const { Content: LayoutContent } = Layout;
const { Search } = Input;
const AllCategoriesTableComponent = ({ merchantId, setSelectedCategory }) => {
  const dispatch = useDispatch();

  const [checkedCategory, setCheckedCategory] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loading,
    formLoading,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    data,
    userInfo,
  } = useSelector(
    ({ CategoryReducer, userLoginReducer }) => ({
      data: CategoryReducer.data,
      loading: CategoryReducer.loading,
      formLoading: CategoryReducer.formLoading,
      totalRecord: CategoryReducer.totalRecord,
      nextPage: CategoryReducer.nextPage,
      previousPage: CategoryReducer.previousPage,
      currentPage: CategoryReducer.currentPage,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const checkBox = (item) => {
    setCheckedCategory((prevCheckedMenu) => {
      const isItemChecked = prevCheckedMenu.some(
        (checkedItem) => checkedItem === item
      );
      if (!isItemChecked) {
        return [...prevCheckedMenu, item];
      } else {
        return prevCheckedMenu.filter((checkedItem) => checkedItem !== item);
      }
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      userId: merchantId,
      searchText: delaySearchText,
    };
    dispatch(fetch(payload));
  }, [delaySearchText, dispatch, merchantId]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetch(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      userId: merchantId,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetch({
        ...nextPage,
        userId: merchantId,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetch({
        ...previousPage,
        userId: merchantId,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetch({
        ...defaultPage,
        userId: merchantId,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetch({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        userId: merchantId,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <Checkbox onChange={() => checkBox(original.id)}></Checkbox>
          </div>
        ),
        width: 100,
      },
      {
        Header: "Title",
        accessor: "title",
      },
    ],
    [formLoading]
  );

  useEffect(() => {
    dispatch(fetch(defaultPage));
  }, [dispatch, defaultPage]);

  useEffect(() => {
    setSelectedCategory(checkedCategory);
  }, [checkedCategory]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={data}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage: { page: 1, limit: 1 },
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };
  return <>{renderContent()}</>;
};

export default AllCategoriesTableComponent;
