import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Button,
  Form,
  Input,
  Tooltip,
  notification,
  DatePicker,
  Switch,
  Modal,
  Select,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import Icon from "@mdi/react";
import { mdiLockReset } from "@mdi/js";
import dayjs from "dayjs";
// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";
import {
  fetchAllCustomers,
  resetPassword,
  fetchFilterCity,
  updateAccessStatus,
} from "../redux/actions/customers";
import moment from "moment/moment";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const Customer = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [toformattedDate, setToFormattedDate] = useState(null);

  const [filterCity, setFilterCity] = useState("");

  const [deactivationModal, setDeactivationModal] = useState(false);
  const [userToDeactive, setUserToDeactive] = useState({});
  const [deactivate, setDeactivate] = useState(false);
  const [deactivationNote, setDeactivationNote] = useState("");

  const {
    loading,
    customersData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,

    filterCityData,

    userInfo,
  } = useSelector(
    ({ customerReducer, userLoginReducer }) => ({
      customersData: customerReducer.data,
      totalRecord: customerReducer.totalRecord,
      nextPage: customerReducer.nextPage,
      previousPage: customerReducer.previousPage,
      currentPage: customerReducer.currentPage,
      loading: customerReducer.loading,
      filterCityData: customerReducer.filterCityData,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const handleChange = (value) => {
    setFilterCity(value);
  };

  const onSwitch = async (checked, user) => {
    if (!checked) {
      setUserToDeactive(user);
      setDeactivationModal(true);
      setDeactivate(true);
    } else {
      // reactivate account
      setDeactivate(false);
      await onSubmit(user);
    }
  };

  const onSubmit = async (user) => {
    try {
      let userDataContainer =
        Object.keys(userToDeactive).length !== 0 ? userToDeactive : user;

      if (deactivationNote === "" && deactivate) {
        openNotification("ERROR", "Reason is required");
        return;
      }

      const payload = {
        user: userDataContainer,
        deactivate: deactivate,
        reason: deactivationNote,
      };

      const { data } = await dispatch(updateAccessStatus(payload));

      if (data) {
        openNotification("SUCCESS", data.message);
        setDeactivationModal(false);
        setUserToDeactive({});
        setDeactivate(false);
        setDeactivationNote("");
      }
    } catch (error) {
      console.log("updating access status  error:", error);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    const i = {
      page: 1,
      limit: 15,
    };
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      dateFilter:
        formatDate(selectedDate) === "Invalid Date"
          ? undefined
          : formatDate(selectedDate) + " " + "00:00:00",
      todateFilter:
        formatDate(toSelectedDate) === "Invalid Date"
          ? undefined
          : formatDate(toSelectedDate) + " " + "23:59:59",
      filterCity,
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(fetchAllCustomers(payload));
    } else {
      dispatch(fetchAllCustomers(i));
    }
  }, [delaySearchText, selectedDate, toSelectedDate, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAllCustomers(payload));
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };
  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setSelectedDate(dates[0]);

      setToSelectedDate(dates[1]);
    } else {
      setSelectedDate(null);
      setToSelectedDate(null);
    }
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>Customers</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <RangePicker
                style={{ width: "30vw" }}
                value={[selectedDate, toSelectedDate]}
                onChange={handleDateChange}
                placeholder={["Start Date", "End Date"]}
                format="YYYY-MM-DD"
              />
              {/* select here */}
              {userInfo.role === "admin" && (
                <Select
                  placeholder="Select City"
                  style={{
                    width: 150,
                  }}
                  showSearch
                  onChange={handleChange}
                  options={
                    [{ label: "All", value: "" }, ...filterCityData] || []
                  }
                />
              )}
              {/* <Form.Item label="From:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={selectedDate}
                  onChange={(date, dateString) => {
                    setSelectedDate(date);
                    setFormattedDate(
                      dayjs(dateString)
                        .startOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item>
              <Form.Item label="To:">
                <DatePicker
                  style={{ width: "15vw" }}
                  value={toSelectedDate}
                  onChange={(date, dateString) => {
                    setToSelectedDate(date);
                    setToFormattedDate(
                      dayjs(dateString)
                        .endOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")
                    );
                  }}
                  placeholder="Select Date"
                />
              </Form.Item> */}
            </div>
          </Form>
        </div>
      </div>
    );
  };

  //   const extra = () => {
  //     const addButton = (
  //       <Button key="1" onClick={onAdd} className="mr-2">
  //         <PlusOutlined /> Add
  //       </Button>
  //     );

  //     return [addButton];
  //   };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      filterCity,
    };
  }, [filterCity]);

  const onClickNextPage = () => {
    dispatch(
      fetchAllCustomers({
        ...nextPage,
        searchText,
        filterCity,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAllCustomers({
        ...previousPage,
        searchText,
        filterCity,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAllCustomers({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAllCustomers({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        searchText,
      })
    );
  };

  const onClickResetPassword = async (payload) => {
    try {
      const { data } = await dispatch(resetPassword(payload));
      return openNotification("SUCCESS", data.message);
    } catch (error) {
      console.log(">>> error resetting password");
      return openNotification("ERROR", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <>
            {/* {userInfo?.role === "admin" && (
              <div>
                <Button
                  disabled={loading}
                  onClick={() => {
                    onClickResetPassword(original);
                  }}
                  size="small"
                  type="text"
                >
                  <Tooltip title="Reset password">
                    <Icon path={mdiLockReset} size="1rem" />
                  </Tooltip>
                </Button>
              </div>
            )} */}

            <div>
              <Tooltip title="Update active status">
                <Switch
                  size="small"
                  defaultChecked={original.access_status === "active"}
                  checked={original.access_status === "active"}
                  onChange={(checked) => {
                    onSwitch(checked, original);
                  }}
                />
              </Tooltip>
            </div>
          </>
        ),
        width: 100,
      },

      {
        Header: "Customer Name",
        // accessor: (row) => `${row.first_name} ${row.last_name}`,
        accessor: "fullName",
      },
      {
        Header: "Contact number",
        accessor: "contact_number",
      },
      // {
      //   Header: "Municipality",
      //   accessor: "municipality",
      // },
      // {
      //   Header: "Province",
      //   accessor: "province",
      // },
      {
        Header: "Account creation",
        accessor: (row) => {
          const formattedDate = moment(row.account_creation_date).format(
            "MMMM Do YYYY, h:mm:ss a"
          );
          return formattedDate;
        },
      },
      {
        Header: "Complete Address",
        accessor: "complete_address",
      },
      // {
      //   Header: "Referrer Id",
      //   accessor: "operator_id",
      //   // accessor: (row) =>
      //   //   `${row.referral_operator_first_name} ${row.referral_operator_last_name}`,
      // },
      // {
      //   Header: "referral code",
      //   accessor: "customer_used_referral_code",
      // },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllCustomers(defaultPage));
  }, [dispatch, defaultPage]);

  useEffect(() => {
    dispatch(fetchFilterCity());
  }, [dispatch]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={customersData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          extra={[]}
          customFilters={customFilters}
          dateFilters={{ selectedDate }}
          // search={true}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            <Modal
              okText="Save"
              title="Reason for deactivation"
              open={deactivationModal}
              onOk={onSubmit}
              onCancel={() => {
                setDeactivationModal(false);
                setUserToDeactive({});
                setDeactivate(false);
                setDeactivationNote("");
              }}
            >
              <p>Account: {userToDeactive?.fullName}</p>

              <Select
                onChange={(value) => {
                  setDeactivationNote(value);
                }}
                defaultValue={deactivationNote}
                value={deactivationNote}
                placeholder="Select reason for deactivation"
                style={{ width: "100%" }}
                options={[
                  { value: "Fake bookings", label: "Fake bookings" },
                  { value: "Bot Activity", label: "Bot Activity" },
                  { value: "Account Compromise", label: "Account Compromise" },
                  {
                    value: "Abuse of Promotional Offers",
                    label: "Abuse of Promotional Offers",
                  },
                  { value: "Identity Theft", label: "Identity Theft" },
                  {
                    value: "Terms of Service Violations",
                    label: "Terms of Service Violations",
                  },
                ]}
              />
            </Modal>
          </>
        }
      />
    </>
  );
};

export default Customer;
