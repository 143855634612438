import actions from "../actionTypes";
const { SUCCEEDINGRATE } = actions;

const userInitialState = {
  modal: false,
  formLoading: false,
  data: [], // for merchant and grocery
  succeedingRatePabiliPadala: [], // pabili padala dfee settings
  form: {},
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
};

export const succeedingRateReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUCCEEDINGRATE.TOGGLE:
      return { ...state, modal: payload, error: null };
    case SUCCEEDINGRATE.SET:
      return { ...state, form: payload };

    case SUCCEEDINGRATE.CREATE.REQUEST:
      return { ...state, formLoading: true };

    case SUCCEEDINGRATE.CREATE.SUCCESS:
      const newData = payload;

      return {
        ...state,
        formLoading: false,
        data: newData.data.data,
      };
    case SUCCEEDINGRATE.CREATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    case SUCCEEDINGRATE.FETCH.REQUEST:
      return { ...state, loading: true };
    case SUCCEEDINGRATE.FETCH.SUCCESS:
      let data = payload.data.data.result;

      const merchantsDfee = data.filter((d) => d.isPabiliPadala === 0);
      const pabiliPadalaDfee = data.filter((c) => c.isPabiliPadala === 1);

      return {
        ...state,
        data: merchantsDfee,
        totalRecord: payload.data.count,
        nextPage: payload.data?.data?.next,
        previousPage: payload.data?.data?.previous,
        currentPage: payload.data?.currentPage,
        loading: false,
        succeedingRatePabiliPadala: pabiliPadalaDfee,
      };
    case SUCCEEDINGRATE.FETCH.FAILURE:
      return { ...state, error: payload, loading: false };

    case SUCCEEDINGRATE.UPDATE.REQUEST:
      return { ...state, formLoading: true };
    case SUCCEEDINGRATE.UPDATE.SUCCESS:
      const updatedData = payload.data.data;
      const updatedItem = Array.isArray(updatedData)
        ? updatedData[0]
        : updatedData;
      return {
        ...state,
        formLoading: false,
        form: payload.data.data,
        data: state.data.map((d) =>
          d.id === updatedItem?.id ? updatedItem : d
        ),
      };

    case SUCCEEDINGRATE.UPDATE.FAILURE:
      return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
