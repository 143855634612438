import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { NEWRIDERS } = actions;

export const fetchNewRiders = (payload) => async (dispatch, getState) => {
  dispatch({ type: NEWRIDERS.FETCH.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  try {
    let url = `/api/rider/new-riders`;

    if (payload !== undefined) {
      const { page, limit, searchText } = payload;
      url = `/api/rider/new-riders?page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/rider/new-riders?page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }

    const response = await axiosInstance(token).get(url);
    dispatch({
      type: NEWRIDERS.FETCH.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: NEWRIDERS.FETCH.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
