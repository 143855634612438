import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { OPERATORPERFORMANCE } = actions;

export const fetchAllOperatorPerformance =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: OPERATORPERFORMANCE.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    let { role } = userInfo;
    try {
      let url = `/api/operator/operator-transaction/all`;
      console.log(role);
      if (payload !== undefined) {
        const { page, limit, searchText } = payload;
        if (role === "operator_admin") {
          url = `/api/operator/operator-transaction/all?page=${page}&limit=${limit}&partner_id=${userInfo.id}&role=${role}`;
        } else {
          url = `/api/operator/operator-transaction/all?page=${page}&limit=${limit}`;
        }
        if (page && limit && searchText) {
          if (role === "operator_admin") {
            url = `/api/operator/operator-transaction/all?page=${page}&limit=${limit}&searchText=${searchText}&partner_id=${userInfo.id}&role=${role}`;
          } else {
            url = `/api/operator/operator-transaction/all?page=${page}&limit=${limit}&searchText=${searchText}`;
          }
        }
      }

      const response = await axiosInstance(token).get(url);
      dispatch({
        type: OPERATORPERFORMANCE.FETCH.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: OPERATORPERFORMANCE.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };
