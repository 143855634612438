import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";

import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  notification,
  Upload,
  Tag,
  Space,
  Select,
  Switch,
  Input,
  Radio,
  Alert,
  Spin,
  Card,
} from "antd";
import moment from "moment";
import {
  MinusCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  toggleModalSwitch,
  //   create,
  //   setForm,
  //   update,
  //   fetchAllMerchantTag,
  //   clearError,
} from "../../redux/actions/nationalMerchants";

import {
  createMerchantWithMenus,
  createMerchantWithMenusMultipleBranch,
} from "../../redux/actions/nationalMerchantMenu";

// Utilities
import renderInput from "../utilities/inputForms";

const merchantTypeOption = [
  {
    value: "1",
    label: "Local",
  },
  {
    value: "2",
    label: "National",
  },
  {
    value: "3",
    label: "DTI",
  },
];

const ToggleModal = ({
  nationalMerchantData = [],
  isAddedMoreArea = false,
}) => {
  const dispatch = useDispatch();

  const { modal, formLoading, error, form, tags, userInfo, autoCreateLoading } =
    useSelector(
      ({
        nationalMerchantsReducer,
        userLoginReducer,
        nationalMerchantMenuReducer,
      }) => ({
        modal: nationalMerchantsReducer.modal,
        formLoading: nationalMerchantsReducer.formLoading,
        error: nationalMerchantsReducer.error,
        form: nationalMerchantsReducer.form,
        userInfo: userLoginReducer.userInfo,
        tags: nationalMerchantsReducer.tags,
        autoCreateLoading: nationalMerchantMenuReducer.autoCreateLoading,
      }),
      isEqual
    );

  const [cities, setCities] = useState([]);
  const [barangays2, setBarangays2] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [isProvinceEdited, setIsProvinceEdited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [isBarangayEdited, setIsBarangayEdited] = useState(false);
  const [isNationalBrand, setIsNationalBrad] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedMerchantType, setSelectedMerchantType] = useState("");
  const [istwentyFourHrs, setIsTwentyFourHrs] = useState(false);
  const [branchData, setBranchData] = useState([]);
  const [setvariant, setVariantStatus] = useState(false);
  const [selectedMunicipality, setSelectedMunicipality] = useState([]);
  const [selectedBarangay, setSelectedBarangay] = useState([]);
  const [is24Hours, setIs24Hours] = useState(false);
  const [isMultipleBranch, setIsMultipleBranch] = useState(false);
  // logo
  const [imageFileLogo, setImageFileLogo] = useState(null);
  const [imageUrlLogo, setImageUrlLogo] = useState("");
  const [loading, setLoading] = useState(false); // same with featured

  //featured Image
  const [imageFileFeatured, setImageFileFeatured] = useState(null);
  const [imageUrlFeatured, setImageUrlFeatured] = useState("");
  const [sample, SetSample] = useState(true);
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  const handleChangeLogo = (info) => {
    setLoading(true);
    const file = info.file.originFileObj;

    setImageFileLogo(file);

    const localUrl = URL.createObjectURL(file);
    setImageUrlLogo(localUrl);
    setLoading(false);
  };

  useEffect(() => {
    if (form?.timeClose === "24:00:00" && form?.timeOpen === "00:00:00") {
      setIsTwentyFourHrs(true);
    }
  }, [form]);

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (hasForm) {
      if (form.merchant_type_id) {
        const option = merchantTypeOption.find(
          (option) => Number(option.value) === Number(form.merchant_type_id)
        );

        setSelectedMerchantType(option.value);
      }
    }
  }, [hasForm, form]);

  useEffect(() => {
    if (hasForm) {
      if (form.merchant_tags === null) {
        setSelectedTags([]);
      } else {
        const tagsArray = JSON.parse(form?.merchant_tags);

        setSelectedTags(tagsArray);
      }
    }
  }, [hasForm, form.merchant_tags, tags]);

  const schema = isMultipleBranch
    ? yup.object().shape({})
    : yup.object().shape({
        city: yup.string().required(),
        barangay: yup.string().required(),
        longitude: yup
          .string()
          .matches(/^[\d.]+$/)
          .required("Longitude is a required field")
          .min(14, "Longitude must be at least 14 characters long"),
        latitude: yup
          .string()
          .matches(/^[\d.]+$/)
          .required("Latitude is a required field")
          .min(13, "Latitude must be at least 13 characters long"),
        landmark: yup.string().required("landmark is required field"),
        timeOpen: istwentyFourHrs
          ? ""
          : yup.string().required("Time open is required field"),
        timeClose: istwentyFourHrs
          ? ""
          : yup.string().required("Time close is required field"),
      });

  let defaultValues = {
    city: "",
    barangay: "",
    latitude: "",
    longitude: "",
    image: "",
    imageFile: null,
    landmark: "",
    imageFileLogo: "",
    featured_image_url: "",
  };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onCheck = (status) => {
    setIsNationalBrad(status);
  };

  const onCheck2 = (status) => {
    setVariantStatus(status);
  };

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModalSwitch(false));
    // dispatch(setForm({}));
    setIsProvinceEdited(false);
    setIsCityEdited(false);
    setIsBarangayEdited(false);
    setSelectedTags([]);
    setSelectedMerchantType("");
    setImageFileLogo(null);
    setImageFileFeatured(null);
    setImageUrlLogo("");
    setImageUrlFeatured("");
    setIsTwentyFourHrs(false);
  };

  const onChange = (checked) => {
    setIsTwentyFourHrs(!istwentyFourHrs);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  useEffect(() => {
    const cities = phil.getCityMunByProvince(Number(userInfo.prov_code));
    // const groupedArea = JSON.parse(userInfo.grouped_areas);
    if (cities.length > 0) {
      // let data = [];
      // groupedArea.map((d) =>
      //   cities
      //     .filter((dd) => dd.name === d.toUpperCase())
      //     .map((ddd) => data.push(ddd))
      // );

      setCities(cities);
    }
  }, [userInfo]);

  const onChangeCity2 = (mun_code) => {
    setValue("barangay", "");
    const barangaysJsonGeer = phil.getBarangayByMun(mun_code);
    setBarangays2(barangaysJsonGeer);

    if (mun_code === "137404" || mun_code === 137404) {
      const additionalBrgy = [
        {
          id: 999999999,
          brgy_code: "Bago Bantay",
          name: "Bago Bantay",
          reg_code: "13",
          prov_code: "1374",
          mun_code: "137404",
        },
        {
          id: 111111111,
          brgy_code: "Kalayaan",
          name: "Kalayaan",
          reg_code: "13",
          prov_code: "1374",
          mun_code: "137404",
        },
        {
          id: 222222222,
          brgy_code: "West Avenue",
          name: "West Avenue",
          reg_code: "13",
          prov_code: "1374",
          mun_code: "137404",
        },
      ];
      setBarangays2([...barangaysJsonGeer, ...additionalBrgy]);
    }
    setIsCityEdited(true);
  };
  const onChangeProvince = (prov_code) => {
    console.log("prov_code : >> ", prov_code);
    console.log("userinfo : >> ", userInfo.prov_code);
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
    setIsProvinceEdited(true);
  };
  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    const barangaysJsonGeer = phil.getBarangayByMun(mun_code);
    setBarangays(barangaysJsonGeer);

    if (mun_code === "137404" || mun_code === 137404) {
      const additionalBrgy = [
        {
          id: 999999999,
          brgy_code: "Bago Bantay",
          name: "Bago Bantay",
          reg_code: "13",
          prov_code: "1374",
          mun_code: "137404",
        },
        {
          id: 111111111,
          brgy_code: "Kalayaan",
          name: "Kalayaan",
          reg_code: "13",
          prov_code: "1374",
          mun_code: "137404",
        },
        {
          id: 222222222,
          brgy_code: "West Avenue",
          name: "West Avenue",
          reg_code: "13",
          prov_code: "1374",
          mun_code: "137404",
        },
      ];
      setBarangays([...barangaysJsonGeer, ...additionalBrgy]);
    }
    setIsCityEdited(true);
  };

  const multipleBranch = async () => {
    if (isMultipleBranch) {
      const { data } = await dispatch(
        createMerchantWithMenusMultipleBranch(branchData)
      );
      if (data) {
        openNotification("SUCCESS", data.message);
        close();
        setBranchData([]);
        // setIsMultipleBranch(false);
      }
    } else {
      console.log("Multiple Branch ERROR");
    }
  };

  const onSubmit = async (values) => {
    try {
      if (!isMultipleBranch) {
        const parsedTimeOpen = moment(values.timeOpen, "HH:mm");
        let formattedTimeOpen = parsedTimeOpen.format("HH:mm:ss");

        const parsedTimeClose = moment(values.timeClose, "HH:mm");
        let formattedTimeClose = parsedTimeClose.format("HH:mm:ss");

        if (istwentyFourHrs) {
          formattedTimeOpen = "00:00:00";
          formattedTimeClose = "24:00:00";
        }
        const province = phil.provinces.filter(
          (province) => province.prov_code === values.province
        )[0];
        const city = phil.city_mun.filter(
          (city) => city.mun_code === values.city
        )[0].name;
        let barangay = phil.barangays.filter(
          (barangay) => barangay.brgy_code === values.barangay
        )[0]?.name;

        if (barangay === undefined || barangay === "undefined") {
          barangay = values.barangay;
        }
        const randomThreeDigitNumber = Math.floor(Math.random() * 900) + 100;
        const formData = new FormData();

        console.log(nationalMerchantData.name + randomThreeDigitNumber);
        // comment
        formData.append("image_url", nationalMerchantData.feature_image);
        formData.append("logo", nationalMerchantData.logo);
        formData.append("name", nationalMerchantData.name);
        formData.append("province", userInfo.province);
        formData.append("city", city);
        formData.append("barangay", barangay);
        formData.append(
          "username",
          nationalMerchantData.name + randomThreeDigitNumber
        );
        formData.append("password", 123456);
        formData.append("latitude", values.latitude);
        formData.append("longitude", values.longitude);
        formData.append("isNationalBrand", 1);
        formData.append("timeOpen", formattedTimeOpen);
        formData.append("timeClose", formattedTimeClose);
        formData.append("tags", nationalMerchantData.merchant_tags);
        formData.append("merchantTypeId", 2);
        formData.append("landmark", values.landmark);
        formData.append("partner_id", userInfo.id);
        formData.append("national_merchant_id", nationalMerchantData.id);

        const { data } = await dispatch(createMerchantWithMenus(formData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
          console.log(data.message);
        }
      } else {
        multipleBranch();
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
    }
  };

  useEffect(() => {
    if (error) {
      openNotification("ERROR", error);
      //   dispatch(clearError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (form) {
      reset(form);
    }
  }, [form, reset]);

  useEffect(() => {
    if (isAddedMoreArea) {
      setIsMultipleBranch(true);
    } else {
      setIsMultipleBranch(false);
    }
  }, [isAddedMoreArea]);

  const handleSwitchChange = (index, checked) => {
    let newbranchData = [...branchData];
    newbranchData[index].switchState = checked;
    if (checked) {
      newbranchData[index].timeOpen = "00:00:00";
      newbranchData[index].timeClose = "24:00:00";
    } else {
      newbranchData[index].timeOpen = "";
      newbranchData[index].timeClose = "";
    }

    setBranchData(newbranchData);
    console.log(newbranchData);
  };
  const handle24Hours = (index, checked) => {
    let newbranchData = [...branchData];
    newbranchData[index].is24Hours = checked;
    setBranchData(newbranchData);
  };

  const handleAdd = () => {
    const currentYear = new Date().getFullYear().toString().slice(-2);
    const currentMonth = (new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const currentDate = new Date().getDate().toString().padStart(2, "0");
    const lastDigitOfTime = new Date().getMinutes().toString().slice(-1);
    const uniqueNumber1 = Math.floor(Math.random() * 10);
    const uniqueNumber2 = Math.floor(Math.random() * 10);

    const newBranchId = parseInt(
      currentYear +
        currentMonth +
        currentDate +
        lastDigitOfTime +
        uniqueNumber1.toString() +
        uniqueNumber2.toString()
    );

    const randomThreeDigitNumber = Math.floor(Math.random() * 900) + 100;
    setBranchData([
      ...branchData,
      {
        branchId: newBranchId,
        image_url: nationalMerchantData.feature_image,
        logo: nationalMerchantData.logo,
        name: nationalMerchantData.name,
        province: "",
        city: "",
        barangay: "",
        password: 123456,
        username: nationalMerchantData.name + randomThreeDigitNumber,
        latitude: "",
        longitude: "",
        timeOpen: "",
        timeClose: "",
        tags: nationalMerchantData.merchant_tags,
        merchantTypeId: 2,
        landmark: "",
        partner_id: userInfo.id,
        national_merchant_id: nationalMerchantData.id,
        switchState: false,
        is24Hours: false,
      },
    ]);

    // setBranchData([
    //   ...branchData,
    //   { branchId: newBranchId, variant: "", price: "", isActive: true },
    // ]);
  };

  const handleRemove = (index) => {
    const newbranchData = [...branchData];
    newbranchData.splice(index, 1);
    setBranchData(newbranchData);
  };

  const handleChange = (index, key, value) => {
    const newbranchData = [...branchData];
    newbranchData[index][key] = value;
    setBranchData(newbranchData);
  };

  const handleRadioChange = (e) => {
    setIsMultipleBranch(e.target.value);
  };

  return (
    <Modal
      title={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "center",
            marginBottom: isAddedMoreArea ? 35 : 0,
          }}
        >
          <div style={{ fontSize: 22 }}>Activate</div>
          <div style={{ fontSize: 22 }}>{nationalMerchantData.name}</div>
        </div>
      }
      open={modal}
      width={700}
      footer={null}
      onCancel={() => close()}
      closable={false}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <>
          {autoCreateLoading && (
            <Alert
              message={
                <div style={{ fontSize: 22, fontWeight: "bold" }}>WARNING!</div>
              }
              description={
                <div style={{ fontSize: 22 }}>
                  <span style={{ fontWeight: "bold" }}>Please wait</span> while
                  data is uploading.
                </div>
              }
              type="warning"
            />
          )}

          {!autoCreateLoading && (
            <>
              {!isAddedMoreArea && (
                <>
                  <div style={{ fontSize: 15, marginTop: 20 }}>
                    Multiple Branch?
                  </div>

                  <Radio.Group
                    onChange={handleRadioChange}
                    defaultValue={isMultipleBranch}
                  >
                    <Radio
                      style={{ fontSize: 13, marginBottom: 10 }}
                      value={false}
                    >
                      NO
                    </Radio>
                    <Radio
                      style={{ fontSize: 13, marginBottom: 10 }}
                      value={true}
                    >
                      YES
                    </Radio>
                  </Radio.Group>
                </>
              )}
              {!isMultipleBranch && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col className="gutter-row" span={24}>
                      {renderInput(
                        {
                          label: "Province",
                          name: "province",
                          errors: errors,
                          required: "true",
                          type: "select",
                          options: phil.provinces,
                          valueKey: "prov_code",
                          valueText: "name",
                          onChangeOutside: onChangeProvince,
                        },
                        control
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      {renderInput(
                        {
                          label: "City",
                          name: "city",
                          errors: errors,
                          required: "true",
                          type: "select",
                          options: cities,
                          valueKey: "mun_code",
                          valueText: "name",
                          onChangeOutside: onChangeCity,
                        },
                        control
                      )}
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      {renderInput(
                        {
                          label: "Barangay",
                          name: "barangay",
                          errors: errors,
                          required: "true",
                          type: "select",
                          options: barangays,
                          valueKey: "brgy_code",
                          valueText: "name",
                          onChangeOutside: () => setIsBarangayEdited(true),
                        },
                        control
                      )}
                    </Col>
                    <Col span={12}>
                      {renderInput(
                        {
                          label: "landmark",
                          name: "landmark",
                          errors: errors,
                          required: "true",
                          placeholder: "mall name/street/barangay",
                        },
                        control
                      )}
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      {renderInput(
                        {
                          label: "Latitude",
                          name: "latitude",
                          errors: errors,
                          required: "true",
                        },
                        control
                      )}
                    </Col>
                    <Col span={12}>
                      {renderInput(
                        {
                          label: "Longitude",
                          name: "longitude",
                          errors: errors,
                          required: "true",
                        },
                        control
                      )}
                    </Col>
                  </Row>

                  <br />
                  <Row gutter={[16, 16]}>
                    <Col span={10}>
                      {renderInput(
                        {
                          type: "time",
                          label: "Time Open",
                          name: "timeOpen",
                          errors: errors,
                          required: "false",
                          disabled: istwentyFourHrs,
                        },
                        control
                      )}
                    </Col>
                    <Col span={10}>
                      {renderInput(
                        {
                          type: "time",
                          label: "Time Close",
                          name: "timeClose",
                          errors: errors,
                          required: "false",
                          disabled: istwentyFourHrs,
                        },
                        control
                      )}
                    </Col>
                    <Col span={4}>
                      <div style={{ paddingTop: 35 }}>
                        <Switch
                          checked={istwentyFourHrs}
                          onChange={onChange}
                          checkedChildren="24hrs"
                          unCheckedChildren="24hrs?"
                          defaultChecked={istwentyFourHrs}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {/* <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 19,
                marginTop: -25,
                paddingTop: 15,
                paddingBottom: 15,
              }}
            >
              <div style={{ fontSize: 15 }}>Add Branches</div>
              <Switch
                size="medium"
                checked={setvariant}
                onChange={(checked) => {
                  onCheck2(checked);
                }}
                defaultValue={setvariant}
              />
            </div>
          </Col> */}

              {isMultipleBranch && (
                <>
                  <Form.List name="branchData" initialValue={branchData}>
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {fields.map((field, index) => (
                          <Card style={{ marginBottom: 15 }}>
                            <Form.Item
                              {...(index === 0
                                ? formItemLayout
                                : formItemLayout)}
                              label={index === 0 ? "" : ""}
                              required={false}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={["onChange", "onBlur"]}
                                noStyle
                              >
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>Province</span>

                                    <Select
                                      showSearch
                                      value={branchData[index]?.province || ""}
                                      name={`province`}
                                      allowClear
                                      style={{ width: "100%" }}
                                      placeholder={`Please select Province`}
                                      onChange={(value) => {
                                        handleChange(index, "province", value);
                                        const i = phil.provinces
                                          .filter(
                                            (province) =>
                                              province.name === value
                                          )
                                          .map((d) => d);
                                        onChangeProvince(i[0].prov_code);
                                      }}
                                      options={phil.provinces.map(
                                        (province) => ({
                                          value: province.name,
                                          label: province.name,
                                        })
                                      )}
                                    />
                                  </div>
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>Municipality</span>

                                    <Select
                                      showSearch
                                      value={branchData[index]?.city || ""}
                                      name={`municipality`}
                                      allowClear
                                      style={{ width: "100%" }}
                                      placeholder={`Please select Municipality`}
                                      onChange={(value) => {
                                        handleChange(index, "city", value);
                                        const i = cities
                                          .filter((city) => city.name === value)
                                          .map((d) => d);
                                        onChangeCity2(i[0].mun_code);
                                      }}
                                      options={cities.map((city) => ({
                                        value: city.name,
                                        label: city.name,
                                      }))}
                                    />
                                  </div>
                                  <br />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>Barangay</span>
                                    <Select
                                      showSearch
                                      value={branchData[index]?.barangay || ""}
                                      name={`Barangay`}
                                      allowClear
                                      style={{ width: "100%" }}
                                      placeholder={`Please select Barangay`}
                                      onChange={(value) => {
                                        // setSelectedBarangay(value);
                                        handleChange(index, "barangay", value);
                                      }}
                                      options={barangays2.map((city) => ({
                                        value: city.name,
                                        label: city.name,
                                      }))}
                                    />{" "}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 5,
                                    alignItems: "center",
                                    marginTop: 20,
                                    marginBottom: 20,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>Latitude</span>
                                    <Input
                                      disabled={!isMultipleBranch}
                                      placeholder="latitude"
                                      style={{ width: "100%" }}
                                      value={branchData[index]?.latitude || ""}
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "latitude",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>Longitude</span>
                                    <Input
                                      disabled={!isMultipleBranch}
                                      placeholder="longitude"
                                      style={{ width: "100%" }}
                                      value={branchData[index]?.longitude || ""}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        // Check if the input value contains only numbers and dots
                                        if (
                                          /^\d*\.?\d*$/.test(inputValue) ||
                                          inputValue === ""
                                        ) {
                                          handleChange(
                                            index,
                                            "longitude",
                                            inputValue
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span>Landmark</span>
                                  <Input
                                    disabled={!isMultipleBranch}
                                    placeholder="landmark"
                                    style={{ width: "100%" }}
                                    value={branchData[index]?.landmark || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        index,
                                        "landmark",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 5,
                                    marginTop: 20,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>Time open</span>
                                    <Input
                                      type="time"
                                      disabled={
                                        !isMultipleBranch ||
                                        branchData[index]?.is24Hours
                                      }
                                      placeholder="timeOpen"
                                      style={{ width: "100%" }}
                                      value={
                                        branchData[index]?.switchState
                                          ? ""
                                          : branchData[index]?.timeOpen
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "timeOpen",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>Time close</span>
                                    <Input
                                      type="time"
                                      disabled={
                                        !isMultipleBranch ||
                                        branchData[index]?.is24Hours
                                      }
                                      placeholder="timeClose"
                                      style={{ width: "100%" }}
                                      value={
                                        branchData[index]?.switchState
                                          ? ""
                                          : branchData[index]?.timeClose
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          index,
                                          "timeClose",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div style={{ marginTop: 20 }}>
                                    <Switch
                                      checked={
                                        branchData[index]?.switchState || false
                                      }
                                      onChange={(checked) => {
                                        handleSwitchChange(index, checked);
                                        setIs24Hours(checked);
                                        handle24Hours(index, checked);
                                      }}
                                      checkedChildren="24hrs"
                                      unCheckedChildren="24hrs?"
                                    />
                                  </div>
                                </div>
                              </Form.Item>

                              {fields.length > 0 ? (
                                <Button
                                  style={{
                                    marginTop: 10,
                                    color: "white",
                                    backgroundColor: "tomato",
                                  }}
                                  onClick={() => {
                                    handleRemove(index);
                                    remove(field.name);
                                  }}
                                >
                                  Remove
                                </Button>
                              ) : null}
                            </Form.Item>
                          </Card>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                              handleAdd();
                            }}
                            style={{ width: "60%" }}
                            icon={<PlusOutlined />}
                            disabled={!isMultipleBranch}
                          >
                            Add Branch
                          </Button>

                          <Button
                            type="danger"
                            onClick={() => {
                              for (let i = fields.length - 1; i >= 0; i--) {
                                handleRemove(i);
                                remove(i);
                                setBranchData([]);
                              }
                            }}
                            style={{ marginLeft: 8 }}
                            icon={<DeleteOutlined />}
                            disabled={!isMultipleBranch}
                          >
                            Remove All
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </>
              )}
            </>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {autoCreateLoading && (
              <Spin
                indicator={
                  <LoadingOutlined style={{ fontSize: 104, margin: 24 }} spin />
                }
              />
            )}
          </div>
        </>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
            disabled={autoCreateLoading}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" disabled={autoCreateLoading}>
            {"Activate"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ToggleModal;
