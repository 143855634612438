import React, { useState, useEffect } from "react";
import { Button, Modal, notification, Form, Col } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../helpers/axios";
import renderInput from "../utilities/inputForms";
import { updateContactNumber, setForm } from "../../redux/actions/adminAccount";

const openNotification = (title, message) => {
  notification[title.toLowerCase()]({
    message: title,
    description: message,
    duration: 7,
  });
};

const ChangeContactNumberModal = ({ modal, setModal }) => {
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const dispatch = useDispatch();

  const {
    userInfo,
    loading: userLoading,
    formLoading,
  } = useSelector(({ userLoginReducer }) => ({
    userInfo: userLoginReducer.userInfo,
    loading: userLoginReducer.loading,
    formLoading: userLoginReducer.formLoading,
  }));

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const schema = yup.object().shape({
    contactNumber: yup
      .string()
      .required("Contact number is a required field")
      .matches(/^[0-9]{11}$/, "Contact number must be exactly 11 digits"),
  });

  const defaultValues = {
    contactNumber: userInfo ? userInfo.contact_number : "",
  };

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (values) => {
    if (!userInfo || !userInfo.token) {
      openNotification("ERROR", "User information is not available.");
      return;
    }

    try {
      setLoading(true);
      const response = await axiosInstance(userInfo.token).put(
        "/api/admin-account/change-contact-number",
        { contactNumber: values.contactNumber, id: userInfo.id }
      );

      if (response?.data) {
        if (isMounted) {
          openNotification("SUCCESS", response.data.message);
          setModal(false);
          setLoading(false);

          const updatedUserInfo = {
            ...userInfo,
            contact_number: values.contactNumber,
          };


          localStorage.setItem("userInfo", JSON.stringify(updatedUserInfo));


          dispatch(updateContactNumber(updatedUserInfo));
          dispatch(setForm(updatedUserInfo));

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } catch (error) {
      console.log(">>> error changing contact number", error);
      if (isMounted) {
        openNotification("ERROR", "Something went wrong");
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      title="Change Contact Number"
      open={modal}
      footer={null}
      closable={false} 
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <Col className="gutter-row" span={24}>
          {renderInput(
            {
              label: "Contact Number",
              name: "contactNumber",
              errors: errors,
              required: "true",
              type: "text",
            },
            control
          )}
        </Col>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => setModal(false)}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Update
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangeContactNumberModal;