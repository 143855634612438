import actions from "../actionTypes";
const { PAYABLESTATUSINFO } = actions;

const userInitialState = {
  data: [],
  loading: false,
  error: null,
  totalRecord: 0,
  nextPage: undefined,
  previousPage: undefined,
  currentPage: undefined,
};

export const payableStatusInfoReducer = (state = userInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PAYABLESTATUSINFO.FETCH.REQUEST:
      return { ...state, loading: true };
    case PAYABLESTATUSINFO.FETCH.SUCCESS:
      return {
        ...state,
        data: payload.data.data.result,
        // totalRecord: payload.data.count,
        // nextPage: payload.data?.data?.next,
        // previousPage: payload.data?.data?.previous,
        // currentPage: payload.data?.currentPage,
        loading: false,
      };
    // case PAYABLESTATUSINFO.FETCH.FAILURE:
    //   return { ...state, error: payload, loading: false };

    // case PAYABLESTATUSINFO.UPDATE.REQUEST:
    //   return { ...state, formLoading: true };
    // case PAYABLESTATUSINFO.UPDATE.SUCCESS:
    //   return {
    //     ...state,
    //     formLoading: false,
    //     form: payload.data.data,
    //     data: payload.data.data.result,
    //     totalRecord: payload.data.count,
    //     nextPage: payload.data?.data?.next,
    //     previousPage: payload.data?.data?.previous,
    //     currentPage: payload.data?.currentPage,
    //     loading: false,
    //   };

    // case PAYABLESTATUSINFO.UPDATE.FAILURE:
    //   return { ...state, formLoading: false, error: payload };

    default:
      return state;
  }
};
