import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  notification,
  Select,
  Input,
  Switch,
} from "antd";
import axiosInstance from "../../helpers/axios";
import {
  toggleModal,
  create,
  setForm,
  update,
} from "../../redux/actions/merchantMenu";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
// Utilities
import renderInput from "../utilities/inputForms";

const VariantModal = ({ changePassword = false }) => {
  const { modal, formLoading, error, form, userInfo } = useSelector(
    ({ MerchantMenuReducer, userLoginReducer, CategoryReducer }) => ({
      modal: MerchantMenuReducer.modal,
      formLoading: MerchantMenuReducer.formLoading,
      error: MerchantMenuReducer.error,
      form: MerchantMenuReducer.form,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );
  const dispatch = useDispatch();
  const { merchantId, id } = useParams();
  const [hasMenuData, setHasMenuData] = useState(false);
  const [setvariant, setVariantStatus] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [menu, setMenu] = useState([]);
  const [variations, setVariations] = useState([]);
  const [variantId, setVariantId] = useState(1);
  const [newVariants, setNewVariants] = useState(form?.Size);
  const hasForm = form ? Object.keys(form).length !== 0 : false;
  // const yourMaxSizeInBytes = 5 * 1024 * 1024;
  const schema = yup.object().shape({
    // title: yup.string().required(),
    // description: yup.string().required(),
  });

  let defaultValues = {
    title: "",
    sub_title: "",
    description: "",
    price: "",
    image: "",
    imageFile: null,
  };

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    setSelectedItem({});
  };
  const parseSizeOrvariants = (data) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return [];
    }
  };

  const handleAdd = () => {
    setVariations([
      ...variations,
      { variantID: variantId, variant: "", price: "" },
    ]);
    setVariantId(variantId + 1);
  };
  const handleRemove = (index) => {
    const newVariations = [...variations];
    newVariations.splice(index, 1);
    setVariations(newVariations);
  };

  const handleChange = (index, key, value) => {
    const newVariations = [...variations];
    newVariations[index][key] = value;
    setVariations(newVariations);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const fetchCategories = useCallback(async () => {
    try {
      // setGroceriaProductsLoading(true);
      const response = await axiosInstance(userInfo.token).get(
        `/api/merchant/menu-category/${merchantId}`
      );
      setMenu(response?.data?.categories);
      // setGroceriaProductsLoading(false);
    } catch (error) {
      // setGroceriaProductsLoading(false);
      console.log(">>> error getting groceria products:", error);
    }
  }, [userInfo]);
  const onSelectProduct = (value) => {
    const selected = menu.find((d) => d.title === value);
    setSelectedItem(selected);
  };

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onCheck = (status) => {
    setVariantStatus(status);
  };

  const handlePriceChange = (variantID, newPrice) => {
    setNewVariants((prevVariants) => {
      const updatedVariants = parseSizeOrvariants(prevVariants).map((variant) =>
        variant.variantID === variantID
          ? { ...variant, price: newPrice }
          : variant
      );
      return JSON.stringify(updatedVariants);
    });
  };
  console.log(newVariants);
  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        if (changePassword) {
          const { data } = await dispatch(
            update({
              id: form.id,
              password: values.newPassword,
            })
          );
          close();
          return openNotification("SUCCESS", data.message);
        } else {
          const newValues = {
            title: values.title,
            sub_title: values.sub_title,
            description: values.description,
            price: values.price,
            menus_categories_id: id,
            variants: newVariants,
          };

          const toMatch = omit(form, ["id"]);
          const changesKeys = Object.keys(
            ObjectDiff(toMatch, { ...toMatch, ...newValues })
          );

          if (!changesKeys.length) {
            return openNotification("INFO", "No changes made.");
          }

          const { data } = await dispatch(
            update({
              ...newValues,
              id: form.id,
            })
          );
          if (data) {
            openNotification("SUCCESS", data.message);
            close();
          }
        }
      } else {
        const imageFile = values.image[0];
        const fileName = imageFile.name;
        const formData = new FormData();

        // const newData = {
        //   title: values.title,
        //   sub_title: values.sub_title,
        //   description: values.description,
        //   price: values.price,
        //   partner_merchant_id: merchantId,
        //   menus_categories_id: selectedItem.id,
        // };
        const variationsData = JSON.stringify(variations, null, 2);

        formData.append("title", values.title);
        formData.append("sub_title", values.sub_title);
        formData.append("description", values.description);
        formData.append("price", setvariant ? " " : values.price);
        formData.append("partner_merchant_id", merchantId);
        formData.append("menus_categories_id", selectedItem.id);
        formData.append("image", values.image[0]);
        // formData.append("imageName", fileName);
        formData.append("caterogy_name", selectedItem.title);
        formData.append(
          "variants",
          setvariant === false ? " " : variationsData
        );
        const { data } = await dispatch(create(formData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (error) openNotification("ERROR", error);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  useEffect(() => {
    // Check if the menu array has data
    setHasMenuData(menu.length > 0);
  }, [menu]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        <>
          <div style={{ marginTop: 15 }}></div>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Title",
                  name: "title",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
          </Row>
          <div>
            {form?.Size !== null &&
              typeof form?.Size === "string" &&
              form?.Size.trim() !== "" && (
                <>
                  {parseSizeOrvariants(form?.Size).map((d) => (
                    <div
                      key={d.variantID}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                        marginBottom: 10,
                      }}
                    >
                      <Input
                        disabled={true}
                        value={d.variant}
                        style={{ width: "60%" }}
                      />
                      <Input
                        placeholder="variant price"
                        defaultValue={d.price}
                        style={{ width: "60%" }}
                        required
                        onChange={(newPrice) =>
                          handlePriceChange(d.variantID, newPrice.target.value)
                        }
                      />
                    </div>
                  ))}
                </>
              )}
          </div>

          {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Image",
                  name: "image",
                  errors: errors,
                  required: "false",
                  type: "file",
                  disabled: true,
                },
                control
              )}
            </Col>
          </Row> */}
        </>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={formLoading}
            disabled={!hasMenuData}
          >
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default VariantModal;
