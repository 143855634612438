import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Layout, Form, Input } from "antd";
import moment from "moment/moment";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import { fetchAreaAffiliates } from "../redux/actions/affiliates";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const AreaAffiliates = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");

  const {
    loadingAreaAffiliate,
    dataAreaAffiliate,
    totalRecordAreaAffiliate,
    nextPageAreaAffiliate,
    previousPageAreaAffiliate,
    currentPageAreaAffiliate,
  } = useSelector(({ affiliatesReducer }) => ({
    dataAreaAffiliate: affiliatesReducer.dataAreaAffiliate,
    totalRecordAreaAffiliate: affiliatesReducer.totalRecordAreaAffiliate,
    nextPageAreaAffiliate: affiliatesReducer.nextPageAreaAffiliate,
    previousPageAreaAffiliate: affiliatesReducer.previousPageAreaAffiliate,
    currentPageAreaAffiliate: affiliatesReducer.currentPageAreaAffiliate,
    loadingAreaAffiliate: affiliatesReducer.loadingAreaAffiliate,
  }));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
    };
    dispatch(fetchAreaAffiliates(payload));
  }, [delaySearchText, dispatch]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
    };

    dispatch(fetchAreaAffiliates(payload));
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <h1 style={{ fontSize: 30 }}>Area Affiliates</h1>
        <div
          className="p-2 flex justify-between"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
    };
  }, []);

  const onClickNextPage = () => {
    dispatch(
      fetchAreaAffiliates({
        ...nextPageAreaAffiliate,
        searchText,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchAreaAffiliates({
        ...previousPageAreaAffiliate,
        searchText,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchAreaAffiliates({
        ...defaultPage,
        searchText,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchAreaAffiliates({
        page: Math.ceil(totalRecordAreaAffiliate / 15),
        limit: 15,
        searchText,
      })
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (row) => (
          <p
            style={{ color: "black" }}
          >{`${row.first_name} ${row.last_name}`}</p>
        ),
      },
      {
        Header: "Contact number",
        accessor: "contact_number",
      },
      {
        Header: "Email address",
        accessor: "email_address",
      },

      {
        Header: "Registration Date",
        accessor: (row) => {
          return moment(row.created_at).format("MMMM D, YYYY");
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAreaAffiliates(defaultPage));
  }, [dispatch, defaultPage]);

  const renderContent = () => {
    return (
      <LayoutContent>
        <ServerSideTable
          columns={columns}
          data={dataAreaAffiliate} //
          loading={loadingAreaAffiliate}
          serverSidePagination={{

            totalRecordAreaAffiliate,
            onClickNextPage,
            onClickPreviousPage,
            currentPageAreaAffiliate,
            nextPageAreaAffiliate,
            previousPageAreaAffiliate,

            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
        />
      </LayoutContent>
    );
  };

  return (
    <>
      <Wrapper content={renderContent()} modal={<></>} />
    </>
  );
};

export default AreaAffiliates;
