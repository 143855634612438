import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";

import { Form, Row, Col, Button, Modal, notification } from "antd";

// Actions
import {
  toggleModal,
  create,
  setForm,
  updateSucceedingRate,
} from "../../redux/actions/succeedingDistanceRate";

// Utilities
import renderInput from "../utilities/inputForms";

const SucceedingRateModal = ({
  changeSucceedingRate = false,
  setChangeSucceedingRate,
}) => {
  const dispatch = useDispatch();

  const [cities, setCities] = useState([]);

  const { modal, formLoading, error, form, userInfo } = useSelector(
    ({ succeedingRateReducer, userLoginReducer }) => ({
      modal: succeedingRateReducer.modal,
      formLoading: succeedingRateReducer.formLoading,
      error: succeedingRateReducer.error,
      form: succeedingRateReducer.form,
      userInfo: userLoginReducer.userInfo,
    }),
    isEqual
  );

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  const schema = changeSucceedingRate
    ? yup.object().shape({
        amount: yup
          .number()
          .required("Amount is required")
          .typeError("Amount must be a valid number"),
        based_delivery_fee: yup
          .number()
          .required("Based delivery fee is required")
          .typeError("Based delivery fee must be a valid number"),
      })
    : yup.object().shape({
        amount: yup
          .number()
          .required("Amount is required")
          .typeError("Amount must be a valid number"),
        province:
          userInfo.role === "operator_admin" ? "" : yup.string().required(),
        city: yup.string().required(),
        based_delivery_fee: yup
          .number()
          .required("Based delivery fee is required")
          .typeError("Based delivery fee must be a valid number"),
      });

  let defaultValues = changeSucceedingRate
    ? { amount: "", based_delivery_fee: "" }
    : {
        amount: "",
        province: "",
        city: "",
        based_delivery_fee: "",
      };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    dispatch(setForm({}));
    setChangeSucceedingRate(false);
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  useEffect(() => {
    try {
      if (userInfo.role === "operator_admin") {
        let partnerArea = JSON.parse(userInfo.grouped_areas);

        // align the obj keys to json geer
        let alignedKeys = partnerArea.map((city) => {
          return {
            mun_code: city,
            name: city,
          };
        });

        setCities(alignedKeys);
      }
    } catch (error) {
      console.log(">> error parsing grouped areas");
    }
  }, [userInfo.grouped_areas, userInfo.role]);

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setCities(phil.getCityMunByProvince(prov_code));
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
  };

  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        const { data } = await dispatch(
          updateSucceedingRate({
            amount: values.amount,
            id: form.id,
            based_delivery_fee: values.based_delivery_fee,
          })
        );

        close();
        return openNotification("SUCCESS", data.message);
      } else {
        let province = "";
        let city = "";

        if (userInfo.role !== "operator_admin") {
          province = phil.provinces.filter(
            (province) => province.prov_code === values.province
          )[0];
          city = phil.city_mun.filter(
            (city) => city.mun_code === values.city
          )[0].name;

          province = province.name;
        } else {
          city = values.city;
          province = userInfo.province;
        }

        const newData = {
          province,
          city,
          amount: values.amount,
          based_delivery_fee: values.based_delivery_fee,
          partner_id: userInfo.role === "operator_admin" ? userInfo.id : null,
        };

        const { data } = await dispatch(create(newData));
        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("Create Succeeding rate error:>>", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  return (
    <Modal
      title={hasForm ? "Update" : "Add New"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
      >
        {changeSucceedingRate ? (
          <>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Based delivery fee",
                  name: "based_delivery_fee",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Succeeding rate amount",
                  name: "amount",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
          </>
        ) : (
          <>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Based delivery fee",
                  name: "based_delivery_fee",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Succeeding rate amount",
                  name: "amount",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {userInfo.role !== "operator_admin" && (
                <Col className="gutter-row" span={24}>
                  {renderInput(
                    {
                      label: "Province",
                      name: "province",
                      errors: errors,
                      required: "true",
                      type: "select",
                      options: phil.provinces,
                      valueKey: "prov_code",
                      valueText: "name",
                      onChangeOutside: onChangeProvince,
                    },
                    control
                  )}
                </Col>
              )}

              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "City",
                    name: "city",
                    errors: errors,
                    required: "true",
                    type: "select",
                    options: cities,
                    valueKey: "mun_code",
                    valueText: "name",
                    onChangeOutside: onChangeCity,
                  },
                  control
                )}
              </Col>
            </Row>
          </>
        )}

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={formLoading}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SucceedingRateModal;
