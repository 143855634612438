import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  Layout,
  Switch,
  Input,
  Button,
  Tooltip,
  Select,
  Modal,
  Popconfirm,
  DatePicker,
  Form,
  Descriptions,
  message,
  Table,
  Popover,
  Space,
} from "antd";
import dayjs from "dayjs";
import Icon from "@mdi/react";
import { mdiMotorbike } from "@mdi/js";

import { CheckSquareOutlined, CloseSquareOutlined } from "@ant-design/icons";
import moment from "moment-timezone";

// Components
import Wrapper from "../components/wrapper";
import ServerSideTable from "../components/table/serverSideTable";

// Actions
import {
  fetchBookings,
  updateOrderStatus,
  cancelBooking,
  franchisee,
  toggleAlreadyAcceptedValue,
  availableRiders,
} from "../redux/actions/bookings";

import { reportAccount } from "../redux/actions/riderInMyArea";
import { Link } from "react-router-dom";

import { InfoCircleOutlined } from "@ant-design/icons";
import { FaMotorcycle } from "react-icons/fa";

const { Content: LayoutContent } = Layout;
const { Search } = Input;

const Bookings = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const [searchText, setSearchText] = useState("");
  const [delaySearchText, setDelaySearchText] = useState("");
  const [status, setStatus] = useState("pending");
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [merchantDetail, setMerchantDetail] = useState("");
  const [update, setUpdate] = useState(false);

  const [customerModal, setCustomerModal] = useState(false);
  const [customerDetail, setCustomerDetail] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [toSelectedDate, setToSelectedDate] = useState(null);
  const [toformattedDate, setToFormattedDate] = useState(null);
  const [availableRidersModal, setAvailableRidersModal] = useState(false);
  const [riderDetail, setRiderDetail] = useState("");
  const [openPopoverKey, setOpenPopoverKey] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const {
    loading,
    bookingsData,
    totalRecord,
    nextPage,
    previousPage,
    currentPage,
    userInfo,
    alreadyAccepted,
    showAvailableRidersData,
    availableRidersLoading,
    reportLoading,
  } = useSelector(
    ({ bookingsReducer, userLoginReducer, riderReducer }) => ({
      userInfo: userLoginReducer.userInfo,
      bookingsData: bookingsReducer.data,
      totalRecord: bookingsReducer.totalRecord,
      nextPage: bookingsReducer.nextPage,
      previousPage: bookingsReducer.previousPage,
      currentPage: bookingsReducer.currentPage,
      loading: bookingsReducer.loading,
      alreadyAccepted: bookingsReducer.alreadyAccepted,
      showAvailableRidersData: bookingsReducer.showAvailableRidersData,
      availableRidersLoading: bookingsReducer.availableRidersLoading,
      reportLoading: riderReducer.reportLoading,
    }),
    isEqual
  );
  const handleOpenChange = (record, newOpen) => {
    setOpenPopoverKey(newOpen ? record.user_id : null); // Use user_id as the key for each row
  };

  const handleClose = () => {
    setOpenPopoverKey(null);
  };

  const handleReport = async (record) => {
    const inputValue = inputValues[record.user_id] || "";
    if (!inputValue) {
      message.error("Please enter a value before reporting!");
      return;
    }

    const newData = {
      reason: inputValue,
      partner_id: record.partners_id,
      rider_id: record.user_id,
    };
    const { data } = await dispatch(reportAccount(newData));

    if (data) {
      message.success(
        `Report submitted for ${record.fullName} with input: ${inputValue}`
      );
      setInputValues((prev) => ({ ...prev, [record.user_id]: "" }));
      setOpenPopoverKey(null);
    }
  };

  const content = (record) => (
    <Space direction="vertical" style={{ width: 200 }}>
      <Input.TextArea
        placeholder="Enter reason"
        value={inputValues[record.user_id] || ""}
        onChange={(e) =>
          setInputValues((prev) => ({
            ...prev,
            [record.user_id]: e.target.value,
          }))
        }
        autoSize={{ minRows: 2, maxRows: 2 }}
      />
      <Space direction="horizontal">
        <Button type="default" onClick={handleClose} size="small">
          Close
        </Button>
        <Button
          type="primary"
          onClick={() => handleReport(record)}
          size="small"
          loading={reportLoading}
        >
          Report
        </Button>
      </Space>
    </Space>
  );

  const info = useCallback(() => {
    messageApi.open({
      type: "warning",
      content: "This order is already accepted!",
    });
  }, []);

  useEffect(() => {
    if (alreadyAccepted) {
      info();
      dispatch(toggleAlreadyAcceptedValue(false));
    }
  }, [alreadyAccepted, dispatch, info]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelaySearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    const formatDate = (date) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : undefined;
    };
    const i = {
      page: 1,
      limit: 15,
      status,
    };
    const payload = {
      page: 1,
      limit: 15,
      searchText: delaySearchText,
      status,
      dateFilter:
        formatDate(selectedDate) === "Invalid Date"
          ? undefined
          : formatDate(selectedDate) + " " + "00:00:00",
      todateFilter:
        formatDate(toSelectedDate) === "Invalid Date"
          ? undefined
          : formatDate(toSelectedDate) + " " + "23:59:59",
    };
    if (formatDate(selectedDate) !== undefined) {
      dispatch(fetchBookings(payload));
    } else {
      dispatch(fetchBookings(i));
    }
  }, [delaySearchText, toSelectedDate, selectedDate, dispatch, status]);

  const onSearch = () => {
    const payload = {
      page: 1,
      limit: 15,
      searchText,
      status,
    };
    dispatch(fetchBookings(payload));
  };

  const handleChange = (value) => {
    setStatus(value);
  };

  const handleAutoRefreshChange = (checked) => {
    setAutoRefresh(checked);
  };

  useEffect(() => {
    if (autoRefresh) {
      const refreshInterval = setInterval(() => {
        const payload = {
          page: 1,
          limit: 15,
          searchText: delaySearchText,
          status,
        };
        dispatch(fetchBookings(payload));
      }, 30000); // 30 seconds

      return () => {
        clearInterval(refreshInterval);
      };
    }
  }, [autoRefresh, delaySearchText, dispatch, status]);

  useEffect(() => {
    if (riderDetail) {
      const payload = {
        data: riderDetail.data,
        orderNumber: riderDetail.order_number,
      };
      dispatch(availableRiders(payload));
    }
  }, [riderDetail, dispatch]);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      setSelectedDate(dates[0]);

      setToSelectedDate(dates[1]);
    } else {
      setSelectedDate(null);
      setToSelectedDate(null);
    }
  };

  const customFilters = () => {
    return (
      <div className="mb-2">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <h1>Bookings</h1> */}
          <div>
            <span style={{ marginRight: "10px" }}>Auto-Refresh</span>
            <Switch
              defaultChecked={autoRefresh}
              onChange={handleAutoRefreshChange}
            />
          </div>
          <Select
            defaultValue="pending"
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: "pending",
                label: "Pending",
              },
              {
                value: "preparing",
                label: "Preparing",
              },
              {
                value: "pickup",
                label: "Picked up",
              },
              {
                value: "delivered",
                label: "Delivered",
              },
              {
                value: "undelivered",
                label: "Undelivered",
              },
              {
                value: "cancelled",
                label: "Cancelled",
              },
              {
                value: "toConfirm",
                label: "To Confirm",
              },
            ]}
          />
        </div>
        <div className="p-2 flex " style={{ backgroundColor: "#fafafa" }}>
          <Form layout="inline">
            <Form.Item label="Search">
              <Search
                placeholder="input search text"
                allowClear
                style={{ width: "15VW" }}
                value={searchText}
                type="secondary"
                size="medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onSearch={onSearch}
              />
            </Form.Item>
          </Form>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            {/* <Form.Item label="From:">
              <DatePicker
                style={{ width: "15vw" }}
                value={selectedDate}
                onChange={(date, dateString) => {
                  setSelectedDate(date);
                  setFormattedDate(
                    dayjs(dateString)
                      .startOf("day")
                      .format("YYYY-MM-DD HH:mm:ss")
                  );
                }}
                placeholder="Select Date"
              />
            </Form.Item>
            <Form.Item label="To:">
              <DatePicker
                style={{ width: "15vw" }}
                value={toSelectedDate}
                onChange={(date, dateString) => {
                  setToSelectedDate(date);
                  setToFormattedDate(
                    dayjs(dateString).endOf("day").format("YYYY-MM-DD HH:mm:ss")
                  );
                }}
                placeholder="Select Date"
              />
            </Form.Item> */}

            <RangePicker
              style={{ width: "30vw" }}
              value={[selectedDate, toSelectedDate]}
              onChange={handleDateChange}
              placeholder={["Start Date", "End Date"]}
              format="YYYY-MM-DD"
            />
          </div>
        </div>
      </div>
    );
  };

  const defaultPage = useMemo(() => {
    return {
      page: 1,
      limit: 15,
      status,
    };
  }, [status]);

  const onClickNextPage = () => {
    dispatch(
      fetchBookings({
        ...nextPage,
      })
    );
  };

  const onClickPreviousPage = () => {
    dispatch(
      fetchBookings({
        ...previousPage,
      })
    );
  };

  const onClickFirstPage = () => {
    dispatch(
      fetchBookings({
        ...defaultPage,
      })
    );
  };

  const onClickLastPage = () => {
    dispatch(
      fetchBookings({
        page: Math.ceil(totalRecord / 15),
        limit: 15,
        status,
      })
    );
  };

  const handleCancel = async (payload) => {
    dispatch(cancelBooking(payload));
    setUpdate((prevUpdate) => !prevUpdate);
  };
  const handleFranchiSee = async (payload) => {
    dispatch(franchisee(payload));
    setUpdate((prevUpdate) => !prevUpdate);
  };

  const formatDate = (date) => {
    const dateString = date;
    const formattedDate = moment.utc(dateString).format("MMM D, YYYY hh:mm A");
    return formattedDate;
  };

  const calculateTimePassed = (createdAt) => {
    const pHlocalTimezone = moment()
      .tz("Asia/Manila")
      .format("YYYY-MM-DD HH:mm:ss");
    const createdTime = moment.utc(createdAt).format("YYYY-MM-DD HH:mm:ss");

    const duration = moment.duration(
      moment(pHlocalTimezone).diff(moment(createdTime))
    );

    const days = duration.asDays();
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (days >= 1) {
      return `${Math.floor(days)} ${
        Math.floor(days) === 1 ? "day" : "days"
      } ago`;
    } else if (hours >= 1) {
      return `${Math.floor(hours)} ${
        Math.floor(hours) === 1 ? "hour" : "hours"
      } ago`;
    } else {
      return `${Math.floor(minutes)} ${
        Math.floor(minutes) === 1 ? "minute" : "minutes"
      } ago`;
    }
  };

  const columns = useMemo(() => {
    const commonColumns = [
      {
        Header: "Order number",
        accessor: "order_number",
      },
      {
        Header: "Customer name",
        // accessor: (row) => `${row.first_name} ${row.last_name}`,
        accessor: "fullName",
      },
      {
        Header: "Address",
        accessor: (row) =>
          row.municipality !== null
            ? `${row.municipality} ${row.province}`
            : "---",
      },
      {
        Header: "Total amount",
        accessor: "total_price",
      },
      {
        Header: "Delivery fee",
        accessor: "estimated_shipping_fee",
      },
      {
        Header: "Date-time",
        accessor: (row) => formatDate(row.created_at),
      },
      {
        Header: "Time passed",
        accessor: (row) => calculateTimePassed(row.created_at),
      },
      {
        Header: "Type",
        accessor: (row) => {
          let type = "PABILI/PADALA";

          if (row.partner_merchants_id && !row.partner_stores_id) {
            type = "MERCHANT";
          } else if (!row.partner_merchants_id && row.partner_stores_id) {
            type = "GROCERY";
          }

          return type;
        },
      },
      {
        Header: "Status",
        accessor: "status",
      },
      { Header: "Total Orders", accessor: "total_count_delivered" },
    ];

    if (
      status === "pickup" ||
      status === "pending" ||
      status === "preparing" ||
      status === "delivered" ||
      status === "undelivered" ||
      status === "cancelled"
    ) {
      const additionalColumns = [];

      const isAcceptedByVisible = [
        "preparing",
        "delivered",
        "undelivered",
      ].includes(status)
        ? [
            {
              Header: "Accepted by",
              accessor: (row) =>
                `${row.operator_firstName} ${row.operator_lastName}`,
            },
          ]
        : [];
      return [
        {
          Header: "Actions",
          Cell: ({ row: { original } }) => (
            <>
              {status === "pending" && (
                <>
                  <Link
                    to={`/booking-available-riders/${original.delivery_address_id}`}
                  >
                    {/* <Button disabled={loading} size="small" type="text">
                      <Tooltip title="Riders">
                        <span className="anticon anticon-check-square">
                          <Icon path={mdiMotorbike} size={1} />
                        </span>
                      </Tooltip>
                    </Button> */}
                  </Link>
                  <Popconfirm
                    title="Cancel this booking?"
                    description="Are you sure you want to cancel this booking?"
                    onConfirm={() => {
                      handleCancel(original);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button disabled={loading} size="small" type="text">
                      <Tooltip title="Cancel">
                        <CloseSquareOutlined />
                      </Tooltip>
                    </Button>
                  </Popconfirm>
                </>
              )}
              <Button
                disabled={loading}
                size="small"
                type="text"
                onClick={() => {
                  setCustomerModal(true);
                  setCustomerDetail(original);
                }}
              >
                <Tooltip title="Display Booking details">
                  <InfoCircleOutlined />
                </Tooltip>
              </Button>

              {userInfo.role === "admin" && (
                <>
                  <Button
                    disabled={loading}
                    size="small"
                    style={{ marginBottom: -38 }}
                    type="text"
                    onClick={() => {
                      setAvailableRidersModal(true);
                      setRiderDetail(original);
                    }}
                  >
                    <Tooltip title="Show Available Riders">
                      <FaMotorcycle className="mt-3" />
                    </Tooltip>
                  </Button>
                </>
              )}
              {/* {userInfo.role === "operator_admin" && (
                <>
                  <Popconfirm
                    title="Prepare the item"
                    description="Are you sure you want to prepare this item?"
                    onConfirm={() =>
                      handleFranchiSee({
                        order_number: original.order_number,
                        id: userInfo.id,
                        status: "preparing",
                        city: userInfo.city,
                        province: userInfo.province,
                      })
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{ marginLeft: 5 }}
                      disabled={status !== "pending"}
                      size="small"
                    >
                      Prepare
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title="Undeliver the item"
                    description="Are you sure you want to Undeliver this item?"
                    onConfirm={() =>
                      handleFranchiSee({
                        order_number: original.order_number,
                        id: userInfo.id,
                        status: "undelivered",
                        city: userInfo.city,
                        province: userInfo.province,
                      })
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{ marginLeft: 5 }}
                      disabled={
                        status === "undelivered" ||
                        status === "delivered" ||
                        status === "cancelled" ||
                        status === "pending"
                      }
                      size="small"
                    >
                      Undeliver
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title="Deliver the item"
                    description="Are you sure you want to Deliver this item?"
                    onConfirm={() =>
                      handleFranchiSee({
                        order_number: original.order_number,
                        id: userInfo.id,
                        status: "delivered",
                        city: userInfo.city,
                        province: userInfo.province,
                      })
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{ marginLeft: 5 }}
                      disabled={
                        status === "delivered" ||
                        status === "undelivered" ||
                        status === "cancelled" ||
                        status === "pending"
                      }
                      size="small"
                    >
                      Deliver
                    </Button>
                  </Popconfirm>
                </>
              )} */}
            </>
          ),
          width: 100,
        },
        ...commonColumns,
        ...additionalColumns,
      ];
    } else if (status === "toConfirm") {
      return [
        {
          Header: "Actions",
          Cell: ({ row: { original } }) => (
            <>
              <Button
                disabled={loading}
                size="small"
                type="text"
                onClick={() => {
                  setModalDetail(true);
                  setMerchantDetail(original);
                }}
              >
                <Tooltip title="Display Merchant details">
                  <InfoCircleOutlined />
                </Tooltip>
              </Button>
              <Button
                disabled={loading}
                onClick={() => {
                  confirmMerchantOrderStatus(original);
                }}
                size="small"
                type="text"
              >
                <Tooltip title="Approve">
                  <CheckSquareOutlined />
                </Tooltip>
              </Button>
            </>
          ),
          width: 100,
        },
        ...commonColumns,
      ];
    } else {
      return commonColumns;
    }
  }, [status, loading]);

  // useEffect(() => {
  //   dispatch(fetchBookings(defaultPage));
  // }, [dispatch, defaultPage, update]);

  const confirmMerchantOrderStatus = (payload) => {
    dispatch(updateOrderStatus(payload));
    setUpdate((prevUpdate) => !prevUpdate);
  };

  const renderContent = () => {
    return (
      <LayoutContent>
        {contextHolder}
        <ServerSideTable
          columns={columns}
          data={bookingsData}
          loading={loading}
          serverSidePagination={{
            totalRecord,
            onClickNextPage,
            onClickPreviousPage,
            currentPage,
            nextPage,
            previousPage,
            onClickFirstPage,
            onClickLastPage,
          }}
          customFilters={customFilters}
          dateFilters={{ selectedDate }}
        />
      </LayoutContent>
    );
  };

  const renderMerchantItem = (data) => {
    try {
      const parsedData = JSON.parse(data?.data);

      if (Array.isArray(parsedData)) {
        return (
          <>
            {/* <p>
              <span style={{ fontWeight: "bold" }}> Merchant Name: </span>
              {customerDetail.merchant_name}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Merchant Number: </span>
              {customerDetail.merchant_mobile_number}
            </p> */}
            {/* <span style={{ fontWeight: "bold" }}>Order: </span> */}
            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              {parsedData.map((d, index) => (
                <>
                  <li key={index}>
                    {d.title} - x{d.quantity}
                  </li>
                </>
              ))}
            </ul>

            <span style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              {parsedData.map((d, index) => (
                <>
                  {d.note !== undefined && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 5,
                        marginTop: -10,
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        Additional Instruction:
                      </span>
                      <p>Note : {d.note}</p>
                    </div>
                  )}
                </>
              ))}
            </span>
          </>
        );
      } else {
        return (
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>Note: </span>
              {data?.data}
            </p>
          </div>
        );
      }
    } catch (error) {
      return (
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>Note: </span>
            {data?.data}
          </p>
        </div>
      );
    }
  };

  const renderStoreItem = (data) => {
    try {
      const parsedData = JSON.parse(data?.data);

      if (Array.isArray(parsedData.GROCERIA)) {
        return (
          <>
            {/* <span style={{ fontWeight: "bold" }}>Order: </span> */}
            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              {parsedData.GROCERIA.map((d, index) => (
                <li key={index}>
                  {d.product_name} - x{d.quantity}
                </li>
              ))}
            </ul>
          </>
        );
      }
    } catch (error) {
      console.log(">> error parsing fails:", error);
    }
  };

  const renderNoPartner = (data) => {
    try {
      const parsedData = JSON.parse(data?.data);

      if (parsedData && typeof parsedData === "object") {
        if (Array.isArray(parsedData.GROCERIA)) {
          return (
            <>
              {/* <span style={{ fontWeight: "bold" }}>Order: </span> */}
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                {parsedData.GROCERIA.map((d, index) => (
                  <li key={index}>
                    {d.product_name} - x{d.quantity}
                  </li>
                ))}
              </ul>
            </>
          );
        } else {
          return (
            <>
              <p>
                <span style={{ fontWeight: "bold" }}>Note: </span>
                {parsedData.note}
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>pickup: </span>
                {parsedData.pickupLocation.locationText}
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>drop off: </span>
                {parsedData.dropOffLocation.locationText}
              </p>
            </>
          );
        }
      }
    } catch (error) {
      return (
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>Note: </span>
            {data?.data}
          </p>
        </div>
      );
    }
  };

  const columnsAvailableRider = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contactNumber",
    },
    {
      title: "Valid Reports",
      dataIndex: "valid_reports_count",
      key: "validReports",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Popover
          content={content(record)}
          title="Reason"
          trigger="click"
          open={openPopoverKey === record.user_id}
          onOpenChange={(newOpen) => handleOpenChange(record, newOpen)}
        >
          <Button type="primary" size="small" danger>
            Report
          </Button>
        </Popover>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Order Number",
      children: customerDetail?.order_number,
    },
    {
      key: "2",
      label: "Customer Name",
      children:
        customerDetail?.first_name !== null
          ? `${customerDetail?.first_name} ${customerDetail?.last_name}`
          : `${customerDetail?.fullName}`,
    },

    {
      key: "3",
      label: "Customer Number",
      children:
        customerDetail?.contact_number === null
          ? "N/A"
          : customerDetail?.contact_number,
      span: 2,
    },
    {
      key: "4",
      label: "Customer Address",
      children:
        customerDetail?.province === null ? (
          `Please check the pick-up and drop-off locations for the Padala Pabili order.`
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 5,
            }}
          >
            <div style={{ fontWeight: "bold" }}>
              {customerDetail?.municipality} {customerDetail?.province}{" "}
            </div>

            <div>{customerDetail?.complete_address}</div>
          </div>
        ),
    },

    {
      key: "8",
      label: "Merchant Name",
      children:
        customerDetail.merchant_name === null
          ? "N/A"
          : customerDetail.merchant_name,
    },
    {
      key: "9",
      label: "Merchant Number",
      children:
        customerDetail.merchant_mobile_number === null
          ? "N/A"
          : customerDetail.merchant_mobile_number,
    },
    {
      key: "10",
      label: "Bookings",
      children: customerDetail?.partner_merchants_id
        ? renderMerchantItem(customerDetail)
        : customerDetail?.partner_stores_id
        ? renderStoreItem(customerDetail)
        : customerDetail?.partner_merchants_id === null &&
          customerDetail?.partner_stores_id === null &&
          renderNoPartner(customerDetail),
    },
    {
      key: "6",
      label: "Rider:",
      children:
        customerDetail?.operator_firstName !== null
          ? customerDetail?.operator_firstName +
            " " +
            customerDetail?.operator_lastName
          : customerDetail.user_name,
    },
    {
      key: "5",
      label: "Total Item Price",
      children: customerDetail?.total_price,
    },

    {
      key: "7",
      label: "Delivery Fee",
      children: Number(customerDetail?.estimated_shipping_fee),
    },

    {
      key: "9",
      label: "Applied Voucher",
      children:
        customerDetail.voucher_amount === "0.00"
          ? "N/A"
          : customerDetail.voucher_amount,
    },

    {
      key: "10",
      label: "Distance",
      children:
        customerDetail.distance === null
          ? "N/A"
          : `${customerDetail.distance} km`,
    },
    userInfo.role === "admin" && {
      key: "11",
      label: "Partner username",
      children: customerDetail.partner_username,
    },
    userInfo.role === "admin" && {
      key: "12",
      label: "Partner contact number",
      children: customerDetail.partner_contact_number,
    },
  ];
  return (
    <>
      <Wrapper
        content={renderContent()}
        modal={
          <>
            {/* toConfirmModal */}
            <Modal
              title="Merchant details"
              open={modalDetail}
              onOk={() => {
                setModalDetail(false);
              }}
              onCancel={() => {
                setModalDetail(false);
              }}
            >
              <p>
                <span style={{ fontWeight: "bold" }}> Merchant Name: </span>
                {merchantDetail.merchant_name}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Contact Number: </span>
                {merchantDetail.merchant_mobile_number}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Customer Contact Number:
                </span>
                {merchantDetail.contact_number}
              </p>
              <ul>
                <span style={{ fontWeight: "bold" }}>Orders</span>
                {merchantDetail !== "" &&
                  merchantDetail.data &&
                  (() => {
                    try {
                      const jsonData = JSON.parse(merchantDetail.data);

                      if (Array.isArray(jsonData)) {
                        // merchantDetail.data is valid JSON and an array
                        return (
                          <ul>
                            {jsonData.map((d, index) => (
                              <li key={index}>{d.title}</li>
                            ))}
                          </ul>
                        );
                      }
                    } catch (error) {
                      return (
                        <div>
                          <p>
                            <span style={{ fontWeight: "bold" }}>Note: </span>
                            {merchantDetail.data}
                          </p>
                        </div>
                      );
                    }
                  })()}
              </ul>
            </Modal>
            <Modal
              open={customerModal}
              onOk={() => {
                setCustomerModal(false);
              }}
              onCancel={() => {
                setCustomerModal(false);
              }}
            >
              <>
                <Descriptions
                  title="BOOKING DETAILS"
                  bordered
                  items={items}
                  size={"medium"}
                  column={1}
                />
              </>
              {/* <p>
                <span style={{ fontWeight: "bold" }}> Order Number: </span>
                {customerDetail?.order_number}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}> Customer Name: </span>
               
                {customerDetail?.first_name !== null
                  ? `${customerDetail?.first_name} ${customerDetail?.last_name}`
                  : `${customerDetail?.fullName}`}
              </p> */}

              {/* <p>
                <span style={{ fontWeight: "bold" }}> Customer Number: </span>
                {customerDetail?.contact_number}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}> Customer Address: </span>
  
                {customerDetail?.province === null
                  ? `Please check the pick-up and drop-off locations for the Padala Pabili order.`
                  : `${customerDetail?.barangay} ${customerDetail?.municipality} ${customerDetail?.province}`}
              </p> */}

              {/* <p>
                <span style={{ fontWeight: "bold" }}> Total price: </span>
                {customerDetail?.total_price}
              </p> */}

              {/* {customerDetail?.operator_firstName !== null && (
                <p>
                  <span style={{ fontWeight: "bold" }}>Rider: </span>
                  {`${customerDetail?.operator_firstName} ${customerDetail?.operator_lastName}`}
                </p>
              )} */}

              {/* <p>
                {customerDetail?.partner_merchants_id &&
                  renderMerchantItem(customerDetail)}
              </p> */}
              {/* <p>
                {customerDetail?.partner_stores_id &&
                  renderStoreItem(customerDetail)}
              </p>

              <p>
                {customerDetail?.partner_merchants_id === null &&
                  customerDetail?.partner_stores_id === null &&
                  renderNoPartner(customerDetail)}
              </p> */}
            </Modal>
            <Modal
              open={availableRidersModal}
              onOk={() => setAvailableRidersModal(false)}
              onCancel={() => setAvailableRidersModal(false)}
            >
              <div>
                <h3>AVAILABLE RIDERS</h3>
                {availableRidersLoading ? (
                  <div>Loading available riders, please wait...</div>
                ) : showAvailableRidersData.length === 0 ? (
                  <div className="text-center mt-8">
                    - No active rider available -
                  </div>
                ) : (
                  <Table
                    columns={columnsAvailableRider}
                    dataSource={showAvailableRidersData}
                    bordered
                    pagination={false}
                  />
                )}
              </div>
            </Modal>
          </>
        }
      />
    </>
  );
};

export default Bookings;
