import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { isEqual, omit } from "lodash";
import { diff as ObjectDiff } from "deep-object-diff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import phil from "../../external_node_modules/philippine-location-json-for-geer";

import { Form, Row, Col, Button, Modal, notification, Alert } from "antd";

import {
  toggleModal,
  create,
  setForm,
  update,
} from "../../redux/actions/manageOperaotrs";

// Utilities
import renderInput from "../utilities/inputForms";
import { getRegionByProvince } from "../utilities/getPhillipineMajorIslands";

const OperatorAccountModal = () => {
  const dispatch = useDispatch();

  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [isProvinceEdited, setIsProvinceEdited] = useState(false);
  const [isCityEdited, setIsCityEdited] = useState(false);
  const [isBarangayEdited, setIsBarangayEdited] = useState(false);
  const { modal, loading, error, form, createLoading, userInfo } = useSelector(
    ({ manageOperatorReducer, userLoginReducer }) => ({
      modal: manageOperatorReducer.modal,
      loading: manageOperatorReducer.loading,
      error: manageOperatorReducer.error,
      createLoading: manageOperatorReducer.createLoading,
      userInfo: userLoginReducer.userInfo,
      form: manageOperatorReducer.form,
    }),
    isEqual
  );

  const hasForm = form ? Object.keys(form).length !== 0 : false;

  const schema = yup.object().shape({
    first_name: yup.string().required("Name is required field"),
    rider_comission: yup.string().required("Rider Comission is required field"),
    last_name: yup.string().required("Name is required field"),
    username: hasForm
      ? ""
      : yup.string().required("username is required field"),
    contact_number: yup
      .string()
      .required("Contact Number is required field")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .min(11, "too short")
      .max(11, "too long"),
    province: yup.string().required(),
    city: yup.string().required(),
    barangay: yup.string().required(),
    password: hasForm
      ? ""
      : yup.string().required("Password is required field"),
    dob: hasForm
      ? ""
      : yup
          .date()
          .required("Date of Birth is a required field")
          .max(new Date(), "Date of Birth cannot be in the future"),
  });

  let defaultValues = {
    first_name: "",
    last_name: "",
    province: "",
    city: "",
    barangay: "",
    contact_number: "",
    password: "",
    dob: "",
    username: "",
    rider_comission: "",
  };

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (hasForm) {
      const cities = phil.getCityMunByProvince(Number(userInfo.prov_code));

      if (cities.length > 0) {
        setCities(cities);
      }
    }
  }, [hasForm, userInfo]);

  const close = () => {
    reset(defaultValues);
    dispatch(toggleModal(false));
    // dispatch(setForm({}));
    setIsProvinceEdited(false);
    // setChangePassword(false);
    setIsCityEdited(false);
    setIsBarangayEdited(false);
    dispatch(setForm({}));
  };

  const openNotification = (title, message) => {
    notification[title.toLowerCase()]({
      message: title,
      description: message,
      duration: 7,
    });
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
    setIsProvinceEdited(true);
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    setBarangays(phil.getBarangayByMun(mun_code));
    setIsCityEdited(true);
  };

  const onSubmit = async (values) => {
    try {
      if (hasForm) {
        let city = values.city;
        let barangay = values.barangay;

        if (Number(city) || Number(barangay)) {
          if (Number(city)) {
            city = await phil.city_mun.filter(
              (city) => city.mun_code === values.city
            )[0].name;
          }

          if (Number(barangay)) {
            barangay = await phil.barangays.filter(
              (barangay) => barangay.brgy_code === values.barangay
            )[0].name;
          }
        }

        const updatedValues = {
          // tbl_user_profile
          first_name: values.first_name,
          middle_name: "",
          last_name: values.last_name,
          contact_number: values.contact_number,

          // tbl_user_delivery_address && tbl_operator_address
          province: values.province,
          city: city,
          barangay: barangay,
          address: `${values.province} ${city} ${barangay}`,

          comission: values.rider_comission,
          user_id: form.user_id,
        };

        const { data } = await dispatch(update(updatedValues));

        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }

        // update
      } else {
        const province = phil.provinces.filter(
          (province) => province.prov_code === values.province
        )[0];
        const city = phil.city_mun.filter(
          (city) => city.mun_code === values.city
        )[0].name;
        const barangay = phil.barangays.filter(
          (barangay) => barangay.brgy_code === values.barangay
        )[0].name;

        const createData = {
          first_name: values.first_name,
          middle_name: "",
          last_name: values.last_name,
          date_of_birth: values.dob,
          contact_number: values.contact_number,
          user_name: values.username,
          user_password: values.password,
          role: userInfo.role === "operator_admin" ? "rider" : "operator",
          province: province.name,
          city: city,
          barangay: barangay,
          address: `${province.name} ${city} ${barangay}`,
          completeAddress: `${province.name} ${city} ${barangay}`,
          referral_code: "",
          partners_id: userInfo.role === "operator_admin" ? userInfo.id : null,
          is_verified: userInfo.role === "operator_admin" ? true : false,
          comission: values.rider_comission,
        };

        const { data } = await dispatch(create(createData));

        if (data) {
          openNotification("SUCCESS", data.message);
          close();
        }
      }
    } catch (error) {
      console.log("Create partner merchant error:>>", error);
    }
  };

  useEffect(() => {
    if (error) openNotification("ERROR", error.message);
  }, [error]);

  useEffect(() => {
    if (form) reset(form);
  }, [form, reset]);

  return (
    <Modal
      title={hasForm ? "Update Rider" : "Add Rider"}
      open={modal}
      width={450}
      footer={null}
      onCancel={() => close()}
    >
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "First Name",
                name: "first_name",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>

          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Last Name",
                name: "last_name",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>

          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Contact Number",
                name: "contact_number",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>

          {!hasForm && (
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  type: "date",
                  label: "Date of birth",
                  name: "dob",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
          )}

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Province",
                  name: "province",
                  errors: errors,
                  required: "true",
                  type: "select",
                  options: phil.provinces,
                  valueKey: "prov_code",
                  valueText: "name",
                  onChangeOutside: onChangeProvince,
                  disabled: hasForm,
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "City",
                  name: "city",
                  errors: errors,
                  required: "true",
                  type: "select",
                  options: cities,
                  valueKey: "mun_code",
                  valueText: "name",
                  onChangeOutside: onChangeCity,
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Barangay",
                  name: "barangay",
                  errors: errors,
                  required: "true",
                  type: "select",
                  options: barangays,
                  valueKey: "brgy_code",
                  valueText: "name",
                  onChangeOutside: () => setIsBarangayEdited(true),
                },
                control
              )}
            </Col>

            {!hasForm && (
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Username",
                    name: "username",
                    errors: errors,
                    required: "true",
                  },
                  control
                )}
              </Col>
            )}

            {!hasForm && (
              <Col className="gutter-row" span={24}>
                {renderInput(
                  {
                    label: "Password",
                    name: "password",
                    errors: errors,
                    required: "true",
                    type: "password",
                  },
                  control
                )}
              </Col>
            )}

            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Rider Commission (%)",
                  name: "rider_comission",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            {/* <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Referral code",
                  name: "referralCode",
                  errors: errors,
                  required: "false",
                  placeholder: "optional",
                },
                control
              )}
            </Col> */}
          </Row>
        </>

        <div className="text-right mt-5">
          <Button
            key="back"
            style={{ marginRight: "12px" }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" loading={createLoading}>
            {hasForm ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default OperatorAccountModal;
