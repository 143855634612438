import axiosInstance from "../../helpers/axios.js";
import actions from "../actionTypes.js";
const { AFFILIATES } = actions;

export const fetchReferredAffiliates =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: AFFILIATES.FETCH.REQUEST });

    const {
      userLoginReducer: { userInfo },
    } = getState();
    let { token } = userInfo;
    let { id } = userInfo;

    try {
      let url = `/api/affiliate/referred-affiliate`;

      if (payload !== undefined) {
        const { page, limit, searchText } = payload;
        url = `/api/affiliate/referred-affiliate?partner_id=${id}&page=${page}&limit=${limit}`;
        if (page && limit && searchText) {
          url = `/api/affiliate/referred-affiliate?partner_id=${id}&page=${page}&limit=${limit}&searchText=${searchText}`;
        }
      }

      const response = await axiosInstance(token).get(url);

      dispatch({
        type: AFFILIATES.FETCH.SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: AFFILIATES.FETCH.FAILURE,
        payload: error.response ? error.response.data : error.message,
      });
    }
  };

export const fetchAreaAffiliates = (payload) => async (dispatch, getState) => {
  dispatch({ type: AFFILIATES.FETCHAREAAFFILIATE.REQUEST });

  const {
    userLoginReducer: { userInfo },
  } = getState();
  let { token } = userInfo;
  let { id } = userInfo;

  try {
    let url = `/api/affiliate/referred-affiliate`;

    if (payload !== undefined) {
      const { page, limit, searchText } = payload;
      url = `/api/affiliate/area-affiliate?partner_id=${id}&page=${page}&limit=${limit}`;
      if (page && limit && searchText) {
        url = `/api/affiliate/area-affiliate?partner_id=${id}&page=${page}&limit=${limit}&searchText=${searchText}`;
      }
    }

    const response = await axiosInstance(token).get(url);

    dispatch({
      type: AFFILIATES.FETCHAREAAFFILIATE.SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: AFFILIATES.FETCHAREAAFFILIATE.FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
